import { css } from '@emotion/core';

const iconSize = '16px';

export const $root = css`
  display: grid;
  grid-template-columns: ${iconSize} 1fr;
  margin-bottom: 24px;
`;

export const $icon = (color) => [{
  '--angelou-svg-primary-color': color,
  width: iconSize,
  height: iconSize,
  marginTop: '4px'
}];

export const $text = css`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 8px;
`;

export const $name = ({ colors, typographyStyles }) => [
  ...typographyStyles.textM,
  {
    color: colors.ui.greyDark[500],
    margin: '0 0 4px 0'
  }
];

export const $type = ({ colors, typographyStyles }) => [
  ...typographyStyles.textS,
  {
    color: colors.ui.grey[700],
    margin: 0,
    lineHeight: '1.3 !important',
  }
];
