import React from 'react';

import { isString, isUndefined } from 'lodash';
import PropTypes from 'prop-types';

import MenuItemGroupList from '@client/common/components/MenuItemGroupList';
import * as schemas from '@client/common/schema';

export default function AttachmentsMenu ({
  editorTypes,
  onAddItem
}) {
  const getText = (editorType) => {
    return isString(editorType) ? schemas[editorType].typename : editorType.title;
  };

  const getOnclick = (editorType) => {
    return isString(editorType) ? onAddItem(editorType) : onAddItem(editorType.type, editorType.data);
  };

  const getKeyName = (editorType) => {
    return isString(editorType) ? editorType : editorType.type;
  };

  const menuItems = (items) => {
    return items?.map((item, index) => {
      return {
        text: getText(item),
        onClick: getOnclick(item),
        key: `${getKeyName(item)}:${index}`
      };
    });
  };

  /**
   * Returns an array of menu section objects, each with a heading and menu items
   * @returns {array}
   * @example return array
   * [{
   *   heading: 'Assessment',
   *   menuItems: [
   *     {
   *       text: 'Quiz',
   *       onClick: handleClick
   *     },
   *     {
   *       text: 'Write Prompt',
   *       onClick: handleClick
   *     }
   *   ]
   * },
   * {
   *   heading: 'Lesson Planning',
   *   menuItems: [
   *     {
   *       text: 'Instructional Note',
   *       onClick: handleClick
   *     },
   *     {
   *       text: 'Lesson Spark',
   *       onClick: handleClick
   *     }
   *   ]
   * },
   * ...]
   */
  const menuData = () => {
    return (editorTypes || []).reduce((acc, value, index, array) => {
      const heading = value.type || 'Lesson Planning';
      const headingExists = acc.find((item) => item?.heading === heading);
      if (!headingExists || isUndefined(headingExists)) {
        return acc.concat(
          {
            heading,
            menuItems: menuItems(array.filter((editorType) => (editorType?.type || 'Lesson Planning') === heading))
          }
        );
      } else {
        return acc;
      }
    }, []);
  };

  return (
    <MenuItemGroupList
      menuData={menuData()}
    />
  );
}

AttachmentsMenu.propTypes = {
  editorTypes: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.shape({
        type: PropTypes.string,
        title: PropTypes.string,
        data: PropTypes.object
      }),
      PropTypes.string
    ]),
  ),
  onAddItem: PropTypes.func,
};
