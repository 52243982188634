import cuid from 'cuid';
import { action, thunk, thunkOn } from 'easy-peasy';
import { get } from 'lodash-es';
import plur from 'plur';

import { apolloClient } from '@client/apollo-client';
import { queries } from '@client/common/graph';
import { VALIDATION_ERROR, VALIDATION_WARNING } from '@client/utils/constants';
import { getRootVariant, getVariant } from '@client/utils/form-validation';
import * as toast from '@client/utils/toast';

function getValidationMessage (str, count) {
  const toBe = count === 1 ? 'is' : 'are';
  return `There ${toBe} ${count} ${plur(str, count)}`;
}

async function fetchValidation ({ id, cascadeToStreams, isPublishAll }) {
  return get(await apolloClient.query({
    query: queries.contentValidity,
    variables: { id, cascadeToStreams, isPublishAll },
    fetchPolicy: 'network-only'
  }), 'data.contentValidity');
}

const scopeDataInitialState = { id: null, streams: [] };
const validityInitialState = {
  hasErrors: false,
  hasWarnings: false,
  errors: [],
  warnings: [],
  errorIds: [],
  warningIds: []
};

export default {
  // We're storing a few Form-specific things. This is merged into the main
  // store (common/store.js) under 'forms'.

  validation: {
    isEnabled: false, // Switch button state.
    enabledScope: null,
    main: scopeDataInitialState,
    drawer: scopeDataInitialState,
    validity: validityInitialState,
    variants: {},
    assessmentId: null, // We need the assessment id to filter assessment question issues.

    setIsEnabled: action((state, payload) => {
      state.isEnabled = payload;
    }),
    setEnabledScope: action((state, payload) => {
      state.enabledScope = payload;
    }),
    setAssessmentId: action((state, payload) => {
      state.assessmentId = payload;
    }),

    setData: action((state, payload) => {
      const { scope, id, streams } = payload;
      state[scope] = { id, streams };
    }),
    clearData: action((state, scope) => {
      state[scope] = { ...scopeDataInitialState };
    }),

    setValidity: action((state, payload) => {
      state.validity = { ...payload };
    }),
    clearValidity: action((state) => {
      state.validity = { ...validityInitialState };
    }),

    setVariants: action((state, payload) => {
      state.variants = { ...payload };
    }),
    addToVariants: action((state, payload) => {
      state.variants = { ...state.variants, ...payload };
    }),
    updateVariants: action((state, payload) => {
      state.variants = Object.keys(state.variants).reduce((acc, currentId) => {
        acc[currentId] = currentId === state.main.id
          ? getRootVariant(state.validity)
          : getVariant(currentId, state.validity);
        return acc;
      }, {});
    }),
    resetVariants: thunk((actions, payload, { getState }) => {
      const variants = Object.keys(getState().variants).reduce((acc, currentId) => {
        acc[currentId] = null;
        return acc;
      }, {});
      actions.setVariants(variants);
    }),

    validate: thunk(async (actions, payload, { getState, getStoreActions }) => {
      const setStatus = getStoreActions().saveStatus.setStatus;
      setStatus({ isValidating: true });
      try {
        const state = getState();
        const { id, streams } = state[state.enabledScope];
        const cascadeToStreams = streams.map((stream) => stream.uid);
        const validity = await fetchValidation({ id, cascadeToStreams, isPublishAll: payload?.isPublishAll });
        actions.setValidity(validity);
        actions.updateVariants();
        return validity;
      } finally {
        setStatus({ isValidating: false });
      }
    }),

    enable: thunk(async (actions, payload) => {
      actions.setIsEnabled(true);
      actions.setEnabledScope(payload.scope);
      const validity = await actions.validate(payload);
      actions.displayErrorsAndWarnings(validity);
      return validity;
    }),

    disable: thunk((actions) => {
      actions.setIsEnabled(false);
      actions.setEnabledScope(null);
      actions.setAssessmentId(null);
      actions.clearValidity();
      actions.resetVariants();
    }),

    clear: thunk((actions) => {
      actions.disable();
      actions.setVariants({});
    }),

    displayErrorsAndWarnings: thunk((actions, { errors, warnings }) => {
      const errorCount = errors.length;
      const warningCount = warnings.length;
      if (errorCount && warningCount) {
        toast.error(`${getValidationMessage(VALIDATION_ERROR, errorCount)} and ${warningCount} ${plur(VALIDATION_WARNING, warningCount)}`);
      } else if (errorCount) {
        toast.error(getValidationMessage(VALIDATION_ERROR, errorCount));
      } else if (warningCount) {
        toast.warn(getValidationMessage(VALIDATION_WARNING, warningCount));
      } else {
        toast.success('Content is ready to publish');
      }
    }),

    onSwitchDrawer: thunkOn(
      // Listen to the start stage of the switchDrawer thunk
      // See: https://easy-peasy-v3.vercel.app/docs/api/listeners.html#listening-to-specific-stages-of-a-thunk
      (actions, storeActions) => storeActions.drawer.switch.startType,
      async (actions, target, { getStoreState, getState }) => {
        const storeState = getStoreState();
        const payload = target.payload;
        // Set the assessmentId when entering in an assessment question
        if (payload.type === 'AssessmentQuestion') {
          actions.setAssessmentId(target.payload.parent.id);
          // Disable validation and update variants when changing to different content in the Inventory app.
        } else if (storeState.appName === 'inventory' && getState().drawer?.id !== payload.id) {
          actions.clear();
          actions.setVariants({ [payload.id]: null });
        }
        // Clear the assessmentId when leaving assessment questions.
        if (
          // Since we are listening to the start stage, this is the previous state.
          storeState.drawer.data?.type === 'AssessmentQuestion' &&
          // This is the next state.
          payload.type !== 'AssessmentQuestion'
        ) {
          actions.setAssessmentId(null);
        }
      }
    ),

    onSetStatus: thunkOn(
      (actions, storeActions) => storeActions.saveStatus.setStatus,
      async (actions, target, { getState }) => {
        const state = getState();
        if (!state.isEnabled) return;
        const { isSaving, isPublishing, disallowRevalidation } = target.payload;
        // Revalidate after saving.
        if (isSaving === false && !disallowRevalidation) {
          actions.validate();
        }
        // Disable validation after publishing.
        if (isPublishing === false) {
          actions.disable();
        }
      }
    )
  },

  headerImagesSelect: {
    // This is for a temporary workaround to send a unique key prop
    // to our react-select Async components
    // Without it, Async components do not reload options when data changes
    // Github issue: https://github.com/JedWatson/react-select/issues/1581
    asyncSelectKey: '',

    update: action((state, payload) => {
      state.asyncSelectKey = payload;
    })
  },

  // Modal forms are intended to break the user out of their normal
  // drawer + autosave form experience. They should only be used for
  // situations where you do NOT want forms to autosave, but rather need
  // an explicit save (and cancel) user flow.
  modal: {
    current: {},

    open: action((state, config) => {
      state.current = config;
    }),

    close: action((state) => {
      state.current = {};
    }),

    setData: action((state, value) => {
      state.current.data = value;
    })
  },

  // Used when we need to refresh the form with content from the server
  // such as ArticleLoader functions
  formRefresh: {
    nonce: cuid(),

    refreshForm: action((state) => {
      state.nonce = cuid();
    })
  }
};
