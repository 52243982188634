import React from 'react';

import { FormField } from 'mineral-ui/Form';
import PropTypes from 'prop-types';
import ReactSelect from 'react-select';

import {
  $revisionSelector,
  $header,
  $selectInputs,
  $selectInput
} from './style';

// Renders the inputs for level comparison, which is passed in from RevisionHistory.
export default function RevisionLevelSelector ({
  name,
  styles,
  groupedLevels,
  selectLevel
}) {
  return (
    <section css={$revisionSelector}>
      <h3 css={$header}>Select Levels</h3>
      <div css={$selectInputs}>
        <FormField label='Base Level' css={$selectInput}>
          <ReactSelect
            name={name}
            options={groupedLevels}
            styles={styles}
            placeholder='Select Level'
            onChange={(option) => selectLevel(option.value, 'base')}
          />
        </FormField>
        <FormField label='Revision Level' css={$selectInput}>
          <ReactSelect
            name={name}
            options={groupedLevels}
            styles={styles}
            placeholder='Select Level'
            onChange={(option) => selectLevel(option.value, 'comparison')}
          />
        </FormField>
      </div>
    </section>
  );
}

RevisionLevelSelector.propTypes = {
  /** Name of input, required field on react-select components */
  name: PropTypes.string,
  /** Alexandria custom styling for react-selct inputs */
  styles: PropTypes.object,
  /** Current levels, grouped in the format react-select wants */
  groupedLevels: PropTypes.array,
  selectLevel: PropTypes.func
};
