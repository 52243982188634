import { MAX_IMG_WIDTH, MAX_IMG_HEIGHT } from '@client/utils/constants';

/**
 * Calculate dimensions bounded by the maximim width. This is mostly used for
 * horizontal images, but may be used for vertical images if the width would
 * exceed our maximums.
 * @param {number} w
 * @param {number} h
 * @returns {object} with { w, h }
 */
function getHorizontalAR (w, h) {
  return {
    w: MAX_IMG_WIDTH,
    h: Math.round(MAX_IMG_WIDTH * h / w)
  };
}

/**
 * Calculate dimensions bounded by the maximim height. This is mostly used for
 * vertical images, but may be used for horizontal images if the height would
 * exceed our maximums.
 * @param {number} w
 * @param {number} h
 * @returns {object} with { w, h }
 */
function getVerticalAR (w, h) {
  return {
    w: Math.round(MAX_IMG_HEIGHT * w / h),
    h: MAX_IMG_HEIGHT
  };
}

/**
 * Get the correct width and height based on the selected aspect ratio.
 * @param {string} aspectRatio
 * @returns {object} with { w, h }
 */
export function getAspectRatio (aspectRatio) {
  const [arWidth, arHeight] = aspectRatio.split(':');
  const w = parseInt(arWidth);
  const h = parseInt(arHeight);

  if (w > h) {
    // Image should be horizontal
    const ar = getHorizontalAR(w, h);

    // If the calculated height is acceptable, return these dimensions.
    // Otherwise, calculate them based on the height instead.
    return ar.h <= MAX_IMG_HEIGHT ? ar : getVerticalAR(w, h);
  } else {
    // Image should be vertical
    const ar = getVerticalAR(w, h);

    // If the calculated width is acceptable, return these dimensions.
    // Otherwise, calculate them based on the width instead.
    return ar.w <= MAX_IMG_WIDTH ? ar : getHorizontalAR(w, h);
  }
}

/**
 * Get the top or left edge.
 * @param {number} pos focal point position
 * @param {number} zoom
 * @param {string} dimension 'top' or 'left'
 * @param {object} ar with { w, h }
 * @returns {number} top or left edge position
 */
export function getTopLeft (pos, zoom, dimension, ar) {
  const trueMax = dimension === 'top' ? MAX_IMG_HEIGHT : MAX_IMG_WIDTH;
  const max = dimension === 'top' ? ar.h : ar.w;
  const zoomedMax = Math.round(max / zoom);
  const val = pos - Math.round(zoomedMax / 2);

  if (val < 0) {
    // Set a lower bound (it shouldn't be less than zero).
    return 0;
  } else if (val + zoomedMax > trueMax) {
    // Set an upper bound (the mask must fit in the avaiable width/height).
    const nudge = val + zoomedMax - trueMax;

    return val - nudge;
  } else {
    return val;
  }
}

/**
 * Get the bottom or right edge.
 * @param {number} pos focal point position
 * @param {number} zoom
 * @param {string} dimension 'top' or 'left'
 * @param {object} ar with { w, h }
 * @returns {number} bottom or right edge position
 */
export function getBottomRight (pos, zoom, dimension, ar) {
  const trueMax = dimension === 'bottom' ? MAX_IMG_HEIGHT : MAX_IMG_WIDTH;
  const max = dimension === 'bottom' ? ar.h : ar.w;
  const zoomedMax = Math.round(max / zoom);
  const val = pos + Math.round(zoomedMax / 2);

  if (val > trueMax) {
    return 0;
  } else if (val - zoomedMax < 0) {
    const nudge = zoomedMax - val;

    return trueMax - (val + nudge);
  } else {
    return trueMax - val;
  }
}
