import { faVideo } from '@fortawesome/pro-light-svg-icons';
import gql from 'graphql-tag';
import { isString, escapeRegExp } from 'lodash-es';
import smartquotes from 'smartquotes';

import { transformSlug } from '@client/utils/fields';

import Content from './content';
import ContentProvider from './content-provider';
import Stream from './stream';
import Tag from './tag';

function filter (query) {
  const quoted = smartquotes(query);

  return {
    or: [
      { title: { alloftext: quoted } },
      { description: { alloftext: quoted } },
      { slug: { regexp: `/${escapeRegExp(query)}/i` } }
    ]
  };
}

function quickFilter (query) {
  const quoted = smartquotes(query);

  return {
    title: { alloftext: quoted },
    rootFn: 'title|alloftext'
  };
}

const Video = {
  previewFragment: gql`
    fragment previewVideo on Video {
      title(format: TEXT_PLAIN)
      description(format: TEXT_PLAIN)
      imageUrl
    }
  `,
  // Include htmlDescription so that Apollo's cache will update when saving data.
  // This will trigger the preview to show the updated htmlDescription.
  fullFragment: gql`
    fragment fullVideo on Video {
      title(format: TEXT_PLAIN)
      rawTitle: title(format: TEXT_RAW)
      description(format: TEXT_PLAIN)
      rawDescription: description(format: TEXT_RAW)
      htmlDescription: description(format: TEXT_HTML)
      slug
      imageUrl
      lengthInSeconds
      videoUrl
      audioTrackUrl
      textTrackUrl
      transcriptUrl
    }
  `,
  filter,
  quickFilter,
  defaults: (id, data) => {
    const contentDefaults = Content.defaults(id, data);

    return {
      client: {
        ...contentDefaults.client,
        __typename: 'Video',
        contentType: 'VIDEO',
        name: 'Video',
        title: 'Video',
        rawTitle: null,
        description: null,
        rawDescription: null,
        htmlDescription: null,
        slug: null,
        imageUrl: null,
        lengthInSeconds: null,
        videoUrl: null,
        audioTrackUrl: null,
        textTrackUrl: null,
        transcriptUrl: null
      },
      server: {
        ...contentDefaults.server,
        contentType: 'VIDEO'
      }
    };
  },
  isContent: true,
  icon: faVideo,
  typename: 'Video',
  forms: {
    shared: [
      {
        input: 'prosemirror',
        name: 'title',
        value: 'rawTitle',
        formats: ['italic']
      },
      {
        input: 'prosemirror',
        name: 'description',
        value: 'rawDescription',
        isMultiline: true,
        formats: ['bold', 'italic', 'list', 'orderedList', 'link']
      },
      {
        input: 'text',
        name: 'slug',
        transform: (data = '') => transformSlug(data),
        button: {
          input: 'magic-button',
          // Get text from raw prosemirror if we haven't persisted it to the server
          // and received plain text back yet
          value: (data) => isString(data.title)
            ? data.title
            : data.title.textContent,
          description: 'Copy title'
        }
      },
      {
        ...ContentProvider.inputs.contentProvider,
        required: true
      },
      {
        input: 'cloudinary',
        name: 'videoUrl',
        label: 'Video'
      },
      {
        input: 'file',
        name: 'imageUrl',
        label: 'Video Thumbnail',
        accept: 'image/*',
        caption: 'Still image to use on the content card for the video'
      },
      {
        input: 'file',
        name: 'audioTrackUrl',
        label: 'Audio Description',
        accept: 'audio/*',
        caption: 'A .mp3 file that narrates the visual action of the video (not the dialogue) for vision-impaired users'
      },
      {
        input: 'file',
        name: 'textTrackUrl',
        label: 'Text Track',
        accept: '.vtt',
        caption: 'A .vtt file that describes the visual action of the video (not the dialogue) for vision-impaired users'
      },
      {
        input: 'file',
        name: 'transcriptUrl',
        label: 'Transcription',
        accept: '.json',
        caption: 'A .json closed-captioning file that transcribes the dialogue and other aural content of the video'
      },
      {
        input: 'duration',
        name: 'lengthInSeconds',
        label: 'Video Length',
        caption: 'Video Length is calculated automatically when uploading videos, but may also be manually set'
      },
      {
        input: 'editor',
        name: 'replacedByContent',
        editorType: 'ExternalLink',
        defaultData: {
          label: 'Video'
        }
      },
      Tag.inputs.tags,
      Stream.inputs.subjectProductStreams,
      Stream.inputs.customStreams,
      Content.inputs.notes,
      Content.inputs.parentsList,
      Content.inputs.id
    ]
  }
};

export default Video;
