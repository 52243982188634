import { useEffect, useState } from 'react';

import { TARGET_LEXILE } from '@client/utils/constants';
import { fetchJSON } from '@client/utils/rest';

const useLeveler = ({ gradeBand, rawText }) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const convertBandToRange = (grade) => {
      const range = TARGET_LEXILE[grade];
      return range && range.length === 2 ? `${range[0]}-${range[1]}L` : null;
    };

    const range = convertBandToRange(gradeBand);

    if (!range) {
      setError(`Can't level the grade band: ${gradeBand}`);
      return;
    }

    const fetchLeveledArticle = async () => {
      setLoading(true);
      try {
        const body = JSON.stringify({
          original_text: {
            format: 'TEXT_RAW',
            raw_text: rawText
          },
          targets: [
            {
              lexile: range
            }
          ]
        });

        const options = {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body
        };

        const levelerURL = `${process.env.SETI_BASE_URL}/leveler/level-article`;

        const res = await fetchJSON(levelerURL, options);
        setData(res);
      } catch (err) {
        setError('Error fetching leveled article.');
      } finally {
        setLoading(false);
      }
    };

    fetchLeveledArticle();
  }, [gradeBand, rawText]);

  return { data, loading, error };
};

export default useLeveler;
