import cuid from 'cuid';
import { last } from 'lodash-es';

import { logError } from '@client/utils/log';
import { error } from '@client/utils/toast';
import { ARTICLE_TYPES_WITHOUT_LEXILE } from '@shared/constants';

// Event Handlers run when users interact with options:

/**
 * Handler for selecting existing options.
 * @param {string} name
 * @param {object|array} newOption
 * @param {object} inputArgs
 * @param {string} loadingSpinnerMessage
 */
export function selectOption (name, newOption, { onChange, value, config, schema }, loadingSpinnerMessage) {
  try {
    // When handling options in multi-select, they're always the last object in the array
    const newSelectedOption = config.isMulti ? last(newOption) : newOption;

    const newValue = newSelectedOption.value;
    const newLabel = newSelectedOption.label;

    if (name === 'articleType' && ARTICLE_TYPES_WITHOUT_LEXILE.includes(newOption.value)) {
      window.alert('Setting the article to this type will prevent Lexile calculation.');
    }

    if (config.isRelation && config.isMulti) {
      // Array of relations. newValue will be a uid. Because we're
      // calling schema.defaults() for the optimistic client-side data, we pass in
      // newValue as both the id and uid. This means that the optimistic data will
      // have the wrong id (until real data is returned from the server), but will
      // have the correct uid (so we can edit the data without worrying).
      onChange(
        { [name]: { [config.mapping.value]: newValue } },
        { [name]: [...value, schema.defaults(newValue, { uid: newValue, newLabel }).client] },
        'set',
        false,
        loadingSpinnerMessage
      );
    } else if (config.isRelation) {
      // Single relation to another type. newValue will be a uid. Because we're
      // calling schema.defaults() for the optimistic client-side data, we pass in
      // newValue as both the id and uid. This means that the optimistic data will
      // have the wrong id (until real data is returned from the server), but will
      // have the correct uid (so we can edit the data without worrying).
      onChange(
        { [name]: { [config.mapping.value]: newValue } },
        {
          [name]: config.isSearchFilter
            ? { [config.mapping.label]: newLabel, [config.mapping.value]: newValue }
            : schema.defaults(newValue, { uid: newValue, newLabel }).client
        },
        'set',
        false,
        loadingSpinnerMessage
      );
    } else if (config.isMulti) {
      // Array of scalar values
      onChange(
        { [name]: newValue },
        { [name]: [...value, newValue] },
        'set',
        false,
        loadingSpinnerMessage
      );
    } else if (config.hasImage) {
      // Single scalar value, but needs to pass in extra fields to update state
      onChange(
        { [name]: newValue },
        { [name]: newValue, headerImageUrl: newSelectedOption.image },
        'set',
        false,
        loadingSpinnerMessage
      );
    } else {
      // Single scalar value
      // We pass a null value to onChange so the form will optimistically
      // update with the serverData (the first argument).
      onChange({ [name]: newValue }, null, 'set', false, loadingSpinnerMessage);
    }
  } catch (err) {
    logError(`selectOption error selecting ${name} with ${newOption}: ${err.message}`, err);
    throw err;
  }
}

/**
 * Get the optimistic value by filtering based on the removed option. Also
 * get the key based on the mapping.
 * @param {*} newValue
 * @param {array} value
 * @param {object} config
 * @returns {object} with optional { key, optimisticValue }
 */
export function getValueAndKey (newValue, value, config) {
  if (config.isAsync) {
    // Relations (e.g. tags) require isAsync & a mapping.
    // If there is no mapping, async items fall back to uid.
    const key = config.mapping ? config.mapping.value : 'uid';

    return {
      key,
      optimisticValue: value.filter((item) => item[key] !== newValue)
    };
  } else if (config.isMulti) {
    // Non-async multi-selects operate on scalar values. Compare them
    // in a case-insensitive manner. They don't return a key.
    return { optimisticValue: value.filter((item) => item.toLowerCase() !== newValue.toLowerCase()) };
  } else {
    // Single scalar values don't return key or optimistic data.
    return {};
  }
}

/**
 * Handler for deselecting existing options.
 * @param {string} name
 * @param {object} removedOption
 * @param {object} inputArgs
 * @param {string} loadingSpinnerMessage
 */
export function deselectOption (name, removedOption, { onChange, value, config }, loadingSpinnerMessage) {
  try {
    const newValue = removedOption.value;
    const { key, optimisticValue } = getValueAndKey(newValue, value, config);

    if (config.isAsync) {
      // Array of relations (or single relation) to another type
      onChange(
        { [name]: { [key]: newValue } },
        { [name]: optimisticValue },
        'unset',
        false,
        loadingSpinnerMessage
      );
    } else if (config.isMulti) {
      // Array of scalar values
      onChange(
        { [name]: newValue },
        { [name]: optimisticValue },
        'unset',
        false,
        loadingSpinnerMessage
      );
    } else {
      // Single scalar value
      // We pass a null value to onChange so the form will optimistically
      // update with the serverData (the first argument).
      onChange({ [name]: null }, null, 'unset', false, loadingSpinnerMessage);
    }
  } catch (err) {
    logError(`deselectOption error deselecting ${name} with ${removedOption}: ${err.message}`, err);
    throw err;
  }
}

/**
 * Handler for clearing all options.
 * @param {string} name
 * @param {object} inputArgs
 * @param {string} loadingSpinnerMessage
 */
export function clearOptions (name, { onChange, config }, loadingSpinnerMessage) {
  if (config.isAsync || config.isMulti) {
    // Array of relations (or single relation) to another type
    // OR Array of single values
    onChange(
      { [name]: null },
      { [name]: [] },
      'unset',
      false,
      loadingSpinnerMessage
    );
  } else {
    // Single scalar value
    // We pass a null value to onChange so the form will optimistically
    // update with the serverData (the first argument).
    onChange(
      { [name]: null },
      null,
      'unset',
      false,
      loadingSpinnerMessage
    );
  }
}

/**
 * Handler for creating new options.
 * @param {string} name
 * @param {object|array} newOption
 * @param {object} inputArgs
 * @param {string} loadingSpinnerMessage
 */
export async function createOption (name, newOption, { onChange, value, config, schema }, loadingSpinnerMessage) {
  try {
    const { isMulti, isAsync, mapping, isValidChange } = config;
    if (isValidChange && !isValidChange({ newOption })) {
      error(`Invalid value ${newOption.value} for ${config.name}`);
      throw new Error(`Invalid value ${newOption.value} for ${config.name}, config: ${JSON.stringify(config)}`);
    }
    // When handling options in multi-select, they're always the last object in the array
    const newCreatedOption = isMulti ? last(newOption) : newOption;
    const newValue = newCreatedOption.value;
    const newLabel = newCreatedOption.label;

    if (isAsync) {
      // Array of relations (or single relation) to another type
      // We're assuming that the label of the new option maps to mapping.label,
      // and that the type's defaults() method can handle it and save it as the
      // relevant title field.
      const id = cuid();
      const newItem = schema.defaults(id, { newLabel });

      return onChange(
        {
          [name]: {
            ...newItem.server,
            ...mapping.image && { [mapping.image]: newCreatedOption.image }
          }
        },
        { [name]: [...value, newItem.client] },
        'set',
        false,
        loadingSpinnerMessage
      );
    } else if (isMulti) {
      // Array of scalar values, same logic as selecting existing options
      return onChange(
        { [name]: newValue },
        { [name]: [...value, newValue] },
        'set',
        false,
        loadingSpinnerMessage
      );
    } else {
      // Single scalar value, same logic as selecting existing options
      // We pass a null value to onChange so the form will optimistically
      // update with the serverData (the first argument).
      return onChange(
        { [name]: newValue },
        null,
        'set',
        false,
        loadingSpinnerMessage
      );
    }
  } catch (err) {
    logError(`Error in createOption creating ${name}: ${err.message}`, err);
    throw err;
  }
}
