/* eslint-disable no-console */
// Client-side error logging. These functions wrap console, so we can mock them
// out in tests while still allowing devs to use console.log() to debug tests.

// Note: For user-facing alerts, please use toasts.
export function logError (message, e) {
  console.error(message, e);
}

export function logWarning (message) {
  console.warn(message);
}

export function logInfo (message) {
  console.log(message);
}
