import { ArticlePublication } from '@client/common/schema';
import { SUPPORTED_LANGUAGES } from '@client/utils/constants';

/**
 * Extract the visibleAfter value of a specific language from the articlePublications array.
 * @param {Array} articlePublications ex: [{ uid, language, visibleAfter }, ...]
 * @param {String} language ex: LANG_EN
 * @returns {String|null} visibleAfter value
 */
export function transformArticlePublication (articlePublications, language) {
  const articlePublication = articlePublications?.find((item) => item.language === language);
  return articlePublication?.visibleAfter || null;
}

/**
 * Return the article publication change to be passed to the form's onChange method.
 * @param {Array} articlePublications ex: [{ uid, language, visibleAfter }, ...]
 * @param {String} language ex: LANG_EN
 * @param {String} visibleAfter ex: 2022-12-30T12:12:12.000Z
 * @returns {Object|null} change with { server, client }
 */
export function getArticlePublicationChange (articlePublications, language, visibleAfter) {
  // Early return if language is not supported.
  if (!SUPPORTED_LANGUAGES.includes(language)) return null;
  // If language is supported, get its index.
  const languageIndex = articlePublications.findIndex((item) => item.language === language);
  // We need the other languages to include them in optimisticData
  const otherLanguages = articlePublications.filter((item) => item.language !== language);
  // The existent pubDate uid. Or null if we are creating a new one.
  const uid = languageIndex !== -1 ? articlePublications[languageIndex].uid : null;
  const articleHeaderId = languageIndex !== -1 ? articlePublications[languageIndex].articleHeaderId : null;
  const newOrUpdatedPubDate = ArticlePublication.defaults(uid, { language, visibleAfter, articleHeaderId });

  return {
    server: {
      uid: newOrUpdatedPubDate.server.uid,
      visibleAfter,
      ...visibleAfter && { language } // Do not include language for unset mutations.
    },
    // This is used to immediately display the change to the user and also to prevent
    // the other language value from disappearing while waiting for the server to respond.
    optimisticData: [...otherLanguages, newOrUpdatedPubDate.client]
  };
}

/**
 * Get the 'required' property value
 * @param {Object} config field config with { language }
 * @param {Object} formData with { articleLevels }
 * @returns {Boolean} isRequired
 */
export function getArticlePublicationIsRequired (config, formData) {
  return (formData.articleLevels || [])
    .filter((level) => level.language === config.language)
    .some((level) => level.isActive);
}
