import { faImage } from '@fortawesome/pro-light-svg-icons';
import cuid from 'cuid';
import gql from 'graphql-tag';
import smartquotes from 'smartquotes';

import rawSchema from '@client/schema';
import { DEFAULT_LANG, DEFAULT_GRADE } from '@client/utils/constants';
import { enumOptions } from '@client/utils/fields';

import Content from './content';
import Level from './level';
import MultilingualText from './multilingual-text';

function filter (query) {
  const quoted = smartquotes(query);

  return {
    or: [
      { altText: { alloftext: quoted } },
      { credit: { alloftext: quoted } }
    ]
  };
}

function quickFilter (query) {
  const quoted = smartquotes(query);

  return {
    altText: { alloftext: quoted },
    rootFn: 'altText|alloftext'
  };
}

const Image = {
  // Include cropping info on search fragment, so we can display
  // the thumbnail with the proper cropping.
  searchFragment: gql`
    fragment searchImage on Image {
      url
      cropType
      focalPointX
      focalPointY
      focalPointZ
    }
  `,
  // Does not include contentProvider, captionLevels.
  fullFragment: gql`
    fragment fullImage on Image {
      credit(format: TEXT_PLAIN)
      rawCredit: credit(format: TEXT_RAW)
      url
      width
      height
      cropType
      focalPointX
      focalPointY
      focalPointZ
      altTexts {
        ...fullMultilingualText
      }
      longDescription
    }
    ${MultilingualText.fullFragment}
  `,
  filter,
  quickFilter,
  defaults: (id, data) => {
    const contentDefaults = Content.defaults(id, data);
    // When creating new images, they're created with a single caption level.
    const captionLevel = Level.defaults(cuid(), {
      language: DEFAULT_LANG,
      gradeBand: DEFAULT_GRADE
    });
    // When creating a new images, we automatically add its first alt text.
    // This text is in English, but is not set as the original language (because
    // we generally write the text ourselves rather than getting it from a
    // content partner).
    const firstAltText = MultilingualText.defaults(cuid(), {
      plainText: null,
      isOriginalLanguage: false
    });

    return {
      client: {
        ...contentDefaults.client,
        __typename: 'Image',
        contentType: 'IMAGE',
        name: 'Image',
        credit: null,
        rawCredit: null,
        url: null,
        width: null,
        height: null,
        cropType: 'CROP_AUTO',
        focalPointX: 0.5,
        focalPointY: 0.5,
        focalPointZ: 1,
        altTexts: [firstAltText.client],
        captionLevels: [captionLevel.client],
        longDescription: null
      },
      server: {
        ...contentDefaults.server,
        contentType: 'IMAGE',
        captionLevels: [captionLevel.server],
        altTexts: [firstAltText.server]
      }
    };
  },
  isContent: true,
  icon: faImage,
  typename: 'Image',
  forms: {
    shared: [
      {
        input: 'file',
        name: 'url',
        label: 'Upload Image',
        accept: 'image/*'
      },
      {
        input: 'select',
        name: 'cropType',
        label: 'Crop Type',
        enum: 'CropType',
        options: rawSchema.enums.CropType
      },
      {
        input: 'focal-point',
        name: 'focalPointX', // This input also sets focalPointY and focalPointZ
        label: 'Image Preview'
      },
      {
        input: 'multilingual-text',
        name: 'altTexts',
        label: 'Alt text',
        originalLanguageLabel: 'Original text from a content partner',
        showLabel: false
      },
      {
        input: 'level',
        name: 'captionLevels',
        label: 'Caption',
        formats: ['italic']
      },
      {
        input: 'prosemirror',
        name: 'credit',
        value: 'rawCredit',
        formats: ['italic']
      },
      {
        input: 'textarea',
        name: 'longDescription',
        label: 'Long Description',
        caption: 'For accessibility purposes, "complex images" (e.g. graphs) should include a long description.'
      },
      Content.inputs.notes,
      Content.inputs.id
    ],
    levelSelect: [{
      input: 'grade-band-level-select',
      name: 'captionLevels',
      showLabel: false,
      gradeBands: enumOptions(rawSchema.enums.GradeBand, 'GradeBand'),
      languages: enumOptions(rawSchema.enums.Language, 'Language')
    }]
  }
};

export default Image;
