import { css } from '@emotion/core';

const maskBase = css`
  background-color: rgba(0, 0, 0, .7);
  height: 100%;
  position: absolute;
  width: 100%;
`;

export const $maskTop = (topY) => [
  maskBase,
  css`
    height: ${topY}px;
    top: 0;
  `
];

export const $maskBottom = (bottomY) => [
  maskBase,
  css`
    bottom: 0;
    height: ${bottomY}px;
  `
];

export const $maskLeft = (leftX, topY, sideHeight) => [
  maskBase,
  css`
    height: ${sideHeight}px;
    left: 0;
    top: ${topY}px;
    width: ${leftX}px;
  `
];

export const $maskRight = (rightX, topY, sideHeight) => [
  maskBase,
  css`
    height: ${sideHeight}px;
    right: 0;
    top: ${topY}px;
    width: ${rightX}px;
  `
];

export const $focalPoint = (x, y) => ({ colors }) => {
  const SIZE = 20;
  const top = y - (SIZE / 2);
  const left = x - (SIZE / 2);

  return css`
    background: transparent;
    border: 3px solid ${colors.ui.red[700]};
    border-radius: 50%;
    cursor: move;
    height: ${SIZE}px;
    left: ${left}px;
    position: absolute;
    top: ${top}px;
    width: ${SIZE}px;
  `;
};
