import { faStrikethrough } from '@fortawesome/pro-regular-svg-icons';

import { strikethrough } from '@shared/prosemirror/marks';

import { markActive, toggleMark } from './helpers';

const icon = faStrikethrough;
export const mark = strikethrough;
export const keys = {
  'Mod-Shift-x': toggleMark('strikethrough')
};
export const menuMark = {
  title: 'Toggle Strikethrough',
  content: icon,
  active: markActive('strikethrough'),
  run: toggleMark('strikethrough')
};
