import { get, reduce } from 'lodash-es';

import * as schemas from '@client/common/schema';
import rawSchema from '@client/schema';
import { getContextualFieldName } from '@client/utils/fields';

function getTabs (config) {
  return reduce(config, (acc, item) => {
    if (item.tab) {
      // Create a new tab. Tabs may have non-standard layouts, e.g. 'wide' or 'two-column'
      acc.push({
        title: item.tab,
        fields: [],
        type: 'tab',
        layout: item.layout,
        ...item.hasNestedFields && { hasNestedFields: item.hasNestedFields }
      });
    } else if (acc.length === 0) {
      // Create an initial tab.
      acc.push({ title: 'General', fields: [item], type: 'tab' });
    } else {
      // Push the current item into the last tab.
      acc[acc.length - 1].fields.push(item);
    }
    return acc;
  }, []);
}

function getSections (config) {
  return reduce(config, (acc, item) => {
    if (item.section) {
      // Create a new section.
      acc.push({
        title: item.section,
        ...item.description && { description: item.description },
        fields: [],
        type: 'section'
      });
    } else if (acc.length === 0) {
      // Create an initial section, without a title.
      acc.push({ fields: [item], type: 'section' });
    } else {
      // Push the current item into the last section.
      acc[acc.length - 1].fields.push(item);
    }
    return acc;
  }, []);
}

/**
 * Get a form config from a client-side schema. 'shared' is the default form
 * that types use when they're edited in places other than their own apps.
 * For assessment questions, we look at the questionType to determine form config.
 * @param {string} type e.g. Article or Bundle
 * @param {string} [formKey='shared'] key inside of schema.forms
 * @param {object} data
 * @returns {array} form config, or empty array
 */
export function getFormConfig (type, formKey = 'shared', data = {}) {
  if (type === 'AssessmentQuestion' && data.questionType) {
    // For assessment questions, look in their data to determine which form
    // to render.
    return get(schemas, `${type}.forms.${data.questionType}`);
  }

  return get(schemas, `${type}.forms.${formKey}`, []);
}

/**
 * Parse a form config from the schema, adding tabs, sections, and validation.
 * @param {array} config from the type schema
 * @param {string} type e.g. Article or Bundle
 * @returns {array} with tabs, sections, and validation
 */
export function parseFormConfig (config, type) {
  let formConfig = config || [];
  // Get validation rules from the server schema.
  const validationFields = rawSchema.validation[type];

  // Add those rules to our form fields.
  formConfig = formConfig.map((field) => ({
    ...field,
    // If we've set a validationName (e.g. for computed fields), we want to
    // use the validation rules for that underlying field. This will display
    // stuff like the 'Required' secondaryText. To show the actual errors and
    // warnings on the field, we have separate logic (in the FormField component).
    ...validationFields && validationFields[getContextualFieldName({
      ...field.validationName && { validationName: field.validationName },
      name: field.name
    })
    ]
  }));

  const hasTabs = !!formConfig.find((item) => item.tab);
  const hasSections = !!formConfig.find((item) => item.section);

  if (hasTabs && hasSections) {
    const withTabs = getTabs(formConfig);

    return withTabs.map((tab) => ({ ...tab, fields: getSections(tab.fields) }));
  } else if (hasTabs) {
    return getTabs(formConfig);
  } else if (hasSections) {
    return getSections(formConfig);
  } else {
    return formConfig;
  }
}
