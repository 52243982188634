import { Toast } from '@newsela/angelou';
import deline from 'deline';
import { isEmpty } from 'lodash-es';

import CopyText from '@client/common/components/CopyText/';
import { isUnauthenticated } from '@client/utils/authentication';
import { error, info, warn, globalToasterRef } from '@client/utils/toast';

const DEFAULT_ERROR_MESSAGE = deline`
  Sorry, something went wrong when talking to the server.
  An error report has been sent to the Alexandria team. Please try
  refreshing the page or reach out to #content-production if you're stuck.
`;

function showErrorMessage (errorData) {
  const traceData = errorData?.extensions?.dd;
  const supportInfo = `Error report:
    URL: ${location.href}
    ${errorData.message ? 'Error: ' + errorData.message : ''}
    ${traceData.traceId ? 'Trace ID: ' + traceData.traceId : ''}
    ${
      traceData.spanTraceId ? 'Span Trace ID: ' + traceData.spanTraceId : ''
    }`;

  const userMessage = errorData.extensions?.showToUser
    ? errorData.message
    : DEFAULT_ERROR_MESSAGE;

  const errorToastProps = {
    showIcon: true,
    saturation: 'light',
    statusColor: Toast.statusColor.danger,
    children: <CopyText
      text={userMessage}
      customCopyText={supportInfo}
      actionText='Copy error report'
              />
    ,
  };

  globalToasterRef.current.displayToast(errorToastProps);
}

function showWarningInfoMessage (error) {
  const { showToUser, errorType } = error.extensions.metadata;
  if (showToUser && ['warning', 'info'].includes(errorType)) {
    const toastFunction = errorType === 'warning' ? warn : info;
    toastFunction(error.message, { persist: true });
  }
}

export function displayErrorsToUser ({ graphQLErrors, networkError }) {
  if (isEmpty(graphQLErrors) || networkError) {
    error(DEFAULT_ERROR_MESSAGE, { persist: true });
  } else {
    graphQLErrors.forEach((e) => {
      if (e.extensions.metadata.errorType === 'error') {
        showErrorMessage(e);
      } else {
        showWarningInfoMessage(e);
      }
    });
  }
}

export function handleServerErrors ({ graphQLErrors, networkError, operation }) {
  if (isUnauthenticated(graphQLErrors)) {
    error('Session expired, please log in!', { persist: true });
    document.dispatchEvent(new CustomEvent('session-expired'));
  } else {
    displayErrorsToUser({ graphQLErrors, networkError });
  }
}
