import React from 'react';

import { isEmpty } from 'lodash-es';
import { FormField as MineralUIField } from 'mineral-ui/Form';
import PropTypes from 'prop-types';

import supportedInputs, { isSupportedInput } from '@client/forms/inputs';
import { getLabel, getValue, getContextualFieldName } from '@client/utils/fields';
import {
  getFieldValidation,
  getCaption,
  getSecondaryText
} from '@client/utils/form-validation';

import { $root } from './style';
import ValidationMessage from '../ValidationMessage';

export default function FormField ({
  field,
  layout,
  validatedContent,
  data,
  onChange,
  breadcrumbs,
  parent,
  lexileInView,
  fieldPath,
  formId,
  levelUid
}) {
  // eslint-disable-next-line no-param-reassign
  validatedContent = !isEmpty(validatedContent) ? validatedContent : { errors: [], warnings: [] };
  // Filter errors and warnings to get the ones specific to this field.
  // If this field is embedded in an inline form, the full fieldPath and a formId
  // will be passed in. Otherwise, use the field name from its config and data.id.
  const contentFilter = { id: formId || data.id };
  const name = getContextualFieldName({
    ...field.validationName && { validationName: field.validationName },
    name: field.name,
    fieldPath
  });
  const { errors, warnings } = getFieldValidation(validatedContent, contentFilter, name);
  const { variant, caption } = getCaption(errors, warnings, field) || null;
  let { requiredText, secondaryText } = getSecondaryText(field, data);

  if (field.showValidation === false) {
    requiredText = null;
    secondaryText = null;
  }

  if (!isSupportedInput(field.input)) {
    // Return early if the input type of this field is not one of our
    // supported input types.
    return null;
  }

  // Determine what input to render, and generate props to pass into it.
  const FieldInput = supportedInputs[field.input];
  const inputProps = {
    // Value of the field itself.
    value: getValue(field, data),
    // Name of the field. This is the property that will be saved when the
    // field is changed.
    name: field.name,
    // Field config, from the client-side schema.
    config: field,
    // Function that is called when the field is changed.
    onChange,
    // Data for the entire form, used by some inputs.
    formData: data,
    // Breadcrumbs for the current form.
    breadcrumbs,
    // Parent content for the current form.
    parent,
    // 'danger' or 'warning' variant, if the field has errors or warnings.
    variant,
    // Validation for the current form, passed through to complicated inputs
    // so they can display their own validation messages.
    validatedContent,
    // Form layout, used by complicated inputs to determine field widths in
    // inline forms.
    layout,
    // This is an in-view ref used to determine whether overall lexile is
    // in the viewport. Used to toggle the sticky lexile on the text editor.
    lexileInView,
    levelUid,
    fieldPath,
    requiredText
  };

  return (
    <MineralUIField
      label={getLabel(field, data)}
      required={!!requiredText}
      requiredText={requiredText}
      secondaryText={secondaryText}
      caption={variant ? <ValidationMessage variant={variant} message={caption} /> : caption}
      hideLabel={field.showLabel === false}
      {...variant && { variant }}
      css={$root(layout)}
    >
      <FieldInput {...inputProps} />
    </MineralUIField>
  );
}

FormField.propTypes = {
  field: PropTypes.object,
  layout: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array
  ]),
  validatedContent: PropTypes.object,
  data: PropTypes.object,
  onChange: PropTypes.func,
  breadcrumbs: PropTypes.array,
  parent: PropTypes.object,
  lexileInView: PropTypes.bool,
  /**
   * For inline forms, their fields include a full path so we can get the
   * validation issues specific to this field. */
  fieldPath: PropTypes.string,
  formId: PropTypes.string,
  levelUid: PropTypes.string
};
