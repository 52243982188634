import { faTag } from '@fortawesome/pro-light-svg-icons';
import cuid from 'cuid';
import gql from 'graphql-tag';

const MetadataTag = {
  searchFragment: gql`
    fragment searchMetadataTag on MetadataTag {
      id
      name
      tagTitle
      tagDescription
      tagDisplayName
      tagType
      hierarchy
      createdAt
      updatedAt
      hasDerivedStandards
    }
  `,
  fullFragment: gql`
    fragment fullMetadataTag on MetadataTag {
      id
      name
      tagTitle
      tagDescription
      tagDisplayName
      tagType
      hierarchy
      certicaId
      origin
      createdAt
      updatedAt
      hasDerivedStandards
    }
  `,
  defaults: (id, data = {}) => {
    const tagId = id || cuid();
    const tagTitle = data.newLabel || null;

    return {
      client: {
        id: tagId,
        __typename: 'MetadataTag',
        tagType: 'PUBLIC_TAG',
        name: tagTitle || 'MetadataTag',
        tagTitle,
        tagDescription: null,
        tagDisplayName: null,
        hasDerivedStandards: false,
        hierarchy: [],
        certicaId: null,
        origin: 'HUMAN',
        createdAt: (new Date()).toISOString(),
        updatedAt: (new Date()).toISOString(),
        assignedCount: 0
      },
      server: {
        id,
        tagTitle
      }
    };
  },
  icon: faTag,
  typename: 'MetadataTag',
  openInApp: false,
  forms: {
    // metadataTags are sourced from Langston and are not edited in Alexandria

  },
  inputs: {
    metadataTags: {
      input: 'select',
      name: 'metadataTags',
      label: 'Reading Skills, Certica Topics and Concepts',
      isMulti: true,
      isAsync: true,
      isRelation: true,
      isMetadataService: true,
      type: 'MetadataTag',
      query: 'searchMetadataTags',
      showDropdown: false,
      mapping: {
        label: 'tagTitle',
        value: 'id',
        type: 'tagType',
        path: 'hierarchy',
        hasDerivedStandards: 'hasDerivedStandards',
      }
    }
  },
};

export default MetadataTag;
