import React, { useState, isValidElement } from 'react';

import { Button } from '@newsela/angelou';
import { isEmpty } from 'lodash-es';
import TextInput from 'mineral-ui/TextInput';
import IconSearch from 'mineral-ui-icons/IconSearch';
import PropTypes from 'prop-types';

import {
  $button,
  $heading,
  $menuItems,
  $filter
} from './style';

// Exported for testing.
export function MenuItem ({
  text,
  disabled,
  onClick,
  icon
}) {
  // This needs to support the old and new Button versions
  // These are the possible icon values:
  // 1. an Icon node { icon: <Icon SvgComponent={ChevronRightSVG} /> }
  // 2. an SVG { icon: ChevronRightSVG }
  // 3. an old icon prop usage { icon: { SvgComponent: <Icon SvgComponent={ChevronRightSVG} />, alignment: ... } }
  // 4. a new icon prop usage { icon: { SvgComponent: ChevronRightSVG, alignment: ... } }
  let angelouIconProps;
  if (isValidElement(icon)) {
    // supports first condition
    angelouIconProps = { legacy_icon: { SvgComponent: icon } };
  } else if (icon?.SvgComponent) {
    if (!isValidElement(icon?.SvgComponent)) {
      // supports fourth condition
      angelouIconProps = { icon, alignment: icon.alignment };
    } else {
      // supports third condition
      angelouIconProps = { legacy_icon: icon, alignment: icon.alignment };
    }
  } else {
    // supports second condition
    angelouIconProps = { icon: { SvgComponent: icon } };
  }

  return !isEmpty(text)
    ? (
      <Button
        __cssFor={{ root: $button }}
        onClick={onClick}
        disabled={disabled}
        /* AUTOGENERATED TODO: update angelou to new flavor.
          see https://github.com/newsela/angelou/blob/main/src/components/Button/README.md#MIGRATION
          for migration guide. */
        legacy_flavor={Button.legacy_flavor.link}
        {...angelouIconProps}
      >
        <span>{text}</span>
      </Button>
      )
    : null;
}

MenuItem.propTypes = {
  text: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  icon: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.shape({
      SvgComponent: PropTypes.element,
      alignment: PropTypes.string
    })
  ])
};

function MenuItemGroup ({
  heading,
  menuItems,
  isSearchable,
  isScrollable
}) {
  const [filter, setFilter] = useState(''); // re render on change
  // If Searchable or Scrollable are true, they only have effect if
  // the group has more than 4 items.
  const shouldBecomeSearchadleOrScrollable = menuItems.length > 4;
  return (
    <div>
      {!isEmpty(heading) && <span css={$heading}>{heading.toUpperCase()}</span>}
      {isSearchable && shouldBecomeSearchadleOrScrollable &&
        // It filters the menuItems.
        <TextInput
          css={$filter}
          iconStart={<IconSearch />}
          type='search'
          placeholder='Begin typing to search...'
          onChange={(e) => setFilter(e.target.value)}
        />}
      <div css={$menuItems(isScrollable && shouldBecomeSearchadleOrScrollable)}>
        {menuItems.filter((item) => item.text.toLowerCase().includes(filter.toLowerCase())).map((menuItem, index) => {
          // To avoid this linting error:
          // Handler function for onClick prop key must be a camelCase name
          // beginning with 'handle' only  react/jsx-handler-names.
          const handleClick = menuItem.onClick;
          return (
            <MenuItem
              key={index}
              text={menuItem.text}
              disabled={menuItem.disabled}
              onClick={handleClick}
              icon={menuItem.icon}
            />
          );
        })}
      </div>
    </div>
  );
}

MenuItemGroup.propTypes = {
  heading: PropTypes.string,
  menuItems: PropTypes.arrayOf(MenuItem),
  /** Boolean flag to indicate if it's possible to search items in the groups */
  isSearchable: PropTypes.bool,
  /** Boolean flag to indicate if there should be a search bar */
  isScrollable: PropTypes.bool
};

export default function MenuItemGroupList ({
  menuData,
  isSearchable,
  isScrollable
}) {
  return (
    menuData
      ? menuData.map((menuItemGroup, index) => {
        return (
          <MenuItemGroup
            key={index}
            heading={menuItemGroup.heading}
            menuItems={menuItemGroup.menuItems}
            isSearchable={isSearchable}
            isScrollable={isScrollable}
          />
        );
      })
      : null
  );
}

MenuItemGroupList.propTypes = {
  /** Groups of items in the menu. Each group may have an optional heading. */
  menuData: PropTypes.arrayOf(
    PropTypes.shape({
      heading: PropTypes.string,
      menuItems: PropTypes.arrayOf(
        PropTypes.shape({
          text: PropTypes.string,
          disabled: PropTypes.bool,
          onClick: PropTypes.function
        })
      )
    })
  ),
  /** Boolean flag to indicate if it's possible to search items in the groups */
  isSearchable: PropTypes.bool,
  /** Boolean flag to indicate if there should be a search bar */
  isScrollable: PropTypes.bool
};
