import React, { useState } from 'react';

import { Button, ButtonWithPopOut, CaratRightSVG } from '@newsela/angelou';
import PropTypes from 'prop-types';

import MenuItemGroupList from '@client/common/components/MenuItemGroupList';

import { $buttonContainer, $popOutMenu, $popOutButton } from './style';

export default function AddQuestionButton ({
  config,
  formData,
  onAddItem,
  articleLevels = [],
  assessmentLevel
}) {
  // This avoids comparing several times.
  const isPowerWordsActivity = formData.assessmentType === 'POWER_WORDS_ACTIVITY';

  const [menuData, setMenuData] = useState([]);
  const setDefaultMenuData = () => setMenuData([{ heading: 'Select the question type', menuItems }]);

  const articleLevel = articleLevels.find((articleLevel) => {
    return articleLevel.gradeBand === assessmentLevel?.gradeBand &&
    articleLevel.language === assessmentLevel.language;
  });

  const setPowerWordsDropDown = (questionType, wordDefinitions, position) => {
    setMenuData([{
      heading: questionType.label,
      menuItems: wordDefinitions.map((word) => ({
        text: word.wordForm,
        onClick: (e) => {
          e.preventDefault();
          onAddItem({ questionType: questionType.value, wordDefinition: { uid: word.uid }, position });
        }
      }))
    }]);
  };

  const menuItems = config.questionTypes(formData.assessmentType).map((questionType) => {
    return ({
      text: questionType.label,
      icon: isPowerWordsActivity ? CaratRightSVG : null,
      onClick: (e) => {
        // Angelou dropdown buttons don't have a type='button' attribute,
        // so we need to prevent the default submit action.
        e.preventDefault();
        if (isPowerWordsActivity) {
          const position = (assessmentLevel?.questions?.length || 0) + 1;
          const wordDefinitions = articleLevel?.wordDefinitions || [];
          setPowerWordsDropDown(questionType, wordDefinitions, position);
        } else {
          onAddItem({ questionType: questionType.value });
        }
      }
    });
  });

  return menuItems && (
    <div css={$buttonContainer}>
      <ButtonWithPopOut
        __cssFor={{
          PopOut: {
            root: $popOutMenu
          },
          Button: {
            root: $popOutButton
          }
        }}
        id='add-question-button'
        /* AUTOGENERATED TODO: update angelou to new flavor.
          see https://github.com/newsela/angelou/blob/main/src/components/Button/README.md#MIGRATION
          for migration guide. */
        legacy_flavor={Button.legacy_flavor.solid}
        legacy_size={Button.legacy_size.justified}
        buttonIcon={<></>} // The empty element is used to make the icon disappear. Angelou don't provide an easy way to do this.
        popOutContents={<MenuItemGroupList menuData={menuData} isSearchable={isPowerWordsActivity} isScrollable={isPowerWordsActivity} />}
        buttonContents='Add Question'
        horizontalOffset='0'
        onClick={setDefaultMenuData}
        closeOnBlur
      />
    </div>
  );
}

AddQuestionButton.propTypes = {
  /** Full configuration object */
  config: PropTypes.object,
  formData: PropTypes.object,
  onAddItem: PropTypes.func,
  articleLevels: PropTypes.array,
  assessmentLevel: PropTypes.object
};
