import { faHorizontalRule } from '@fortawesome/pro-regular-svg-icons';

import { divider } from '@shared/prosemirror/blocks';

import { getBlock } from './helpers';

const insertDivider = (state, dispatch) => {
  const hr = getBlock('divider_block', state).create();
  dispatch(state.tr.replaceSelectionWith(hr).scrollIntoView());
  return true;
};

const icon = faHorizontalRule;
export const block = divider;
export const keys = {
  'Mod-_': insertDivider
};
export const menuInsert = {
  title: 'Insert Divider',
  content: icon,
  run: insertDivider
};
