import cuid from 'cuid';
import gql from 'graphql-tag';

const LiteProductGrant = {
  fullFragment: gql`
    fragment fullLiteProductGrant on LiteProductGrant {
        uid
        dateStarts
        dateEnds
        isActive
    }
  `,
  searchFragment: gql`
    fragment searchLiteProductGrant on LiteProductGrant {
      uid
      dateStarts
      dateEnds
      isActive
    }
  `,
  defaults: (existingUid, data = {}) => {
    const uid = existingUid || `_:${cuid()}`;
    const dateStarts = data.dateStarts || null;
    const dateEnds = data.dateEnds || null;

    return {
      client: {
        __typename: 'LiteProductGrant',
        uid,
        dateStarts,
        dateEnds,
        isActive: true
      },
      server: {
        uid,
        dateStarts,
        dateEnds
      }
    };
  },
  typename: 'Lite Product Grant',
};

export default LiteProductGrant;
