import React, { useEffect } from 'react';

import { isString, isNull } from 'lodash-es';
import Button from 'mineral-ui/Button';
import PropTypes from 'prop-types';

import FileItem from '@client/forms/components/FileItem';

import { $widgetButton, $noFiles, $fileContainer } from './style';

export const NO_FILE_UPLOADED = 'No file uploaded';
export const UPLOAD_VIDEO = 'Upload Video';

export default function Cloudinary ({ name, value, config, onChange }) {
  // Cloudinary doesn't have an ES6 way to import their widget so we're
  // dynamically updating the DOM with the script when rendering this component.
  // This way we're able to call Cloudinary through the browser's `window` object.
  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://widget.cloudinary.com/v2.0/global/all.js';
    script.async = true;
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  const showUploadWidget = () => {
    window.cloudinary.openUploadWidget({
      cloudName: 'newsela-operations',
      uploadPreset: 'lnt0ovas',
      folder: '/videos',
      sources: ['local'],
      multiple: false,
      defaultSource: 'local'
    },
    (error, result) => {
      if (!error && result && result.event === 'success') {
        // When uploading a video, we need to update both `videoUrl` and `lengthInSeconds`
        onChange({ [name]: result.info.secure_url, lengthInSeconds: Math.trunc(result.info.duration) });
      }
    });
  };

  const onRemove = (filename) => {
    onChange({ [name]: null }, 'unset');
  };

  return (
    <div>
      <div css={$fileContainer}>
        {/* Show file as link */}
        {isString(value) ? <FileItem filename={value} onRemove={onRemove} /> : null}
        {/* Show message if no files uploaded */}
        {isNull(value) ? <p css={$noFiles}>{NO_FILE_UPLOADED}</p> : null}
      </div>
      <Button
        type='button'
        name={name}
        aria-label={UPLOAD_VIDEO}
        css={$widgetButton}
        onClick={showUploadWidget}
      >
        <span>{UPLOAD_VIDEO}</span>
      </Button>
    </div>
  );
}

Cloudinary.propTypes = {
  /** Field name, which is also the property the data will be saved to */
  name: PropTypes.string,
  /** Field value, from the form-level state */
  value: PropTypes.string,
  /** Full configuration object */
  config: PropTypes.object,
  /** Function that updates the form state and persists data */
  onChange: PropTypes.func
};

// Add 'group' to the display name, to prevent wrapping the whole input in <label>.
Cloudinary.displayName = 'CloudinaryUploadGroup';
