import { faLanguage } from '@fortawesome/pro-light-svg-icons';
import cuid from 'cuid';
import gql from 'graphql-tag';

import { DEFAULT_LANG } from '@client/utils/constants';

const MultilingualText = {
  fullFragment: gql`
    fragment fullMultilingualText on MultilingualText {
      uid
      plainText
      isOriginalLanguage
      language
    }
  `,
  defaults: (id, data = {}) => {
    const uid = id ? `_:${id}` : `_:${cuid()}`;
    const language = data.language || DEFAULT_LANG;
    const isOriginalLanguage = data.isOriginalLanguage || false;
    const plainText = data.plainText || null;

    return {
      client: {
        __typename: 'MultilingualText',
        uid,
        plainText,
        isOriginalLanguage,
        language
      },
      server: {
        uid,
        plainText,
        isOriginalLanguage,
        language
      }
    };
  },
  icon: faLanguage,
  typename: 'Multilingual Text'
};

export default MultilingualText;
