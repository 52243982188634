import { faImage } from '@fortawesome/pro-regular-svg-icons';
import cuid from 'cuid';

import { Image } from '@client/common/schema';
import { image } from '@shared/prosemirror/blocks';

import { getBlock } from './helpers';
const icon = faImage;

const insertImage = (state, dispatch) => {
  const tr = state.tr;
  const id = cuid();
  // Generate a new image, passing the server data back into Prosemirror so
  // it can be sent to the server.
  const newImage = Image.defaults(id);
  tr.setMeta('insertContent', newImage.server);
  const imageBlock = getBlock('image_block', state).create({ contentId: id, isFullWidth: true });
  tr.insert(tr.selection.from, imageBlock);
  dispatch(tr);
};

export const block = image;

export const menuInsert = {
  title: 'Insert Inline Image',
  content: icon,
  active: getBlock('image_block'),
  run: insertImage
};
