import { faBolt, faPlus } from '@fortawesome/pro-regular-svg-icons';
import { useStoreState } from 'easy-peasy';

import { powerWord } from '@shared/prosemirror/blocks';

import powerWordsPlugin from '../plugins/power-words';

const { isActive, setPowerWordsModeTransaction, setMakePowerWordTransaction } = powerWordsPlugin.custom.format;

function enablePWAdd (state) {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const saveStatus = useStoreState((state) => state.saveStatus);

  return !saveStatus?.isPerformingAction;
}

export const block = powerWord;
export const menuPowerWord = [{
  title: 'Toggle Power Words Suggestions',
  content: faBolt,
  active: isActive,
  run: setPowerWordsModeTransaction
}, {
  title: 'Make Power Word',
  content: faPlus,
  select: isActive,
  enable: enablePWAdd,
  run: setMakePowerWordTransaction
}];
