import { faTag } from '@fortawesome/pro-light-svg-icons';
import { constants } from '@newsela/angelou';
import cuid from 'cuid';
import gql from 'graphql-tag';
import { escapeRegExp } from 'lodash-es';
import smartquotes from 'smartquotes';

import rawSchema from '@client/schema';
import { NON_EDITABLE_TAG_TYPES, REGEX_SEARH_MIN, METADATA_SOURCED_TAG_TYPES } from '@client/utils/constants';

function filter (query, { uid }) {
  const andBlock = [
    {
      not: {
        tagType: {
          eq: METADATA_SOURCED_TAG_TYPES
        }
      }
    }
  ];

  // When searching for Tags in the tags editor, uid is not passed in.
  if (uid) {
    // Only push this block if we are searching tags not assigned to content.
    andBlock.push({ not: { assignedTo: { uid_in: [uid] } } });
  }

  return {
    // Tags are not saved with smart quotes, so we don't transform the
    // query before we pass it into the filters.
    and: andBlock,
    ...query.length <= REGEX_SEARH_MIN
      ? {
          // If the query is 3 or fewer characters long, do a term filter.
          tagTitle: { allofterms: query },
          rootFn: 'tagTitle|allofterms'
        }
      : {
          // If the query is longer than three characters, we can do a more accurate
          // regexp filter.
          tagTitle: { regexp: `/${escapeRegExp(query)}/i` },
          rootFn: 'tagTitle|regexp'
        }
  };
}

const Tag = {
  searchFragment: gql`
    fragment searchTag on Tag {
      id
      uid
      name
      tagTitle
      tagDescription
      tagDisplayName
      tagType
      hierarchy
      createdAt
      updatedAt
      assignedCount
      hasDerivedStandards
    }
  `,
  fullFragment: gql`
    fragment fullTag on Tag {
      id
      uid
      name
      tagTitle
      tagDescription
      tagDisplayName
      tagType
      hierarchy
      certicaId
      origin
      createdAt
      updatedAt
      assignedCount
      hasDerivedStandards
    }
  `,
  filter,
  quickFilter: filter,
  defaults: (id, data = {}) => {
    const tagId = id || cuid();
    const uid = data.uid || `_:${tagId}`;
    // newLabel is passed in from AsyncSelect, when new tags are created.
    const tagTitle = data.newLabel || null;

    return {
      client: {
        id: tagId,
        uid,
        __typename: 'Tag',
        tagType: 'PUBLIC_TAG',
        name: tagTitle || 'Tag',
        tagTitle,
        tagDescription: null,
        tagDisplayName: null,
        hierarchy: [],
        certicaId: null,
        origin: 'HUMAN',
        createdAt: (new Date()).toISOString(),
        updatedAt: (new Date()).toISOString(),
        assignedCount: 0
      },
      server: {
        id,
        uid,
        tagTitle
      }
    };
  },
  icon: faTag,
  typename: 'Tag',
  openInApp: false,
  forms: {
    // Tags are only edited in their own app.
    app: [
      {
        input: 'select',
        name: 'tagType',
        isCreatable: false,
        enum: true,
        options: rawSchema.enums.TagType.filter(
          (tagType) => !NON_EDITABLE_TAG_TYPES.includes(tagType)
        )
      },
      {
        input: 'text',
        name: 'tagTitle',
        label: 'Title',
        transform: (data = '') => smartquotes(data)
      },
      {
        input: 'static',
        name: 'assignedCount',
        label: 'Assigned Content Count'
      },
      {
        input: 'static',
        name: 'id',
        label: 'Tag ID',
        caption: 'Used for debugging'
      }
    ]
  },
  inputs: {
    tags: {
      input: 'select',
      name: 'tags',
      isAsync: true,
      isMulti: true,
      isRelation: true,
      type: 'Tag',
      query: 'tags',
      showDropdown: false,
      mapping: {
        label: 'tagTitle',
        value: 'uid',
        type: 'tagType',
        path: 'hierarchy',
        hasDerivedStandards: 'hasDerivedStandards'
      }
    }
  },
  app: {
    icon: faTag,
    color: constants.colors.ui.greyDark[700],
    accentColor: constants.colors.ui.grey[700],
    title: 'Tags',
    href: '/tags',
    permissionForAdmin: true,
    permissionForStaff: true,
    permissionForContributor: false,
    columns: [
      {
        value: 'id',
        showColumn: false
      },
      {
        value: 'uid',
        showColumn: false
      },
      {
        label: 'Title',
        value: 'name',
        isClickable: true
      },
      {
        label: 'Public Tag',
        value: 'tagType',
        checked: 'PUBLIC_TAG'
      },
      {
        label: 'Created On',
        value: 'createdAt',
        date: true
      },
      {
        label: 'Updated On',
        value: 'updatedAt',
        date: true
      },
      {
        label: 'Assigned Content',
        value: 'assignedCount'
      }
    ],
    order: { asc: 'tagTitle' }
  }
};

export default Tag;
