import { faTextWidth } from '@fortawesome/pro-regular-svg-icons';

import { blockActive } from './helpers';
const icon = faTextWidth;

const alignImage = (state, dispatch) => {
  const node = state.selection.node;
  const tr = state.tr.setNodeMarkup(state.selection.from, node.type, { ...node.attrs, isFullWidth: !node.attrs.isFullWidth });
  dispatch(tr);
};

export const menuInsert = {
  title: 'Toggle Image Alignment',
  content: icon,
  select: blockActive('image_block'),
  run: alignImage
};
