import { faLayerGroup } from '@fortawesome/pro-light-svg-icons';
import cuid from 'cuid';
import gql from 'graphql-tag';

import { isLevelerFeatureOn } from '@client/forms/inputs/ProsemirrorLeveler/utils/feature';
import rawSchema from '@client/schema';
import { DEFAULT_LANG, DEFAULT_GRADE } from '@client/utils/constants';
import { sortGradeBands, filterGradeBands } from '@client/utils/fields';
import { NO_LEXILE } from '@shared/constants';

import WordDefinition from './word-definition';

const prosemirrorLevelerInput = {
  input: 'prosemirrorLeveler',
  name: 'text',
  value: 'rawText',
  isMultiline: true,
  // Shows lexile per paragraph to the left of the text editor
  // and overall lexile/word count at the bottom of the editor.
  showLexile: true,
  showVocabLevel: true,
  showPowerWords: true,
  formats: ['bold', 'italic', 'headings', 'quote', 'language', 'list', 'orderedList', 'link', 'pre', 'ignore', 'image', 'imageAlignment', 'powerWord', 'leveler', 'toggleLeveler']
};

const prosemirrorLegacyInput = {
  input: 'prosemirror',
  name: 'text',
  value: 'rawText',
  isMultiline: true,
  // Shows lexile per paragraph to the left of the text editor
  // and overall lexile/word count at the bottom of the editor.
  showLexile: true,
  showVocabLevel: true,
  showPowerWords: true,
  formats: ['bold', 'italic', 'headings', 'quote', 'language', 'list', 'orderedList', 'link', 'pre', 'ignore', 'image', 'imageAlignment', 'powerWord']
};

const prosemirrorInput = isLevelerFeatureOn ? prosemirrorLevelerInput : prosemirrorLegacyInput;

const ArticleLevelForLeveler = {
  // We need to ask for index in lexilePerParagraph because even though
  // we're not using it on the client, we need to call it on the API
  // to make sure scores get sorted correctly in the resolver.
  fullFragment: gql`
    fragment fullArticleLevel on ArticleLevel {
      uid
      gradeBand
      language
      rawLexile
      lexile
      isOriginalLevel
      isActive
      shortTitle
      preventLexileUpdate
      autogeneratedByline
      bylineOverride
      title(format: TEXT_PLAIN)
      htmlTitle: title(format: TEXT_HTML)
      rawTitle: title(format: TEXT_RAW)
      teaser(format: TEXT_PLAIN)
      htmlTeaser: teaser(format: TEXT_HTML)
      rawTeaser: teaser(format: TEXT_RAW)
      text(format: TEXT_PLAIN)
      htmlText: text(format: TEXT_HTML)
      rawText: text(format: TEXT_RAW)
      wordCount
      wordDefinitions {
        ...fullWordDefinition
      }
      lexilePerParagraph {
        index
        lexile
        rawLexile
        wordCount
      }
    }
    ${WordDefinition.fullFragment}
  `,
  defaults: (id, data = {}) => {
    const uid = id ? `_:${id}` : `_:${cuid()}`;
    const language = data.language || DEFAULT_LANG;
    const gradeBand = data.gradeBand || DEFAULT_GRADE;
    const isOriginalLevel = data.isOriginalLevel || false;

    return {
      client: {
        uid,
        gradeBand,
        language,
        rawLexile: null,
        lexile: NO_LEXILE,
        lexilePerParagraph: null,
        isOriginalLevel,
        isActive: true,
        __typename: 'ArticleLevel',
        shortTitle: null,
        autogeneratedByline: null,
        bylineOverride: null,
        title: null,
        rawTitle: null,
        htmlTitle: null,
        teaser: null,
        rawTeaser: null,
        htmlTeaser: null,
        text: null,
        rawText: null,
        htmlText: null,
        preventLexileUpdate: false,
        wordCount: 0,
        wordDefinitions: []
      },
      server: {
        uid,
        gradeBand,
        language,
        isOriginalLevel,
        isActive: true
      }
    };
  },
  icon: faLayerGroup,
  typename: 'Article Level',
  forms: {
    shared: [
      {
        input: 'prosemirror',
        name: 'title',
        value: 'rawTitle',
        formats: ['italic']
      }, {
        input: 'text',
        name: 'shortTitle',
        label: 'Short Title',
        caption: 'Used for cards and search results',
        button: {
          input: 'magic-button',
          value: (data) => data.title || '',
          description: 'Copy title'
        }
      }, {
        input: 'static',
        name: 'autogeneratedByline',
        label: 'Autogenerated byline'
      },
      {
        input: 'text',
        name: 'bylineOverride',
        label: 'Byline on view page',
        caption: 'This field overrides the autogenerated byline',
        placeholder: (data) => data.autogeneratedByline
      },
      {
        input: 'prosemirror',
        name: 'teaser',
        value: 'rawTeaser',
        formats: ['bold', 'italic']
      },
      {
        ...prosemirrorInput
      },
      { section: 'Current Level Metadata' },
      // Allows changing the current level's Original status and grade band.
      // Note that we don't allow changing the language because it's not common
      // and it breaks a number of assumptions in the ArticleLevelsSelector input.
      {
        input: 'checkbox',
        name: 'isOriginalLevel',
        longLabel: 'Original Level',
        showLabel: false,
        caption: 'Is this the original article data from our content partner?'
      },
      {
        input: 'select',
        name: 'gradeBand',
        label: 'Grade Band',
        // Sort the grade bands in descending order, but only include
        // numerical grade bands (because articles must use numerical grade bands).
        options: rawSchema.enums.GradeBand
          .sort((a, b) => sortGradeBands({ gradeBand: a }, { gradeBand: b }))
          .filter((gradeBand) => filterGradeBands(false)({ value: gradeBand }))
      }
    ]
  }
};

const ArticleLevelLegacy = {
  // We need to ask for index in lexilePerParagraph because even though
  // we're not using it on the client, we need to call it on the API
  // to make sure scores get sorted correctly in the resolver.
  fullFragment: gql`
    fragment fullArticleLevel on ArticleLevel {
      uid
      updatedAt
      gradeBand
      language
      rawLexile
      lexile
      isOriginalLevel
      isActive
      shortTitle
      preventLexileUpdate
      autogeneratedByline
      bylineOverride
      title(format: TEXT_PLAIN)
      htmlTitle: title(format: TEXT_HTML)
      rawTitle: title(format: TEXT_RAW)
      teaser(format: TEXT_PLAIN)
      htmlTeaser: teaser(format: TEXT_HTML)
      rawTeaser: teaser(format: TEXT_RAW)
      text(format: TEXT_PLAIN)
      htmlText: text(format: TEXT_HTML)
      rawText: text(format: TEXT_RAW)
      wordCount
      wordDefinitions {
        ...fullWordDefinition
      }
      lexilePerParagraph {
        index
        lexile
        rawLexile
        wordCount
      }
    }
    ${WordDefinition.fullFragment}
  `,
  defaults: (id, data = {}) => {
    const uid = id ? `_:${id}` : `_:${cuid()}`;
    const language = data.language || DEFAULT_LANG;
    const gradeBand = data.gradeBand || DEFAULT_GRADE;
    const isOriginalLevel = data.isOriginalLevel || false;

    return {
      client: {
        uid,
        gradeBand,
        language,
        rawLexile: null,
        lexile: NO_LEXILE,
        lexilePerParagraph: null,
        isOriginalLevel,
        isActive: true,
        __typename: 'ArticleLevel',
        shortTitle: null,
        autogeneratedByline: null,
        bylineOverride: null,
        title: null,
        rawTitle: null,
        htmlTitle: null,
        teaser: null,
        rawTeaser: null,
        htmlTeaser: null,
        text: null,
        rawText: null,
        htmlText: null,
        preventLexileUpdate: false,
        wordCount: 0,
        wordDefinitions: []
      },
      server: {
        uid,
        gradeBand,
        language,
        isOriginalLevel,
        isActive: true
      }
    };
  },
  icon: faLayerGroup,
  typename: 'Article Level',
  forms: {
    shared: [
      {
        input: 'prosemirror',
        name: 'title',
        value: 'rawTitle',
        formats: ['italic']
      }, {
        input: 'text',
        name: 'shortTitle',
        label: 'Short Title',
        caption: 'Used for cards and search results',
        button: {
          input: 'magic-button',
          value: (data) => data.title || '',
          description: 'Copy title'
        }
      }, {
        input: 'static',
        name: 'autogeneratedByline',
        label: 'Autogenerated byline'
      },
      {
        input: 'text',
        name: 'bylineOverride',
        label: 'Byline on view page',
        caption: 'This field overrides the autogenerated byline',
        placeholder: (data) => data.autogeneratedByline
      },
      {
        input: 'prosemirror',
        name: 'teaser',
        value: 'rawTeaser',
        formats: ['bold', 'italic']
      },
      {
        ...prosemirrorInput
      },
      { section: 'Current Level Metadata' },
      // Allows changing the current level's Original status and grade band.
      // Note that we don't allow changing the language because it's not common
      // and it breaks a number of assumptions in the ArticleLevelsSelector input.
      {
        input: 'checkbox',
        name: 'isOriginalLevel',
        longLabel: 'Original Level',
        showLabel: false,
        caption: 'Is this the original article data from our content partner?'
      },
      {
        input: 'select',
        name: 'gradeBand',
        label: 'Grade Band',
        // Sort the grade bands in descending order, but only include
        // numerical grade bands (because articles must use numerical grade bands).
        options: rawSchema.enums.GradeBand
          .sort((a, b) => sortGradeBands({ gradeBand: a }, { gradeBand: b }))
          .filter((gradeBand) => filterGradeBands(false)({ value: gradeBand }))
      }
    ]
  }
};

const ArticleLevel = isLevelerFeatureOn ? ArticleLevelForLeveler : ArticleLevelLegacy;

export default ArticleLevel;
