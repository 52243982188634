import { useHeap } from './hooks/useHeap';

/**
 * Central hub for analytics.
 *
 * This component integrates Heap for user identification and event tracking.
 * The `useHeap` hook handles user data and custom Heap information during initialization.
 *
 * Future plans include adding Snitch for further analytics capabilities.
 *
 * @component
 */
const EventTracker = () => {
  useHeap();

  // In the future, additional event tracking logic can be added here as needed.
  return null;
};

export default EventTracker;
