import React from 'react';

import { isArray, isEmpty, identity } from 'lodash-es';
import PropTypes from 'prop-types';
import Slider, { Range } from 'rc-slider';

import 'rc-slider/assets/index.css';
import { $root } from './style';

export default function SliderInput ({ value, config, name, onChange }) {
  const transform = config.transform || identity;
  const format = config.format || identity;
  const isRange = !!config.isRange;
  const min = config.minValue || 0;
  const max = config.maxValue || 100;
  let step = config.step || 1;
  let marks;

  // If we explicitly disallow steps, set it to null. This forces users to
  // select a value that corresponds to a specific mark, and prevents them from
  // selecting a value in between marks.
  if (config.hasSteps === false) {
    step = null;
  }

  // Call the format function if specified in the config. This transforms the
  // value into something that can be displayed in the slider.
  value = format(value);

  // config.marks may be an array of numbers, or an object with { [val]: label }.
  // More details: http://react-component.github.io/slider/?path=/story/rc-slider--marks
  if (isArray(config.marks) || isEmpty(config.marks)) {
    marks = (config.marks || [min, Math.round(max / 2), max]).reduce((acc, m) => {
      return {
        ...acc,
        [m]: m
      };
    }, {});
  } else {
    marks = config.marks;
  }

  const handleSelect = (val) => {
    // Call the transform function if specified in the config,
    // then pass the resulting value up to the form's onChange() function.
    const transformedVal = transform(val);

    onChange({ [name]: transformedVal });
  };

  value = value || (isRange ? [min, max] : min); // Cast to array or number.
  return isRange
    ? <Range
        min={min}
        max={max}
        step={step}
        marks={marks}
        defaultValue={value}
        onAfterChange={handleSelect}
        css={$root}
      />
    : <Slider
        min={min}
        max={max}
        step={step}
        marks={marks}
        defaultValue={value}
        onAfterChange={handleSelect}
        css={$root}
      />;
}

SliderInput.propTypes = {
  /** Field value, from the form-level state */
  value: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.number,
    PropTypes.string
  ]),
  /** Full configuration object */
  config: PropTypes.object,
  /** Field name, which is also the property the data will be saved to */
  name: PropTypes.string,
  /** Function that updates the form state and persists data */
  onChange: PropTypes.func
};
