import { faGraduationCap } from '@fortawesome/pro-light-svg-icons';
import gql from 'graphql-tag';

const MetadataStandard = {
  searchFragment: gql`
    fragment searchMetadataStandard on MetadataStandard {
      id
      certicaId
      standardTitle
      standardDescription
      region
      grades
      subjectShort
      subjectLong
      hasDerivedStandards
    }
  `,
  blueprintFragment: gql`
    fragment blueprintMetadataStandard on MetadataStandard {
      id
      standardTitle
      standardDescription
    }
  `,
  fullFragment: gql`
    fragment fullMetadataStandard on MetadataStandard {
      id
      certicaId
      standardTitle
      standardDescription
      region
      grades
      subjectShort
      subjectLong
      hasDerivedStandards
    }
  `,
  defaults: (id, data = {}) => {
    // newLabel is passed in from AsyncSelect. Because standards are NEVER
    // created on the client, Standard.defaults() returns empty 'server'
    // information.
    const standardTitle = data.newLabel || null;
    // Reference existing ids when adding standards.

    return {
      client: {
        id: data.id,
        __typename: 'MetadataStandard',
        certicaId: null,
        standardTitle,
        standardDescription: null,
        region: null,
        grades: null,
      },
      server: {}
    };
  },
  icon: faGraduationCap,
  typename: 'MetadataStandard',
  // Because standards come directly from Certica, they're never edited directly.
  // This schema only exports an input for content types to add and remove standards.
  inputs: {
    metadataStandards: {
      input: 'standard-select',
      name: 'metadataStandards',
      label: 'Standard'
    }
  }
};

export default MetadataStandard;
