import React, { useState, useCallback, useEffect } from 'react';

import { useStoreState } from 'easy-peasy';
import { isString, isFunction, identity, debounce } from 'lodash-es';
import TextInput from 'mineral-ui/TextInput';
import PropTypes from 'prop-types';

import AttachedButton from '@client/forms/components/AttachedButton';
import { TEXT_DEBOUNCE_TIME } from '@client/utils/constants';
import { evaluateBooleanValueFromConfig as isConfigReadOnly } from '@client/utils/fields';

import { $root } from './style';

function toString (val) {
  if (val && !isString(val)) {
    // Cast the value to a string if we're passed a number.
    return val.toString();
  } else if (!val) {
    return ''; // Cast empty values to a string.
  } else {
    return val; // Pass through strings.
  }
}

export default function Text ({ value, name, config, onChange, formData, variant }) {
  const prefix = config.prefix && config.prefix(formData)
    ? config.prefix(formData)
    : null;
  const transform = config.transform || identity;
  const type = ['int', 'float'].includes(config.type) ? 'number' : 'text';

  // Set the placeholder value. You can pass a string or a function to
  // config.placeholder. If you pass a function, it will be called with the
  // form data.
  const placeholder = isFunction(config.placeholder)
    ? config.placeholder(formData)
    : config.placeholder;

  const [text, setText] = useState(toString(value));
  const refreshForm = useStoreState((state) => state.forms.formRefresh.nonce);

  // For most fields, optimistic mutations update appropriately but for fields where the user is typing in real-time,
  // optimistic updates are slow enough to cause input lag. These fields (Text, TextArea, Prosemirror) require their onChange to be debounced.
  // The addition of the useCallback hook prevents the debounced onChange from being recreated on every render,
  // which would prevent the onChange from properly debouncing.
  const debouncedOnChange = useCallback(debounce(onChange, TEXT_DEBOUNCE_TIME), [formData]);

  // Reset text when changing drawers and when the form updates from the server.
  useEffect(() => {
    setText(toString(value));
  }, [formData?.id, formData?.uid, refreshForm, value]);

  // Called when text is entered via typing or a button.
  const onUpdate = (str) => {
    let val;

    if (config.type === 'int') {
      // Cast value to an integer.
      val = parseInt(str);
    } else if (config.type === 'float') {
      // Cast value to a floating point number.
      val = parseFloat(str);
    } else {
      // Value is a string!
      val = str;
    }

    setText(toString(transform(val)));
    if (transform(val)) {
      // Additive change.
      debouncedOnChange({ [name]: transform(val) });
    } else {
      // User has cleared out the input. Unset it!
      debouncedOnChange({ [name]: transform(val) }, 'unset');
    }
  };
  const onTextInput = (e) => {
    const updatedValue = e.target.value;
    onUpdate(updatedValue);
  };
  const button = config.button
    ? <AttachedButton config={config} onUpdate={onUpdate} formData={formData} />
    : null;

  // Read Only is used for inputs that will NEVER be enabled,
  // Disabled is used for inputs that are temporarily disabled.
  return (
    <TextInput
      css={$root(variant)}
      type={type}
      value={text}
      name={name}
      aria-label={config.label || name}
      id={`${name}-text-input`}
      onChange={onTextInput}
      readOnly={isConfigReadOnly(config.isReadOnly, formData)}
      disabled={config.isDisabled}
      placeholder={placeholder}
      required={config.required}
      iconEnd={button}
      maxLength={config.maxLength || null}
      {...prefix && { prefix }}
    />
  );
}

Text.propTypes = {
  /** Field value, from the form-level state */
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  /** Field name, which is also the property the data will be saved to */
  name: PropTypes.string,
  /** Full configuration object */
  config: PropTypes.object,
  /** Function that updates the form state and persists data */
  onChange: PropTypes.func,
  /** Full form data */
  formData: PropTypes.object,
  variant: PropTypes.string
};
