import React from 'react';

import PropTypes from 'prop-types';

import { $metaCurrentVal } from '@client/forms/inputs/ArticleLevelsSelector/style';
import { getTarget } from '@client/utils/lexile';
import { NO_LEXILE } from '@shared/constants';

import {
  $levelMetaData,
  $current,
  $heading,
  $target
} from './style';

export default function LexileWordCount ({ formData }) {
  const { rawLexile, lexile, wordCount, gradeBand, isOriginalLevel } = formData;

  return (
    <>
      <div css={$levelMetaData}>
        <p css={$current}><span css={$heading}>Lexile:</span> <span css={$metaCurrentVal(rawLexile, gradeBand, 'rawLexile', isOriginalLevel)}>{lexile || NO_LEXILE}</span></p>
        <p css={$target}><span css={$heading}>Target:</span> <span>{getTarget(gradeBand, 'rawLexile', formData.isOriginalLevel)}</span></p>
      </div>
      <div css={$levelMetaData}>
        <p css={$current}><span css={$heading}>Word Count:</span> <span css={$metaCurrentVal(wordCount, gradeBand, 'wordCount', isOriginalLevel)}>{wordCount}</span></p>
        <p css={$target}><span css={$heading}>Target:</span> <span>{getTarget(gradeBand, 'wordCount', isOriginalLevel)}</span></p>
      </div>
    </>
  );
}

LexileWordCount.propTypes = {
  /** Full form data */
  formData: PropTypes.object
};
