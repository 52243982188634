import { css } from '@emotion/core';

export const $fileName = ({ colors }) => css`
  color: ${colors.ui.greyDark[500]};
  margin-left: 10px;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;

export const $button = ({ colors }) => css`
  flex: 0 1 auto;

  svg[role='img'] {
    color: ${colors.ui.greyDark[500]};
  }

  &:hover {
    background-color: ${colors.ui.greyLight[100]};
  }
`;

export const $spacer = css`
  flex: 1 1 auto;
`;

export const $fileInfo = css`
  align-items: center;
  display: flex;
  jusify-content: flex-start;
  margin: 10px 0;
  padding: 0 2px;
  width: 100%;
`;
