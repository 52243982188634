import { ArticleSVG, Button } from '@newsela/angelou';
import { useStoreActions } from 'easy-peasy';
import PropTypes from 'prop-types';

import * as schemas from '@client/common/schema';
import { logError } from '@client/utils/log';
import * as toast from '@client/utils/toast';

export default function AppButton ({ value, config, formData }) {
  const closeDrawer = useStoreActions((actions) => actions.drawer.close);
  const type = config.type || formData.__typename;
  const schema = schemas[type];

  let icon;
  let buttonlabel;
  let goToApp;
  let disabled = false;

  if (!schema) {
    toast.error(`Cannot find app configuration for type: ${type}`);
    logError(`AppButton cannot find schema for type: ${type}`);
    return null;
  }

  // We do not currently have any content types whose forms are DIFFERENT
  // in the Bundles app vs Inventory Management, but if we ever do this in the
  // future this code will gracefully support it.
  const appHrefPath = schema.app?.href || '/inventory';
  const appTitle = schema.app?.title || 'Inventory Management';

  if (formData.__typename === 'LegacyArticle' && !value) {
    // For legacy articles without a value (articledId) button should be disabled
    disabled = true;
    icon = ArticleSVG;
    buttonlabel = 'Open Articles App';
  } else {
    // For all other content types, and for LegacyArticles with an articleId,
    // we redirect to the proper Alexandria app.
    icon = schema.icon;
    buttonlabel = config.buttonLabel || `Open ${appTitle} App`;
    goToApp = () => {
      closeDrawer();
      // Close the drawer and open the article editor in a new tab.
      const newPath = window.location.href.replace(/(\/bundles\/.*|\/$|$)/, `${appHrefPath}/${value}`);
      window.open(newPath, '_blank');
    };
  }

  return (
    <Button
      icon={{
        SvgComponent: icon,
        alignment: Button.iconAlignments.left
      }}
      /* AUTOGENERATED TODO: update angelou to new flavor.
        see https://github.com/newsela/angelou/blob/main/src/components/Button/README.md#MIGRATION
        for migration guide. */
      legacy_flavor={Button.legacy_flavor.solid}
      legacy_size={Button.legacy_size.justified}
      disabled={disabled}
      onClick={goToApp}
    >
      {buttonlabel}
    </Button>
  );
}

AppButton.propTypes = {
  /** Value will be the content id, if it exists */
  value: PropTypes.string,
  config: PropTypes.object,
  formData: PropTypes.object
};
