
import { useLocation } from '@reach/router';
import { isObject, isArray, isEmpty, last, get, has } from 'lodash-es';

import { createBreadcrumbs, fields, getParent } from '@client/utils/breadcrumbs';

function getPathString (searchParamsString) {
  const searchParams = new URLSearchParams(searchParamsString);
  return searchParams.get('path');
}

/**
 * Transforms a deep link path string into an array.
 * @param {String} pathString ex: 'children.childrenId.attached.attachedId.questions.questionsId'
 * @returns {Array} pathArray with [ { field, id }, { field, id}, ... ]
 */
export function pathStringToArray (pathString) {
  if (!pathString || !pathString.includes('.')) return [];

  return pathString.split('.').reduce((acc, curr) => {
    if (last(acc)?.field && !last(acc)?.id) {
      last(acc).id = curr;
    } else {
      acc.push({ field: curr });
    }
    return acc;
  }, []);
}

/**
 * Transforms a deep link path array into a string.
 * @param {Array} pathArray with [ { field, id }, { field, id}, ... ]
 * @returns {String} pathString ex: 'children.childrenId.attached.attachedId.questions.questionsId'
 */
export function pathArrayToString (pathArray) {
  if (!isArray(pathArray)) return '';
  return pathArray
    .filter(isObject)
    .filter((item) => has(item, 'field') && has(item, 'id'))
    .map((item) => `${item.field}.${item.id}`)
    .join('.');
}

export function getDestinationContent (rootData, pathArray) {
  const { content } = pathArray.reduce((acc, current) => {
    const field = acc.content[current.field];
    acc.path = acc.path ? `${acc.path}.` : acc.path;
    if (isArray(field)) {
      const index = field.findIndex((item) => item.id === current.id);
      acc.path = `${acc.path}${current.field}.${index}`;
    } else if (isObject(field)) {
      acc.path = `${acc.path}${current.field}`;
    }
    acc.content = get(rootData, acc.path, null);
    return acc;
  }, { path: '', content: rootData });
  return content;
}

/**
 * Extracts the target data from the root data.
 * @param {Object} rootData root content data (Article or Root Bundle)
 * @param {Object} info with { field, id, fieldPath }
 * @returns {Object} targetData
 */
export function getDestinationData (rootData, pathArray) {
  const lastPath = last(pathArray);
  const info = fields[lastPath.field];
  const destination = { ...lastPath, ...info };
  const content = getDestinationContent(rootData, pathArray);

  if (content) {
    destination.uid = content.uid;
    destination.type = content.__typename;
    return destination;
  } else {
    return null;
  }
}

/**
 * Generates the data needed to open a deep link.
 * @param {Object} rootData root content data (Article or Root Bundle)
 * @param {String} searchParamsString URL search params string
 * @returns {Object} deepLinkData with { id, uid, type, drawerType, parent, breadcrumbs }
 */
export function getDeepLinkData (rootData, searchParamsString) {
  const pathArray = pathStringToArray(getPathString(searchParamsString));
  if (isEmpty(pathArray)) return {};

  const destination = getDestinationData(rootData, pathArray);
  if (!destination) return {};

  const destinationParent = getDestinationContent(rootData, pathArray.slice(0, -1));

  return {
    id: destination.id,
    uid: destination.uid,
    type: destination.type,
    drawerType: destination.drawerType,
    parent: getParent({ parent: destinationParent, field: destination.field }),
    breadcrumbs: createBreadcrumbs(rootData, pathArray)
  };
}

export function expandToChild (tree, mutateTree, searchParamsString) {
  const pathArray = pathStringToArray(getPathString(searchParamsString));
  // Edge case: when there are duplicate ids in the tree it may not expand correctly.
  const mutatedTree = pathArray.slice(0, -1).reduce((acc, path) => {
    const id = Object.keys(acc.items).find((itemId) => itemId.startsWith(path.id));
    return tree.items[id]?.hasChildren ? mutateTree(acc, id, { isExpanded: true }) : acc;
  }, tree);
  return mutatedTree;
}

/**
 * Generates a path array from breadcrumbs.
 * @param {Array} breadcrumbs with [ { id, parent, ... }, { id, parent, ...}, ... ]
 * @returns {Array} with [ { field, id }, { field, id}, ... ]
 */
export function getPathArrayFromBreadcrumbs (breadcrumbs) {
  return breadcrumbs
    .slice(1) // remove the root content
    .map((item) => ({ field: item.parent.field, id: item.id }));
}

/**
 * Generates a new path string from breadcrumbs, parent and content data.
 * @param {Array} breadcrumbs with [ { id, parent, ... }, { id, parent, ...}, ... ]
 * @param {Object} parent with { field, ... }
 * @param {Object} data with { id, ... }
 * @returns {String} ex: 'children.ch1.children.ch2.attached.at1.questions.q1'
 */
export function getNewPath (breadcrumbs, parent, data) {
  const parentsPath = getPathArrayFromBreadcrumbs(breadcrumbs);
  const currentPath = { field: parent.field, id: data.id };
  return pathArrayToString([...parentsPath, currentPath]);
}

export function getUpdatedSearchParams (searchParams, param, path) {
  if (path) {
    searchParams.set(param, path);
  } else {
    searchParams.delete(param);
  }

  return `?${searchParams.toString()}`;
}

export const useSearchParams = () => {
  const location = useLocation();
  return new URLSearchParams(location.search);
};
