import { mutations } from '@client/common/graph';

/**
 * Create a new content
 *
 * @param {Object} content data
 * @param {Object} client ApolloClient
 */
export async function createInsertedContent (content, client) {
  await client.mutate({
    mutation: mutations.setContent,
    variables: {
      input: content
    }
  });
}
