import React from 'react';

import Button from 'mineral-ui/Button'; // Angelou buttons don't handle anchor tags
import PropTypes from 'prop-types';

import image from './lost.jpg';
import {
  $root,
  $header,
  $image,
  $button
} from './style';

export const DEFAULT_MESSAGE = '404 - You Must Be Lost';
export default function FourOhFour ({ message = DEFAULT_MESSAGE, back }) {
  return (
    <section css={$root}>
      <h1 css={$header}>{message}</h1>
      <img
        src={image} alt='BMO Lost, Adventure Time Season 5 Episode 17'
        css={$image}
      />
      {back ? (<Button as='a' href={back} css={$button}>Back</Button>) : null}
    </section>
  );
}

FourOhFour.propTypes = {
  /** Error message to display */
  message: PropTypes.string,
  /** Path that the back button should link to */
  back: PropTypes.string
};
