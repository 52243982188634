import { FolderSmartSVG } from '@newsela/angelou';
import gql from 'graphql-tag';
import { escapeRegExp } from 'lodash-es';
import smartquotes from 'smartquotes';

import Bundle from './bundle';
import Content from './content';
import MetadataStandard from './metadataStandard';
import MetadataTag from './metadataTag';
import Stream from './stream';
import Tag from './tag';

function filter (query) {
  const quoted = smartquotes(query);

  return {
    or: [
      { title: { alloftext: quoted } },
      { description: { alloftext: quoted } },
      // Label and slug do not use smart quotes.
      { label: { allofterms: query } },
      { slug: { regexp: `/${escapeRegExp(query)}/i` } }
    ]
  };
}

function quickFilter (query) {
  const quoted = smartquotes(query);

  return {
    title: { alloftext: quoted },
    rootFn: 'title|alloftext'
  };
}

const SmartBundle = {
  previewFragment: gql`
    fragment previewSmartBundle on SmartBundle {
      label
      algorithm
      algorithmParameters
      algorithmLimit
      description(format: TEXT_PLAIN)
      htmlDescription: description(format: TEXT_HTML)
    }
  `,
  blueprintFragment: gql`
    fragment blueprintSmartBundle on SmartBundle {
      label
      rawTitle: title(format: TEXT_RAW)
      rawDescription: description(format: TEXT_RAW)
    }
  `,
  fullFragment: gql`
    fragment fullSmartBundle on SmartBundle {
      label
      title(format: TEXT_PLAIN)
      rawTitle: title(format: TEXT_RAW)
      description(format: TEXT_PLAIN)
      htmlDescription: description(format: TEXT_HTML)
      rawDescription: description(format: TEXT_RAW)
      slug
      algorithm
      algorithmParameters
      algorithmLimit
    }
  `,
  filter,
  quickFilter,
  defaults: (id, data) => {
    const contentDefaults = Content.defaults(id, data);

    return {
      client: {
        ...contentDefaults.client,
        __typename: 'SmartBundle',
        contentType: 'SMART_BUNDLE',
        name: 'Smart Bundle',
        label: 'Smart Bundle',
        // All fields need to be specified here, as they're used
        // to optimistically update the apollo-client cache.
        title: null,
        rawTitle: null,
        description: null,
        htmlDescription: null,
        rawDescription: null,
        slug: null,
        algorithm: null,
        algorithmParameters: null,
        algorithmLimit: null
      },
      server: {
        ...contentDefaults.server,
        contentType: 'SMART_BUNDLE'
      }
    };
  },
  isContent: true,
  icon: FolderSmartSVG,
  typename: 'Smart Bundle',
  forms: {
    shared: [
      // SmartBundle shares a lot of fields with Bundle, so we pull in the
      // configs directly from the Bundle schema.
      { tab: 'General' },
      Bundle.inputs.title,
      {
        ...Bundle.inputs.label,
        defaultValue: 'Smart Bundle'
      },
      Bundle.inputs.slug,
      Bundle.inputs.description,
      Content.inputs.attached,
      Tag.inputs.tags,
      MetadataTag.inputs.metadataTags,
      MetadataStandard.inputs.metadataStandards,
      Stream.inputs.subjectProductStreams,
      Stream.inputs.customStreams,
      Content.inputs.notes,
      Content.inputs.parentsList,
      Content.inputs.id,
      { tab: 'Algorithm' },
      {
        input: 'select',
        name: 'algorithm',
        isAsync: true,
        preloadOptions: true,
        type: 'Algorithm',
        query: 'smartBundleAlgorithms'
      },
      {
        input: 'algorithm-parameters',
        name: 'algorithmParameters',
        label: 'Algorithm Parameters'
      },
      {
        input: 'text',
        name: 'algorithmLimit',
        type: 'int',
        label: 'Limit',
        caption: 'The maximum amount of content to be displayed'
      }
    ]
  }
};

export const Algorithm = {
  // When fetching the list of algorithms, we never filter it.
  search: () => {
    return {};
  }
};

export default SmartBundle;
