/* eslint-disable camelcase */
import { css } from '@emotion/core';

import { MODAL_WIDTH } from '@client/utils/constants';
const leftColumnWidth = MODAL_WIDTH / 3;
const rightColumnWidth = leftColumnWidth * 2;

export const $form = ({ colors, typographyStyles }) => css`
  display: flex;
  justify-content: center;
  overflow: hidden;
  width: 100%;
  height: 100%;

  & label > div:first-of-type {
    margin-bottom: 16px;
  }

  & label span[id^='formField'] {
    ${typographyStyles.textCaps}
    font-weight: initial;
  }

  & input[type='checkbox']:checked + span,
  & input[type='checkbox']:indeterminate + span {
    background-color: ${colors.ui.greyDark[700]};
    border-color: ${colors.ui.greyDark[700]};
  }

  & input[type='checkbox']:focus + span  {
    box-shadow: 0 0 0 1px #ffffff, 0 0 0 3px ${colors.ui.coreBlue[700]};
  }

  & label[class]:hover span:first-of-type {
    border-color: ${colors.ui.greyDark[700]};
  }

  & > div {
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    padding: 0 32px;
  }

  & > div:nth-of-type(1) {
    width: ${leftColumnWidth}px;
  }

  & > div:nth-of-type(2) {
    border-left: 1px solid ${colors.ui.greyLight[100]};
    width: ${rightColumnWidth}px;
  }

  & > div > div {
    margin: 32px 0;
  }

  & > div > :not(div:first-of-type) {
    margin-top: 0;
  }
`;
