import React, { useState } from 'react';

import Button from 'mineral-ui/Button';
import Tooltip from 'mineral-ui/Tooltip';
import PropTypes from 'prop-types';

import Icon from '@client/common/components/Icon';

import {
  $menuButton,
  $menuDivider
} from './style';

export default function FormattingButton ({ item, statefulView, isDisabled, primary }) {
  const [isRunning, setRunning] = useState(false);

  if (item.isDivider) {
    // If we're rendering a divider, it'll be the string 'divider' rather than
    // a button config object.
    return <span css={$menuDivider} role='separator' />;
  } else {
    return (
      <Tooltip content={item.title}>
        <Button
          aria-label={item.title}
          minimal
          css={$menuButton(primary)}
          disabled={isDisabled || isRunning}
          primary={primary}
          onMouseDown={(e) => {
            // Prevent mousedowns on the menu buttons from stealing focus
            // from the input.
            e.preventDefault();
          }}
          onClick={(e) => {
            e.preventDefault();
            if (!isRunning) {
              setRunning(true);
              item.run(statefulView.current.state, statefulView.current.dispatch);
              // Wait a bit before allowing another click. This prevents double
              // clicks from being registered, and allows time for the Prosemirror
              // state to update.
              setTimeout(() => {
                setRunning(false);
              }, 300);
            }
          }}
        >
          <Icon icon={item.content} />
        </Button>
      </Tooltip>
    );
  }
}

FormattingButton.propTypes = {
  item: PropTypes.shape({
    _key: PropTypes.string,
    title: PropTypes.string,
    content: PropTypes.any,
    isDivider: PropTypes.bool,
    select: PropTypes.func,
    active: PropTypes.func,
    run: PropTypes.func
  }),
  statefulView: PropTypes.object,
  isDisabled: PropTypes.bool,
  /** Passed in from ButtonGroup when button is active. */
  primary: PropTypes.bool
};
