import { faBolt } from '@fortawesome/free-solid-svg-icons';
import { constants } from '@newsela/angelou';
import gql from 'graphql-tag';

import { APP_NAME } from '@client/utils/constants';

// Types used by alexandria client, in alphabetical order:
import Article, { LegacyWorkflow } from './article';
import ArticleLevel from './article-level';
import ArticlePublication from './article-publication';
import Assessment from './assessment';
import AssessmentLevel from './assessment-level';
import AssessmentQuestion from './assessment-question';
import Audio from './audio';
import Bundle from './bundle';
import Content, { Event } from './content';
import ContentProvider from './content-provider';
import Documentation from './documentation';
import ExternalLink from './external-link';
import Image from './image';
import InstructionalNote from './instructional-note';
import LegacyArticle from './legacy-article';
import LessonSpark from './lesson-spark';
import Level from './level';
import LiteProductGrant from './lite-product-grant';
import MetadataStandard from './metadataStandard';
import MetadataTag from './metadataTag';
import MultilingualText from './multilingual-text';
import QuestionOption from './question-option';
import SmartBundle, { Algorithm } from './smart-bundle';
import Standard from './standard';
import Stream from './stream';
import Studio from './studio';
import Tag from './tag';
import TaxonomyTag from './taxonomyTag';
import Video from './video';
import Wire from './wire';
import Word from './word';
import WordDefinition from './word-definition';

export {
  Algorithm,
  Article,
  ArticleLevel,
  ArticlePublication,
  Assessment,
  AssessmentLevel,
  AssessmentQuestion,
  Audio,
  Bundle,
  Content,
  ContentProvider,
  Documentation,
  Event,
  ExternalLink,
  Image,
  InstructionalNote,
  LegacyArticle,
  LegacyWorkflow,
  LessonSpark,
  Level,
  LiteProductGrant,
  MetadataStandard,
  MultilingualText,
  QuestionOption,
  SmartBundle,
  Standard,
  Stream,
  Tag,
  Video,
  Studio,
  Wire,
  Word,
  WordDefinition,
  MetadataTag,
  TaxonomyTag
};

// Displayed in alphabetical order in Alexandria.
export const apps = {
  default: {
    icon: faBolt,
    color: constants.colors.ui.greyDark[700],
    accentColor: constants.colors.ui.grey[700],
    title: APP_NAME,
    href: '/'
  },
  articles: Article.app,
  bundles: Bundle.app,
  'content-providers': ContentProvider.app,
  inventory: Content.app,
  streams: Stream.app,
  tags: Tag.app,
  studio: Studio.app,
  wire: Wire.app,
  documentation: Documentation.app
};

const appTitles = {
  Article: Article.app.title,
  Bundle: Bundle.app.title,
  ContentProvider: ContentProvider.app.title,
  Content: Content.app.title, // Inventory app
  Stream: Stream.app.title,
  Tag: Tag.app.title
};

/**
 * Returns the app title for the given content type.
 * @param {String} contentType ex: 'ContentProvider', 'Stream', 'Tag'.
 * @returns {String|undefined} The app title.
 */
export function getAppTitle (contentType) {
  return appTitles[contentType];
}

// The outline content fragment contains only the fields we need to render
// and update the outline.
export const outlineComposedContentFragment = gql`
  fragment outlineComposedContent on Content {
    ...outlineContent
    attached {
      id
      name
      contentType
     }
  }
  ${Content.outlineFragment}
`;

// The preview content fragment contains only the fields we need to render
// the bundle preview. Attachments are only fetched for bundles.
export const previewComposedContentFragment = gql`
  fragment previewComposedContent on Content {
    ...previewContent

    ...on Audio {
      ...previewAudio
    }

    ...on Bundle {
      ...previewBundle

      attached(filter: { contentType: { eq: "LESSON_SPARK" } }) {
        ...previewContent
      }
    }

    ...on SmartBundle {
      ...previewSmartBundle

      attached(filter: { contentType: { eq: "LESSON_SPARK" } }) {
        ...previewContent
      }
    }

    ...on LegacyArticle {
      headerUrl
    }

    ...on ExternalLink {
      ...previewExternalLink
    }

    ...on Video {
      ...previewVideo
    }
  }
  ${Audio.previewFragment}
  ${Content.previewFragment}
  ${Bundle.previewFragment}
  ${SmartBundle.previewFragment}
  ${ExternalLink.previewFragment}
  ${Video.previewFragment}
`;

// The search content fragment contains only the fields we need to render
// the search results in the ContentPicker or List components. When displaying
// streams in content results, we ONLY care about the id (to populate the cache)
// and the stream name. We don't care about the full Stream.searchFragment
export const searchComposedContentFragment = gql`
  fragment searchComposedContent on Content {
    ...searchContent
    # attachment counts are displayed on search result cards, but
    # we don't need any other fields from them. We get their ids and typenames
    # so we can group their icons by type.
    attached { id }

    streams {
      id
      name
    }

    ...on Bundle {
      label
      # children counts are displayed on search result cards, but we don't
      # need any other fields from the children.
      children { id }
    }
    ...on SmartBundle { label }
    ...on ExternalLink { label }
    ...on Article { articleType }
    ...on Image { ...searchImage }
    ...on LegacyArticle {
      article {
        ...on Article {
          id
          uid
        }
      }
    }
  }
  ${Content.searchFragment}
  ${Image.searchFragment}
`;

// The full content fragment includes bundle fields, as it's not intended
// to be part of deep queries.
export const fullComposedContentFragment = gql`
  fragment fullComposedContent on Content {
    ...fullContent
    tags { ...fullTag }
    taxonomyTags { ...fullTaxonomyTag}
    metadataTags {...fullMetadataTag}
    metadataStandards { ...fullMetadataStandard }
    streams(order: [{ asc: streamTitle }]) { ...fullStream }
    contentProvider { ...fullContentProvider }
    replacedByContent { ...fullContent }

    ...on Audio {
      ...fullAudio
    }

    ...on Image {
      ...fullImage
      captionLevels { ...fullLevel }
    }

    ...on LegacyArticle {
      ...fullLegacyArticle
    }

    ...on LessonSpark {
      ...fullLessonSpark
    }

    ...on InstructionalNote {
      ...fullInstructionalNote
    }

    ...on Bundle {
      ...fullBundle
    }

    ...on SmartBundle {
      ...fullSmartBundle
    }

    ...on Assessment {
      ...fullAssessment
    }

    ...on ExternalLink {
      ...fullExternalLink
    }

    ...on Video {
      ...fullVideo
    }

    ...on Article {
      ...fullArticle
      headerImage {
        ...fullContent
        ...on Image { ...fullImage }
      }
    }
  }

  ${Audio.fullFragment}
  ${Content.fullFragment}
  ${Tag.fullFragment}
  ${TaxonomyTag.fullFragment}
  ${MetadataTag.fullFragment}
  ${MetadataStandard.fullFragment}

  ${Stream.fullFragment}
  ${Image.fullFragment}
  ${InstructionalNote.fullFragment}
  ${Level.fullFragment}
  ${LegacyArticle.fullFragment}
  ${LessonSpark.fullFragment}
  ${Bundle.fullFragment}
  ${SmartBundle.fullFragment}
  ${Assessment.fullFragment}
  ${ExternalLink.fullFragment}
  ${Article.fullFragment}
  ${Video.fullFragment}
  ${ContentProvider.fullFragment}
`;

// The blueprint content fragment only includes fields we need for
// blueprint generation.
export const blueprintComposedContentFragment = gql`
  fragment blueprintComposedContent on Content {
    ...blueprintContent
    metadataStandards { ...blueprintMetadataStandard }
    streams { ...blueprintStream }

    ...on Bundle {
      ...blueprintBundle
    }

    ...on SmartBundle {
      ...blueprintSmartBundle
    }

    ...on LessonSpark {
      ...blueprintLessonSpark
    }
  }

  ${Content.blueprintFragment}
  ${MetadataStandard.blueprintFragment}
  ${Stream.blueprintFragment}
  ${Bundle.blueprintFragment}
  ${SmartBundle.blueprintFragment}
  ${LessonSpark.blueprintFragment}
`;
