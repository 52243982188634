import { datadogRum } from '@datadog/browser-rum';

const rumConfig = {
  applicationId: 'd91ec13b-9384-4994-bc93-4952ba2f3b08',
  clientToken: 'pubbacde572d065a24a9a63709112b71c2a',
  site: 'datadoghq.com',
  service: 'alexandria',
  env: process.env.DD_ENV || 'dev',
  version: process.env.DD_VERSION || 'feature',
  sampleRate:
    process.env.DD_RUM_SAMPLE_RATE !== undefined
      ? Number(process.env.DD_RUM_SAMPLE_RATE)
      : 100,
  sessionReplaySampleRate:
    process.env.DD_RUM_SESSION_REPLAY_SAMPLE_RATE !== undefined
      ? Number(process.env.DD_RUM_SESSION_REPLAY_SAMPLE_RATE)
      : 100,
  trackInteractions: true,
  trackResources: true,
  trackLongTasks: true,
  tracingSampleRate:
    process.env.DD_RUM_TRACE_SAMPLE_RATE !== undefined
      ? Number(process.env.DD_RUM_TRACE_SAMPLE_RATE)
      : 100,
  allowedTracingOrigins: [process.env.ALEXANDRIA_URL],
  defaultPrivacyLevel: 'allow'
};

export function initializeDatadog () {
  datadogRum.init(rumConfig);
  datadogRum.startSessionReplayRecording();
}
