import React, { useState, useEffect } from 'react';

import { useQuery } from '@apollo/client';
import PropTypes from 'prop-types';

import LoadingSpinner from '@client/common/components/LoadingSpinner';
import { queries } from '@client/common/graph';
import InlineForm from '@client/forms/containers/InlineForm';

import { $form } from './style';

function getStreamValues (array) {
  return array.map((item) => item?.value);
}

function getFieldsByColumn (config, column) {
  return config.filter((field) => field.column === column);
}

export default function PublishAllModal ({ config, data, onChange }) {
  const { data: response } = useQuery(queries.publishAllStatus, {
    variables: { id: data.id },
    fetchPolicy: 'network-only'
  });

  // All content available to publish
  const [allContent, setAllContent] = useState([]);
  // Form values
  const [values, setValues] = useState({
    content: [],
    streams: []
  });

  const updateSelected = (serverData, optimisticData, changeType) => {
    const newValues = { ...values, ...optimisticData };
    setValues(newValues);
    onChange(serverData, newValues, changeType);
  };

  useEffect(() => {
    const content = (response && response.publishAllStatus) || [];
    // Sets the content in the right column
    setAllContent(content);
    // All streams are selected by default
    const defaultStreams = getStreamValues(data?.options?.streams || []);
    // Update the form with content and default streams
    updateSelected(null, { ...values, streams: defaultStreams });
  }, [response]);

  useEffect(() => {
    const filteredContentBySelectedStreams = allContent.map((content) => {
      const sharesAtLeastOneStream = content.streams?.some((stream) => values.streams.includes(stream.id));
      const included = sharesAtLeastOneStream || (values.streams.length && content.type === 'Image');
      return { ...content, included };
    });
    setValues({ ...values, content: filteredContentBySelectedStreams });
  }, [values.streams]);

  const commonProps = {
    type: 'Content',
    onChange: updateSelected
  };

  const leftColumn = {
    form: getFieldsByColumn(config, 'left'),
    data: { options: { ...data.options }, ...values }
  };

  const rightColumn = {
    form: getFieldsByColumn(config, 'right'),
    data: { options: { content: allContent }, ...values }
  };

  return (
    <>
      {!response ? (<LoadingSpinner label='Loading content to be published' />) : (
        <form css={$form}>
          <InlineForm {...commonProps} {...leftColumn} />
          <InlineForm {...commonProps} {...rightColumn} />
        </form>
      )}
    </>
  );
}

PublishAllModal.propTypes = {
  config: PropTypes.array,
  data: PropTypes.shape({
    id: PropTypes.string.isRequired,
    streams: PropTypes.array,
    options: PropTypes.object
  }),
  onChange: PropTypes.func
};
