import React from 'react';

import { RadioGroup } from 'mineral-ui/Radio';
import PropTypes from 'prop-types';

import isInline from '@client/utils/inline-group';

import { $revisionContext, $header } from './style';

// Renders the radio group responsible for selecting the context of the revisions.
// Currently defaults to levels.
export default function RevisionContext ({ config, onChange }) {
  return (
    <section css={$revisionContext}>
      <h3 css={$header}>Comparison Context</h3>
      <RadioGroup
        inline={isInline(config)}
        name={config.name}
        defaultChecked={config.defaultValue}
        onChange={onChange}
        data={config.options}
      />
    </section>
  );
}

RevisionContext.propTypes = {
  /** Full form config */
  config: PropTypes.object,
  onChange: PropTypes.func
};
