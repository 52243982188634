/**
 * Determine if an icon is from Angelou. Angelou icons look like
 * { $$typeof, render } whereas FontAwesome icons look like { prefix, iconName, icon }
 * @param {object} icon from Angelou or FontAwesome
 * @returns {boolean}
 */
export default function isAngelouIcon (icon) {
  if (!icon) {
    return false;
  }

  if (typeof icon === 'function') {
    // Angelou v0.22 icons were react functions.
    return true;
  }

  // Angelou v0.23+ icons are objects with a render function.
  return !!icon.render;
}
