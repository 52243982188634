/* eslint-disable camelcase */
import { css } from '@emotion/core';

import { getVariantColor, getBorderColors } from '@client/utils/styles';

// Add space for the level info.
export const $root = css`
  margin-bottom: 28px;
  display: flex;
  flex: 0 0 auto;
  flex-flow: column nowrap;
`;

export const $fieldsWrapper = css`
  position: relative;
  width: 100%;
`;

export const $bottomCaption = css`
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-self: flex-end;
  flex-direction: row-reverse;
  column-gap: 1rem;
  width: 100%;
`;

export const $dropdown = (variant) => {
  const variantColor = getVariantColor(variant);
  return css`
  border: 2px solid ${variant ? variantColor : 'transparent'};
  display: flex;
  height: 100%;
  button:focus { outline:${variant ? '0 !important' : ''}; }
`;
};

export const $menuItem = (variant, selected) => ({ colors, typography }) => {
  const variantBackgroundColor = getVariantColor(variant, 'light');
  return [
    typography.family.circular,
    typography.size.M,
    css`
      font-weight: ${selected ? 'bold' : 'normal'};
      &:hover, &:focus {
        background-color: ${variant ? variantBackgroundColor : colors.ui.greyExtraLight[50]};
      }
      span {
        align-items: center;
      }
    `
  ];
};

// Need to use custom css to override the styles passed in from MineralUI's menu.
export const $icon = css`
  font-size: 12px;
  margin: 5px 0;
`;

export const $info = ({ typography, colors }) => [
  typography.family.circular,
  typography.size.S,
  css`
    color: ${colors.ui.grey[500]};
    margin-top: 3px;
    flex: 1 0 auto;
    text-align: right;
    align-self: flex-start;
  `
];

export const $contextTab = (variant) => (theme) => {
  const { typography, backgroundColor } = theme;
  const [borderColor, borderColor_hover, borderColor_active] = getBorderColors(theme);
  const variantColor = getVariantColor(variant);
  return [
    typography.family.circular,
    typography.size.button,
    css`
    border: 1px solid ${borderColor};
    border-radius: 3px 3px 0 0;
    font-weight: 700;
    height: 36px;
    min-width: 34px;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1;
    &:hover {
      border-color: ${variantColor || borderColor_hover};
    }
    &:focus, &:active {
      box-shadow: 0 0 0 1px ${variantColor || backgroundColor}, 0 0 0 2px ${variantColor || borderColor_active};
    }
  `
  ];
};
