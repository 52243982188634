import { css } from '@emotion/core';

import { VARIANT_ERROR } from '@client/utils/constants';
import { getVariantColor } from '@client/utils/styles';

export const $root = (variant, size) => ({ colors, typographyStyles }) => {
  const textColor = variant === VARIANT_ERROR ? getVariantColor(variant) : colors.ui.greyDark[500];

  return [
    size === 'SMALL' ? typographyStyles.textXS : typographyStyles.textL,
    css`
    align-items: center;
    color: ${textColor};
    display: grid;
    grid-template-columns: max-content auto;
    margin-top: 5px;
    width: 100%;
  `];
};

export const $icon = (variant, size) => {
  const topMargin = size === 'SMALL' ? '2px' : '6px';

  return css`
    color: ${getVariantColor(variant)};
    align-self: start;
    margin-top: ${topMargin};
    margin-right: 5px;
  `;
};
