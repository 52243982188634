import { css } from '@emotion/core';

export const $inputs = (context) => () => {
  const base = css`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  `;

  const wide = css`
    /* Half of the difference between the width and the parent width */
    left: -178px;
    position: relative;
    margin: 0 auto;
    /* RevisionContext + margin + RevisionVersionSelector */
    width: 1055px;
  `;

  // When comparing versions, use the wide styles.
  return context === 'versions' ? [base, wide] : base;
};
