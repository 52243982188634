import React from 'react';

import { FormField } from 'mineral-ui/Form';
import PropTypes from 'prop-types';
import ReactSelect from 'react-select';

import { formatDate } from '@client/utils/time';

import {
  $revisionSelector,
  $header,
  $selectInputs,
  $selectInput,
  $option,
  $secondaryText
} from './style';

// This component is used to display each option in the input.
function VersionOption ({ innerProps, innerRef, label, data, isSelected, isFocused }) {
  const secondaryText = data.secondaryText;

  return (
    <div {...innerProps} ref={innerRef} css={$option(isSelected, isFocused)}>
      <span>{label}</span>
      <br />
      <span css={$secondaryText}>{secondaryText}</span>
    </div>
  );
}

VersionOption.propTypes = {
  innerProps: PropTypes.object,
  innerRef: PropTypes.object,
  label: PropTypes.string,
  data: PropTypes.object,
  isSelected: PropTypes.bool,
  isFocused: PropTypes.bool
};

// This component is used to display the selected value in the input.
function VersionValue ({ innerProps, innerRef, data }) {
  // When displaying the value, don't include the time or status.
  // Also, use initials rather than the full name of the user(s).
  const date = formatDate(data.value);
  const initials = data.userInitials;
  const label = `${date} (${initials})`;

  return <div {...innerProps} ref={innerRef}>{label}</div>;
}

VersionValue.propTypes = {
  innerProps: PropTypes.object,
  innerRef: PropTypes.object,
  data: PropTypes.object
};

// Renders the inputs for version comparison, which is passed in from RevisionHistory.
export default function RevisionVersionSelector ({
  name,
  styles,
  groupedLevels,
  possibleSnapshots,
  selectVersionLevel,
  selectVersion
}) {
  return (
    <div css={$revisionSelector}>
      <section>
        <h3 css={$header}>Select Level</h3>
        <div css={$selectInputs}>
          <FormField label='Base Level' css={$selectInput}>
            <ReactSelect
              name={name}
              options={groupedLevels}
              styles={styles}
              placeholder='Select Level'
              onChange={(option) => selectVersionLevel(option.value)}
            />
          </FormField>
        </div>
      </section>
      <section>
        <h3 css={$header}>Select Versions</h3>
        <div css={$selectInputs}>
          <FormField label='Base Version' css={$selectInput}>
            <ReactSelect
              name={name}
              options={possibleSnapshots}
              styles={styles}
              placeholder='Select Version'
              isSearchable={false}
              components={{ Option: VersionOption, SingleValue: VersionValue }}
              onChange={(option) => selectVersion(option.version, option.value, 'base')}
            />
          </FormField>
          <FormField label='Revision Version' css={$selectInput}>
            <ReactSelect
              name={name}
              options={possibleSnapshots}
              styles={styles}
              placeholder='Select Version'
              isSearchable={false}
              components={{ Option: VersionOption, SingleValue: VersionValue }}
              onChange={(option) => selectVersion(option.version, option.value, 'comparison')}
            />
          </FormField>
        </div>
      </section>
    </div>
  );
}

RevisionVersionSelector.propTypes = {
  /** Name of input, required field on react-select components */
  name: PropTypes.string,
  /** Alexandria custom styling for react-selct inputs */
  styles: PropTypes.object,
  /** Current levels, grouped in the format react-select wants */
  groupedLevels: PropTypes.array,
  possibleSnapshots: PropTypes.array,
  selectVersionLevel: PropTypes.func,
  selectVersion: PropTypes.func
};
