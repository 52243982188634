import React, { useState, useEffect, useRef } from 'react';

import { useApolloClient } from '@apollo/client';
import { isString, get, pick, isUndefined } from 'lodash-es';
import PropTypes from 'prop-types';

import { queries } from '@client/common/graph';
import InlineForm from '@client/forms/containers/InlineForm';

/**
 * Returns the specific fields for each param
 * @param {object} param
 * @returns {object}
 */
export function generateFieldConfig (param) {
  const base = {
    name: param.value,
    label: param.label,
    caption: param.description
  };

  switch (param.type) {
    case 'PARAM_STRING': return {
      ...base,
      input: 'text'
    };
    case 'PARAM_BOOLEAN': return {
      ...base,
      input: 'checkbox',
      longLabel: param.label,
      showLabel: false
    };
    case 'PARAM_NUMBER': return {
      ...base,
      input: 'text',
      type: 'int'
    };
    case 'PARAM_STRINGARRAY': return {
      ...base,
      input: 'select',
      isCreatable: true,
      isMulti: true,
      showDropdown: false,
      ignoreCase: true
    };
  }
}

function usePrevious (value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

export default function AlgorithmParameters ({ value, name, onChange, formData }) {
  const [form, setForm] = useState([]);
  const client = useApolloClient();
  const previousAlgorithm = usePrevious(formData.algorithm);

  const editParameters = (serverData, optimisticDataOrChangeType) => {
    if (isString(optimisticDataOrChangeType)) {
      optimisticDataOrChangeType = null;
    }

    // Because algorithm parameters are a JSON blob, we always want to set them.
    // Additionally, we need to merge existing params in with the incoming
    // changes.
    const changedData = {
      ...value,
      // Get any server data.
      ...serverData,
      // BUT overwrite server data with optimistic data, if it exists.
      // (This handles situations where data is unset, since the optimistic
      // data will have the new expected value)
      ...optimisticDataOrChangeType
    };

    onChange({ [name]: changedData });
  };

  // When the algorithm changes, regenerate the form config.
  useEffect(() => {
    const updateFormConfig = async (algorithm) => {
      // The smartBundleAlgorithms query is heavily cached, so it's not a problem
      // to run this query fairly often.
      const algorithms = get(await client.query({
        query: queries.smartBundleAlgorithms
      }), 'data.smartBundleAlgorithms');
      const parameters = algorithms
        .find((algo) => algo.value === algorithm)
        .parameters;
      const formConfig = parameters.map(generateFieldConfig);

      setForm(formConfig);
      // Filter out data for fields that don't exist in our current algorithm.
      // This prevents users from accidentally sending parameters for other
      // algorithms if they switch the algorithm after editing a field.
      if (!isUndefined(previousAlgorithm)) {
        onChange({ [name]: pick(value, formConfig.map((field) => field.name)) });
      }
    };

    if (formData.algorithm) {
      updateFormConfig(formData.algorithm);
    }
  }, [formData.algorithm]);

  return (
    <InlineForm
      data={value}
      form={form}
      id={formData.id}
      onChange={editParameters}
    />
  );
}

AlgorithmParameters.propTypes = {
  /** Field value, from the form-level state */
  value: PropTypes.object,
  /** Field name, which is also the property the data will be saved to */
  name: PropTypes.string,
  /** Function that updates the form state and persists data */
  onChange: PropTypes.func,
  formData: PropTypes.object
};

AlgorithmParameters.displayName = 'AlgorithmParametersGroup';
