import React, { useState, forwardRef, useCallback, useEffect } from 'react';

import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { ButtonWithPopOut, Button } from '@newsela/angelou';
import cuid from 'cuid';
import { debounce } from 'lodash-es';
import Checkbox from 'mineral-ui/Checkbox';
import { FormField as MineralUIField } from 'mineral-ui/Form';
import TextInput from 'mineral-ui/TextInput';
import Tooltip from 'mineral-ui/Tooltip';
import PropTypes from 'prop-types';

import Icon from '@client/common/components/Icon';
import MenuItemGroupList from '@client/common/components/MenuItemGroupList';
import { MultilingualText as MLTextSchema } from '@client/common/schema';
import rawSchema from '@client/schema';
import { TEXT_DEBOUNCE_TIME } from '@client/utils/constants';
import { formatLanguage, enumOptions } from '@client/utils/fields';

import { $textWrapper, $text, $checkbox, $popOutMenu } from './style';

const languages = enumOptions(rawSchema.enums.Language, 'Language');

/**
 * Individual languages for multilingual text. Each of these allow editing the
 * text in the specific language. This component is defined in this file because
 * it's not intended to be used by components other than MultilingualText.
 * @param {object} props
 * @returns {JSX.Element}
 */
export const MultilingualTextItem = forwardRef(({ item, config, isDeleteDisabled, onUpdate, onRemove }, ref) => {
  const [text, setText] = useState(item.plainText || '');
  const [checked, setChecked] = useState(item.isOriginalLanguage || false);
  const label = `${config.label || 'Text'} in ${formatLanguage(item.language)}`;
  // For most fields, optimistic mutations update appropriately but for fields
  // where the user is typing in real-time, optimistic updates are slow enough
  // to cause input lag.
  const debouncedOnUpdate = useCallback(debounce(onUpdate, TEXT_DEBOUNCE_TIME), [item?.uid]);
  // Styles for the Remove language button, passed into __cssFor.
  const removeButtonStyles = {
    root: {
      marginLeft: '10px',
      height: '40px' // Based on text input height.
    }
  };

  const onTextUpdate = (e) => {
    const val = e.target.value;

    setText(val);
    debouncedOnUpdate({ uid: item.uid, plainText: val });
  };

  const onCheckUpdate = (e) => {
    const val = e.target.checked;

    setChecked(val);
    onUpdate({ uid: item.uid, isOriginalLanguage: val });
  };

  return (
    <MineralUIField label={label}>
      <>
        <div css={$textWrapper}>
          <TextInput
            css={$text}
            type='text'
            value={text}
            name={`${item.uid}-plaintext`}
            onChange={onTextUpdate}
            // Set the passed-in ref here so that it gets focused when it's added.
            inputRef={(textInputRef) => {
              if (ref) ref.current = textInputRef;
            }}
          />
          <Tooltip content='Remove language'>
            <Button
              __cssFor={removeButtonStyles}
              ariaProps={{ 'aria-label': 'Remove language' }}
              /* AUTOGENERATED TODO: update angelou to new flavor.
                see https://github.com/newsela/angelou/blob/main/src/components/Button/README.md#MIGRATION
                for migration guide. */
              legacy_flavor={Button.legacy_flavor.flat}
              legacy_statusColor={Button.legacy_statusColor.black}
              disabled={isDeleteDisabled}
              onClick={() => onRemove(item.uid)}
            >
              <Icon icon={faTimes} />
            </Button>
          </Tooltip>
        </div>
        <Checkbox
          css={$checkbox}
          checked={checked}
          label={config.originalLanguageLabel || 'Original language'}
          name={`${item.uid}-isoriginal`}
          onChange={onCheckUpdate}
        />
      </>
    </MineralUIField>
  );
});

MultilingualTextItem.propTypes = {
  /** Value of a single MultilingualText record. */
  item: PropTypes.shape({
    uid: PropTypes.string.isRequired,
    plainText: PropTypes.string,
    language: PropTypes.string,
    isOriginalLanguage: PropTypes.bool
  }),
  /** Config for the whole field. */
  config: PropTypes.object,
  /** This determines whether the button to remove this language is disabled. */
  isDeleteDisabled: PropTypes.bool,
  /** Function that passes updates back to MultilingualText. */
  onUpdate: PropTypes.func,
  /** Function to remove a language. This deletes the language record in the database. */
  onRemove: PropTypes.func
};

export default function MultilingualText ({ value, name, config, onChange }) {
  value = value || [];
  const ref = React.createRef();
  // Unless we set an empty object, useEffect ignores it.
  ref.current = {};
  // Keep track of when new TextInputs are created for a language
  // so that we can focus on them as soon as they are added (for accessibility).
  const [isNewTextInput, setIsNewTextInput] = useState(false);
  // Keep track of whether the pop-out menu is open so that we can toggle it
  const [isPopOutVisible, setIsPopOutVisible] = useState(false);

  // Focus new TextInput
  useEffect(() => {
    if (isNewTextInput && ref?.current?.focus) {
      ref.current.focus();
      setIsNewTextInput(false);
      setIsPopOutVisible(false);
    }
  }, [ref.current]);

  // Function that is called when a language is added.
  const addLanguage = async (language) => {
    const newLanguage = MLTextSchema.defaults(cuid(), {
      language
    });

    await onChange(
      { [name]: newLanguage.server },
      { [name]: [...value, newLanguage.client] },
      'set',
      false // Don't debounce updates.
    );
  };

  // Function that is called when a language is removed.
  const removeLanguage = (languageUid) => {
    onChange(
      { [name]: { uid: languageUid } },
      { [name]: value.filter((item) => item.uid !== languageUid) },
      'unset',
      false // Don't debounce updates.
    );
  };

  // Generate the menu items for the 'Add translation' dropdown button.
  const menu = [{
    menuItems: languages.map((language) => ({
      text: language.label,
      // Disable the language if it's already added.
      disabled: value.some((item) => item.language === language.value),
      onClick: async () => {
        await addLanguage(language.value);
        // So that we can focus new TextInput in useEffect hook.
        setIsNewTextInput(true);
      }
    }))
  }];
  const buttonText = config.label
    ? `Add ${config.label.toLowerCase()} translation`
    : 'Add translation';

  // Function that is called when an individual language is updated, e.g.
  // if text is changed or if the original language checkbox is toggled.
  const onUpdateItem = (item) => {
    const updatedItem = {
      ...value.find((existingItem) => existingItem.uid === item.uid),
      ...item
    };
    const optimisticValue = value.map((existingItem) => {
      return existingItem.uid === updatedItem.uid
        ? updatedItem
        : existingItem;
    });

    onChange({ [name]: item }, { [name]: optimisticValue });
  };

  // Disable the delete button if there's only one language.
  const isDeleteDisabled = value.length <= 1;

  return (
    <>
      {value.map((item) => (
        <MultilingualTextItem
          key={item.uid}
          item={item}
          config={config}
          isDeleteDisabled={isDeleteDisabled}
          onUpdate={onUpdateItem}
          onRemove={removeLanguage}
          // Pass on the ref so that we can set it in the Mineral UI TextInput.
          ref={ref}
        />
      ))}
      <ButtonWithPopOut
        __cssFor={{ PopOut: { popOutContents: $popOutMenu } }}
        id='multilingual-text-menu'
        /* AUTOGENERATED TODO: update angelou to new flavor.
          see https://github.com/newsela/angelou/blob/main/src/components/Button/README.md#MIGRATION
          for migration guide. */
        legacy_flavor={Button.legacy_flavor.solid}
        legacy_size={Button.legacy_size.justified}
        popOutContents={<MenuItemGroupList menuData={menu} />}
        buttonContents={buttonText}
        horizontalOffset='0'
        // We can't use the closeOnBlur prop here because all the options
        // in the pop-out will be disabled (no focus) after we add all the languages,
        // meaning that the pop-out will never dispatch the blur event.
        isPopOutVisible={isPopOutVisible}
        // Since we're using the isPopOutVisible prop, we need to set the following props
        // that are required when we use the ButtonWithPopOut as a controlled component.
        onClickButton={() => setIsPopOutVisible(!isPopOutVisible)}
        onClickOff={() => setIsPopOutVisible(false)}
        onEsc={() => setIsPopOutVisible(false)}
      />
    </>
  );
}

MultilingualText.propTypes = {
  /** Field value, from the form-level state */
  value: PropTypes.array,
  /** Field name, which is also the property the data will be saved to */
  name: PropTypes.string,
  /** Full configuration object */
  config: PropTypes.object,
  /** Function that updates the form state and persists data */
  onChange: PropTypes.func
};

// Prevent this input from being wrapped in a <label>.
MultilingualText.displayName = 'MultilingualTextGroup';
