import { ExternalLinkSVG } from '@newsela/angelou';
import gql from 'graphql-tag';
import smartquotes from 'smartquotes';

import { getVisibility } from '@client/utils/search';

import Content from './content';
import ContentProvider from './content-provider';
import MetadataStandard from './metadataStandard';
import MetadataTag from './metadataTag';
import Stream from './stream';
import Tag from './tag';
import TaxonomyTag from './taxonomyTag';

const isVideo = (data) => !data.label ||
  data.label === 'Video' ||
  Boolean(data.url?.endsWith('config.json'));

export const isValidLabelChange = (newOption) =>
  !newOption.value.toLowerCase().includes('video') && !isFormativeLabel(newOption.value);

const isFormativeLabel = (value) => value?.toLowerCase().includes('formative');

const contentProviderWithSkip = {
  ...ContentProvider.inputs.contentProvider,
  skip: (data) => !isVideo(data)
};

const openSearch = {
  index: 'external_link',
  fields: ['title^10', 'name^7'],
  sort: [
    {
      updated_at: {
        order: 'desc'
      }
    }
  ],
  // If the user included a query we disable the sort so we don't affect the ranking.
  disableSortOnQuery: true,
  _source: ['id', 'content_type', 'updated_at', 'name', 'label', 'status', 'streams', 'thumbnail', 'events', 'uid'],
  mapping: {
    contentType: 'content_type',
    updatedAt: 'updated_at',
    createdAt: 'created_at'
  }
};

const labelSelect = {
  input: 'select',
  name: 'label',
  defaultValue: 'Webpage',
  isCreatable: true,
  isDisabled: ({ value }) => isFormativeLabel(value),
  options: [
    'Webpage',
    'Worksheet',
    'Live Webinar',
    'Webinar',
    'Resource',
    'Course'
  ],
  showDropdown: false,
  skip: (data) => isVideo(data),
  isValidChange: ({ newOption }) => isValidLabelChange(newOption),
};

const labelVideo = {
  input: 'select',
  name: 'label',
  defaultValue: 'Video',
  isCreatable: false,
  options: [
    'Video'
  ],
  isReadOnly: true,
  skip: (data) => !isVideo(data),
};

function getSharedFilter (options) {
  const visibility = getVisibility(options);
  return {
    ...!visibility.not && visibility,
    not: {
      or: [
        ...(visibility.not ? [{ ...visibility.not }] : []),
        { label: { eq: 'Formative' } }
      ]
    }
  };
}

function filter (query, options) {
  const quoted = smartquotes(query);

  return {
    or: [
      { linkTitle: { allofterms: quoted } },
      { linkDescription: { alloftext: quoted } },
      { linkCTA: { allofterms: quoted } },
      { author: { alloftext: quoted } },
      { label: { allofterms: query } }
    ],
    ...getSharedFilter(options)
  };
}

function quickFilter (query, options) {
  const quoted = smartquotes(query);

  return {
    linkTitle: { allofterms: quoted },
    rootFn: 'linkTitle|allofterms',
    ...getSharedFilter(options)
  };
}

const ExternalLink = {
  previewFragment: gql`
    fragment previewExternalLink on ExternalLink {
      label
      url
    }
  `,
  // Does not include contentProvider, captionLevels.
  fullFragment: gql`
    fragment fullExternalLink on ExternalLink {
      url
      label
      linkTitle
      linkDescription
      linkCTA
      author
      estimatedTime
      imageUrl
    }
  `,
  filter,
  quickFilter,
  openSearch,
  defaults: (id, data) => {
    const contentDefaults = Content.defaults(id, data);
    const label = data?.label || 'Webpage';

    return {
      client: {
        ...contentDefaults.client,
        __typename: 'ExternalLink',
        contentType: 'EXTERNAL_LINK',
        name: label,
        url: null,
        label,
        linkTitle: null,
        linkDescription: null,
        linkCTA: null,
        author: null,
        estimatedTime: null,
        imageUrl: null,
        taxonomyTags: [],
      },
      server: {
        ...contentDefaults.server,
        contentType: 'EXTERNAL_LINK',
        label
      }
    };
  },
  isContent: true,
  icon: ExternalLinkSVG,
  typename: 'External Link',
  forms: {
    shared: [
      labelSelect,
      labelVideo,
      {
        input: 'text',
        name: 'url',
        label: 'External Link URL',
        isReadOnly: (data) => isVideo(data),
      },
      {
        input: 'text',
        name: 'linkTitle',
        label: 'Title',
        transform: (data = '') => smartquotes(data)
      },
      {
        input: 'text',
        name: 'author',
        transform: (data = '') => smartquotes(data)
      },
      {
        input: 'duration',
        name: 'estimatedTime',
        label: 'Estimated Time',
        caption: 'How long does it take to view this content?'
      },
      {
        input: 'textarea',
        name: 'linkDescription',
        label: 'Short Description',
        transform: (data = '') => smartquotes(data)
      },
      {
        input: 'text',
        name: 'linkCTA',
        label: 'Link Provider',
        isReadOnly: (data) => isVideo(data),
        transform: (data = '') => smartquotes(data)
      },
      {
        input: 'file',
        name: 'imageUrl',
        label: 'Link Image',
        accept: 'image/*'
      },
      contentProviderWithSkip,
      Tag.inputs.tags,
      TaxonomyTag.inputs.taxonomyTags,
      MetadataTag.inputs.metadataTags,
      MetadataStandard.inputs.metadataStandards,
      Stream.inputs.subjectProductStreams,
      Stream.inputs.customStreams,
      Content.inputs.notes,
      Content.inputs.parentsList,
      Content.inputs.id,
    ]
  }
};

export default ExternalLink;
