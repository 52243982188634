/* eslint-disable camelcase */
import { css } from '@emotion/core';

// TODO: Refactor Forms to allow custom margins
// so we can remove this hacky style
export const $powerWordListWrapper = css`
  & > div:first-of-type {
    margin-top: -35px
  }
`;
