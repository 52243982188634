import { forwardRef, useState } from 'react';

import { faPlus } from '@fortawesome/pro-regular-svg-icons';
import { Button } from '@newsela/angelou';
import { useStoreState } from 'easy-peasy';
import Tooltip from 'mineral-ui/Tooltip';

import { powerWord } from '@shared/prosemirror/blocks';

import powerWordsPlugin from '../plugins/power-words';

const { isActive, setPowerWordsModeTransaction, setMakePowerWordTransaction } = powerWordsPlugin.custom.format;

function enablePWAdd (state) {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const saveStatus = useStoreState((state) => state.saveStatus);

  return !saveStatus?.isPerformingAction;
}

const PowerWordSVG = () => {
  return (
    <svg width='25' height='24' viewBox='0 0 25 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path fillRule='evenodd' clipRule='evenodd' d='M10.873 3.5H10.2136L9.95384 4.10608L5.45384 14.6061L4.85645 16H6.37298H10.623V19.5V22.2621L12.3912 20.1402L19.8912 11.1402L21.258 9.5H19.123H14.741L17.0174 4.94721L17.741 3.5H16.123H10.873ZM7.88952 14L11.5324 5.5H14.5049L12.2286 10.0528L11.505 11.5H13.123H16.9879L12.623 16.738V15V14H11.623H7.88952Z' fill='white' />
    </svg>
  );
};

const ForwardSVGRef = forwardRef(PowerWordSVG);

const PowerWordButton = ({ item, statefulView }) => {
  const [isRunning, setRunning] = useState(false);

  return (
    <Tooltip content={item.title}>
      <Button
        __cssFor={{
          root: {
            backgroundColor: '#0B1386',
            padding: '10px', // Almost like 12px but accounts for minor layout shifts due to Angelou's Button native behavior.
            display: 'flex',
            alignItems: 'center',
            borderRadius: '8px !important',
            margin: '0 8px'
          }
        }}
        icon={{
          SvgComponent: ForwardSVGRef,
          alignment: Button.iconAlignments.left,
        }}
        onClick={(e) => {
          e.preventDefault();
          if (!isRunning) {
            setRunning(true);
            item.run(statefulView.current.state, statefulView.current.dispatch);
            // Wait a bit before allowing another click. This prevents double
            // clicks from being registered, and allows time for the Prosemirror
            // state to update.
            setTimeout(() => {
              setRunning(false);
            }, 300);
          }
        }}
        // disabled={levelerStatus.isLevelerDisabled}
      />
    </Tooltip>
  );
};

export const block = powerWord;

export const menuPowerWord = [{
  title: 'Toggle Power Words Suggestions',
  active: isActive,
  customButton: PowerWordButton,
  run: setPowerWordsModeTransaction
}, {
  title: 'Make Power Word',
  content: faPlus,
  select: isActive,
  enable: enablePWAdd,
  run: setMakePowerWordTransaction
}];
