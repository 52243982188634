import { constants, typographyStyles } from '@newsela/angelou';
import { createTheme } from 'mineral-ui/themes';

import { APP_HEADER_HEIGHT, STATUS } from '@client/utils/constants';

/**
 * Create a color ramp from our colors,
 * which range from 100 to 700
 *
 * @param  {Object} color
 * @return {Object}
 */
function createRamp (color) {
  return {
    10: color[100],
    20: color[100],
    30: color[300],
    40: color[300],
    50: color[300],
    60: color[500],
    70: color[500],
    80: color[500],
    90: color[700],
    100: color[700],
    inflection: 30
  };
}

const mineralTheme = createTheme({
  colors: {
    theme: createRamp(constants.colors.status.primary),
    danger: createRamp(constants.colors.status.danger),
    success: createRamp(constants.colors.status.success),
    warning: createRamp(constants.colors.status.warning),
    black: constants.colors.ui.greyDark[700],
    white: constants.colors.ui.white[100]
  },
  overrides: {
    fontFamily: 'Circular, Helvetica, Arial, sans-serif',
    // Use dark grey (rather than blue) for icons in menus by default.
    MenuItemIcon_color: constants.colors.ui.greyDark[500],
    // Use a slightly darker grey for disabled text (for accessibility).
    color_disabled: constants.colors.ui.greyLight[500],
    // Use a slightly darker grey for placeholder text (for accessibility).
    input_color_placeholder: constants.colors.ui.grey[500],
    // Use an Angelou color for FormField labels.
    h6_color: constants.colors.ui.grey[700],
    // Hardcode a hover color because Angelou doesn't provide tints for our
    // warning color.
    borderColor_warning_hover: '#ffd899',
    // 2022 Designs have specific border radii.
    // borderRadius_1 is used in Mineral-UI components like checkboxes. We do
    // not want to override it.
    borderRadius_2: '8px', // Used in content cards.
    borderRadius_3: '4px', // Used in status pill.
    // Slightly smaller than borderRadius_2, so that images appear correctly
    // in cards with validation.
    borderRadius_4: '6px'
  }
});

// Status colors used for publishing status.
const { DRAFT, PUBLISHED, CHANGED, ARCHIVED } = STATUS;
export const statusColors = {
  [DRAFT]: 'ui.yellow.500',
  [PUBLISHED]: 'ui.green.500',
  [CHANGED]: 'brand.purple.500',
  [ARCHIVED]: 'brand.greyDark.500'
};

const gradientColors = {
  biGradient: {
    // This is a gradient based on our purple and blue brand colors.
    // To use it in your styles, set the static color first and then the
    // dynamic color, e.g.
    // background: ${colors.biGradient.static};
    // background: ${colors.biGradient.dynamic};
    static: 'rgb(161,61,255)',
    dynamic: 'linear-gradient(135deg, rgba(161,61,255,1) 21%, rgba(95,84,250,1) 74%, rgba(16,111,243,1) 100%)'
  }
};

const headerHeight = `${APP_HEADER_HEIGHT}px`;
const subheaderHeight = '108px';
// Minimal (one row) subheader height, used in the Articles app.
const minimalSubheaderHeight = '68px';
// Form tabs have a specific height.
const formTabHeight = '52px';

/*
 * This will allow devs to use `props.theme.*` to access style constants inside
 * their components, e.g. props.theme.colors.blue.medium[0] or
 * props.typography.size.XXL
 */
const theme = {
  ...mineralTheme,
  colors: {
    ...constants.colors,
    status: {
      ...constants.colors.status,
      ...statusColors
    },
    ...gradientColors
  },
  typography: constants.legacy_typography,
  typographyStyles,
  headerHeight,
  subheaderHeight,
  minimalSubheaderHeight,
  formTabHeight,
  // Body heights are calculated from header heights
  bodyHeight: `calc(100vh - ${headerHeight})`,
  formHeight: `calc(100vh - ${headerHeight} - ${subheaderHeight})`,
  articleFormHeight: `calc(100vh - ${headerHeight} - ${minimalSubheaderHeight})`,
  previewMessageHeight: `calc(100vh - ${headerHeight} - ${minimalSubheaderHeight} - 40px)`,
  // Override mineral-ui theme
  fontSize_ui: '1em'
};

export default theme;
