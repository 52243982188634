import React from 'react';

import Checkbox from 'mineral-ui/Checkbox';
import PropTypes from 'prop-types';

export default function CheckboxInput ({ value, config, name, onChange, variant }) {
  value = value || false; // Cast empty values to a boolean.
  const onCheckInput = (e) => {
    const checked = e.target.checked;

    onChange({ [name]: checked });
  };

  return (
    <Checkbox
      checked={value}
      label={config.longLabel || 'Enable'}
      name={name}
      {...variant && { variant }}
      onChange={onCheckInput}
    />
  );
}

CheckboxInput.propTypes = {
  /** Field value, from the form-level state */
  value: PropTypes.bool,
  /** Full configuration object */
  config: PropTypes.object,
  /** Field name, which is also the property the data will be saved to */
  name: PropTypes.string,
  /** Function that updates the form state and persists data */
  onChange: PropTypes.func,
  variant: PropTypes.string
};
