import React, { useEffect, useState } from 'react';

import { useMutation } from '@apollo/client';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { get, isEmpty } from 'lodash-es';
import { FormField } from 'mineral-ui/Form';
import PropTypes from 'prop-types';

import { mutations } from '@client/common/graph';
import ProsemirrorGroup from '@client/forms/inputs/Prosemirror';
import Select from '@client/forms/inputs/Select';

import { $definitionForm } from './style';

export default function PowerWordDefinition ({
  wordUid,
  wordDefinition = {}
}) {
  const onUpdateWord = useStoreState((state) => state.drawer?.data?.onUpdateWordCallback);
  const setStatus = useStoreActions((actions) => actions.saveStatus.setStatus);
  const [definition, setDefinition] = useState(wordDefinition || {});
  const [refreshValue, setRefreshValue] = useState(wordDefinition.rawDefinition || {});

  // Mutation we're going to use.
  const [setWordDefinition] = useMutation(mutations.setWordDefinition);

  // After saving any updates to the definition, update the status in the
  // main nav
  const stopSaving = () => {
    setStatus({
      isEditing: true,
      isSaving: false
    });
  };

  // On the FIRST render of the Power Word definitions, update the save status
  useEffect(stopSaving, []);

  /**
   * Save and then refresh word definition.
   */
  const updateDefinition = async (serverData) => {
    // If the user selects a definition from the dropdown, it will be in `word-definitions`. If they've
    // typed it into the Prosemirror input, it will be in `definition`.
    let definitionToSave = serverData['word-definitions'] || serverData.definition;
    const definitionText = get(definitionToSave, 'content[0].content[0].text');

    // Treat whitespace definitions like empty input
    if (definitionText && isEmpty(definitionText?.trim())) {
      definitionToSave = {
        type: 'doc',
        content: [{ type: 'paragraph' }]
      };
    }

    setStatus({ isSaving: true });
    // Send the mutation to the server.
    const response = await setWordDefinition({
      variables: {
        input: {
          uid: definition.uid,
          definition: definitionToSave
        }
      }
    });
    stopSaving();
    // Refresh the word definition in the view.
    setDefinition(response.data.setWordDefinition);
    if (serverData['word-definitions']) {
      // If the user selected a definition in the dropdown, we also want to manually refresh
      // the Prosemirror input with the newly-selected definition.
      setRefreshValue(response.data.setWordDefinition.rawDefinition);
    }
    // Update easy-peasy store with updated definition. This will trigger
    // a callback in the Prosemirror input which updates the mark and re-fetches
    // the list of power words.
    onUpdateWord(response.data.setWordDefinition);
  };

  return (
    <div>
      <FormField label='Suggested Definitions' css={$definitionForm}>
        <Select
          name='word-definitions'
          formData={{ uid: wordUid }}
          onChange={updateDefinition}
          value={[]}
          config={
          {
            type: 'WordDefinition',
            label: 'Definition',
            showLabel: true,
            isAsync: true,
            preloadOptions: true,
            query: 'wordDefinitions',
            dedupeBy: 'definition',
            forceFilter: true,
            mapping: {
              label: 'definition',
              value: 'rawDefinition'
            },
            placeholder: 'Select a previous definition'
          }
        }
        />
      </FormField>
      <FormField label='Definition' css={$definitionForm}>
        <ProsemirrorGroup
          refreshValue={refreshValue}
          formData={{}}
          config={{
            formats: ['bold', 'italic'],
            isMultiline: true,
            value: 'rawDefinition',
            name: 'definition',
            placeholder: 'Or enter a new definition'
          }}
          value={definition?.rawDefinition}
          name='definition'
          onChange={updateDefinition}
        />
      </FormField>
    </div>
  );
}

PowerWordDefinition.propTypes = {
  wordUid: PropTypes.string,
  wordDefinition: PropTypes.shape({
    id: PropTypes.string,
    uid: PropTypes.string,
    definition: PropTypes.string,
    rawDefinition: PropTypes.object
  })
};
