import { differenceInCalendarDays } from 'date-fns';
import { format, utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz';

export const timeZone = 'America/New_York';
const dateFormat = 'M/d/yy';
const timeFormat = 'h:mm a (\'ET\')';

/**
 * Parse a timestamp into eastern time.
 * @param {string|Date|number} timestamp
 * @returns {Date}
 */
export function toEasternTime (timestamp) {
  return utcToZonedTime(timestamp, timeZone);
}

/**
 * Parse a timestamp into UTC.
 * @param {string|Date|number} timestamp
 * @returns {string}
 */
export function toUTCString (timestamp) {
  const utcTime = zonedTimeToUtc(timestamp, timeZone);

  return utcTime.toISOString();
}

/**
 * Format dates in EDT/EST, following the standard date format. This does NOT
 * include the times.
 *
 * @param  {string|Date|number} timestamp
 * @return {string}
 */
export function formatDate (timestamp) {
  return format(toEasternTime(timestamp), dateFormat, { timeZone });
}

/**
 * Format dates and times in EDT/EST, following the standard date and
 * time format. Exported for testing.
 *
 * @param  {string|Date|number} timestamp
 * @return {string}
 */
export function formatDateTime (timestamp) {
  return format(toEasternTime(timestamp), `${dateFormat} 'at' ${timeFormat}`, { timeZone });
}

/**
 * Format dates and times in EDT/EST, following the standard time
 * format, but with a relative day (Today, Yesterday, Tomorrow) rather than
 * the calendar date. Exported for testing.
 *
 * @param  {string|Date|number} timestamp
 * @param  {string} [relativeDay] defaults to 'Today'
 * @return {string}
 */
export function formatTimeClose (timestamp, relativeDay = 'Today') {
  return format(
    toEasternTime(timestamp),
    `'${relativeDay} at' ${timeFormat}`,
    { timeZone }
  );
}

/**
 * Format dates and times in EDT/EST, following the standard time and/or date format.
 * If it's the same day, it returns the time e.g. 'Today at 4:20 PM (ET)'
 * If it's one day in the past or future, it returns the time e.g. 'Tomorrow at 4:20 PM (ET)'
 * Otherwise, it returns the date and time e.g. '4/20/69 at 4:20 PM (ET)'
 *
 * @param  {string|Date|number} timestamp
 * @param  {Number} [now] defaults to current timestamp, but can be passed in for testing
 * @return {string}
 */
export function formatDynamic (timestamp, now = Date.now()) {
  const easternTime = utcToZonedTime(timestamp, timeZone);
  const currentEasternTime = utcToZonedTime(now, timeZone);
  // Determine if the timestamp is today, tomorrow, or yesterday.
  const diff = differenceInCalendarDays(easternTime, currentEasternTime);
  const isToday = diff === 0;
  const isYesterday = diff === -1;
  const isTomorrow = diff === 1;

  if (isToday) {
    return formatTimeClose(timestamp, 'Today');
  } else if (isYesterday) {
    return formatTimeClose(timestamp, 'Yesterday');
  } else if (isTomorrow) {
    return formatTimeClose(timestamp, 'Tomorrow');
  } else {
    return formatDateTime(timestamp);
  }
}
