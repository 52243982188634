/* eslint-disable camelcase */
import { css } from '@emotion/core';
import { constants } from '@newsela/angelou';

// The Button component `__cssFor` prop takes in a style object, not a
// css function so we're creating a colors variable explicitly from
// Angelou to be able to use colors in the style object below.
const { colors } = constants;

export const $button = css`
  background-color: ${colors.ui.coreBlue[700]};
  color: ${colors.ui.white[700]};

  &:hover {
    background-color: ${colors.ui.coreBlue[700]};
    color: ${colors.ui.white[700]};
  }

  display: flex;
  flex-direction: column;
  height: 40px;
  margin: 20px 0;
`;
