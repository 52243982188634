import React, { useMemo } from 'react';

import PropTypes from 'prop-types';

import { MAX_IMG_HEIGHT } from '@client/utils/constants';

import {
  getAspectRatio,
  getTopLeft,
  getBottomRight
} from './cropping-helpers';
import {
  $maskTop,
  $maskBottom,
  $maskLeft,
  $maskRight,
  $focalPoint
} from './style';

/**
 *
 * @param {number} x focal point x position, focalPointX * MAX_IMG_WIDTH
 * @param {number} y focal point y position, focalPointY * MAX_IMG_HEIGHT
 * @param {number} zoom focalPointZ value
 * @param {string} aspectRatio based on image width and height
 * @returns {JSX}
 */
export default function CroppingMask ({ x, y, zoom, aspectRatio }) {
  // Determine the image dimensions based on the aspect ratio.
  const ar = useMemo(() => getAspectRatio(aspectRatio), [aspectRatio]);

  // Determine the size and dimensions of the cropping mask based on the
  // focal point.
  const topY = getTopLeft(y, zoom, 'top', ar);
  const bottomY = getBottomRight(y, zoom, 'bottom', ar);
  const leftX = getTopLeft(x, zoom, 'left', ar);
  const rightX = getBottomRight(x, zoom, 'right', ar);
  // Get the height for the sides (between the top and bottom Y values).
  const sideHeight = MAX_IMG_HEIGHT - topY - bottomY;

  return (
    <>
      <div css={$maskTop(topY)} />
      <div css={$maskBottom(bottomY)} />
      <div css={$maskLeft(leftX, topY, sideHeight)} />
      <div css={$maskRight(rightX, topY, sideHeight)} />
      <div css={$focalPoint(x, y)} />
    </>
  );
}

CroppingMask.propTypes = {
  x: PropTypes.number.isRequired,
  y: PropTypes.number.isRequired,
  zoom: PropTypes.number.isRequired,
  aspectRatio: PropTypes.string.isRequired
};
