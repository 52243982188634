import React from 'react';

import { HomeFilledSVG } from '@newsela/angelou';
import { Link } from '@reach/router';
import { useStoreState } from 'easy-peasy';

import Icon from '@client/common/components/Icon';
import NonProdEnv from '@client/common/components/NonProdEnv';
import PageMetadata from '@client/common/components/PageMetadata';
import { apps } from '@client/common/schema';
import isAngelouIcon from '@client/utils/is-angelou-icon';

import {
  $root,
  $nav,
  $info,
  $home,
  $contentTitle,
  $link,
  $title,
  $body,
  $userWrapper,
  $user,
  $userName,
  $userRole,
} from './style';

export default function AppHeader () {
  const appName = useStoreState((state) => state.appName);
  const isContentApp = appName === 'bundles' || appName === 'articles';
  const isContent = useStoreState((state) => state.content.data.id);
  const contentTitle = useStoreState((state) => state.content.data.name);
  const name = useStoreState((state) => state.user.data.name || 'Nobody');
  const role = useStoreState((state) => state.user.role || 'Unknown');
  const isPreview = useStoreState((state) => state.isPreview);
  const app = apps[appName] || apps.default;
  const appIcon = app.icon;

  // When previewing content, don't display the header
  if (isPreview) {
    return null;
  }

  // Determine what to pass into the header for the favicon.
  let faviconObj;
  let FaviconSVG;

  if (isAngelouIcon(appIcon)) {
    FaviconSVG = appIcon;
  } else {
    // The app.icon is a font awesome object that has
    // an icon property which is an array with the following shape:
    // [ width, height, ligatures, unicode, svgPathData ]
    const { 0: width, 1: height, 4: svgPathData } = appIcon?.icon;

    faviconObj = { width, height, svgPathData };
  }

  return (
    <header css={$root(app.color)} aria-label='Main Nav'>
      <PageMetadata
        title={`${app.title} Homepage`}
        faviconObj={faviconObj}
        FaviconSVG={FaviconSVG}
      />
      <nav css={$nav}>
        <Link to='/' title='Alexandria Suite' css={$home}>
          <Icon icon={HomeFilledSVG} size={24} color='ui.white.500' />
        </Link>
        <Link to={app.href} css={$link}>
          <h1 css={$title(app.accentColor)}>
            {app.title}
          </h1>
        </Link>
        {isContentApp && isContent &&
          <section
            aria-label='Content Title'
            tabIndex='0'
            css={$contentTitle(app.accentColor)}
          >
            {contentTitle}
          </section>}
      </nav>
      <section css={$info}>
        <NonProdEnv />
      </section>
      <section css={$body}>
        <div css={$userWrapper(app.accentColor)}>
          <div css={$user} aria-label='Current User' tabIndex='0'>
            <span css={$userName}>{name}</span>
            <span css={$userRole}>{role}</span>
          </div>
        </div>
      </section>
    </header>
  );
}
