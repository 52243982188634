/* eslint-disable camelcase */
import { css } from '@emotion/core';

export const $table = css`
  border-collapse: collapse;
  margin-bottom: 0.5em;
  width: 100%;
`;

const cellPadding = '6px 0';

export const $heading = ({ colors }) => css`
  border-bottom: 1px solid ${colors.ui.greyLight[300]};
  padding: ${cellPadding};
  text-align: left;
`;

export const $row = (index) => ({ colors }) => {
  // Odd rows (1, 3, 5, etc) are white.
  // Even rows (2, 4, 6, etc) are light grey.
  const rowBg = index % 2 === 1 ? colors.ui.greyExtraLight[500] : colors.ui.white[500];

  return css`
    background-color: ${rowBg};
    padding: 0;
  `;
};

export const $cell = css`
  padding: ${cellPadding};
`;
