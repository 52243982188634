import { faGraduationCap } from '@fortawesome/pro-light-svg-icons';
import gql from 'graphql-tag';
import { escapeRegExp } from 'lodash-es';

const sharedFilters = (uid) => ({
  not: { assignedTo: { uid_in: uid } },
  standardTitle: { exists: true },
  standardType: {
    eq: [
      'OBJECTIVE',
      'SUB_OBJECTIVE',
      'THEME',
      'SUB_THEME',
      'OTHER'
    ]
  },
  retiredAt: { exists: false }
});

function filter (query, { uid }) {
  return {
    ...sharedFilters(uid),
    or: [
      { standardTitle: { allofterms: query } },
      { standardDescription: { alloftext: query } }
    ]
  };
}

function quickFilter (query, { uid }) {
  return {
    ...sharedFilters(uid),
    ...query.length <= 3
      ? {
          // If the query is 3 or fewer characters long, do a term filter.
          standardTitle: { allofterms: query },
          rootFn: 'standardTitle|allofterms'
        }
      : {
          // If the query is longer than three characters, we can do a more accurate
          // regexp filter.
          standardTitle: { regexp: `/${escapeRegExp(query)}/i` },
          rootFn: 'standardTitle|regexp'
        },
    or: [
      // National Science standards
      {
        region: { eq: 'NGSS' },
        publicationLong: { eq: 'NGSS Arranged by Topic' }
      },
      // National Social Studies standards
      {
        region: { eq: 'United States of America' },
        publicationShort: { eq: 'C3' }
      },
      // National ELA standards
      { region: { eq: 'CCSS' } }
    ]
  };
}

const Standard = {
  searchFragment: gql`
    fragment searchStandard on Standard {
      id
      uid
      certicaId
      standardTitle
      standardDescription
      region
      grades
      subjectShort
      subjectLong
      hasDerivedStandards
    }
  `,
  blueprintFragment: gql`
    fragment blueprintStandard on Standard {
      id
      standardTitle
      standardDescription
    }
  `,
  fullFragment: gql`
    fragment fullStandard on Standard {
      id
      uid
      certicaId
      standardTitle
      standardDescription
      region
      grades
      subjectShort
      subjectLong
      hasDerivedStandards
    }
  `,
  filter,
  quickFilter,
  defaults: (id, data = {}) => {
    // newLabel is passed in from AsyncSelect. Because standards are NEVER
    // created on the client, Standard.defaults() returns empty 'server'
    // information.
    const standardTitle = data.newLabel || null;
    // Reference existing uids when adding standards.
    const uid = data.uid;

    return {
      client: {
        id,
        uid,
        __typename: 'Standard',
        certicaId: null,
        standardTitle,
        standardDescription: null,
        region: null,
        grades: null
      },
      server: {}
    };
  },
  icon: faGraduationCap,
  typename: 'Standard',
  // Because standards come directly from Certica, they're never edited directly.
  // This schema only exports an input for content types to add and remove standards.
  inputs: {
    standards: {
      input: 'standard-select',
      name: 'standards'
    }
  }
};

export default Standard;
