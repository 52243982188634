import { constants, PaperHollowSVG } from '@newsela/angelou';

const Documentation = {
  app: {
    icon: PaperHollowSVG,
    color: constants.colors.ui.greyDark[700],
    accentColor: constants.colors.ui.grey[700],
    title: 'Documentation',
    href: 'https://docs.google.com/document/d/1B6Idc8OPST3QxqKqT2jDV455apgI7nRKGuh4ymTzl9c/edit?usp=sharing',
    isExternalLink: true,
    permissionForAdmin: true,
    permissionForStaff: true,
    permissionForContributor: true,
  },
  isContent: false
};

export default Documentation;
