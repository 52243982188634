import { faGripLines } from '@fortawesome/pro-regular-svg-icons';
import { splitBlock } from 'prosemirror-commands';

import { pre as preBlock } from '@shared/prosemirror/blocks';

import { blockActive, setBlockType, createNewline, togglePreBlock } from './helpers';

export const block = preBlock;
export const keys = {
  'Shift-Enter': splitBlock,
  Enter: createNewline('pre_block'),
  'Ctrl->': setBlockType('pre_block')
};
export const menuBlock = {
  title: 'Preserve Whitespace',
  content: faGripLines,
  active: blockActive('pre_block'),
  run: togglePreBlock
};
