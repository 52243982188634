import React from 'react';

import {
  faFile,
  faFileImage,
  faFileVideo,
  faFileMusic,
  faFilePdf,
  faFileCode,
  faFileCsv,
  faFileAlt
} from '@fortawesome/pro-light-svg-icons';
import ISO6391 from 'iso-639-1';
import {
  get,
  startCase,
  isArray,
  isObject,
  isEmpty,
  isString,
  isNull,
  isUndefined,
  isNaN,
  isFunction,
  last
} from 'lodash-es';
import numbro from 'numbro';
import getSlug from 'speakingurl';
import stringLength from 'string-length';

import ParentBundleList from '@client/common/components/ParentBundleList/ParentBundleList';
import PublishableStatus from '@client/common/components/PublishableStatus';
import rawSchema from '@client/schema';
import { titleCaseSpaced, titleCase } from '@client/utils/cases';
import {
  GRADE_BAND_PREFIX_LENGTH,
  NUMERICAL_GRADE_BANDS
} from '@client/utils/constants';
/**
 * Transform slugs in form fields.
 *
 * @param  {string} data
 * @return {string}
 */
export function transformSlug (data) {
  // If they type a space or hyphen, preserve it
  const spaceOrSpacer = /(-|\s)$/;
  let trailingChar = '';
  if (data.match(spaceOrSpacer)) {
    trailingChar = '-';
  }
  return getSlug(data) + trailingChar;
}

/**
 * Get label for a field.
 *
 * @param  {Object} config
 * @return {string}
 */
export function getLabel (config, formData) {
  if (config.input === 'checkbox') {
    // Don't fall back to using name. This allows checkboxes to eschew a label
    // in favor of the longLabel that sits next to the checkbox itself.
    return config.label || '';
  } else if (config.input === 'select' && config.hasImage) {
    // If it's an image input, it uses the name of the image field as a label
    return startCase(config.initialMapping.image);
  } else if (isFunction(config.label)) {
    return config.label(config, formData);
  } else {
    return config.label || startCase(config.name);
  }
}

/**
 * Determine the value that should populate in fields.
 *
 * @param  {Object} config - for the field
 * @param  {Object} data   - for the record
 * @param  {Object} [options]
 * @return {*}
 */
export function getValue (config, data, { plaintext } = {}) {
  if (plaintext) {
    return get(data, config.name); // Get the plaintext from prosemirror, e.g. 'text' not 'rawText'
  } else {
    return get(data, config.value) ?? get(data, config.name) ?? config.defaultValue ?? null;
  }
}

/**
 * Get options from config or formData.
 * @param {Object} config
 * @param {Object} data
 * @returns {Array}
 */
export function getOptions (config, data) {
  if (isArray(config.options)) {
    return config.options;
  } else if (isObject(data.options)) {
    return get(data.options, config.name);
  }
}

/**
 * Format options to be used in a checkbox field.
 * From { name, id } to { label, value }.
 * @param {Array} options ex: [{ name: 'Option 1', id: 'option1' }, ...]
 * @param {Object} config ex: { shortNames: { 'Social Studies', 'SS' }... }
 * @returns {Array} ex: [{ label: 'Option 1', value: 'option1' }, ...]
 */
export function formatOptions (options, config) {
  return options.map((option) => (
    {
      label: config?.shortNames?.[option.name] || option.name,
      value: option.id
    }
  ));
}

/**
 * Format labels for grades and grade bands.
 *
 * @param {string} value
 * @return {string}
 */
export function formatGradeBand (value) {
  // Grade bands get the following abbreviations:
  const abbreviations = {
    GRADE_K: 'K',
    GRADE_LOWER_ELEMENTARY: 'LE',
    GRADE_UPPER_ELEMENTARY: 'UE',
    GRADE_LOWER_AND_UPPER_ELEMENTARY: 'LE + UE',
    GRADE_MIDDLE_SCHOOL: 'MS',
    GRADE_HIGH_SCHOOL: 'HS',
    GRADE_COLLEGE: 'COLLEGE'
  };

  // In the case of numerical grades, display an ordinal number.
  return abbreviations[value]
    ? abbreviations[value]
    : numbro(parseInt(value.slice(GRADE_BAND_PREFIX_LENGTH))).format({ output: 'ordinal' });
}

/**
 * Convert enums into options that can be passed into select inputs. Certain
 * fields have special formatting.
 *
 * @param {array} [list]
 * @param {string} [type]
 * @param {object} [schemas]
 */
export function enumOptions (list = [], type = null, schemas = {}) {
  return list.map((item) => enumOption(item, type, schemas));
}

/**
 * Format language based on enum value.
 * e.g. LANG_EN -> English
 * @param {string} enumValue
 */
export function formatLanguage (enumValue) {
  return ISO6391.getName(enumValue.slice(5).toLowerCase());
}

/**
 * Convert a single enum into an option that can be passed into select inputs.
 * Certain fields have special formatting.
 *
 * @param {array} item
 * @param {string} [type]
 * @param {object} [schemas] to determine content type
 */
export function enumOption (item, type, schemas) {
  let label;

  switch (type) {
    case 'GradeBand':
      label = formatGradeBand(item);
      break;
    case 'Language':
      label = formatLanguage(item);
      break;
    case 'ArticleType':
      label = titleCaseSpaced(item).replace('Article ', '');
      break;
    case 'CropType':
      label = titleCaseSpaced(item).replace('Crop ', '');
      break;
    case 'ContentType':
      label = schemas[titleCase(item)]
        ? schemas[titleCase(item)].typename
        : titleCaseSpaced(item);
      break;
    default:
      label = titleCaseSpaced(item);
  }

  return {
    label,
    value: item
  };
}

/**
 * Unset a relation in a form.
 * @param {string} key a key in data
 * @param {object} val the value we want to remove from data[key]
 * @param {object} data formData
 * @returns {array|null} data[key] without val
 */
export function unsetRelation (key, val, data) {
  const prevVal = data[key];

  if (isArray(prevVal)) {
    // Remove a relation from a list
    return prevVal.filter((item) => item.uid !== val.uid);
  } else {
    // Remove a 1:1 relation
    return null;
  }
}

/**
 * Unset a value in a form. Mutates the data object in place.
 * @param {string} key a key in data
 * @param {*} val the value to remove from data[key] or to replace data[key]
 * @param {object} data formData
 */
export function unsetValue (key, val, data) {
  if (isObject(val) && val.uid) {
    // We're removing a relationship!
    // eslint-disable-next-line no-param-reassign
    data[key] = unsetRelation(key, val, data);
  } else if (isNull(val)) {
    // We're removing all values on a field!
    // eslint-disable-next-line no-param-reassign
    data[key] = null;
  } else {
    // We're replacing the old value with the updated value
    // eslint-disable-next-line no-param-reassign
    data[key] = val;
  }
}

/**
 * Determine if the field value is "empty".
 *
 * @param  {*} val - for the field
 * @return {boolean}
 */
export function isFieldEmpty (val) {
  if (isArray(val) || isObject(val)) {
    return isEmpty(val);
  } else if (isString(val)) {
    return val.length === 0;
  } else if (isNull(val) || isUndefined(val) || isNaN(val)) {
    return true;
  } else {
    // numbers, booleans, etc are never considered empty, as their falsy values
    // are still valid data
    return false;
  }
}

export function validateLength (val, comparison, type) {
  if (isFieldEmpty(val)) {
    // Empty fields are considered fine. To validate against them,
    // set them as 'required'
    return { isValid: true, length: 0 };
  }

  const length = isString(val) ? stringLength(val) : val.length;
  const isInvalid = type === 'min' ? length < comparison : length > comparison;

  if (isInvalid) {
    return { isValid: false, length };
  } else {
    return { isValid: true, length };
  }
}

// Get icon based on filetype.
export function getFileIcon (filename) {
  const ext = last(filename.split('.'));

  switch (ext) {
    case 'jpg':
    case 'jpeg':
    case 'png':
    case 'gif':
    case 'webp':
      return faFileImage;
    case 'mp4':
    case 'mov':
    case 'webm':
      return faFileVideo;
    case 'mp3':
    case 'wav':
      return faFileMusic;
    case 'pdf':
      return faFilePdf;
    case 'json':
      return faFileCode;
    case 'csv':
      return faFileCsv;
    case 'txt':
    case 'vtt':
      return faFileAlt;
    default:
      return faFile;
  }
}

export function getFileName (filename) {
  return last(filename.split('/'));
}

/**
 * Given a list of possible grade bands, find an exact or next-best match.
 * @param {string} levelToMatch // ex: 'GRADE_12'
 * @param {array} possibleLevels // ex: ['GRADE_11', 'GRADE_9', 'GRADE_HIGH_SCHOOL']
 * @returns {string|null} // ex: 'GRADE_11'
 */
export function matchGradeBands (levelToMatch, possibleLevels) {
  const bandPositions = {
    ...NUMERICAL_GRADE_BANDS,
    GRADE_K: 0,
    GRADE_LOWER_ELEMENTARY: 1.5, // mid-point between 0-3
    GRADE_LOWER_AND_UPPER_ELEMENTARY: 2.5, // mid-point between 0-5
    GRADE_UPPER_ELEMENTARY: 4.5, // mid-point between 4-5
    GRADE_MIDDLE_SCHOOL: 7.5, // mid-point between 6-8, but higher than 7
    GRADE_HIGH_SCHOOL: 10.5 // mid-point between 9-12
  };

  if (!isEmpty(possibleLevels)) {
    if (possibleLevels.includes(levelToMatch)) {
      return levelToMatch;
    }
    const absoluteVals = {};
    possibleLevels.forEach((level) => {
      absoluteVals[level] = Math.abs(bandPositions[levelToMatch] - bandPositions[level]);
    });
    const absValMapping = Object.entries(absoluteVals); // [[levelString, absVal]]
    // sort the array based off of the absVal, in ascending order
    const sortedByAbsVal = absValMapping.sort((a, b) => a[1] - b[1]);
    sortedByAbsVal.sort((a, b) => {
      // if two levels have the same abs val from the target level, order them in ascending order
      // so that the lower grade is first in the array and we favor it when returning a match
      if (a[1] === b[1]) {
        return bandPositions[a[0]] - bandPositions[b[0]];
      }
      return 0; // pass through without reordering
    });
    // return the string from first item from the sort
    return sortedByAbsVal[0][0];
  } else {
    return null;
  }
}

/**
 * Sort levels by gradeBand, handling non-numerical grade bands.
 * This returns levels in REVERSE grade order (highest first).
 * @param {object} levelA
 * @param {object} levelB
 */
export function sortGradeBands (levelA, levelB) {
  const gradeA = levelA.gradeBand;
  const gradeB = levelB.gradeBand;
  // Assign numerical positions to the non-numerical grade bands.
  const bandPositions = {
    GRADE_K: 0, // Before grade 1.
    GRADE_LOWER_ELEMENTARY: 3.5, // After grade 3.
    GRADE_UPPER_ELEMENTARY: 5.5, // After grade 5.
    GRADE_LOWER_AND_UPPER_ELEMENTARY: 5.6, // After grade 5 and UE.
    GRADE_MIDDLE_SCHOOL: 8.5, // After grade 8.
    GRADE_HIGH_SCHOOL: 12.5, // After grade 12.
    GRADE_COLLEGE: 13
  };
  // Try to parse the grade into an integer.
  const intA = parseInt(gradeA.slice(GRADE_BAND_PREFIX_LENGTH));
  const intB = parseInt(gradeB.slice(GRADE_BAND_PREFIX_LENGTH));
  // If the grade cannot be parsed into an integer, reference the hardcoded
  // band position. Otherwise, use the grade number itself.
  const posA = isNaN(intA) ? bandPositions[gradeA] : intA;
  const posB = isNaN(intB) ? bandPositions[gradeB] : intB;

  return posB - posA;
}

/**
 * Return all possible grades higher than the passed in gradeBand.
 * @param {string} gradeBand
 * @returns {array} [ 'GRADE_COLLEGE', 'GRADE_12'... ]
 */
export function getHigherGrades (gradeBand) {
  const allGradesDescending = rawSchema.enums.GradeBand
    .sort((a, b) => sortGradeBands({ gradeBand: a }, { gradeBand: b }));

  const allHigherGrades = allGradesDescending
    .slice(0, allGradesDescending.indexOf(gradeBand));

  // Remove grade bands, which are not reflected in the Vocabulary UI.
  const allHigherNumericalGrades = allHigherGrades
    .filter((gradeBand) => filterGradeBands(false)({ value: gradeBand }));

  // But, append the GRADE_COLLEGE band back on, which is reflected in the UI.
  if (allHigherNumericalGrades.length) {
    return ['GRADE_COLLEGE'].concat(allHigherNumericalGrades);
  } else {
    return [];
  }
}

/**
 * Filter grade bands to remove multi-grade bands, e.g. MS, HS
 * @param {boolean} hasMultigradeBands
 * @returns {Function} that runs on each grade band
 */
export function filterGradeBands (hasMultigradeBands) {
  return (gradeBand) => {
    // If multi-grade bands are allowed, always return true.
    if (hasMultigradeBands) {
      return true;
    }

    // Otherwise, determine whether the grade is a multi-grade band.
    return ![
      'GRADE_LOWER_ELEMENTARY',
      'GRADE_UPPER_ELEMENTARY',
      'GRADE_LOWER_AND_UPPER_ELEMENTARY',
      'GRADE_MIDDLE_SCHOOL',
      'GRADE_HIGH_SCHOOL',
      'GRADE_COLLEGE'
    ].includes(gradeBand.value);
  };
}

/**
 * Convert the GradeBand to just the number (as a string).
 * Note that 'GRADE_K' converts to 'K'
 * e.g. 'GRADE_2' -> '2', 'GRADE_COLLEGE' -> '13'
 * @param {string} gradeBand
 * @returns {string}
 */
export function gradeBandToNumber (gradeBand) {
  const grade = gradeBand.split('_')[1];

  return grade === 'COLLEGE' ? '13' : grade;
}

/**
 * Convert the grade number (as a string) to the GradeBand.
 * Note that 'K' converts to 'GRADE_K'
 * e.g. '2' -> 'GRADE_2', '13' -> 'GRADE_COLLEGE'
 * @param {string} gradeNumber
 * @returns {string}
 */
export function numberToGradeBand (gradeNumber) {
  return gradeNumber === '13' ? 'GRADE_COLLEGE' : `GRADE_${gradeNumber}`;
}

/**
 * Get the most appropriate field name for the context.
 * @param {string} validationName field name specific to validation
 * @param {string} fieldPath
 *  field name specific to nested issues related to a piece of content,
 *  like 'levels.0.questions.0'
 * @param {string} name default field name
 * @returns {string}
 */
export function getContextualFieldName ({ validationName, fieldPath, name }) {
  return validationName || fieldPath || name;
}

export function transformContentStatus (allContent) {
  return (
    <>
      {allContent.map(PublishableStatus)}
    </>
  );
}

export function transformParentBundleList (parentsList) {
  return (<ParentBundleList list={parentsList} />);
}

export function evaluateBooleanValueFromConfig (configProp, data) {
  return configProp !== undefined &&
  (
    (typeof configProp === 'boolean' && configProp === true) ||
    (typeof configProp === 'function' && configProp(data))
  );
}
