import { truncate, isString } from 'lodash-es';
import Button from 'mineral-ui/Button';
import Tooltip from 'mineral-ui/Tooltip';
import IconFlashOn from 'mineral-ui-icons/IconFlashOn';

import { $root } from './style';

export default function AttachedButton ({ config, onUpdate, formData }) {
  const buttonConfig = config.button;

  // Allow passing in a custom icon. The icon must be a mineral-ui icon.
  const ButtonIcon = buttonConfig.icon || IconFlashOn;

  if (buttonConfig.input === 'magic-button') {
    const maxLength = config.max;
    // When clicking the button, truncate the value based on the max length
    // of the field the button is attached to.
    const onClick = () => {
      let value = buttonConfig.value(formData);

      if (isString(value)) {
        value = maxLength
          ? truncate(value, {
            length: maxLength,
            omission: '',
            separator: ' '
          })
          : value;
      }

      onUpdate(value);
    };

    // Magic buttons can do many things, though they're most useful for fetching
    // data from other fields in a form.
    return (
      <Tooltip content={buttonConfig.description}>
        <Button
          minimal
          circular
          css={$root}
          size='small'
          type='button'
          iconStart={<ButtonIcon />}
          onClick={onClick}
          aria-label={buttonConfig.description}
        />
      </Tooltip>
    );
  }
}
