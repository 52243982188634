import React from 'react';

import { LoadingSpinner as AngelouLoadingSpinner } from '@newsela/angelou';
import PropTypes from 'prop-types';

import { $loadingWrapper, $loadingModal, $spinnerLabel, $loadingContainer, $loadingContainerSmall, $smallSpinner } from './style';
export default function LoadingSpinner ({
  isWrapped,
  isLoadingModal,
  label,
  setHeight,
  setWidth,
  small
}) {
  const loadingSpinner = (
    <div
      aria-label='loading spinner'
      css={small ? $loadingContainerSmall : $loadingContainer}
    >
      <AngelouLoadingSpinner
        ariaProps={{
          'aria-live': 'polite',
          role: 'alert'
        }}
        __cssFor={{ root: small ? $smallSpinner : null }}
        title={label}
      />
      <div css={$spinnerLabel}>
        {(label || 'Loading content')}
      </div>
    </div>
  );

  if (isLoadingModal) {
    return (
      <div
        aria-label='loading modal'
        css={$loadingModal(small)}
      >
        {loadingSpinner}
      </div>
    );
  } else if (isWrapped) {
    return (
      <div
        aria-label='loading spinner wrapper'
        css={$loadingWrapper(setHeight, setWidth)}
      >
        {loadingSpinner}
      </div>
    );
  } else {
    return loadingSpinner;
  }
}

LoadingSpinner.propTypes = {
  cssFor: PropTypes.object,
  isLoadingModal: PropTypes.bool,
  isWrapped: PropTypes.bool,
  label: PropTypes.string,
  setHeight: PropTypes.string,
  setWidth: PropTypes.string
};
