import { css } from '@emotion/core';
import { constants } from '@newsela/angelou';
import { get, isNumber } from 'lodash-es';

const angelouColors = constants.colors;

// Angelou icons are intended to have different stroke widths depending
// on the size of the icon. This allows them to be more visible in smaller sizes.
function getStrokeWidth (size) {
  if (isNumber(size) && size < 32) {
    return '3px';
  } else {
    return '2px';
  }
}

/**
 * Get the stringified size of the icon.
 * @param {number|string} size
 * @returns {string}
 */
function getSizeString (size) {
  return isNumber(size) ? `${size}px` : size;
}

// Note: Angelou icons can only use colors specified by Angelou. It does not
// have access to our full Alexandria theme.
export const $angelouRoot = (size, color, secondary, customCss, spin) => {
  const primaryColor = get(angelouColors, color, 'inherit');
  const secondaryColor = get(angelouColors, secondary, 'inherit');
  const strokeWidth = getStrokeWidth(size);
  const sizeString = size ? getSizeString(size) : 'inherit';
  const spinString = spin ? `
  animation: rotate 1s linear infinite;
  @keyframes rotate {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }` : '';

  const base = css`
    width: ${sizeString};
    height: ${sizeString};
    --angelou-svg-primary-color: ${primaryColor};
    --angelou-svg-secondary-color: ${secondaryColor};

    /* On non-retina screens, we need to increase the stroke width to
     * account for subpixel anti-aliasing. On retina screens, we can
     * maintain the same stroke width for crisp icons. */
    & * {
      stroke-width: ${strokeWidth};
    }

    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
      & * {
        stroke-width: 2px;
      }
    }

    ${spinString}
  `;

  return customCss ? [base, customCss] : base;
};

export const $faRoot = (size, color, secondary, customCss) => ({ colors }) => {
  const primaryColor = get(colors, color) || 'inherit';
  const secondaryColor = get(colors, secondary) || 'inherit';
  const sizeString = size ? getSizeString(size) : 'inherit';

  const base = css`
    color: ${primaryColor};
    font-size: ${sizeString};
    --fa-primary-color: ${primaryColor};
    --fa-secondary-color: ${secondaryColor};
  `;

  return customCss ? [base, customCss] : base;
};
