/**
 * Convert S3 image urls to imgix urls, depending on the source.
 * @param {string} url
 * @returns {string}
 */
function imgixUrl (url) {
  const isProd = url.match(/prod-newsela-media/);
  const isStaging = url.match(/staging-newsela-media/);
  let imgixHost;
  if (isProd) {
    imgixHost = 'newsela.imgix.net';
  } else if (isStaging) {
    imgixHost = 'staging-newsela-media.imgix.net';
  } else {
    imgixHost = 'newsela-dev.imgix.net';
  }

  return url.replace(/(?:staging-newsela-media|prod-newsela-media|newsela-media-dev)\.s3\.amazonaws\.com/, imgixHost);
}

/**
 * Get the crop argument for imgix, based on cropType.
 * @param {object} data
 * @returns {string|null}
 */
function getCropModes (data) {
  switch (data.cropType) {
    case 'CROP_FOCAL_POINT': return 'focalpoint';
    // Automatically crop to display faces (using imgix face detection),
    // falling back to edge detection if there are none.
    // More details: https://docs.imgix.com/apis/rendering/size/crop
    case 'CROP_AUTO': return 'faces,edges';
    default: return null;
  }
}

/**
 * Get imgixParams based on Image data.
 * @param {object} data
 * @returns {object}
 */
function imgixCrop (data) {
  const fit = data.cropType === 'CROP_NONE' ? 'clip' : 'crop';
  const crop = getCropModes(data);

  return {
    auto: 'format,compress',
    fit,
    ...crop && { crop },
    ...crop === 'focalpoint' && {
      'fp-x': data.focalPointX,
      'fp-y': data.focalPointY,
      'fp-z': data.focalPointZ
    }
  };
}

/**
 * Convert S3 image urls to nails urls (for Monolith article headers).
 * @param {string} url
 * @returns {string}
 */
function nailsUrl (url) {
  if (!url) {
    return null;
  }

  return url.replace(
    /(newsela-media-dev|prod-newsela-media|staging-newsela-media|dev-newsela-media)\.s3\.amazonaws\.com/,
    'nails.newsela.com/s3/$1'
  );
}

/**
 * Convert S3 image urls to nails urls (thumbnail size, used for cards on
 * the Bundles preview and in the content picker).
 * @param {string} url
 * @returns {string}
 */
function nailsThumbnail (url) {
  if (!url) {
    return null;
  }

  const newUrl = nailsUrl(url);

  return `${newUrl}?width=300`;
}

module.exports = {
  imgixUrl,
  imgixCrop,
  nailsUrl,
  nailsThumbnail
};
