import { isObject, isUndefined, isArray, find, uniqBy } from 'lodash-es';

import { queries } from '@client/common/graph';
import * as schemas from '@client/common/schema';
import { evaluateCase } from '@client/utils/cases';
import {
  METADATA_SOURCED_TAG_TYPES
} from '@client/utils/constants';
import { enumOption } from '@client/utils/fields';
import search from '@client/utils/search';

// By default, options are specified as { label, value, isDisabled }.
const defaultMapping = {
  label: 'label',
  value: 'value',
  isDisabled: 'isDisabled'
};

// By default, query for the first 20 results in async select inputs.
// This may be overwritten by the results of a type's search() method.
export const defaultPagination = {
  first: 20,
  offset: 0
};

/**
 * If there's a value, pass it through. Otherwise, format the default value
 * based on the config.
 * @param {*} value
 * @param {object} config
 */
export function formatDefaultValue (value, config) {
  if (value) {
    return value;
  }

  return config.isMulti ? [] : '';
}

/**
 * Get the type of Select component we want to use.
 * @param {object} config
 * @returns {string}
 */
export function getSelectType (config) {
  if (config.isAsync && config.isCreatable) {
    return 'AsyncCreatable';
  } else if (config.isAsync) {
    return 'Async';
  } else if (config.isCreatable) {
    return 'Creatable';
  } else {
    return 'Select';
  }
}

/**
 * Convert a value to the form that react-select wants.
 * @param {string|object} value
 * @param {object} config
 * @param {inputArgs} inputArgs
 * @returns {object} with { label, value } and possibly other properties
 */
export function toOption (value, config = {}, { isInitial, formData } = {}) {
  // If you're not saving data that looks like { label, value }
  // or strings, then you may specify a custom mapping in the form of
  // { label: 'yourLabelProp', value: 'yourValueProp' }
  const mapping = config.mapping || defaultMapping;

  // 'isInitial' is set by the Select input when the input is rendered for the
  // first time. We only do special logic for the initial options if the field
  // ALSO has an 'initialMapping' in its config (otherwise we use the normal
  // config.mapping).
  if (isInitial && config.initialMapping) {
    // Formats initial value based on other properties of the record this field is inside
    const initialMapping = config.initialMapping;

    return {
      // Images will only be included if you specify them in config.initialMapping
      // and if config.hasImage === true
      image: initialMapping.image && formData[initialMapping.image],
      label: formData[initialMapping.label],
      value: formData[initialMapping.value] || ''
    };
  } else if (mapping && isObject(value)) {
    if (mapping.excludeIds?.includes(value.id)) {
      return '';
    }
    // Formats initial values, results of queries, and static options passed in
    const returnObject = {
      // Images will only be included if you specify them in config.mapping
      // and if config.hasImage === true
      // type (e.g. tagType) will also be included if specified in config.mapping
      image: mapping.image && value[mapping.image],
      label: value[mapping.label],
      value: value[mapping.value],
      type: mapping.type && value[mapping.type],
      path: value[mapping.path],
      isDisabled: value.isDisabled,
      hasDerivedStandards: value[mapping.hasDerivedStandards],
      confidenceRating: value.score
    };
    return Object.values(returnObject).every(isUndefined) ? '' : returnObject;
  } else if (config.enum) {
    // Value is an enum. Config.enum may be a string (to use a specific enum
    // formatting) or 'true'. Pass in schemas in case we need to use them
    // to determine the formatted label.
    return enumOption(value, config.enum, schemas);
  } else {
    // Formats initial values, results of queries, and static options passed in as non-objects.
    // Title case check for stream subject options whose values are enum cased.
    return {
      label: evaluateCase(value, config.ignoreCase),
      value
    };
  }
}

/**
 * Convert multiple values to the form that react-select wants.
 * @param {array} values
 * @param {object} config
 * @param {inputArgs} inputArgs
 * @returns {array}
 */
export function toOptions (values, config, { isInitial, formData } = {}) {
  if (config?.name === 'tags') {
    return (values || [])
      .map((val) => toOption(val, config, { isInitial, formData }))
      .filter((tag) => {
        return !METADATA_SOURCED_TAG_TYPES.includes(tag.type);
      });
  }
  return (values || [])
    .map((val) => toOption(val, config, { isInitial, formData }))
    .filter((val) => val !== '');
}

/**
 * Match a value with one of our existing options.
 * @param {*} value
 * @param {object} config
 * @returns {Function} that runs against each option
 */
export function matchOption (value, config) {
  const objValue = config.isMulti
    ? toOptions(value, config)
    : toOption(value, config);

  return (option) => {
    // In multi-select inputs, objValue will be an array.
    return isArray(objValue)
      ? find(objValue, (obj) => option.value === obj.value)
      : option.value === objValue.value;
  };
}

/**
 * Format the initial (synchronous) value
 * @param {*} value
 * @param {object} config
 * @param {inputArgs} inputArgs
 * @param {object} inputArgs.formData
 * @returns {object|array}
 */
function formatInitialValue (value, config, { formData }) {
  return config.isMulti
    ? toOptions(value, config, { isInitial: true, formData })
    : toOption(value, config, { isInitial: true, formData });
}

/**
 * Load synchronous options.
 * @param {*} value
 * @param {object} config
 * @param {object} inputArgs
 * @param {object} inputArgs.formData
 * @returns {object} with { options, objValue }
 */
export function loadSyncOptions (value, config = {}, { formData } = {}) {
  const isAsync = !!config.isAsync;
  const isMulti = !!config.isMulti;

  let options;
  let objValue;

  if (!isAsync && config.options) {
    // First, attempt to format the value from the options. We do this because
    // option labels and values might be different, and we want to display the
    // correct label for the currently-selected value.
    const availableOptions = config.dedupeBy
      ? uniqBy(config.options, config.dedupeBy)
      : config.options;
    options = toOptions(availableOptions, config);
    objValue = isMulti
      ? options.filter(matchOption(value, config))
      : options.find(matchOption(value, config));
  }
  if (isAsync && config.isSearchFilter) {
    objValue = value;
  }
  if (isUndefined(objValue)) {
    // If we haven't formatted the current value (or if we're using async options),
    // attempt to format the current value in isolation from any possible options.
    objValue = formatInitialValue(value, config, { formData });
  }

  if (config.optionsFilter) {
    options = config.optionsFilter(options);
  }
  return { options, objValue };
}

/**
 * Load asynchronous options from our server.
 * - If we're searching streams, this preloads the major product streams.
 * - If we've configured it to preload options, this will query the server when
 *   the Select input loads.
 * - Otherwise, it will query the server when the user has typed in some inputValue.
 * Note: This is not called directly, but instead is called through
 * debouncedLoadAsyncOptions()
 * @param {object} config
 * @param {object} schema
 * @param {object} formData
 * @param {ApolloClient} client
 */
export function loadAsyncOptions ({ config, schema, formData, client, excludeIds }) {
  // Use a custom search function if configured, otherwise use our search util.
  const searchFn = config.search ? schema[config.search] : search;

  const isArticle = formData?.__typename === 'Article';

  return async (inputValue) => {
    // Check if there's input first before making needless API calls. If we
    // explicitly want to preload data (based on the config), then do so.
    if (inputValue || config.preloadOptions) {
      const commonSearchOptions = { uid: formData.uid, forceFilter: config.forceFilter };
      const metadataSearchOptions = {
        variables: {
          needle: inputValue,
          excludeIds
        }
      };

      // The metadata service has a different request format, i.e., filtering is not done on the Alexandria side.
      const isMetadataService = config.isMetadataService;
      const searchOptions = isMetadataService ? metadataSearchOptions : commonSearchOptions;
      // Generate the arguments we want to use for the query.
      // Pass in forceFilter argument to always call a type's filter function even if
      // there is no input value.
      const searchArgs = searchFn(schema, inputValue, searchOptions, excludeIds);

      // Then run the query against the server.
      let { data } = await client.query({
        query: queries[config.query],
        // Spread the results on the search method into the args we send
        // to the query.
        ...searchArgs,
        variables: {
          // Make sure we add the default pagination, in case the search method
          // doesn't specify `first` or `offset` variables.
          ...!isMetadataService && defaultPagination,
          // But if it does, overwrite the defaults.
          ...searchArgs.variables,
          // If we're specifically searching for streams, make sure we
          // order them alphabetically.
          ...config.name === 'streams' && { order: [{ asc: 'streamTitle' }] }
        },
        fetchPolicy: 'network-only'
      });

      if (config.optionsFilter) {
        data = config.optionsFilter(data);
      }

      // Get the query property (it's the only property that should exist for single queries).
      const prop = Object.keys(data)[0];

      let options = data[prop];
      // If our config specifies that we should dedupe the options list dedupe by the given field.
      if (config.dedupeBy) options = uniqBy(options, config.dedupeBy);
      // If our config provides static options, we need to merge them with the dynamic ones.
      if (config.staticOptions) options = [...config.staticOptions, ...options];
      // If our config has the isDisabled property (e.g. legacyWorkflows), we need to add disabled options.
      if (config.isDisabled) options = options.map((option) => ({ ...option, isDisabled: config.isDisabled }));

      // Convert to the format that react-select wants.
      return toOptions(options, config);
      // Decides if we should be showing Recommended Tags for the user.
    } else if (config?.name === 'taxonomyTags' && !inputValue && isArticle) {
      const { data } = await client.query({
        query: queries.recommendedTags,
        variables: { uid: formData.uid },
        fetchPolicy: 'network-only'
      });
      return toOptions(data.getRecommendedTaxonomyTags, config);
    }
  };
}
