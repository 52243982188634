import React from 'react';

import { Link } from '@reach/router';
import { useStoreActions } from 'easy-peasy';
import { sortBy } from 'lodash-es';
import PropTypes from 'prop-types';

import { $linkList } from './style';

export default function ParentBundleList ({ list }) {
  const closeDrawer = useStoreActions((actions) => actions.drawer.close);

  return (
    <ul css={$linkList}>
      {sortBy(list, ['name'])?.map(({ id, name: parentBundleName }) => (
        <li key={id}>
          <Link to={`/bundles/${id}`} title={parentBundleName} onClick={() => closeDrawer()}>
            {parentBundleName} ({id})
          </Link>
        </li>
      ))}
    </ul>
  );
}

ParentBundleList.propTypes = {
  list: PropTypes.array
};
