import React from 'react';

import { ToggleButton } from '@newsela/angelou';
import Tooltip from 'mineral-ui/Tooltip';

const ToggleLeveler = ({ item, levelerStatus, setLevelerStatus }) => {
  const label = !levelerStatus.toggleLeveledText ? 'Show leveled text' : 'Hide leveled text';
  return (
    <Tooltip content={item.title}>
      <ToggleButton
        label={label}
        defaultPressed={false}
        disabled={levelerStatus.isToggleLevelerDisabled}
        onClick={() => {
          setLevelerStatus((prevState) => {
            return { ...prevState, toggleLeveledText: !prevState.toggleLeveledText };
          });
        }}
        css={{
          marginLeft: '8px',
          marginRight: '12px',
        }}
      />
    </Tooltip>
  );
};

export const menuToggleLeveler = [
  {
    title: 'Compare with generated text',
    customButton: ToggleLeveler,
  },
];
