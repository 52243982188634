import React, { forwardRef, useRef } from 'react';

import { faCog } from '@fortawesome/pro-regular-svg-icons';
import { Image, ButtonWithPopOut, Button } from '@newsela/angelou';
import { head, findLast, truncate } from 'lodash-es';
import Tooltip from 'mineral-ui/Tooltip';
import PropTypes from 'prop-types';

import Icon from '@client/common/components/Icon';
import SecondaryMenu from '@client/common/components/SecondaryMenu';
import StatusBadge from '@client/common/components/StatusBadge';
import * as schemas from '@client/common/schema';
import { formatDate } from '@client/utils/time';
import { imgixUrl, imgixCrop } from '@shared/images';

import {
  $root,
  $left,
  $right,
  $title,
  $titleInner,
  $label,
  $subtitle,
  $actionMenu,
  $status,
} from './style';

/**
 * Format the list of streams based on specific names and rename them accordingly.
 * This is only used when displaying content Headers cards.
 * @param {array} streams
 * @returns {string} String containing any or all of ESS, ELA, SCI and SS delimited by a forward slash.
 */
function formatStreamsForArticleHeaders (streams = []) {
  const renameMap = {
    essentials: 'ESS',
    ela: 'ELA',
    science: 'SCI',
    'social studies': 'SS'
  };

  return streams
    .map((stream) => renameMap[stream.name.toLowerCase()] || null)
    .filter((name) => name !== null)
    .join(' / ');
}

/**
 * Format the list of streams. If there are more than one, add (+num)
 * @param {array} streams
 * @returns {string}
 */
function formatStreams (streams = []) {
  if (streams.length > 1) {
    return `${truncate(head(streams).name, 40)} (+${streams.length - 1})`;
  } else if (streams.length === 1) {
    return truncate(head(streams).name, 40);
  } else {
    return '';
  }
}

/**
 * Format the latest publish date, if it exists.
 * @param {array} events
 * @returns {string}
 */
function formatPubDate (events = []) {
  const publish = findLast(events, (event) => event.event === 'PUBLISH');

  return publish ? formatDate(publish.createdAt) : '';
}

/**
 * Format the streams and publish date, if at least one of them exists.
 * @param {string} streams
 * @param {string} publishDate
 * @returns {string}
 */
function formatSubtitle (streams, publishDate) {
  if (streams && publishDate) {
    return `${streams} | ${publishDate}`;
  } else if (streams) {
    return streams;
  } else if (publishDate) {
    return publishDate;
  } else {
    return '';
  }
}

// Card images render differently for content types that use imgix, nails,
// or no images (displaying an icon instead, in that case). Exported for testing.
export function CardImage ({ thumbnail, name, imgixParams, icon, type }) {
  const plainThumbnail = thumbnail && ['LegacyArticle', 'Bundle'].includes(type);
  const imgixThumbnail = thumbnail;
  if (plainThumbnail) {
    // Legacy articles and bundles don't have imgix thumbnails, so don't
    // transform the url or add imgix params.
    return <Image src={thumbnail} alt={name} suppressOptimization />;
  } else if (imgixThumbnail) {
    // All other content types have imgix thumbnails, so transform them.
    return <Image src={imgixUrl(thumbnail)} alt={name} imgixParams={imgixParams} />;
  } else {
    // If there is no thumbnail, show an icon.
    return <Icon icon={icon} size={48} color='ui.white.500' />;
  }
}

CardImage.propTypes = {
  thumbnail: PropTypes.string,
  name: PropTypes.string,
  imgixParams: PropTypes.object,
  icon: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.func
  ]),
  type: PropTypes.string
};

/**
 * Card that displays a piece of content with some metadata about it.
 */
function ContentCard ({
  data,
  onClick,
  menuConfig = {},
  showMenu = true,
  variant
}, ref) { // DOM node reference that can be forward to a parent component.
  const type = data.__typename;
  const schema = schemas[type];
  const icon = schema.icon;
  const thumbnail = data.thumbnail;
  const name = data.name;
  const status = data.status;
  // Label is optional, but will appear above the name if it exists. It falls
  // back to the content type otherwise.
  const label = data.label || schema.typename;
  // Streams and published date are both optional. If they both exist,
  // put a pipe (|) between them.
  const streams = type === 'LegacyArticle' ? formatStreamsForArticleHeaders(data.streams) : formatStreams(data.streams);
  const publishDate = formatPubDate(data.events);
  const subtitle = formatSubtitle(streams, publishDate);
  const menuRef = useRef();
  // If the data has imgixParams, include them.
  const imgixParams = data.cropType
    ? {
        ...imgixCrop(data),
        // Set the width and height of the image based on our card styles.
        ar: '1:1',
        w: 90,
        h: 90
      }
    : {};
  const cardImageOptions = { thumbnail, name, imgixParams, icon, type };

  // When we configure the secondary action menu, add some default options.
  const secondaryMenuConfig = {
    ...menuConfig,
    type,
    attachedCount: data.attached?.length || 0
  };

  // If we're displaying LegacyArticles, include a link to the live site
  // in their secondary action menus.
  if (type === 'LegacyArticle' && data.headerUrl) {
    secondaryMenuConfig.onView = () => window.open(process.env.MONOLITH_BASE_URL + data.headerUrl);
  }

  if (type === 'ExternalLink' && data.label === 'Video') {
    secondaryMenuConfig.onCopy = null;
  }

  const handleKeyboard = (e) => {
    // Only act if the event was triggered from the card itself, not from any of its children.
    if (e.target === e.currentTarget && (e.key === 'Enter' || e.key === ' ')) {
      onClick(e);
    }
  };

  const handleClick = (e) => {
    // Only act if the event was not triggered from the secondary menu.
    if (!menuRef?.current?.contains(e.target)) {
      onClick(e);
    }
  };

  return (
    <div
      tabIndex='0'
      css={$root(!!onClick, variant)}
      onClick={handleClick}
      onKeyDown={handleKeyboard}
    >
      <div css={$left}>
        <CardImage {...cardImageOptions} />
      </div>
      <div css={$right}>
        {label ? <p css={$label}>{label}</p> : null}
        {/* Tooltip is used to display the full title */}
        <Tooltip content={name} css={$title} role='none'>
          <button
            css={$titleInner}
            ref={ref}
            type='button'
            // Temporary workaround to prevent screen readers from announcing the title 4 times.
            aria-describedby='none'
            aria-owns='none'
          >
            {name}
          </button>
        </Tooltip>
        {subtitle ? <span css={$subtitle}>{subtitle}</span> : null}
      </div>

      {/* Render the secondary action menu. */}
      {showMenu && (
        <div ref={menuRef}>
          <ButtonWithPopOut
            __cssFor={{ Button: { root: $actionMenu }, PopOut: SecondaryMenu.popOutStyles }}
            id={`secondary-menu-${data.id}`}
            /* AUTOGENERATED TODO: update angelou to new flavor.
              see https://github.com/newsela/angelou/blob/main/src/components/Button/README.md#MIGRATION
              for migration guide. */
            legacy_flavor={Button.legacy_flavor.flat}
            popOutContents={<SecondaryMenu {...secondaryMenuConfig} />}
            buttonIcon={<Icon icon={faCog} title='Actions' isDecorative={false} />}
            buttonContents=''
            horizontalOffset='-226px'
            verticalOffset='54px'
            ariaProps={{ 'aria-label': 'Actions' }}
          />
        </div>
      )}

      {status ? (
        <div css={$status}>
          <StatusBadge status={status} />
        </div>
      ) : null}
    </div>
  );
}

// Using forwardRef here to be able to foward a DOM node reference to a parent component.
const ExportedContentCard = forwardRef(ContentCard);

export default ExportedContentCard;

// When setting the proptypes for the exported component, we also have to set them
// for the component that's being wrapped, so storybook can see and manipulate them.
const cardPropTypes = {
  /** Data for the content */
  data: PropTypes.object.isRequired,
  /** Function to call when clicked. Passing this in
   * will enable hover/active styles */
  onClick: PropTypes.func,
  /** Secondary Action Menu configuration. */
  menuConfig: PropTypes.shape({
    ...SecondaryMenu.propTypes,
    type: PropTypes.string // Not required in ContentCard, because we set it automatically.
  }),
  /** Explicitly pass in false to hide the secondary action menu. This is only done
   * in Content Provider results.
   */
  showMenu: PropTypes.bool,
  /**
   * Type of validation, either 'danger' (for errors) or 'warning'. This matches
   * the syntax of MineralUI's field variants. */
  variant: PropTypes.oneOf(['danger', 'warning'])
};
ContentCard.propTypes = cardPropTypes;
ExportedContentCard.propTypes = cardPropTypes;
