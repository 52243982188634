/* eslint-disable camelcase */
import { css } from '@emotion/core';
import { constants, typographyStyles } from '@newsela/angelou';

// The Table component `__css` prop takes in a style object, not a
// css function so we're creating variables explicitly from
// Angelou to be able to use colors and typography in the style objects below.
const { colors } = constants;

const base = css`
  width: 100%;
  padding-left: 24px;
  padding-right: 8px;
  padding-bottom: 8px;
`;

export const $heading = [
  typographyStyles.textXS,
  base,
  css`
    display: inline-block;
    color: ${colors.ui.grey[500]};
    font-weight: 700;
    letter-spacing: 1px;
    padding-top: 4px;
    margin-top: 4px;
    margin-bottom: 4px;
    border-bottom: 1px ${colors.ui.greyLight[50]} solid;
  `
];

// These styles do not get called with css`` because they are called with it
// in the Button component itself.
export const $button = [
  typographyStyles.textM,
  base,
  `
    border-radius: 0;
    padding-top: 8px;
    color: ${colors.ui.grey[700]};
    justify-content: left;
    text-decoration: none;

    & svg {
      margin-right: 8px;
    }

    &:first-of-type {
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
    }

    &:last-of-type {
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;
    }

    &:not(:disabled):hover,
    &:not(:disabled):focus {
      background-color: ${colors.ui.greyExtraLight[50]};
    }

    &:not(:disabled):active {
      background-color: ${colors.ui.greyLight[50]};
    }

    &:disabled {
      cursor: not-allowed;
      color: ${colors.ui.grey[500]};
    }
  `
];

export const $menuItems = (isScrollable) => {
  return css`
    overflow: ${isScrollable ? 'scroll' : 'hidden'};
    max-height: ${isScrollable ? '10rem' : 'unset'};
  `;
};

export const $filter = () => [
  typographyStyles.textM,
  base,
  css`
    padding-top: 4px;
    padding-bottom: 4px;
    margin-bottom: 4px;
    border-bottom: 1px ${colors.ui.greyLight[50]} solid;
`];
