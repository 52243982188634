import React from 'react';

import {
  Icon,
  constants,
  CheckCircleFilledSVG,
  CloseCircleFilledSVG
} from '@newsela/angelou';
import PropTypes from 'prop-types';

import { $root, $icon, $text, $name, $type } from './style';

const { ui } = constants.colors;

export default function PublishableStatus ({ id, name, type, included }) {
  const color = included ? ui.green[500] : ui.red[500];
  const icon = included ? CheckCircleFilledSVG : CloseCircleFilledSVG;
  // Add icon name, for testing.
  const iconName = included ? 'CheckCircleFilled' : 'CloseCircleFilled';

  return (
    <div css={$root} key={id} tabIndex='0'>
      <div data-testid={`icon-${iconName}`}>
        <Icon
          __cssFor={{ root: $icon(color) }}
          SvgComponent={icon}
        />
      </div>
      <div css={$text}>
        <p css={$name}>{name}</p>
        <p css={$type}>{type}</p>
      </div>
    </div>
  );
}

PublishableStatus.propTypes = {
  /** Content ID */
  id: PropTypes.string,
  /** Content name */
  name: PropTypes.string,
  /** Content type */
  type: PropTypes.string,
  /** This content will be included in the publishing */
  included: PropTypes.bool
};
