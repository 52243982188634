/* eslint-disable camelcase */
import { css } from '@emotion/core';

export const $menu = ({
  colors,
  backgroundColor_themePrimary,
  backgroundColor_themePrimary_hover,
  backgroundColor_themePrimary_active,
}, isProseMirror) => css`

  ${isProseMirror ? 'display: flex;' : 'display: block;'}
  ${isProseMirror ? 'padding: 11px 0;' : ';'}
  ${isProseMirror ? 'flex-wrap: wrap;' : ';'}
  ${isProseMirror ? 'justify-content: center;' : ';'}
  ${isProseMirror ? 'align-items: center;' : ';'}
  ${isProseMirror ? 'border: solid 1px #c8d1e0;' : ';'}
  ${isProseMirror ? 'border-radius: 8px;' : ';'}
  ${isProseMirror ? 'border-bottom-left-radius: 0;' : 'border-bottom; 0'}
  ${isProseMirror ? 'border-bottom-right-radius: 0;' : ';'}
  ${!isProseMirror ? 'margin-bottom: 2px;' : ';'}

  flex: 1 1 auto;
  
  & > [aria - checked='false'] + [aria - checked='false']: not(: focus) {
  border - left - color: transparent;
}

  & > button: not(: last - of - type),
  & > *: not(: last - of - type) button {
  border - radius: 3px;
}

  & > button: not(: first - of - type),
  & > *: not(: first - of - type) button {
  border - radius: 3px;
}

  & > [aria - checked='true'] button {
  background - color: ${backgroundColor_themePrimary};
}

  /* stylelint-disable selector-max-compound-selectors */
  & > [aria - checked='true'] button * {
  /* stylelint-disable-line */
  color: ${colors.ui.white[500]};
  }
  /* stylelint-enable */

  & > [aria - checked='true']:hover button {
  background - color: ${backgroundColor_themePrimary_hover};
}

  & > [aria - checked='true']:active button,
  & > [aria - checked='true']:focus button {
  background - color: ${backgroundColor_themePrimary_active};
}
`;

export const $menuPlaceholder = css`
visibility: hidden;
`;
