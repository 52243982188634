import { css } from '@emotion/core';

export const $revisionSelector = () => css`
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
`;

export const $header = () => css`
  margin-top: 0;
`;

export const $selectInputs = () => css`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const $selectInput = () => css`
  margin-right: 30px;
  min-width: 250px;
`;

export const $option = (isSelected, isFocused) => ({ colors }) => {
  const base = css`
    padding: 10px;
  `;

  const selected = css`
    background-color: ${colors.ui.coreBlue[300]};
    color: ${colors.ui.white[500]};
  `;

  const focused = css`
    background-color: ${colors.ui.coreBlue[50]};
  `;

  if (isSelected) {
    return [base, selected];
  } else if (isFocused) {
    return [base, focused];
  } else {
    return base;
  }
};

export const $secondaryText = ({ typography }) => [
  typography.size.S
];
