import { isEmpty, findIndex, get, set } from 'lodash-es';
import IconGetApp from 'mineral-ui-icons/IconGetApp';

import { ArticleLevel } from '@client/common/schema';
import { LANG_EN, LANG_ES, MAGIC_BUTTON_DESCRIPTIONS } from '@client/utils/constants';

/**
 * Get maximum article level.
 * @param {String} language
 * @param {Array} levels sorted active article leves keyed by language
 * @returns {Object} maximum article level keyed by language
 */
function getMaxLevel (language, levels) {
  const originalLevel = levels[language]?.filter((level) => level.isOriginalLevel)[0];
  return originalLevel || (levels[language] && levels[language][0]);
}

/**
 * Determine whether this article level is the highest.
 * @param {Object} currentLevel
 * @param {String} currentLanguage
 * @param {Integer} indexInLanguage
 * @param {Object} spanishMaxLevel
 * @returns {Boolean}
 */
function isHighestLevel ({ currentLevel, currentLanguage, indexInLanguage, spanishMaxLevel }) {
  // Manage when the highest level should not return magic buttons.
  if (indexInLanguage === 0) {
    // We're at the highest level in English! Don't add anything.
    const highestEnglishLevel = currentLanguage === LANG_EN;
    // We're at the highest level in Spanish but it's not the max. Don't add anything.
    const highestSpanishLevelButNotMax = currentLanguage === LANG_ES && currentLevel !== spanishMaxLevel;
    if (highestEnglishLevel || highestSpanishLevelButNotMax) {
      return true;
    }
  }
}

/**
 * Determine whether this field name is byline.
 * @param {Object} field
 * @param {Object} currentLevel
 * @param {Object} spanishMaxLevel
 * @returns {Boolean}
 */
function isSpanishByline ({ field, currentLevel, spanishMaxLevel }) {
  // We want to exclude byline since it gets automatcially translated.
  const bylineField = field.name === 'bylineOverride';

  if (spanishMaxLevel === currentLevel) {
    if (bylineField) {
      return true;
    }
  }
}

/**
 * Determine whether this article has no English levels.
 * @param {Object} currentLevel
 * @param {Object} englishMaxLevel
 * @param {Object} spanishMaxLevel
 * @returns {Boolean}
 */
function isSpanishWithoutEnglishLevel ({ currentLevel, englishMaxLevel, spanishMaxLevel }) {
  // No English levels to copy from.
  const noEnglishLevels = !englishMaxLevel;

  if (spanishMaxLevel === currentLevel) {
    if (noEnglishLevels) {
      return true;
    }
  }
}

/**
 * Remove power word blocks and replace them with regular text nodes.
 * Export for testing
 * @param {Object} parsedRawText
 * @param {String} path
 */
export function removePowerWordBlocks (parsedRawText, path) {
  // If the path doesn't exist, return.
  if (!get(parsedRawText, path)) return;

  // iterate over the content array
  get(parsedRawText, path).forEach((node, index) => {
    // If the node contains a power word block, grab the power word text node.
    if (node.type === 'powerWord_block') {
      const powerWordTextNode = node.content[0];

      // Replace the power word block with the power word text node.
      set(parsedRawText, path, [
        ...get(parsedRawText, path).slice(0, index),
        powerWordTextNode,
        ...get(parsedRawText, path).slice(index + 1)
      ]);
    }

    // Navigate to the next level.
    removePowerWordBlocks(parsedRawText, `${path}.${index}.content`);
  });
}

function prepForCopy (data, fieldToCopy) {
  const parsedData = JSON.parse(JSON.stringify(data));
  if (fieldToCopy === 'rawText') {
    removePowerWordBlocks(parsedData, 'content');
  }

  return parsedData;
}

function magicButtons ({ field, currentLanguage, indexInLanguage, currentLevel, levels, spanishMaxLevel, englishMaxLevel }) {
  const higherLevel = levels[currentLanguage][indexInLanguage - 1];
  // Determine which property we should copy. For Prosemirror fields
  // (and potentially others) we want the 'value', not just 'name'.
  const fieldToCopy = field.value || field.name;
  const buttonAttributes = (spanishMaxLevel === currentLevel)
    ? {
      // Note: the description uses nonbreaking spaces to force the tooltip
      // onto a single line.
        description: MAGIC_BUTTON_DESCRIPTIONS.COPY_MAX_ENGLISH_LEVEL,
        value: () => prepForCopy(englishMaxLevel[fieldToCopy], fieldToCopy)
      }
    : {
      // Note: the description uses nonbreaking spaces to force the tooltip
      // onto a single line.
        description: MAGIC_BUTTON_DESCRIPTIONS.COPY_LEVEL_ABOVE,
        value: () => prepForCopy(higherLevel[fieldToCopy] || '', fieldToCopy)
      };

  return magicButton(
    field,
    buttonAttributes
  );
}

/**
 * Add magic button to form fields.
 * @param {Object} levels sorted active article leves keyed by language
 * @param {Object} currentLevel
 * @returns {Array} form config to pass into InlineForm
 */
export function addMagicButtons (levels, currentLevel) {
  // If there isn't a current level, return early.
  if (isEmpty(levels) || !currentLevel) { return []; }

  return ArticleLevel.forms.shared.map((field) => {
    // If the field already has a button, don't add anything.
    if (field.button) { return field; }

    const englishMaxLevel = getMaxLevel(LANG_EN, levels);
    const spanishMaxLevel = getMaxLevel(LANG_ES, levels);

    const currentLanguage = currentLevel.language;
    const indexInLanguage = findIndex(levels[currentLanguage], (level) => level.uid === currentLevel.uid);
    const highestLevel = isHighestLevel({ currentLevel, currentLanguage, indexInLanguage, spanishMaxLevel });
    const spanishByline = isSpanishByline({ field, currentLevel, spanishMaxLevel });
    const spanishNoEnglishLevel = isSpanishWithoutEnglishLevel({ currentLevel, englishMaxLevel, spanishMaxLevel });

    // If this is the highest English level or highest non-Max Spanish level, don't add anything.
    if (highestLevel) { return field; }
    if (currentLanguage === LANG_ES) {
      // Spanish byline is automatically translated so don't add anything.
      // If there's no English level to copy from, don't add anything.
      if (spanishByline || spanishNoEnglishLevel) { return field; }
    }

    return magicButtons({ field, currentLanguage, indexInLanguage, currentLevel, levels, spanishMaxLevel, englishMaxLevel });
  });
}

export function magicButton (field, { description, value }) {
  return {
    ...field,
    // Add the button.
    button: {
      input: 'magic-button',
      icon: IconGetApp,
      description,
      value
    }
  };
}
