import { css } from '@emotion/core';

export const $textWrapper = css`
  display: flex;
  width: 100%;
`;

export const $text = css`
  margin-bottom: 12px;
`;

export const $checkbox = css`
  margin-bottom: 12px;
`;

export const $popOutMenu = css`
  width: 100%;
  z-index: 100;
`;
