import React, { useState, useEffect } from 'react';

import { useApolloClient } from '@apollo/client';
import { useNavigate } from '@reach/router';
import { get, isEmpty } from 'lodash-es';
import PropTypes from 'prop-types';

import { queries } from '@client/common/graph';
import RevisionContext from '@client/forms/components/RevisionContext';
import RevisionLevelSelector from '@client/forms/components/RevisionLevelSelector';
import RevisionVersionSelector from '@client/forms/components/RevisionVersionSelector';
import RevisionView from '@client/forms/components/RevisionView';
import { getUpdatedSearchParams, useSearchParams } from '@client/utils/deep-link';
import { formatGradeBand, formatLanguage, sortGradeBands } from '@client/utils/fields';
import parseEvents from '@client/utils/parse-events';
import { getSelectStyles } from '@client/utils/styles';

import { $inputs } from './style';

const EMPTY_SELECTION = { base: {}, comparison: {} };

/**
 * Format react-select options for levels, grouped by language.
 * @param {array} levels
 */
function groupLevels (levels) {
  return (levels || []).reduce((acc, level) => {
    if (!level.isActive) {
      // Don't add inactive levels to the options.
      return acc;
    }

    const language = level.language;
    // This is the format React-Select expects for its grouped options.
    const option = {
      label: `${formatGradeBand(level.gradeBand)} (${level.lexile})`,
      value: level.uid,
      gradeBand: level.gradeBand // For sorting.
    };

    // In our accumulator, find the language group that matches the current level's language.
    const languageGroup = acc.find((languageOption) => {
      return languageOption.label === formatLanguage(language);
    });

    // Add this level to the relevant language and sort the levels in that language.
    languageGroup.options.push(option);
    languageGroup.options.sort(sortGradeBands);

    return acc;
  }, [{ label: 'English', options: [] }, { label: 'Spanish', options: [] }]);
}

/**
 * Format react-select options for snapshots.
 * @param {array} events
 */
function getPossibleSnapshots (events = []) {
  const parsed = parseEvents(events);

  // When selecting versions in the dropdown, reverse the order of events so
  // the older versions are at the top of the options list. This matches the
  // chronology of the levels dropdown (since the content team does leveling from
  // the highest grade downwards).
  parsed.reverse();

  return parsed.reduce((acc, event) => {
    if (!event.hasSnapshot) {
      // Only include events with snapshots.
      return acc;
    }

    return [
      ...acc,
      {
        // Used to display the option label.
        label: event.text,
        secondaryText: event.secondaryText,
        // Used to display the selected option label.
        userInitials: event.userInitials,
        // Used to keep track of the current value.
        value: event.updatedAt,
        // Used to query for a specific snapshot.
        version: event.eventType
      }
    ];
  }, []);
}

/**
 * Find the selected level in the latest article data.
 * @param {string} uid
 * @param {object} articleData
 */
function findLevelData (uid, articleData) {
  return articleData.articleLevels.find((level) => level.uid === uid);
}

/**
 * Find the selected level in the snapshot data. We need to search by language
 * and grade band because the uids of the levels will be different than the
 * uid of the level from the latest data.
 * @param {string} language
 * @param {string} gradeBand
 * @param {array} snapshotLevels
 */
function findLevelByProps (language, gradeBand, snapshotLevels) {
  return snapshotLevels.find((level) => {
    return level.language === language && level.gradeBand === gradeBand && level.isActive;
    // Return empty object if no level found. This allows us to display a diff
    // showing any added / removed levels across snapshots.
  }) || {};
}

// Parent component that is responsible for rendering lower-level revision components.
// Also will be responsible for local state management and event (onChange, onSelect) functions.
export default function RevisionHistory ({ config, formData }) {
  const id = formData.id;
  const client = useApolloClient();
  const styles = getSelectStyles();
  const navigate = useNavigate();
  const searchParams = useSearchParams();
  // Context is what we're comparing, either 'levels' or 'versions'.
  const [context, setContext] = useState(config.defaultValue);
  // When comparing versions, keep track of the level we're looking at.
  const [versionLevel, setVersionLevel] = useState(null);
  // When comparing versions, keep track of the versions we're comparing.
  // These, and the versionLevel, drive the query for a specific level on a snapshot.
  // Latest draft levels, grouped by language.
  const [baseVersion, setBaseVersion] = useState(null);
  const [compareVersion, setCompareVersion] = useState(null);
  // When comparing levels, keep track of the levels we're comparing. This
  // allows us to wait for both (base and comparison) to be selected before
  // setting viewLevels and updating the view.
  const [baseLevel, setBaseLevel] = useState(null);
  const [comparisonLevel, setComparisonLevel] = useState(null);
  // Levels, grouped by language. These are the options in the level selectors.
  const groupedLevels = groupLevels(formData.articleLevels);
  // List of snapshot timestamps, based on the event history.
  const possibleSnapshots = getPossibleSnapshots(formData.events);
  // Only set the base and comparison levels we want to view when we want to
  // update the view. This allows us to view diffs between snapshots where
  // levels are added/removed, but prevents us from viewing those diffs until
  // we've selected all of the options (e.g. it won't show a diff if we're
  // comparing levels and have only selected our base level).
  const [viewLevels, setViewLevels] = useState(EMPTY_SELECTION);

  // When selecting a new context, we change the stateful value here.
  const onContextChange = (val) => {
    // Set the new context.
    setContext(val.target.value);
    // Clear out any currently selected versions and levels.
    setVersionLevel(null);
    setBaseVersion(null);
    setCompareVersion(null);
    setBaseLevel(null);
    setComparisonLevel(null);
    // Also clear out the current view.
    setViewLevels(EMPTY_SELECTION);
  };

  /**
   * Select a level to use for level comparisons.
   * @param {string} uid
   * @param {string} type either 'base' or 'comparison'
   */
  const selectLevel = (uid, type) => {
    const matchedLevel = findLevelData(uid, formData);

    if (type === 'base') {
      setBaseLevel(matchedLevel);
    } else {
      setComparisonLevel(matchedLevel);
    }
  };

  /**
   * Select a level to use for version comparisons.
   * @param {string} uid
   */
  const selectVersionLevel = (uid) => {
    const matchedLevel = findLevelData(uid, formData);

    setVersionLevel(matchedLevel);
  };

  /**
   * Select a version for comparison. Used by RevisionVersionSelector.
   * @param {string} version
   * @param {string} versionAt
   * @param {string} type either 'base' or 'comparison'
   */
  const selectVersion = (version, versionAt, type) => {
    if (type === 'base') {
      setBaseVersion({ version, versionAt });
    } else {
      setCompareVersion({ version, versionAt });
    }
  };

  // When changing the versionLevel or the versions to compare, run queries
  // to fetch the snapshots and set the base / compare levels.
  useEffect(() => {
    /**
     * Fetch snapshots from our API. Does not cache the snapshots in Apollo cache.
     * @param {string} version
     * @param {string} versionAt rfc3339-formatted datetime string
     * @returns {Promise} with an array of levels, or an empty array
     */
    const getSnapshotLevels = async ({ version, versionAt } = {}) => {
      return get(await client.query({
        query: queries.articleSnapshot,
        fetchPolicy: 'no-cache',
        variables: {
          id,
          version,
          versionAt
        }
      }), 'data.contentSnapshot.articleLevels', []);
    };

    /**
     * Fetch two snapshots from the API and compare their versions of a specific
     * level.
     * @param {object} level from versionLevel
     * @param {object} base version and versionAt from base event
     * @param {object} compare version and versionAt from comparison event
     */
    const compareSnapshots = async (level, base, compare) => {
      const baseLevels = await getSnapshotLevels(base);
      const compareLevels = await getSnapshotLevels(compare);
      // Once we have the levels from both snapshots, find the specific level
      // to compare.
      const baseLevel = findLevelByProps(level.language, level.gradeBand, baseLevels);
      const compareLevel = findLevelByProps(level.language, level.gradeBand, compareLevels);

      // And compare them!
      setViewLevels({
        base: baseLevel,
        comparison: compareLevel
      });
    };

    if (!isEmpty(versionLevel) && !isEmpty(baseVersion) && !isEmpty(compareVersion)) {
      // If we've selected all of the necessary values, fetch snapshots and
      // compare the specified level between two snapshots.
      compareSnapshots(versionLevel, baseVersion, compareVersion);
    }
  }, [versionLevel, baseVersion, compareVersion]);

  // When changing the base or comparison level, set view levels. Only do this
  // if both have been selected.
  useEffect(() => {
    if (!isEmpty(baseLevel) && !isEmpty(comparisonLevel)) {
      setViewLevels({
        base: baseLevel,
        comparison: comparisonLevel
      });
    }
  }, [baseLevel, comparisonLevel]);

  useEffect(() => {
    const pathQueryParam = 'revisions';
    navigate(getUpdatedSearchParams(searchParams, 'tabPath', pathQueryParam));
    return () => navigate(getUpdatedSearchParams(searchParams, 'tabPath'));
  }, []);

  return (
    <>
      <div css={$inputs(context)}>
        <RevisionContext
          config={config}
          onChange={onContextChange}
        />
        {context === 'levels'
          ? <RevisionLevelSelector
              name={config.name}
              styles={styles}
              groupedLevels={groupedLevels}
              selectLevel={selectLevel}
            />
          : <RevisionVersionSelector
              name={config.name}
              styles={styles}
              groupedLevels={groupedLevels}
              possibleSnapshots={possibleSnapshots}
              selectVersionLevel={selectVersionLevel}
              selectVersion={selectVersion}
            />}
      </div>
      <RevisionView values={viewLevels} />
    </>
  );
}

RevisionHistory.propTypes = {
  /** Full configuration object */
  config: PropTypes.object,
  /** Full form data */
  formData: PropTypes.object
};

// Set the display name to 'group' to prevent FormField from wrapping this
// whole component in a <label>.
RevisionHistory.displayName = 'RevisionHistoryGroup';
