import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Icon as AngelouIcon } from '@newsela/angelou';
import PropTypes from 'prop-types';

import isAngelouIcon from '@client/utils/is-angelou-icon';

import {
  $angelouRoot,
  $faRoot
} from './style';

/**
 * Icon component can display Angelou and Font Awesome icons. Please note: Both
 * Angelou and Font Awesome support duotone icons, but they have different opinions
 * of which color should be used for each tone. In the storybook duotone examples,
 * the DARK green is the primary color, while the LIGHT green is the secondary.
 */
export default function Icon ({
  icon,
  size,
  color,
  secondaryColor,
  isDecorative = true,
  title,
  customCss,
  spin
}) {
  if (isAngelouIcon(icon)) {
    return (
      <AngelouIcon
        __cssFor={{ root: $angelouRoot(size, color, secondaryColor, customCss, spin) }}
        SvgComponent={icon}
        isDecorative={isDecorative}
        title={title}
      />
    );
  } else {
    return (
      <FontAwesomeIcon
        css={$faRoot(size, color, secondaryColor, customCss)}
        icon={icon}
        spin={spin}
      />
    );
  }
}

Icon.propTypes = {
  /** The icon we want to render. May be from either Angelou or FontAwesome. */
  icon: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.func
  ]).isRequired,
  /**
   * Icon size, in pixels. Strings are only passed in when Icon is used in
   * Angelou buttons. In that case, we respect the size the button wants.
   */
  size: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string
  ]),
  /** The color of the icon, expressed as a path from `theme.colors`. */
  color: PropTypes.string,
  /** The secondary color of the icon, if using duotone icons. */
  secondaryColor: PropTypes.string,
  /**
   * Whether the icon contains semantic meaning or is simply decorative.
   * Non-decorative icons require a `title` prop.
   */
  isDecorative: PropTypes.bool,
  /** The title of the icon, if it is not decorative. */
  title: PropTypes.string,
  /** Additional styles to pass in. */
  customCss: PropTypes.object
};
