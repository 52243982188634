import { css } from '@emotion/core';
import { get } from 'lodash-es';

import { STATUS } from '@client/utils/constants';

export const $status = (status) => ({ colors, typographyStyles }) => {
  const textColor = status === STATUS.DRAFT ? 'greyDark' : 'white';
  return [
    typographyStyles.textXS,
    css`
      display: flex;
      align-items: center;
      border-radius: 12px;
      padding: 0 8px;
      color: ${colors.ui[textColor][700]};
      background-color: ${get(colors, colors.status[status])};
    `
  ];
};
