import { PaperHollowSVG } from '@newsela/angelou';
import gql from 'graphql-tag';
import smartquotes from 'smartquotes';

import Content from './content';
import MetadataStandard from './metadataStandard';
import MetadataTag from './metadataTag';
import Stream from './stream';
import Tag from './tag';

function filter (query) {
  const quoted = smartquotes(query);

  return {
    or: [
      { label: { allofterms: query } },
      { description: { alloftext: quoted } }
    ]
  };
}

function quickFilter (query) {
  const quoted = smartquotes(query);

  return {
    label: { allofterms: quoted },
    rootFn: 'label|allofterms'
  };
}

const labelOptions = [
  'Instructional Note',
  'Curriculum Guide',
  'Course Guide',
  'Course Standards Map',
  'Additional Course Info',
  'Unit Guide',
  'Standards',
  'Essential Questions',
  'Goals',
  'Key Terms',
  'Resources',
  'Materials',
  'Additional Unit Info',
  'Lesson Objectives',
  'Differentiation',
  'Lesson Sequence'
].sort();

const InstructionalNote = {
  fullFragment: gql`
    fragment fullInstructionalNote on InstructionalNote {
      label
      description(format: TEXT_PLAIN)
      rawDescription: description(format: TEXT_RAW)
      files
    }
  `,
  filter,
  quickFilter,
  defaults: (id, data) => {
    const contentDefaults = Content.defaults(id, data);

    return {
      client: {
        ...contentDefaults.client,
        __typename: 'InstructionalNote',
        contentType: 'INSTRUCTIONAL_NOTE',
        name: 'Instructional Note',
        label: 'Instructional Note',
        description: null,
        htmlDescription: null,
        rawDescription: null,
        files: []
      },
      server: {
        ...contentDefaults.server,
        contentType: 'INSTRUCTIONAL_NOTE'
      }
    };
  },
  isContent: true,
  icon: PaperHollowSVG,
  typename: 'Instructional Note',
  forms: {
    shared: [
      {
        input: 'select',
        name: 'label',
        defaultValue: 'Instructional Note',
        isCreatable: true,
        options: labelOptions
      }, {
        input: 'prosemirror',
        name: 'description',
        value: 'rawDescription',
        label: 'Description',
        isMultiline: true,
        formats: ['bold', 'italic', 'headings', 'list', 'orderedList', 'link']
      },
      {
        input: 'file',
        name: 'files',
        label: 'Files',
        accept: 'application/*, text/*, image/*',
        isMulti: true
      },
      Tag.inputs.tags,
      MetadataTag.inputs.metadataTags,
      MetadataStandard.inputs.metadataStandards,
      Stream.inputs.subjectProductStreams,
      Stream.inputs.customStreams,
      Content.inputs.notes,
      Content.inputs.id,
      Content.inputs.attachmentsCount
    ]
  }
};

export default InstructionalNote;
