// Various functions for dealing with styles, as well as shared styles
import { css } from '@emotion/core';
import { faExclamationCircle, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { constants } from '@newsela/angelou';

import Icon from '@client/common/components/Icon';
import {
  TARGET_LEXILE,
  TARGET_WORD_COUNT,
  VARIANT_ERROR,
  VARIANT_WARNING,
  Z_INDEX_9999_MAX,
  DRAWER_MIN_WIDTH,
  OUTLINE_MIN_WIDTH
} from '@client/utils/constants';

const { status, brand, ui } = constants.colors;

/**
 * Get border colors consistently for inputs
 *
 * @param {object} theme from emotion
 * @param {string} variant e.g. 'success', 'warning', 'danger', 'disabled'
 * @returns {array} [border color, hover color, active/focus color]
 */
export function getBorderColors (theme, variant) {
  const mineralGrey = '#c8d1e0'; // borderColor from mineral-ui
  // eslint-disable-next-line no-param-reassign
  theme = theme || {
    // If we don't pass in a theme, use colors straight from angelou
    borderColor: mineralGrey,
    borderColor_theme_hover: status.primary[300],
    borderColor_theme_active: status.primary[500],
    borderColor_disabled: mineralGrey,
    borderColor_success: status.success[500],
    borderColor_success_hover: status.success[300],
    borderColor_success_active: status.success[300],
    borderColor_danger: status.danger[500],
    borderColor_danger_hover: status.danger[300],
    borderColor_danger_active: status.danger[500],
    borderColor_warning: status.warning[500],
    borderColor_warning_hover: status.warning[300],
    borderColor_warning_active: status.warning[500]
  };

  switch (variant) {
    case 'disabled': return [
      theme.borderColor_disabled,
      theme.borderColor_disabled,
      theme.borderColor_disabled
    ];
    case 'success': return [
      theme.borderColor_success,
      theme.borderColor_success_hover,
      theme.borderColor_success_active
    ];
    case 'danger': return [
      theme.borderColor_danger,
      theme.borderColor_danger_hover,
      theme.borderColor_danger_active
    ];
    case 'warning': return [
      theme.borderColor_warning,
      theme.borderColor_warning_hover,
      theme.borderColor_warning_active
    ];
    default: return [
      theme.borderColor,
      theme.borderColor_theme_hover,
      theme.borderColor_theme_active
    ];
  }
}

export function getActiveShadow (color) {
  return `0 0 0 1px #fff, 0 0 0 2px ${color};`;
}

// Because react-select does styles differently than our other components,
// we're declaring the styles here rather than in a ./style.js file
// in the 'select' or 'standard-select' components
export function getSelectStyles (variant, hasImage, customStyles) {
  const [
    borderColor,
    hoverColor,
    activeColor
  ] = getBorderColors(null, variant);
  const activeShadow = getActiveShadow(activeColor);

  return {
    control: (provided, { isFocused }) => ({
      ...provided,
      borderColor,
      boxShadow: isFocused ? activeShadow : 'none',

      ':hover': {
        borderColor: hoverColor
      },

      ':active': {
        boxShadow: activeShadow
      },

      ':focus': {
        boxShadow: activeShadow
      }
    }),
    input: (base) => ({
      ...base,
      ...hasImage && {
        height: '170px'
      }
    }),
    valueContainer: (base) => ({
      ...base,
      overflow: 'visible', // This is needed to make the tooltip work
      width: '100%'
    }),
    // Overlay all other form components
    menuPortal: (base) => ({ ...base, zIndex: Z_INDEX_9999_MAX }),
    // Override react-select's internal styles
    menu: (base) => ({ ...base, zIndex: `${Z_INDEX_9999_MAX} !important` }),
    singleValue: () => ({
      position: 'unset',
      transform: 'unset'
    }),
    multiValue: (base) => ({
      ...base,
      backgroundColor: ui.greyLight[50],
      borderRadius: '30px',
      padding: '6px 10px',
      marginRight: '4px',
      marginBottom: '4px',
      color: ui.greyDark[700],
      borderColor: ui.greyDark[700],
      border: '1px solid',
      display: 'flex',
      alignItems: 'center',
    }),
    multiValueLabel: (base) => ({
      ...base,
      fontSize: '14px',
      color: ui.greyDark[700]
    }),
    multiValueRemove: (base, state) => {
      const { isFocused } = state;
      const colors = {
        backgroundColor: isFocused ? ui.red[300] : ui.greyDark[700],
        color: ui.greyLight[50]
      };

      return {
        ...base,
        ...colors,
        width: '16px',
        height: '16px',
        borderRadius: '16px',
        padding: '2px',
        marginLeft: '4px',
        ':hover': {
          backgroundColor: ui.red[300]
        }
      };
    },
    ...customStyles
  };
}

/**
 * Determine the color for lexile and word counts based on whether the
 * value is within the expected range.
 * @param {number} val
 * @param {string} gradeBand
 * @param {string} type 'rawLexile' or 'wordCount'
 * @param {object} colors from theme
 * @returns {string}
 */
export function getLexileOrWordCountColor (val, gradeBand, type, colors) {
  const source = type === 'rawLexile' ? TARGET_LEXILE : TARGET_WORD_COUNT;
  // Ranges are stored as [low value, high value].
  const [low, high] = source[gradeBand];

  // If the val is below or above the range, return red.
  // If it's within the range, return green.
  return val < low || val > high ? colors.ui.red[500] : colors.ui.green[500];
}

/**
 * Get colors for vocab highlighting. This is used inline the Prosemirror input,
 * as well as in the vocab buttons in the VocabLevel component.
 * @param {number} category 1 through 4
 * @param {object} colors from theme
 * @returns {object} with { text, textActive, bg, bgActive }
 */
export function getVocabColors (category, colors) {
  const textDark = colors.ui.greyDark[700];
  const textLight = colors.ui.white[500];
  const categories = {
    1: {
      text: textDark,
      textActive: textDark,
      bg: colors.ui.greyLight[50],
      bgActive: colors.ui.greyLight[500]
    },
    2: {
      text: textDark,
      textActive: textDark,
      bg: colors.ui.coreBlue[50],
      bgActive: colors.ui.coreBlue[300]
    },
    3: {
      text: textDark,
      textActive: textDark,
      bg: colors.ui.green[50],
      bgActive: colors.ui.green[300]
    },
    4: {
      text: textDark,
      textActive: textLight,
      bg: colors.brand.purple[300],
      bgActive: colors.brand.purple[500]
    }
  };

  return categories[category];
}

/**
 * Check if the variant is a valid one.
 * @param {String} variant  'danger' or 'warning'
 * @returns {Boolean} variantIsValid
 */
export function variantIsValid (variant) {
  return [VARIANT_ERROR, VARIANT_WARNING].includes(variant);
}

/**
 * Check if the shade is a valid one.
 * @param {String} shade 'dark', 'light', 'text', 'border', 'hover', or 'background'
 * @returns {Boolean} shadeIsValid
 */
export function shadeIsValid (shade) {
  return ['dark', 'light', 'text', 'border', 'hover', 'background'].includes(shade);
}

/**
 * Get the variant color.
 * @param {String} variant 'danger' or 'warning'
 * @param {String} shade 'dark', 'light', 'text', 'border', 'hover', or 'background'
 * @returns {String|undefined} variantColor RGB hex color code
 */
export function getVariantColor (variant, shade = 'dark') {
  if (!variantIsValid(variant) || !shadeIsValid(shade)) return;
  const colors = {
    [VARIANT_ERROR]: {
      dark: status.danger[700],
      light: brand.pink[100],
      text: status.white[700],
      border: ui.red[500],
      hover: ui.red[700],
      background: '#fcebeb'
    },
    [VARIANT_WARNING]: {
      dark: status.warning[700],
      light: brand.yellow[100],
      text: status.black[700],
      border: ui.yellow[100],
      hover: ui.yellow[700],
      background: '#fffdf9'
    }
  };
  return colors[variant][shade];
}

/**
 * Get the variant icon.
 * @param {String} variant 'danger' or 'warning'
 * @param {String} shade 'dark', 'light' or 'text'
 * @returns {Object|undefined} fontawesome icon
 */
export function getVariantIcon (variant, shade = 'dark') {
  if (!variantIsValid(variant) || !shadeIsValid(shade)) return;
  const variantIcons = {
    [VARIANT_ERROR]: faExclamationCircle,
    [VARIANT_WARNING]: faExclamationTriangle
  };
  return (
    <Icon
      icon={variantIcons[variant]}
      customCss={css`
        &, &[role="img"] {
          color: ${getVariantColor(variant, shade)} !important;
        }
        font-size: 16px;
        margin-right: 5px;
      `}
    />
  );
}

/**
 * Calculate the width of the main body of our apps, depending on if the
 * drawer (and, for the Bundles app, the outline) is open or closed.
 * @param {Boolean} [isDrawerOpen]
 * @param {Boolean} [isOutlineOpen]
 * @returns {String} CSS width value
 */
export function getBodyWidth (isDrawerOpen = false, isOutlineOpen = false) {
  const minusDrawer = isDrawerOpen ? ` - ${DRAWER_MIN_WIDTH}px` : '';
  const minusOutline = isOutlineOpen ? ` - ${OUTLINE_MIN_WIDTH}px` : '';

  if (!isDrawerOpen && !isOutlineOpen) {
    return '100%';
  }

  return `calc(100%${minusDrawer}${minusOutline})`;
}

export function derivativeStandardCommon () {
  return css`
    vertical-align: middle;
    color: white;
    font-weight: 700;
    padding: 3px;
    border-radius: 3px;
  `;
}
