/**
* We're managing the cache for pagination here
* @param {object} keyArgs to cache query
* @return {object} Apollo field policy
***/

export function offsetStartPagination (keyArgs = false) {
  return {
    keyArgs,
    /**
     * Merge incoming data into the cache based on the offset.
     * @param {array} existing contents currently in the normalized cache
     * @param {array} incoming contents returned by this API request
     * @param {object} args includes args.offset
     * @return {array} updated contents in the normalized cache
     * NOTE: For example:
     * For a table with page size of 10 and a total of 12 results
     * View page 1:
     *    existing.length: 0
     *    incoming.length: 10
     *    merge returns: 10
     * View page 2
     *    existing.length: 10
     *    incoming.length: 2
     *    merge returns: 12 (total of 12 in cache so page displays last 2)
     * View page 1 again
     *    existing.length: 12
     *    incoming.length: 2
     *    merge returns: 10 (back to total of 10 in cache so page displays first 10)
     * Change page size to 20
     *    existing.length: 10
     *    incoming.length: 2
     *    merge returns: 12 (total of 12 in cache so page displays all 12)
     */
    merge: (existing = [], incoming = [], { args } = {}) => {
      const start = args ? args.offset : existing.length;
      return [...existing.slice(0, start), ...incoming.slice()];
    }
  };
}
