/* eslint-disable camelcase */
import { css } from '@emotion/core';

import { getVariantColor } from '@client/utils/styles';

// Passing in colors here to avoid "Functions that are interpolated in css calls will be stringified" error
// https://github.com/emotion-js/emotion/issues/1085
export const $commonStyle = (variant) => ({ colors }) => {
  const variantColor = getVariantColor(variant);
  return css`
  border: 2px solid ${variant ? variantColor : 'transparent'};
  cursor: pointer;
  padding: 10px 10px 0;
  &.selected, &:active, &:hover, &:focus {
    border-color: ${variant ? variantColor : colors.ui.coreBlue[500]};
  }
`;
};

export const $image = (isFullWidth) => ({ colors, typography }) => {
  const fullWidth = css`
    width: 100%;
  `;
  const floatRight = css`
    float: right;
    width: 50%;
    margin: 0 0 1rem 1rem;
  `;
  const figcaption = [
    typography.family.circular,
    typography.size.S,
    css`
    figcaption {
      line-height: 171%;
      padding: 10px 0px;
      color: ${colors.ui.grey[500]};
    }`];

  return [(isFullWidth ? fullWidth : floatRight), figcaption];
};

export const $placeholder = (variant) => ({ colors }) => {
  const placeholderStyle = css`
    height: 10rem;
    width: 50%;
    display: grid;
    place-content: center;
    text-align: center;
    font-style: italic;
    background-color: ${colors.ui.greyLight[100]};
    border: 2px solid ${variant ? getVariantColor(variant) : 'transparent'};
`;

  return [placeholderStyle, $commonStyle(variant)({ colors })];
};
