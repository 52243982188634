/* eslint-disable camelcase */
import { css } from '@emotion/core';

// Import fonts we're using in Alexandria.
// TODO: Load these more dynamically, so we can browser-cache them.
import circularBold from '../../fonts/lineto-circular-bold.woff';
import circularBook from '../../fonts/lineto-circular-book.woff';
import notoBold from '../../fonts/NotoSerif-Bold.woff';
import notoBoldItalic from '../../fonts/NotoSerif-BoldItalic.woff';
import notoItalic from '../../fonts/NotoSerif-Italic.woff';
import notoRegular from '../../fonts/NotoSerif-Regular.woff';

export const $global = css`
  html,
  body {
    box-sizing: border-box;
    height: 100vh;
    margin: 0;
    min-height: 100%;
    overflow: hidden;
    padding: 0;
  }

  *,
  *:before,
  *:after {
    box-sizing: inherit;
  }

  .ct-toast {
    font-family: Circular, sans-serif;
    font-size: 16px;
  }
`;

export const $fonts = css`
  @font-face {
    font-family: 'Noto Serif';
    src: url('${notoRegular}') format('woff');
    font-weight: 400;
    font-style: normal;
  }

  @font-face {
    font-family: 'Noto Serif';
    src: url('${notoBold}') format('woff');
    font-weight: 700;
    font-style: normal;
  }

  @font-face {
    font-family: 'Noto Serif';
    src: url('${notoItalic}') format('woff');
    font-weight: 400;
    font-style: italic;
  }

  @font-face {
    font-family: 'Noto Serif';
    src: url('${notoBoldItalic}') format('woff');
    font-weight: 700;
    font-style: italic;
  }

  @font-face {
    font-family: Circular;
    src: url('${circularBook}') format('woff');
    font-weight: 400;
    font-style: normal;
  }

  @font-face {
    font-family: Circular;
    src: url('${circularBold}') format('woff');
    font-weight: 700;
    font-style: normal;
  }
`;
