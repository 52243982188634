import { faItalic } from '@fortawesome/pro-regular-svg-icons';

import { italic } from '@shared/prosemirror/marks';

import { markActive, toggleMark } from './helpers';

const icon = faItalic;
export const mark = italic;
export const keys = {
  'Mod-i': toggleMark('italic')
};
export const menuMark = {
  title: 'Toggle Italic',
  content: icon,
  active: markActive('italic'),
  run: toggleMark('italic')
};
