import { css } from '@emotion/core';

export const $revisionSelector = () => css`
  width: 100%;
`;

export const $header = () => css`
  margin-top: 0;
`;

export const $selectInputs = () => css`
  width: 100%; 
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const $selectInput = () => css`
  min-width: 200px;
`;
