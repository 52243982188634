/* eslint-disable camelcase */
import { css } from '@emotion/core';

import { getBorderColors, getActiveShadow } from '../../../utils/styles';

// color_black, color_gray_90 and others comes from
// Mineral UI theming: https://mineral-ui.netlify.app/theming
export const $root = ({
  typography,
  borderColor,
  borderRadius_1,
  color_black,
  color_gray_90,
  color_disabled,
  color_themePrimary,
  backgroundColor_hover,
  backgroundColor_active,
  backgroundColor_themePrimary,
  backgroundColor_themePrimary_hover
}) => [
  typography.family.circular,
  typography.size.M,
  css`
  & .react-datepicker-wrapper,
  & .react-datepicker__input-container {
    width: 100%;
  }

  /* Popover calendar */
  & .react-datepicker {
    border-color: ${borderColor};
    border-radius: ${borderRadius_1};
    color: ${color_black};
  }

  /* Header arrows */
  & .react-datepicker-popper[data-placement^='bottom'] .react-datepicker__triangle {
    border-bottom-color: ${backgroundColor_active};
  }

  & .react-datepicker-popper[data-placement^='bottom'] .react-datepicker__triangle:before {
    border-bottom-color: ${borderColor};
  }

  & .react-datepicker__navigation--previous {
    border-right-color: ${borderColor};
  }

  & .react-datepicker__navigation--next {
    border-left-color: ${borderColor};
  }

  /* Date and Time Headers */
  & .react-datepicker__header {
    background-color: ${backgroundColor_active};
    border-bottom-color: ${borderColor};
    border-top-left-radius: ${borderRadius_1};
    border-top-right-radius: ${borderRadius_1};
  }

  & .react-datepicker__time-container {
    border-left-color: ${borderColor};
  }

  /* Header text */
  & .react-datepicker__current-month,
  & .react-datepicker-time__header,
  & .react-datepicker-year-header {
    color: ${color_gray_90};
  }

  /* Normal Text */
  & .react-datepicker__day-name,
  & .react-datepicker__day,
  & .react-datepicker__time-name {
    color: ${color_black};
  }

  /* Fix styling issues with time list */
  & .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list {
    width: calc(100% - 1px);
  }

  & .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item {
    align-items: center;
    display: flex;
    justify-content: center;
  }

  /* Hovering over dates and times */
  & .react-datepicker__day:hover,
  & .react-datepicker__month-text:hover,
  & .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item:hover {
    background-color: ${backgroundColor_hover};
  }

  /* Selected dates and times */
  & .react-datepicker__day--keyboard-selected,
  & .react-datepicker__month-text--keyboard-selected,
  & .react-datepicker__day--selected,
  & .react-datepicker__day--in-selecting-range,
  & .react-datepicker__day--in-range,
  & .react-datepicker__month-text--selected,
  & .react-datepicker__month-text--in-selecting-range,
  & .react-datepicker__month-text--in-range {
    border-radius: ${borderRadius_1};
    background-color: ${backgroundColor_themePrimary};
    color: ${color_themePrimary};
  }

  & .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected {
    background-color: ${backgroundColor_themePrimary};
    color: ${color_themePrimary};
  }

  /* Hovering over selected dates and times */
  & .react-datepicker__day--keyboard-selected: hover,
  & .react-datepicker__month-text--keyboard-selected: hover,
  & .react-datepicker__day--selected: hover,
  & .react-datepicker__day--in-selecting-range: hover,
  & .react-datepicker__day--in-range: hover,
  & .react-datepicker__month-text--selected: hover,
  & .react-datepicker__month-text--in-selecting-range: hover,
  & .react-datepicker__month-text--in-range: hover,
  & .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected:hover {
    background-color: ${backgroundColor_themePrimary_hover};
  }

  /* Disabled dates (outside min-max range) */
  & .react-datepicker__day[aria-disabled="true"] {
    color: ${color_disabled};
    & :hover {
      background-color: unset;
    }
  }
  `
];

export const $input = (variant) => ({
  typography,
  theme
}) => {
  const [
    color,
    hoverColor,
    activeColor
  ] = getBorderColors(theme, variant);
  const activeShadow = getActiveShadow(activeColor);

  return [
    typography.family.circular,
    typography.size.M,
    css`
    border: 1px solid ${color};
    border-radius: 3px;
    margin: 0;
    outline: none;
    padding: 9px 16px;
    width: 100%;

    &:hover {
      border-color: ${hoverColor};
    }

    &:focus,
    &:active {
      box-shadow: 0 0 0 1px ${activeShadow}, 0 0 0 2px ${activeColor};
    }
  `
  ];
};
