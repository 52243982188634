/* eslint-disable camelcase */
import { css } from '@emotion/core';
import { constants } from '@newsela/angelou';

// The Angelou components `__cssFor` prop takes in a style object, not a css function.
// So we're creating a colors variable explicitly from Angelou
// to be able to use colors in some functions below.
const { colors } = constants;

export const $buttonContainer = css`
  margin: 20px 0;
  display: flex;
  flex-direction: column;
`;

export const $popOutMenu = `
  z-index: 100;

  & div {
    width: 100%;
  }

  & > div:not(:first-of-type) {
    padding-top: 16px;
  }
`;

export const $popOutButton = `
  background-color: ${colors.ui.coreBlue[700]};
  color: ${colors.ui.white[700]};

  &:hover {
    background-color: ${colors.ui.coreBlue[700]};
    color: ${colors.ui.white[700]};
  }
`;
