import React, { forwardRef } from 'react';

import { faTrashAlt } from '@fortawesome/pro-light-svg-icons';
import { Button } from '@newsela/angelou';
import { useLocation, useNavigate } from '@reach/router';
import { useStoreActions } from 'easy-peasy';
import Checkbox from 'mineral-ui/Checkbox';
import Radio from 'mineral-ui/Radio';
import PropTypes from 'prop-types';

import Icon from '@client/common/components/Icon';
import { titleCaseSpaced } from '@client/utils/cases';
import { NON_PUBLISHABLE } from '@client/utils/constants';
import { QUESTION_POWER_WORD_ASSOCIATION, QUESTION_POWER_WORD_EXAMPLE } from '@client/utils/create-assessments';
import { getUpdatedSearchParams, useSearchParams } from '@client/utils/deep-link';

import { $card, $heading, $body, $icons, $optionText, $checkedRadioButton } from './style';

function CheckboxInput ({ renderOptionHtml, option }) {
  return (
    <Checkbox
      css={$checkedRadioButton}
      label={renderOptionHtml(option)}
      disabled
      checked={!!option.isCorrect}
    />
  );
}

CheckboxInput.propTypes = {
  renderOptionHtml: PropTypes.func,
  option: PropTypes.object
};

function RadioInput ({ renderOptionHtml, option }) {
  return (
    <Radio
      css={$checkedRadioButton}
      label={renderOptionHtml(option)}
      disabled
      checked={!!option.isCorrect}
    />
  );
}

RadioInput.propTypes = {
  renderOptionHtml: PropTypes.func,
  option: PropTypes.object
};

/**
 * Determine if a question is for a power word activity.
 * @param {object} question
 * @returns {boolean}
 */
function isPowerWordQuestion (question) {
  return [QUESTION_POWER_WORD_ASSOCIATION, QUESTION_POWER_WORD_EXAMPLE].includes(question.questionType);
}

/* eslint-disable react/no-danger */
// Set each radio option as html, in the case it has any rich text formatting
function renderOptionHtml (option) {
  return <span css={$optionText} dangerouslySetInnerHTML={{ __html: option.htmlText }} />;
}

function renderQuestionPrompt (question) {
  return <span dangerouslySetInnerHTML={{ __html: question.htmlQuestion }} />;
}
/* eslint-enable react/no-danger */

const renderQuestionBody = (question) => {
  if (question.htmlQuestion && question.options) {
    return (
      <>
        {renderQuestionPrompt(question)}
        {renderOptions(question)}
      </>
    );
  } else if (question.htmlQuestion && question.question !== null) {
    return renderQuestionPrompt(question);
  } else if (isPowerWordQuestion(question)) {
    const word = question.wordDefinition?.wordForm;

    return question.questionType === QUESTION_POWER_WORD_ASSOCIATION
      ? <p><em>Blank Association for "{word}"</em></p>
      : <p><em>Blank Example for "{word}"</em></p>;
  } else {
    return <p><em>Blank Question</em></p>;
  }
};

// This will either render a checkbox or radio input in the preview depending if
// there are multiple correct answers.
function renderOptions (question) {
  const numberOfCorrectAnswers = question.options.filter((item) => item.isCorrect === true).length;
  if (numberOfCorrectAnswers > 1) {
    return question.options.map((option) => (
      <CheckboxInput key={option.uid} option={option} renderOptionHtml={renderOptionHtml} />
    ));
  } else {
    return question.options.map((option) => (
      <RadioInput key={option.uid} option={option} renderOptionHtml={renderOptionHtml} />
    ));
  }
}

const QuestionCard = forwardRef(({
  question,
  onDeleteItem,
  assessment,
  questionPosition,
  questionParent,
  assessmentParent,
  breadcrumbs = [],
  variant,
  fieldPath,
  currentLevel
}, ref) => {
  const switchDrawer = useStoreActions((actions) => actions.drawer.switch);
  const searchParams = useSearchParams();
  const navigate = useNavigate();
  const location = useLocation();

  const onSelectItem = (val, questionPosition) => {
    return switchDrawer({
      id: val.id,
      customDrawerTitle: val.name,
      type: 'AssessmentQuestion',
      drawerType: NON_PUBLISHABLE,
      breadcrumbs: [
        // Pass through existing breadcrumbs, so we can navigate back up through
        // the tree.
        ...breadcrumbs,
        {
          id: assessment.id,
          uid: assessment.uid,
          title: titleCaseSpaced(assessment.assessmentType),
          type: assessment.__typename,
          isContent: true,
          parent: assessmentParent,
          levelUid: currentLevel.uid
        }
      ],
      parent: questionParent,
      validationId: assessment.id,
      fieldPath
    });
  };

  // Disable the question card when creating (i.e. when it doesn't have a
  // database-generated uid yet, and is being rendered with only a temporary uid).
  const isDisabled = question.uid.startsWith('_:');

  return (
    <div css={$card(isDisabled, variant)}>
      {/* Uses tabindex and onKeyPress to make the QuestionCard accessible by keyboard */}
      <div
        css={$heading}
        tabIndex='0'
        ref={ref}
        onKeyPress={(e) => {
          if (e.key === 'Enter') !isDisabled && onSelectItem(question, questionPosition);
        }}
        onClick={() => {
          // We presently don't support PowerWord questions navigation, hence this adhoc param clear.
          // This has been documented here: https://newsela.atlassian.net/browse/CMS-1686
          const clearQueryParamsAndNavigate = () => {
            searchParams.forEach((_, key) => {
              getUpdatedSearchParams(searchParams, key);
            });
            navigate(location.pathname);
          };

          clearQueryParamsAndNavigate();

          return !isDisabled && onSelectItem(question, questionPosition);
        }}
        role='button'
        aria-label={`Question Q${questionPosition}`}
      >
        Q{questionPosition}
      </div>
      <div css={$body}>
        {renderQuestionBody(question)}
        <hr />
        <div css={$icons}>
          <Button
            type='button'
            /* AUTOGENERATED TODO: update angelou to new flavor.
              see https://github.com/newsela/angelou/blob/main/src/components/Button/README.md#MIGRATION
              for migration guide. */
            legacy_flavor={Button.legacy_flavor.flat}
            legacy_size={Button.legacy_size.small}
            legacy_statusColor={Button.legacy_statusColor.black}
            onClick={() => !isDisabled && onDeleteItem(question)}
            ariaProps={{ 'aria-label': `Delete question Q${questionPosition}` }}
          >
            <Icon icon={faTrashAlt} />
          </Button>
        </div>
      </div>
    </div>
  );
});

QuestionCard.propTypes = {
  /** Assessment question data */
  question: PropTypes.object,
  /** Delete function */
  onDeleteItem: PropTypes.func,
  /** Assessment this question belongs to */
  assessment: PropTypes.object,
  /** Question's position passed from the questionPositions array */
  questionPosition: PropTypes.number,
  questionParent: PropTypes.object,
  assessmentParent: PropTypes.object,
  breadcrumbs: PropTypes.array,
  /**
   * Type of validation, either 'danger' (for errors) or 'warning'. This matches
   * the syntax of MineralUI's field variants. */
  variant: PropTypes.string,
  /**
   * The question issue location field includes a full path, so we need
   * this prop to get the validation issues specific to this question. */
  fieldPath: PropTypes.string,
  /** Data for the current level */
  currentLevel: PropTypes.object
};

export default QuestionCard;
