import { faLayerGroup } from '@fortawesome/pro-light-svg-icons';
import gql from 'graphql-tag';

import { createAssessmentLevel } from '@client/utils/create-assessments';

import AssessmentQuestion from './assessment-question';

const AssessmentLevel = {
  // When fetching assessment levels, also fetch position for the questions.
  // We don't fetch position when ONLY fetching questions, since that would
  // return null when editing a question in the drawer.
  fullFragment: gql`
    fragment fullAssessmentLevel on AssessmentLevel {
      uid
      gradeBand
      language
      isActive
      questions {
        ...fullAssessmentQuestion
        position
      }
    }
    ${AssessmentQuestion.fullFragment}
  `,
  defaults: (id, data, relatedData) => {
    // NOTE: When adding fields to levels, make sure to also add them to the
    // defaults in @client/utils/create-assessments.js
    return createAssessmentLevel(id, data, relatedData);
  },
  icon: faLayerGroup,
  typename: 'Assessment Level'
};

export default AssessmentLevel;
