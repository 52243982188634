import React, { createRef, useEffect, useState } from 'react';

import { ButtonWithPopOut, Button, PlusSVG, constants, cssVariableStyles } from '@newsela/angelou';
import cuid from 'cuid';
import { useStoreState, useStoreActions } from 'easy-peasy';
import { isEqual, isEmpty } from 'lodash-es';
import PropTypes from 'prop-types';

import ContentCard from '@client/common/components/ContentCard';
import Icon from '@client/common/components/Icon';
import LoadingSpinner from '@client/common/components/LoadingSpinner/LoadingSpinner';
import * as schemas from '@client/common/schema';
import AttachmentsMenu from '@client/forms/components/AttachmentsMenu';
import ValidationMessage from '@client/forms/components/ValidationMessage';
import { getSiblings } from '@client/utils/breadcrumbs';
import { PUBLISHABLE, NON_PUBLISHABLE } from '@client/utils/constants';
import { getFieldValidation, getAggregateMessage } from '@client/utils/form-validation';

import image from './nothing-to-see.svg';
import {
  $buttonContainer,
  $popOutMenu,
  $popOutButton,
  $cardContainer,
  $message,
  $messageContainer,
  $nothingToSeeImage,
  $icon,
  $button,
  $root
} from './style';
const { generateIconColorCss } = cssVariableStyles;

const iconColor = generateIconColorCss({ primaryColor: constants.colors.ui.white[100] });

export default function EditorList ({ value: initialValue, name, config, onChange, formData, breadcrumbs, parent, validatedContent }) {
  const value = initialValue || []; // Cast empty values to an array.
  const rootStreams = useStoreState((state) => state.rootStreams.data, isEqual);
  const switchDrawer = useStoreActions((actions) => actions.drawer.switch);
  const streams = rootStreams.length
    ? rootStreams.map((streamUid) => ({ uid: streamUid }))
    : null;
  const currentType = formData.__typename;
  const currentSchema = schemas[currentType];
  // The breadcrumb for the current content. This will be passed down into the
  // related content when selecting it from the list.
  const currentBreadcrumb = {
    id: formData.id,
    uid: formData.uid,
    type: formData.__typename,
    isContent: currentSchema.isContent,
    // Get the title from the PARENT type
    title: formData.name,
    parent
  };
  const constructedBreadcrumbs = breadcrumbs ? [...breadcrumbs, currentBreadcrumb] : [currentBreadcrumb];
  // Keep track of when new ContentCards are created
  // so that we can focus on them as soon as they are added (for accessibility).
  const [isNewContentCard, setIsNewContentCard] = useState(false);
  const [loading, setLoading] = useState(false);
  const contentCardRef = createRef();
  // Unless we set an empty object, useEffect ignores it.
  contentCardRef.current = {};

  // Focus new ContentCard
  useEffect(() => {
    if (isNewContentCard && contentCardRef?.current?.focus) {
      contentCardRef.current.focus();
      setIsNewContentCard(false);
    }
  }, [contentCardRef.current]);

  const onSelectItem = (val) => {
    const currentItem = {
      id: formData.id,
      uid: formData.uid,
      field: name,
      fieldType: config.input,
      articleLevels: formData.articleLevels
    };

    return switchDrawer({
      id: val.id,
      type: val.__typename,
      drawerType: currentSchema.isContent ? PUBLISHABLE : NON_PUBLISHABLE,
      breadcrumbs: constructedBreadcrumbs,
      parent: {
        ...currentItem,
        siblings: getSiblings({ items: value, parent: currentItem, isContent: (currentSchema.isContent || false) })
      }
    });
  };

  const onDelete = (val) => {
    onChange(
      { [name]: { uid: val.uid } },
      { [name]: value.filter((item) => item.uid !== val.uid) },
      'unset',
      false // Don't debounce, send each API call separately.
    );
  };

  /**
   * Add an item to the list. This creates a new piece of content or other record
   * (depending on the editorType passed in) and adds it to the list.
   * @param {string} typeName e.g. Assessment, InstructionalNote, etc
   * @param {object} [data] to allow dynamic defaults when creating related items
   * @returns {Function} that is called with the click event
   */
  const onAddItem = (typeName, data) => {
    // Schema for the related item.
    const relatedSchema = schemas[typeName];

    let defaultData = config.defaultData;
    let defaultFormData = null;

    if (relatedSchema.isContent) {
      const customData = {};
      if (formData.contentType === 'ARTICLE' && typeName === 'ExternalLink') {
        customData.label = 'Formative';
      }
      defaultData = { ...config.defaultData, ...data, ...customData, streams };
      defaultFormData = formData;
    }

    return async (e) => {
      try {
        setLoading(true);
        e.preventDefault();
        const id = cuid();
        const newItem = relatedSchema.defaults(id, defaultData, defaultFormData);
        contentCardRef?.current?.focus && contentCardRef.current.focus();
        await onChange(
          { [name]: newItem.server },
          { [name]: [...value] },
          'set',
          false // Don't debounce, send each API call separately.
        );
        // After the item is added, flag to focus on the new item.
        setIsNewContentCard(true);
      } finally {
        setLoading(false);
      }
    };
  };

  const plusIcon = <Icon icon={PlusSVG} size={16} color='ui.white.100' customCss={$icon} />;

  return (
    <div css={$root}>
      {loading && <LoadingSpinner isLoadingModal label='Loading' />}
      {!isEmpty(value)
        ? value.map((val) => {
          const { errors, warnings } = getFieldValidation(validatedContent, { id: val?.id });
          const { variant, message } = getAggregateMessage(errors, warnings, { name: val.contentType, isMultiple: false });
          return (
            <div key={val.id} css={$cardContainer}>
              <ContentCard
                data={val}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  onSelectItem(val);
                }}
                menuConfig={{
                  menuType: 'editor-inputs',
                  onRemove: () => onDelete(val)
                }}
                variant={variant}
                // Set the passed-in ref here so that it gets focused when it's added.
                ref={contentCardRef}
              />
              {(variant && message) && <ValidationMessage variant={variant} message={message} />}
            </div>
          );
        })
        : (
          <div css={$messageContainer}>
            <Icon icon={image} customCss={$nothingToSeeImage} />
            <span css={$message}>{config.emptyMessage}</span>
          </div>
          )}
      <div css={$buttonContainer(isEmpty(value))}>
        {/*
          * If there are multiple editor types defined, use a
          * dropdown button. Otherwise, use a single button.
          */}
        {config.editorTypes.length > 1 ? (
          <ButtonWithPopOut
            __cssFor={{
              PopOut: {
                root: $popOutMenu,
              },
              Button: {
                root: $popOutButton
              }
            }}
            id='1'
            /* AUTOGENERATED TODO: update angelou to new flavor.
              see https://github.com/newsela/angelou/blob/main/src/components/Button/README.md#MIGRATION
              for migration guide. */
            legacy_flavor={Button.legacy_flavor.solid}
            legacy_size={Button.legacy_size.justified}
            buttonIcon={<></>} // The empty element is used to make the icon disappear. Angelou don't provide an easy way to do this.
            popOutContents={
              <AttachmentsMenu
                editorTypes={config.editorTypes}
                onAddItem={onAddItem}
              />
            }
            buttonContents={(
              <div css={$button}>
                {plusIcon}
                {config.addText}
              </div>)}
            horizontalOffset='0'
            closeOnBlur
          />
        ) : (
          <div>
            <Button
              __cssFor={{
                root: $popOutButton,
                Icon: {
                  root: [
                    // this styling copies the Icon component above
                    $icon,
                    {
                      ...iconColor,
                      height: '16px',
                      width: '16px'
                    }
                  ]
                }
              }}
              /* AUTOGENERATED TODO: update angelou to new flavor.
                see https://github.com/newsela/angelou/blob/main/src/components/Button/README.md#MIGRATION
                for migration guide. */
              legacy_flavor={Button.legacy_flavor.solid}
              legacy_size={Button.legacy_size.justified}
              onClick={onAddItem(config.editorTypes[0])}
              icon={{
                SvgComponent: PlusSVG,
                alignment: Button.iconAlignments.left
              }}
            >
              {config.addText}
            </Button>
          </div>
        )}
      </div>
    </div>
  );
}

EditorList.propTypes = {
  /** Field value, from the form-level state */
  value: PropTypes.array,
  /** Field name, which is also the property the data will be saved to */
  name: PropTypes.string,
  /** Full configuration object */
  config: PropTypes.shape({
    addText: PropTypes.string,
    emptyMessage: PropTypes.string.isRequired,
    editorTypes: PropTypes.arrayOf(PropTypes.any),
    input: PropTypes.string,
    defaultData: PropTypes.object,
  }),
  /** Function that updates the form state and persists data */
  onChange: PropTypes.func,
  formData: PropTypes.object,
  breadcrumbs: PropTypes.array,
  parent: PropTypes.object,
  validatedContent: PropTypes.shape({
    errorIds: PropTypes.array,
    errors: PropTypes.array,
    hasErrors: PropTypes.bool,
    hasWarnings: PropTypes.bool,
    warningIds: PropTypes.array,
    warnings: PropTypes.array
  })
};

// We're calling this component's display name EditorListGroup because the 'Group' part will
// prevent Mineral-UI's FormField from wrapping the entire thing in a <label>,
// allowing us to get around the native label issues with multiple child buttons
// (when hovering or clicking any input, that behavior is transmitted to the first
// button in the formatting menu).
EditorList.displayName = 'EditorListGroup';
