import React, { useState } from 'react';

import { faAngleDown, faAngleUp, faTrash } from '@fortawesome/free-solid-svg-icons';
import { isEmpty } from 'lodash-es';
import PropTypes from 'prop-types';

import Icon from '@client/common/components/Icon';

import { $root, $wordForm, $gradeBand, $isSuggested, $trash, $definitionArrow } from './style';
import PowerWordDefinition from '../PowerWordDefinition';
export default function PowerWord ({
  wordForm,
  gradeBand,
  wordUid,
  removeWord,
  wordDefinition = {}
}) {
  const [isExpanded, setIsExpanded] = useState(false);
  const isSuggested = isEmpty(wordDefinition);
  const isAdded = !isEmpty(wordDefinition);
  const blankDefinition = isAdded && isEmpty(wordDefinition.definition?.trim());

  return (
    <>
      <div css={$root}>
        <div>
          <div css={$wordForm}>{wordForm}</div>
          <div css={$gradeBand}>{gradeBand}</div>
        </div>
        <div>
          {isSuggested &&
            <span
              css={$isSuggested(isSuggested)}
              onClick={() => setIsExpanded(!isExpanded)}
            >
              Suggested
              {(!isSuggested && blankDefinition) && 'Needs Definition'}
            </span>}
          {(!isSuggested && blankDefinition) &&
            <span
              css={$isSuggested(isSuggested)}
              onClick={() => setIsExpanded(!isExpanded)}
            >
              Needs Definition
            </span>}
          {isAdded &&
            <button
              id={`edit-definition-button-${wordForm}`}
              css={$definitionArrow}
              type='button'
              title='Edit Definition'
              aria-label='Edit Definition'
              aria-expanded={isExpanded}
              onClick={() => setIsExpanded(!isExpanded)}
            >
              <Icon icon={isExpanded ? faAngleUp : faAngleDown} size={22} />
            </button>}
          {isAdded &&
            <button
              id={`remove-power-word-button-${wordForm}`}
              css={$trash}
              type='button'
              title='Remove Power Word'
              aria-label='Remove Power Word'
              onClick={() => removeWord({ wordForm, wordDefinition })}
            >
              <Icon icon={faTrash} size={16} color='ui.red.700' />
            </button>}
        </div>
      </div>
      {(isAdded && isExpanded) &&
        <PowerWordDefinition
          wordUid={wordUid}
          wordDefinition={wordDefinition}
        />}
    </>
  );
}

PowerWord.propTypes = {
  /** Field value, from the form-level state */
  wordForm: PropTypes.string,
  gradeBand: PropTypes.string,
  wordUid: PropTypes.string,
  removeWord: PropTypes.func,
  wordDefinition: PropTypes.shape({
    id: PropTypes.string,
    uid: PropTypes.string,
    definition: PropTypes.string,
    rawDefinition: PropTypes.object
  })
};
