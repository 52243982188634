import React from 'react';

import { faCopy } from '@fortawesome/pro-regular-svg-icons';
import { Button, TrashHollowSVG, AttachmentSVG, NewselaMarkSVG } from '@newsela/angelou';
import cuid from 'cuid';
import { isObject } from 'lodash-es';
import plur from 'plur';
import PropTypes from 'prop-types';

import Icon from '@client/common/components/Icon';
import * as schemas from '@client/common/schema';
import { enumCase } from '@client/utils/cases';

import {
  $root,
  $header,
  $menuTitle,
  $attachedCount,
  $button,
  $menuGroup
} from './style';

// Buttons in the secondary action menu. Exported for testing.
export function MenuButton ({ text, icon, onClick }) {
  return (
    <li>
      <Button
        __cssFor={{ root: $button }}
        onClick={onClick}
        /* AUTOGENERATED TODO: update angelou to new flavor.
          see https://github.com/newsela/angelou/blob/main/src/components/Button/README.md#MIGRATION
          for migration guide. */
        legacy_flavor={Button.legacy_flavor.flat}
        legacy_statusColor={Button.legacy_statusColor.black}
        legacy_size={Button.legacy_size.justified}
        legacy_icon={{
          SvgComponent: <Icon icon={icon} size={20} />,
          alignment: Button.iconAlignments.left
        }}
      >
        {text}
      </Button>
    </li>
  );
}

MenuButton.propTypes = {
  text: PropTypes.string.isRequired,
  icon: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.element,
    PropTypes.object
  ]),
  onClick: PropTypes.func.isRequired
};

// Group headings in the secondary action menu. Exported for testing.
export function MenuGroup ({ text }) {
  return (
    <li>
      <h3 css={$menuGroup}>{text}</h3>
    </li>
  );
}

MenuGroup.propTypes = {
  text: PropTypes.string.isRequired
};

/**
 * Mark an action as default by adding '(default)' to its label.
 * @param {string} action
 * @param {bool} shouldCloneByDefault
 * @returns {string}
 */
function markAsDefault (action, shouldCloneByDefault) {
  if (action === 'copy' && shouldCloneByDefault) {
    return ' (default)';
  } else if (action === 'link' && !shouldCloneByDefault) {
    return ' (default)';
  } else {
    return '';
  }
}

/**
 * Get the available actions for a given type of secondary menu.
 * @param {string} menuType
 */
function getEnabledActions (menuType) {
  switch (menuType) {
    case 'outline-bundle-root':
      return {
        children: true,
        attachments: true,
        duplicate: false,
        remove: true
      };
    case 'outline-bundle':
      return {
        children: true,
        attachments: true,
        duplicate: true,
        remove: true
      };
    case 'outline-smartbundle':
      return {
        attachments: true,
        duplicate: true,
        remove: true
      };
    case 'outline-content-with-attachments':
      return {
        attachments: true,
        remove: true
      };
    case 'outline-content':
      return {
        remove: true
      };
    case 'editor-inputs':
      return {
        remove: true
      };
    case 'content-results':
      return {
        addResult: true
      };
    case 'legacy-article-results':
      return {
        view: true
      };
  }
}

/**
 * Card that displays a piece of content with some metadata about it.
 */
export default function SecondaryMenu ({
  type,
  menuType = 'editor-inputs',
  attachedCount = 0,
  // Actions available in outline items.
  onAddChild,
  onAddAttachment,
  onDuplicate,
  // Actions available in content picker.
  shouldCloneByDefault = false,
  onCopy,
  onLinkExisting,
  onView,
  // Actions available in outline items and editor/editor-list inputs.
  onRemove
}) {
  const enabledActions = getEnabledActions(menuType);
  const menuId = cuid.slug();
  const schema = schemas[type];
  // The available types of content we can add as children
  const availableChildren = schemas.Bundle.availableChildrenGroups;
  // The available types of content we can add as attachments. These are
  // an array of objects and strings, and must be grouped by type to match the
  // syntax of availableChildren.
  const availableAttachments = schemas.Content.availableAttachments.reduce((acc, item) => {
    if (isObject(item) && item.type === 'Assessment') {
      acc[0].menuItems.push(item);
    } else if (isObject(item)) {
      acc[1].menuItems.push(item);
    } else {
      // It's a string! The string is the type of content.
      acc[1].menuItems.push({ type: item });
    }

    return acc;
  }, [{
    heading: 'Add Assessment',
    menuItems: []
  }, {
    heading: 'Add Lesson Planning',
    menuItems: []
  }]);
  const hasManageGroup = enabledActions.addResult ||
    enabledActions.view ||
    enabledActions.duplicate ||
    enabledActions.remove;

  return (
    <menu css={$root} aria-labelledby={menuId}>
      {/* Display the header. */}
      <header css={$header}>
        <h2 id={menuId} css={$menuTitle}>{schema.typename}</h2>
        {/* If there are attachments, display the count. */}
        {attachedCount > 0 && (
          <span css={$attachedCount}>
            {attachedCount} {plur('attachment', attachedCount)}
          </span>
        )}
      </header>

      {/* If we can add children, render all of the groups we can add. */}
      {enabledActions.children && availableChildren.map((group, groupIndex) => (
        <React.Fragment key={groupIndex}>
          <MenuGroup text={group.heading} />
          {(group.menuItems.map((item) => {
            const itemSchema = schemas[item];

            return (
              <MenuButton
                key={`children-${groupIndex}-${item}`}
                text={itemSchema.typename}
                icon={itemSchema.icon}
                onClick={() => onAddChild({ contentType: enumCase(item) })}
              />
            );
          }))}
        </React.Fragment>
      ))}

      {/* If we can add attachments, render all of the groups we can add. */}
      {enabledActions.attachments && availableAttachments.map((group, groupIndex) => (
        <React.Fragment key={groupIndex}>
          <MenuGroup text={group.heading} />
          {(group.menuItems.map((item, itemIndex) => {
            const itemSchema = schemas[item.type];

            return (
              <MenuButton
                key={`children-${groupIndex}-${itemIndex}`}
                text={item.title || itemSchema.typename}
                icon={itemSchema.icon}
                onClick={() => onAddAttachment({
                  type: item.type,
                  data: item.data
                })}
              />
            );
          }))}
        </React.Fragment>
      ))}

      {/* If we have other actions, render buttons for them. */}

      {hasManageGroup ? (
        <MenuGroup text='Manage' />
      ) : null}

      {enabledActions.addResult && (
        <>
          {onCopy && (
            <MenuButton
              text={`Copy${markAsDefault('copy', shouldCloneByDefault)}`}
              icon={faCopy}
              onClick={onCopy}
            />
          )}

          {onLinkExisting && (
            <MenuButton
              text={`Link Existing${markAsDefault('link', shouldCloneByDefault)}`}
              icon={AttachmentSVG}
              onClick={onLinkExisting}
            />
          )}
        </>
      )}

      {enabledActions.view && (
        <MenuButton
          text='View on Site'
          icon={NewselaMarkSVG}
          onClick={onView}
        />
      )}

      {enabledActions.duplicate && (
        <MenuButton
          text='Duplicate'
          icon={faCopy}
          onClick={onDuplicate}
        />
      )}

      {enabledActions.remove && (
        <MenuButton
          text='Remove'
          icon={TrashHollowSVG}
          onClick={onRemove}
        />
      )}
    </menu>
  );
}

SecondaryMenu.propTypes = {
  /** Type is used to generate the header for this menu. */
  type: PropTypes.string.isRequired,
  /** Type of menu to render. This determines which buttons are displayed. */
  menuType: PropTypes.oneOf([
    'outline-bundle',
    'outline-smartbundle',
    'outline-content-with-attachments',
    'outline-content',
    'editor-inputs',
    'content-results',
    'legacy-article-results'
  ]),
  /** Number of attachments, used to generate the header. */
  attachedCount: PropTypes.number,
  /** Function to add a child, passed in for bundles in the outline. */
  onAddChild: PropTypes.func,
  /** Function to add an attachment, passed in for content in the outline. */
  onAddAttachment: PropTypes.func,
  /** Function to duplicate the content, passed in for content in the outline. */
  onDuplicate: PropTypes.func,
  /**
   * Determines whether this content should be cloned by default in the content picker.
   * Bundles and all attachments are cloned by default, whereas other content is
   * referenced by default.
   */
  shouldCloneByDefault: PropTypes.bool,
  /** Function to copy the content, passed in for content in the content picker. */
  onCopy: PropTypes.func,
  /** Function to link to existing content, passed in for content in the content picker. */
  onLinkExisting: PropTypes.func,
  /** Function to view the content on the live site, passed in for content in the content picker. */
  onView: PropTypes.func,
  /** Function to remove the content, passed in for content in many places. */
  onRemove: PropTypes.func
};

// When using the SecondaryMenu in an Angelou PopOut component, we need to
// override the PopOut's default styles. Use these instead:
SecondaryMenu.popOutStyles = {
  popOutContents: {
    boxShadow: 'none',
    zIndex: '1'
  }
};
