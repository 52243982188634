import { css } from '@emotion/core';

export const $root = css`
  margin: 10px 0;
  position: relative;
  width: 100%;
`;

export const $select = css`
  margin-bottom: 15px;
`;

export const $image = (isFocalPoint) => ({ colors }) => {
  // Add space for the zoom slider when we're focal point cropping.
  const bottomPadding = isFocalPoint ? '45px' : '0';

  return css`
    align-items: center;
    background-color: ${colors.ui.greyDark[500]};
    display: flex;
    height: 300px;
    justify-content: center;
    margin-bottom: ${bottomPadding};
    position: relative;
    width: 100%;

    & > img {
      max-height: 100%;
      max-width: 100%;
    }
  `;
};

export const $slider = css`
  bottom: -35px;
  position: absolute;
  width: 100%;
`;

export const $noimg = ({ colors }) => css`
  align-items: center;
  background-color: ${colors.ui.greyExtraLight[500]};
  display: flex;
  height: 200px;
  justify-content: center;
  width: 100%;
`;
