/* eslint-disable camelcase */
import { css } from '@emotion/core';

export const $menuButton = (isActive) => ({ colors, backgroundColor_hover, backgroundColor_active }) => {
  const base = css`
    height: 34px;
    min-width: 34px;
    padding: 0;
    transition: background-color 200ms linear;
    width: 34px;

    &:hover,
    &:active,
    &:focus {
      border: 1px solid transparent !important;
    }

    &:disabled,
    &:disabled:hover,
    &:disabled:active,
    &:disabled:focus {
      background-color: ${colors.ui.white[500]};
      border: 1px solid transparent !important;
    }

    &:disabled * {
      color: ${colors.ui.greyLight[500]};
    }

    & > span {
      height: 100%;
    }

    & > span > span {
      padding: 0 !important;
    }
  `;
  const normal = css`
    background-color: transparent;

    &:hover {
      background-color: ${backgroundColor_hover};
    }

    &:active,
    &:focus {
      background-color: ${backgroundColor_active};
    }

    & * {
      color: ${colors.ui.greyDark[500]};
    }
  `;
  const active = css`
    background-color: ${colors.ui.coreBlue[700]};

    &:hover {
      background-color: ${colors.ui.coreBlue[500]};
    }

    &:active,
    &:focus {
      background-color: ${colors.ui.coreBlue[300]};
    }

    & * {
      color: ${colors.ui.white[500]};
    }
  `;

  return isActive ? [base, active] : [base, normal];
};

export const $menuDivider = ({ borderColor }) => css`
  border-right: 1px solid ${borderColor};
  height: 34px;
  margin: 0 3px;
  width: 0;
`;
