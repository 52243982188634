/* eslint-disable camelcase */
import { css } from '@emotion/core';

export const $icon = css`
  margin-right: 10px;
`;

export const $groups = css`
  margin: 0 0 0 10px;
`;

export const $group = css`
  padding: 0 0 0 35px;
  position: relative;
`;

export const $groupLabel = css`
  display: inline-block;
  left: 0;
  position: absolute;
  top: 12px;
`;

export const $option = (isActive) => ({ colors }) => {
  const borderColor = isActive ? colors.ui.coreBlue[100] : colors.ui.greyLight[100];
  const borderColorHover = isActive ? colors.ui.coreBlue[300] : colors.ui.greyLight[300];
  const borderColorFocus = isActive ? colors.ui.coreBlue[500] : colors.ui.greyLight[500];
  const color = isActive ? colors.ui.coreBlue[500] : colors.ui.greyDark[500];

  return css`
    background-color: ${colors.ui.white[500]};
    border: 1px solid ${borderColor};
    border-radius: 20px;
    color: ${color};
    cursor: pointer;
    margin: 5px;
    outline: none;
    padding: 8px 12px;
    transition: 200ms border-color;

    &:hover {
      border-color: ${borderColorHover};
    }

    &:active,
    &:focus {
      border-color: ${borderColorFocus};
    }
  `;
};
