import React from 'react';

import Button from 'mineral-ui/Button';
import { IconClose } from 'mineral-ui-icons';
import PropTypes from 'prop-types';

import Icon from '@client/common/components/Icon';
import { getFileIcon, getFileName } from '@client/utils/fields';

import { $button, $fileInfo, $fileName, $spacer } from './style';

export default function FileItem ({ filename, onRemove }) {
  const icon = getFileIcon(filename);
  const name = getFileName(filename);

  return (
    <div css={$fileInfo}>
      <Icon icon={icon} size={20} />
      <a href={filename} rel='noopener noreferrer' target='_blank' css={$fileName}>{name}</a>
      <span css={$spacer} />
      <Button
        type='button'
        iconStart={<IconClose />}
        circular
        minimal
        aria-label='Remove'
        css={$button}
        onClick={() => onRemove(filename)}
      />
    </div>
  );
}

FileItem.propTypes = {
  filename: PropTypes.string,
  onRemove: PropTypes.func
};
