/* eslint-disable camelcase */
import { css } from '@emotion/core';

import { getVariantColor } from '@client/utils/styles';

export const $card = (isDisabled, variant) => ({ colors }) => {
  let border = `1px solid ${colors.ui.greyLight[100]}`;
  let margin = '20px 0';

  if (variant) {
    border = `2px solid ${getVariantColor(variant)}`;
    margin = '20px 0 0 0';
  }

  const base = css`
    margin: ${margin};
    border: ${border};
  `;
  const disabled = css`
    background-color: ${colors.ui.greyExtraLight[500]};
    pointer-events: none;
  `;

  return isDisabled ? [base, disabled] : [base];
};

export const $heading = ({ colors }) => css`
  cursor: pointer;
  padding: 5px 10px;
  background-color: ${colors.ui.greyExtraLight[500]};
  &:focus {
    outline: ${colors.ui.coreBlue[700]} auto 1px;
  }
`;

export const $body = ({ colors }) => css`
    color: ${colors.ui.greyDark[500]};
    padding: 0 15px 10px 15px;

    label {
      cursor: unset;
    }

    & > hr {
      color: ${colors.ui.greyLight[500]};
    }
`;

export const $icons = () => css`
  display: flex;
  justify-content: flex-end;
`;

export const $optionText = ({ colors }) => css`
  color: ${colors.ui.greyDark[500]};
`;

export const $checkedRadioButton = ({ colors }) => css`
  input[type='radio']:checked + span {
    background-color: ${colors.ui.greyDark[500]};
    border-color: ${colors.ui.greyDark[500]};
  }
`;
