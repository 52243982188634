import { Button } from '@newsela/angelou';
import { isUndefined, isNull, head, tail, isNumber, isNil, isEmpty, isFunction } from 'lodash-es';
import plur from 'plur';

import { getContextualFieldName, validateLength, getValue } from '@client/utils/fields';

import { titleCaseSpaced } from './cases';
import {
  VALIDATION_ERROR,
  VALIDATION_WARNING,
  VARIANT_ERROR,
  VARIANT_WARNING
} from './constants';

const REQUIRED_TEXT = 'requiredText';
const SECONDARY_TEXT = 'secondaryText';

// Note: In these functions, we refer to errors and warnings collectively as
// 'issues'. The logic for filtering and displaying errors is the same as the
// logic for warnings, so these functions run against both types of issues.

/**
 * Filter issues by a specific piece of content.
 * @param {string} id
 * @returns {Function} passed to Array.filter
 */
export function filterContent ({ id }) {
  return (issue) => issue.location.id === id;
}

/**
 * Filter issues to get only those that are NOT for a specific field.
 * @param {object} issue
 * @param {string} fieldPath
 * @returns {boolean}
 */
export function filterForm (issue, fieldPath) {
  return isUndefined(issue.location.field) || isNull(issue.location.field) || issue.location.field === fieldPath;
}

/**
 * Filter issues to get only those that are for a specific field.
 * @param {object} issue
 * @param {string} fieldPath
 * @returns {boolean}
 */
export function filterField (issue, fieldPath) {
  return !filterForm(issue, fieldPath);
}

/**
 * Filter issues by a specific field.
 * @param {string} name
 * @returns {Function} passed to Array.filter
 */
export function filterSpecificField ({ name }) {
  return (issue) => issue?.location?.field === name;
}

/**
 * Filter issues deeply nested within this field.
 * @param {string} name
 * @returns {Function} passed to Array.filter
 */
export function filterNestedFields ({ name }) {
  return (issue) =>
    // We're using a dot at the end to differentiate something like 'levels.1.' from 'levels.10.'.
    issue.location?.field?.startsWith(`${name}.`) || issue.location?.field === name;
}

/**
 * Generate a validation message based on this field's errors and warnings.
 * If there's only one issue, return its message. Otherwise display the first
 * issue's message and the number of additional issues.
 * @param {array} issues
 * @param {string} type either VALIDATION_ERROR or VALIDATION_WARNING
 * @returns {string}
 */
export function getFieldMessage (issues, type) {
  const message = head(issues)?.message || '';
  const count = tail(issues)?.length || 0;

  if (count > 0) {
    return `${message} (plus ${count} additional ${plur(type, count)})`;
  } else {
    return message;
  }
}

/**
 * Determine field caption based on validation state. Caption displays below
 * the field. The issues passed into this function should have already been
 * filtered to only include issues for this field.
 * @param {array} errors specific to this field
 * @param {array} warnings specific to this field
 * @param {string} caption normal caption that would display on the field
 * @returns {string}
 */
export function getCaption (errors, warnings, { caption }) {
  if (errors?.length) {
    return {
      variant: VARIANT_ERROR,
      caption: getFieldMessage(errors, VALIDATION_ERROR)
    };
  } else if (warnings?.length) {
    return {
      variant: VARIANT_WARNING,
      caption: getFieldMessage(warnings, VALIDATION_WARNING)
    };
  } else {
    return {
      variant: null,
      caption: caption || ''
    };
  }
}

/**
 * Generate an aggregated validation message based on this item's errors
 * and warnings. This is used by complicated inputs like the EditorList and
 * level selectors, which need to display all issues in their related types.
 * @param {array} errors specific to this item
 * @param {array} warnings specific to this item
 * @param {string} name of this item. Will be transformed to lowercase spaced.
 * @param {boolean} isMultiple whether this item is a list of items
 * @returns {object} with { variant, message }
 */
export function getAggregateMessage (errors, warnings, { name, isMultiple }) {
  const errorCount = errors.length;
  const warningCount = warnings.length;
  const displayName = titleCaseSpaced(name).toLowerCase();
  const nameMessage = isMultiple ? `These ${plur(displayName, 2)} have` : `This ${displayName} has`;
  const errorMessage = `${errorCount} ${plur(VALIDATION_ERROR, errorCount)}`;
  const warningMessage = `${warningCount} ${plur(VALIDATION_WARNING, warningCount)}`;

  if (errorCount && warningCount) {
    // If there are both errors and warnings, we display this with the
    // VARIANT_ERROR variant. The only time we display it as VARIANT_WARNING is
    // if there's ONLY warnings.
    return {
      variant: VARIANT_ERROR,
      message: `${nameMessage} ${errorMessage} and ${warningMessage}.`
    };
  } else if (errorCount) {
    return {
      variant: VARIANT_ERROR,
      message: `${nameMessage} ${errorMessage}.`
    };
  } else if (warningCount) {
    return {
      variant: VARIANT_WARNING,
      message: `${nameMessage} ${warningMessage}.`
    };
  } else {
    return { variant: null, message: null };
  }
}

/**
 * Determine if we should validate field LENGTH instead of field VALUE. This
 * is used to differentiate validation on 'min' and 'max' rules.
 * @param {*} val
 * @param {string} input
 * @returns {boolean}
 */
function shouldValidateLength (val, input) {
  return input !== 'datepicker' && !isNumber(val);
}

/**
 * Get the secondary text that should display when fields with min/max validation
 * are empty.
 * @param {boolean} required
 * @param {boolean} suggested
 * @param {string} secondaryText
 * @returns {string}
 */
function getZeroText (required, suggested, secondaryText) {
  if (required) {
    return 'Required';
  } else if (suggested) {
    return 'Suggested';
  } else if (secondaryText) {
    return secondaryText;
  } else {
    return '';
  }
}

/**
 * Get the name of the key to use when displaying secondary text. This determines
 * if the text displays as red (an error), yellow (a warning), or dark grey (normal).
 * @param {boolean} isValid
 * @param {number} length
 * @param {boolean} required
 * @returns {string}
 */
function getTextProp (isValid, length, required) {
  if (required && length === 0) {
    // Required fields should still be red if they're empty!
    return REQUIRED_TEXT;
  } else {
    // Other fields (and required fields that are not empty) should be red
    // if they're invalid lengths.
    return isValid ? SECONDARY_TEXT : REQUIRED_TEXT;
  }
}

/**
 * Get the secondary text that displays for fields with min/max validation.
 * @param {*} val
 * @param {object} fieldConfig
 * @returns {object} with { requiredText } or { secondaryText }, or empty object
 */
function getMinMaxText (val, fieldConfig) {
  const {
    min,
    max,
    required,
    suggested,
    secondaryText
  } = fieldConfig;
  const minLength = validateLength(val, min, 'min');
  const maxLength = validateLength(val, max, 'max');
  const zeroText = getZeroText(required, suggested, secondaryText);

  if (zeroText && minLength.length === 0 && maxLength.length === 0) {
    const textProp = getTextProp(true, 0, required);

    return { [textProp]: zeroText };
  } else if (min && max) {
    // Validate against both min and max lengths!
    const isValid = minLength.isValid && maxLength.isValid;
    const textProp = getTextProp(isValid, minLength.length, required);
    // Display the min when we're below it, but otherwise display the max.
    const limit = minLength.length < min ? min : max;

    return { [textProp]: `${minLength.length}/${limit}` };
  } else if (min) {
    // Validate against min length.
    const textProp = getTextProp(minLength.isValid, minLength.length, required);

    return { [textProp]: `${minLength.length}/${min}` };
  } else if (max) {
    // Validate against max length.
    const textProp = getTextProp(maxLength.isValid, maxLength.length, required);

    return { [textProp]: `${maxLength.length}/${max}` };
  } else {
    return {};
  }
}

/**
 * Determine the secondary text (text that displays on the right of fields).
 * Suggested fields will display 'suggested'. Fields with min or max validation
 * will display a live count of their length and the min/max value.
 * @param {object} fieldConfig
 * @param {object} data
 * @returns {object} with { requiredText } or { secondaryText }, or empty object
 */
export function getSecondaryText (fieldConfig, data) {
  const {
    input,
    required,
    suggested,
    secondaryText,
    min,
    max
  } = fieldConfig;
  const val = getValue(fieldConfig, data, { plaintext: input === 'prosemirror' });

  const isRequired = isFunction(required)
    ? required(fieldConfig, data)
    : required;

  if ((min || max) && shouldValidateLength(val, input)) {
    return getMinMaxText(val, fieldConfig);
  } else if (isRequired) {
    return { requiredText: 'Required' };
  } else if (suggested) {
    return { secondaryText: 'Suggested' };
  } else {
    return { secondaryText };
  }
}

/**
 * Get form validation.
 * @param {object} validatedContent results from contentValidity query (has errors and warnings arrays)
 * @param {*} data from form data
 * @returns {object} with summary information and issues grouped as form vs field.
 */
export function getFormValidation (validatedContent, data, fieldPath) {
  const contentErrors = (validatedContent.errors || []).filter(filterContent(data));
  const contentWarnings = (validatedContent.warnings || []).filter(filterContent(data));
  // Filter further to get only errors that pertain to the whole form / content
  // (rather than specific fields)
  const formErrors = contentErrors.filter((issue) => filterForm(issue, fieldPath));
  const formWarnings = contentWarnings.filter((issue) => filterForm(issue, fieldPath));
  // Filter to get field-specific errors, which we pass to fields
  const fieldErrors = contentErrors.filter((issue) => filterField(issue, fieldPath));
  const fieldWarnings = contentWarnings.filter((issue) => filterField(issue, fieldPath));

  return {
    errorCount: contentErrors.length,
    warningCount: contentWarnings.length,
    formErrors,
    formWarnings,
    fieldErrors,
    fieldWarnings,
    // Should we add some text saying that there are additional field errors?
    hasFieldErrors: fieldErrors.length > 0 && formErrors.length > 0,
    hasFieldWarnings: fieldWarnings.length > 0 && formWarnings.length > 0
  };
}

/**
 * Filter errors and warnings for:
 *  - a specific content using the content id
 *  - a specific field using the field path
 *  - nested fields using a field path prefix
 * @param {Object} validatedContent results from contentValidity query (has errors and warnings arrays)
 * @param {Object} data with { id: contentId }
 * @param {String} fieldPath field prefix
 * @param {Boolean} showNestedIssues
 * @returns {Object} with { errors, warnings }
 */
export function getFieldValidation (validatedContent, data, fieldPath, showNestedIssues) {
  let errors = validatedContent?.errors?.filter(filterContent(data)) || [];
  let warnings = validatedContent?.warnings?.filter(filterContent(data)) || [];

  if (fieldPath) {
    const filterIssues = showNestedIssues ? filterNestedFields : filterSpecificField;
    errors = errors?.filter(filterIssues({ name: fieldPath }));
    warnings = warnings?.filter(filterIssues({ name: fieldPath }));
  }

  return { errors, warnings };
}
/**
 * Return the validation state variant for a tab ('danger' or 'warning')
 * @param {Object} validatedContent results from contentValidity query (has errors and warnings arrays)
 * @param {Object} tab form tab
 * @param {String} id content id
 * @returns {String} danger or warning
 */
export function getTabValidationVariant (validatedContent, tab, id) {
  const tabHasSections = tab.fields.find((item) => item.type === 'section');
  // Tabs in the Articles app has sections, but tabs in the Smart Bundle don't. So, we need to check
  // if this tab has no sections, use its fields. Otherwise, collect all fields from all sections.
  const tabFields = !tabHasSections ? tab.fields : tab.fields.flatMap((section) => section.fields);
  const fieldIssues = tabFields.map((field) => {
    const contextualFieldName = getContextualFieldName({
      validationName: field.validationName,
      name: field.name
    });
    return getFieldValidation(
      {
        errors: validatedContent.fieldErrors,
        warnings: validatedContent.fieldWarnings
      },
      { id },
      contextualFieldName,
      tab.hasNestedFields
    );
  }).filter((field) => field.errors.length > 0 || field.warnings.length > 0);
  const hasErrors = fieldIssues.some((field) => field.errors.length > 0);
  const hasWarnings = fieldIssues.some((field) => field.warnings.length > 0);
  const variant = hasErrors ? VARIANT_ERROR : VARIANT_WARNING;

  return (hasErrors || hasWarnings) ? variant : '';
}

/**
 * Return button variant depending on form errors and warnings
 * @param {array} errors
 * @param {array} warnings
 * @returns {object}
 */
/**
 * These variant colors map to the old Button legacy_statusColor
 * Can upgrade after Angelou 0.27.0 release
*/
export function getButtonVariant (errors, warnings) {
  if (errors && errors.length !== 0) {
    return { variant: Button.legacy_statusColor.danger };
  } else if (warnings && warnings.length !== 0) {
    return { variant: Button.legacy_statusColor.warning };
  }
  return null;
}

/**
 * Gets only the field path prefix from an issue.
 * @param {Object} issue error or warning object
 * @returns {String} fieldPathPrefix
 */
export function getFieldPathPrefix (issue) {
  // ex: field 'articleLevels.0.title' returns 'articleLevels.0'
  return issue.location.field.replace(/[^\d]+$/, '');
}

/**
 * Checks if any field path has at least one issue (non-empty intersection).
 * @param {Array} fieldPaths ex: [articleLevels.0, articleLevels.1]
 * @param {Array} issuesFieldPaths ex: [articleLevels.2, articleLevels.1]
 * @returns {Boolean} hasIntersection
 */
export function hasIntersection (fieldPaths, issuesFieldPaths) {
  return fieldPaths.some((fieldPath) => issuesFieldPaths.includes(fieldPath));
}

/**
 * Determine if a form is valid, based on its config.
 * @param {Object} data
 * @param {Array} config
 * @return {boolean} isFormValid
 */
export function getFormValidity (data = {}, config = []) {
  const invalidField = config.find((fieldConfig) => {
    const val = data[fieldConfig.name];
    return fieldConfig.required && (isNil(val) || val === '' || isEmpty(val));
  });

  return typeof invalidField === 'undefined';
}

/**
 * Creates an initial map from content id to validation variant.
 * @param {Object} content the root content, e.g. Article or root Bundle
 * @returns {Object} variants ex: { ck123: null, ck456: null, ... }
 */
export function getInitialVariants (content) {
  const contentIdToVariantMap = {};
  const traverse = (current) => {
    contentIdToVariantMap[current.id] = null;
    current.attached?.forEach((attachment) => { contentIdToVariantMap[attachment.id] = null; });
    if (current.headerImage?.id) contentIdToVariantMap[current.headerImage.id] = null;
    if (!isEmpty(current.children)) current.children.forEach((child) => traverse(child));
  };
  if (content.id) traverse(content);
  return contentIdToVariantMap;
}

export function getVariant (id, validity) {
  if (validity.errorIds.includes(id)) return VARIANT_ERROR;
  else if (validity.warningIds.includes(id)) return VARIANT_WARNING;
  return null;
}

export function getRootVariant (validity) {
  if (!isEmpty(validity.errorIds)) return VARIANT_ERROR;
  else if (!isEmpty(validity.warningIds)) return VARIANT_WARNING;
  return null;
}
