import React, { useRef, useState } from 'react';

import cuid from 'cuid';
import { useStoreState, useStoreActions } from 'easy-peasy';
import { isEqual } from 'lodash-es';
import Button from 'mineral-ui/Button';
import IconAdd from 'mineral-ui-icons/IconAdd';
import PropTypes from 'prop-types';

import ContentCard from '@client/common/components/ContentCard';
import * as schemas from '@client/common/schema';
import ValidationMessage from '@client/forms/components/ValidationMessage';
import { PUBLISHABLE } from '@client/utils/constants';
import { getFieldValidation, getAggregateMessage } from '@client/utils/form-validation';

export default function Editor ({ value, name, config, onChange, formData, breadcrumbs = [], parent, validatedContent }) {
  const rootStreams = useStoreState((state) => state.rootStreams.data, isEqual);
  const switchDrawer = useStoreActions((actions) => actions.drawer.switch);
  const currentType = formData.__typename;
  const currentSchema = schemas[currentType];
  const relatedSchema = schemas[config.editorType];
  const streams = rootStreams.length
    ? rootStreams.map((streamId) => ({ uid: streamId }))
    : null;
  const currentBreadcrumb = {
    id: formData.id,
    uid: formData.uid,
    type: currentType,
    isContent: currentSchema.isContent,
    // Get the title from the PARENT type
    title: formData.name,
    parent
  };
  // Reference to the DOM node that opened the Drawer.
  // This reference is used to get the focus back when Drawer unmounts.
  const returnFocusRef = useRef(null);

  // used for disabling the button and rendering the ContentCard when the DB save is done.
  const [addingImageInProgress, setAddingImageInProgress] = useState(false);

  const toggleAddingImageInProgress = () => {
    setAddingImageInProgress((prevState) => !prevState);
  };

  const onAddItem = async () => {
    toggleAddingImageInProgress(); // Disable the add button
    const id = cuid();

    if (relatedSchema.isContent) {
      const newContent = relatedSchema.defaults(id, { ...config.defaultData, streams });

      await onChange(
        { [name]: newContent.server },
        { [name]: newContent.client },
        'set',
        false // Don't debounce these api calls, send each one to the server separately.
      );
    } else {
      const newItem = relatedSchema.defaults(id, config.defaultData);

      await onChange(
        { [name]: newItem.server },
        { [name]: newItem.client },
        'set',
        false // Don't debounce these api calls, send each one to the server separately.
      );
    }
    toggleAddingImageInProgress(); // Enable the button adding
  };

  const onSelectItem = () => {
    return switchDrawer({
      id: value.id,
      type: config.editorType,
      drawerType: PUBLISHABLE,
      breadcrumbs: [...breadcrumbs, currentBreadcrumb],
      parent: {
        id: formData.id,
        uid: formData.uid,
        field: name,
        fieldType: config.input
      },
      returnFocusRef
    });
  };

  const addText = `Add ${config.label || relatedSchema.typename}`;

  const { errors, warnings } = getFieldValidation(validatedContent, { id: value?.id });
  const { variant, message } = getAggregateMessage(errors, warnings, {
    name: relatedSchema.typename,
    isMultiple: false
  });

  return value && !addingImageInProgress
    ? (
      <>
        <ContentCard
          ref={returnFocusRef}
          data={value}
          onClick={onSelectItem}
          menuConfig={{
            menuType: 'editor-inputs',
            onRemove: () => {
              onChange({ [name]: { uid: value.uid } }, { [name]: null }, 'unset', false);
            }
          }}
          variant={variant}
        />
        {(variant && message) && <ValidationMessage variant={variant} message={message} />}
      </>
      )
    : (
      <Button
        type='button'
        fullWidth
        iconStart={<IconAdd />}
        aria-label={addText}
        disabled={addingImageInProgress}
        onClick={onAddItem}
      >{addText}
      </Button>
      );
}

Editor.propTypes = {
  /** Field value, from the form-level state */
  value: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string
  ]),
  /** Field name, which is also the property the data will be saved to */
  name: PropTypes.string,
  /** Full configuration object */
  config: PropTypes.object,
  /** Function that updates the form state and persists data */
  onChange: PropTypes.func,
  formData: PropTypes.object,
  breadcrumbs: PropTypes.array,
  parent: PropTypes.object,
  validatedContent: PropTypes.shape({
    errorIds: PropTypes.array,
    errors: PropTypes.array,
    hasErrors: PropTypes.bool,
    hasWarnings: PropTypes.bool,
    warningIds: PropTypes.array,
    warnings: PropTypes.array
  })
};

Editor.displayName = 'EditorGroup';
