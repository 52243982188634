import { get, first } from 'lodash-es';

import { LiteProductGrant } from '@client/common/schema';
import { store } from '@client/common/store';

export function transformLiteProductGrant (liteProductGrants, propertyName) {
  return get(liteProductGrants, `0.${propertyName}`, null);
}

export function getLiteProductGrantChange (liteProductGrants, propertyName, newDate) {
  const uid = get(liteProductGrants, '0.uid', null);
  const update = { [propertyName]: newDate };
  const newOrUpdatedLiteProductGrant = LiteProductGrant.defaults(uid, update);

  return {
    server: {
      uid: newOrUpdatedLiteProductGrant.server.uid,
      ...update
    },
    // This is used to immediately display the change to the user and also to prevent
    // the other date value from disappearing while waiting for the server to respond.
    optimisticData: [{
      ...newOrUpdatedLiteProductGrant.client,
      ...first(liteProductGrants),
      ...update
    }]
  };
}

export function getLiteProductGrantIsRequired (_config, formData) {
  if (!store.getState().user?.permissions?.editLiteProductGrants) return false;
  const { dateStarts, dateEnds } = get(formData, 'liteProductGrants.0', {});
  return Boolean(dateStarts || dateEnds);
}
