import { get, some, isEmpty } from 'lodash-es';
import {
  toggleMark as _toggleMark,
  wrapIn as _wrapIn,
  setBlockType as _setBlockType
} from 'prosemirror-commands';
import { Fragment } from 'prosemirror-model';
import {
  wrapInList as _wrapInList,
  splitListItem as _splitListItem,
  liftListItem as _liftListItem,
  sinkListItem as _sinkListItem
} from 'prosemirror-schema-list';
import { findWrapping, liftTarget } from 'prosemirror-transform';

export const getMark = (type, state) => get(state, `config.schema.marks.${type}`);
export const getBlock = (type, state) => {
  return get(state, `config.schema.nodes.${type}`);
};

// We need to wrap some internal prosemirror commands, so they'll fetch the
// post-schema-creation type instead of the pre-schema type config.
// State and dispatch are passed through to prosemirror.
export const toggleMark = (type, attrs) => (s, d) => _toggleMark(
  getMark(type, s),
  attrs
)(s, d);

// Set node to a specific block type, e.g. paragraph or heading
export const setBlockType = (type, options) => (s, d) => _setBlockType(
  getBlock(type, s),
  options
)(s, d);

// Wrap all nodes in a specfic block type, e.g. lists
export const wrapIn = (type) => (s, d) => _wrapIn(getBlock(type, s))(s, d);

export const wrapInList = (type) => (s, d) => _wrapInList(getBlock(type, s))(s, d);
export const splitListItem = (type) => (s, d) => _splitListItem(getBlock(type, s))(s, d);
export const liftListItem = (type) => (s, d) => _liftListItem(getBlock(type, s))(s, d);
export const sinkListItem = (type) => (s, d) => _sinkListItem(getBlock(type, s))(s, d);

export const promptForString = (message, isURL) => {
  let val;
  // TODO: Better URL picker / math formula input.
  if (isURL) {
    let url = window && window.prompt(message, 'https://');

    if (url && !/^https?:\/\//i.test(url)) {
      url = 'http://' + url;
    }
    val = url;
  } else {
    val = window && window.prompt(message);
  }

  return val;
};

/**
 * Determine if a mark is currently active.
 *
 * @param  {string} type
 * @return {boolean}
 */
export const markActive = (type) => (state) => {
  const { from, $from, to, empty } = state.selection;
  const mark = getMark(type, state);

  if (!mark) {
    // This mark isn't relevant for this input.
    return false;
  } else if (empty) {
    // Empty selection, determine if the mark is active where the cursor is.
    return !!mark.isInSet(state.storedMarks || $from.marks());
  } else {
    // Something is selected, determine if the mark is active inside the selection.
    return state.doc.rangeHasMark(from, to, mark);
  }
};

/**
 * Determine if a block is active.
 *
 * @param  {string} type
 * @param  {Object} [attrs={}]
 * @return {boolean}
 */
export const blockActive = (type, attrs = {}) => (state) => {
  const { $from, to, node } = state.selection;
  const block = get(state, `config.schema.nodes.${type}`);

  if (node && !isEmpty(attrs)) {
    // Sometimes we pass in type and attributes that we want to check against (e.g. for different levels of headings)
    return node.hasMarkup(block, attrs);
  } else if (node) {
    // If we are only checking against the node (e.g. for image blocks, who have an id value in their attributes, that is irrelevant to our menu format),
    // pass its attributes straight through so hasMarkup only checks the node attributes against themselves.
    // If we were to pass in the block only, hasMarkup would be comparing node.attributes against an undefined attrs object, resulting in a consistent false return
    return node.hasMarkup(block, node.attrs);
  }

  return to <= $from.end() && $from.parent.hasMarkup(block, attrs);
};

/**
 * Determine if a wrapper block is active.
 *
 * @param  {string} type
 * @param  {Object} [attrs={}]
 * @return {boolean}
 */
export const wrapperBlockActive = (type, attrs = {}) => (state) => {
  const { $from, node, $head } = state.selection;
  const block = get(state, `config.schema.nodes.${type}`);

  if (node) {
    return node.hasMarkup(block, attrs);
  }

  // Get the node at depth 1 from the doc tree.
  const wrapperNode = $head.node(1);

  return (
    // If we cannot find a wrapper node, then check the original node.
    isEmpty(wrapperNode)
      ? $from.parent.hasMarkup(block, attrs)
      : wrapperNode.hasMarkup(block, attrs)
  );
};

/**
 * Wraps a selected node. We are taking source code from Prosemirror and changing the
 * range of the selection that gets passed into the dispatch fn. We want to make sure we're always
 * getting the top level node and creating a range from that to deepest nested element,
 * which is usually a paragraph node.
 *
 * @param  {string} type
 * @return {boolean}
 * @see {@link https://prosemirror.net/docs/ref/#commands.wrapIn}
 */
const customWrapIn = (type) => {
  return function (state, dispatch) {
    const { $to, $head } = state.selection;
    // Gets the range between the start of top level block and end of selection.
    // docNode represents the doc. topLevelBlockPos represents the position of the top level block.
    const docNode = $head.node(0);
    const topLevelBlockPos = $head.before(1);
    const range = docNode.resolve(topLevelBlockPos).blockRange($to);
    // Checks if range can be wrapped in `type`.
    const wrapping = range && findWrapping(range, type);
    if (!wrapping) return false;
    // The 'tr' in state.tr represents transformations. This line of code is responsible
    // for the actual wrapping of the dom elements.
    if (dispatch) dispatch(state.tr.wrap(range, wrapping).scrollIntoView());
    return true;
  };
};

/**
 * Lifts a wrapped element. We are taking source code from Prosemirror and changing the
 * range of the selection, similarly to our custom wrap fn. We want to establish a range
 * that could lift all types of wrapped blocks such as paragraphs as well as lists.
 *
 * @param  {Object} state
 * @param {Function} dispatch
 * @return {boolean}
 * @see {@link https://prosemirror.net/docs/ref/#commands.lift}
 */
const customLift = (state, dispatch) => {
  const { $to, $head } = state.selection;
  // Gets the range between the start of top level block and end of selection.
  // docNode represents the doc. wrapperNode represents the position of the wrapper.
  const docNode = $head.node(0);
  const wrapperNode = $head.before(2);
  // Range of the full selection between wrapper and selected element, in which most cases would be a <p>.
  const range = docNode.resolve(wrapperNode).blockRange($to);
  const target = range && liftTarget(range);
  if (target == null) return false;
  // The 'tr' in state.tr represents transformations. This line of code is responsible
  // for the dom changes necessary for lifting.
  if (dispatch) dispatch(state.tr.lift(range, target).scrollIntoView());
  return true;
};

// Wrap node in a specfic block type, and toggle them.
export const wrapInToggle = (type) => (s, d) => {
  return wrapperBlockActive(type)(s) ? customLift(s, d) : customWrapIn(getBlock(type, s))(s, d);
};

// Toggle between a specific block type and paragraph, e.g. heading <-> paragraph
export const toggleBlockType = (type, attrs) => (state, dispatch) => {
  if (blockActive(type, attrs)(state)) {
    // Block is already the type specified; set it to paragraph
    setBlockType('paragraph', attrs)(state, dispatch);
  } else {
    // Set the block type to the type specified
    setBlockType(type, attrs)(state, dispatch);
  }
};

// Creates new line within the same block.
export const createNewline = (type) => (state, dispatch) => {
  const { $head, $anchor, from } = state.selection;
  const isInType = get($head, 'parent.type.name') === type;
  if (!isInType || !$head.sameParent($anchor)) {
    return false;
  }
  if (dispatch) {
    const br = getBlock('hard_break', state).create();
    dispatch(state.tr.insert(from, br).scrollIntoView());
  }
  return true;
};

/**
 * Allows toggle between pre_block and paragraph.
 * This is used for toggling Preserve Whitespace.
 *
 * @param {Object} state
 * @param {Function} dispatch
 * @returns {boolean}
 * @see {@link https://prosemirror.net/docs/guide/#doc.indexing}
 * @see {@link https://prosemirror.net/docs/ref/#model.ResolvedPos.start}
 */
export const togglePreBlock = (state, dispatch) => {
  const { $from, $to } = state.selection;
  // The absolute position of the start of the first block in the selection
  // The -1 is to get the position right before the token that starts the node.
  const start = $from.start() - 1;
  // The absolute position of the end of the last block in the selection
  const end = $to.end();

  const insidePreBlock = blockActive('pre_block')(state, dispatch);
  if (insidePreBlock) {
    return splitPreIntoParagraphs(start, end)(state, dispatch);
  }
  return replaceWithPreBlock(start, end)(state, dispatch);
};

/**
 * Splits a single pre_block into paragraphs
 *
 * @param {number} start
 * @param {number} end
 * @returns {boolean}
 */
export const splitPreIntoParagraphs = (start, end) => (state, dispatch) => {
  let content = [];
  const p = getBlock('paragraph', state);
  state.doc.nodesBetween(start, end, (node) => {
    // Filter out hard breaks and create paragraphs
    content = node.content.content
      .filter((n) => n.type.name !== 'hard_break')
      .map((n) => p.create(null, n));
    return false;
  });

  const fragment = Fragment.fromArray(content);
  if (dispatch) {
    dispatch(state.tr.replaceRangeWith(start, end, fragment).scrollIntoView());
  }
  return true;
};

/**
 * Allows for highlighting multiple blocks and replaces them with a pre_block.
 *
 * @param {number} start
 * @param {number} end
 * @returns {boolean}
 */
export const replaceWithPreBlock = (start, end) => (state, dispatch) => {
  let content = [];
  const br = getBlock('hard_break', state).create();
  state.doc.nodesBetween(start, end, (node) => {
    // Add line breaks between paragraphs
    content = content.concat(node.content.content, br);
    return false;
  });
  // Remove the last line break after the last paragraph
  content.pop();

  const fragment = Fragment.fromArray(content);
  const newBlock = getBlock('pre_block', state).create(null, fragment);
  if (dispatch) {
    dispatch(state.tr.replaceRangeWith(start, end, newBlock).scrollIntoView());
  }
  return true;
};

/**
 * Is the user using Mac OS?
 * @type {Boolean}
 */
export const isMac = typeof navigator !== 'undefined'
  ? /Mac/.test(navigator.platform)
  : typeof os !== 'undefined' ? window.os.platform() === 'darwin' : false;

export function filterDoc (value, allowedNodes, allowedMarks) {
  // Filter out nodes that are not allowed.
  if (!allowedNodes.includes(value.type)) {
    return {
      type: 'paragraph',
      ...value.content && { content: value.content.map((child) => filterDoc(child, allowedNodes, allowedMarks)) }
    };
  }

  // Filter out marks that are not allowed.
  if (value.marks && some(value.marks, (mark) => !allowedMarks.includes(mark.type))) {
    return {
      type: 'text',
      text: value.text,
      marks: value.marks.filter((mark) => allowedMarks.includes(mark.type))
    };
  }

  // If there are no disallowed things, return the node and traverse to its children.
  return {
    ...value,
    ...value.content && { content: value.content.map((child) => filterDoc(child, allowedNodes, allowedMarks)) }
  };
}

/**
 * Returns the text in a Prosemirror selection
 *
 * @example <caption>Nothing selected</caption>
 * // returns an empty array because no nodes are selected
 * []
 *
 * @example <caption>Single word in a paragraph selected</caption>
 * // applewood
 * // First node (NOT text node) so ignored:
 * // {
 * // "type": "paragraph",
 * //   "content": [
 * //       {
 * //           "type": "text",
 * //           "text": "applewood"
 * //       }
 * //   ]
 * // }
 * // Second node (text node):
 * // {
 * //   "type": "text",
 * //   "text": "applewood"
 * // }
 * // returns an array of the text node texts
 * [ "applewood" ]
 *
 * @example <caption>Portion of a word selected</caption>
 * // ewo (3 middle letters of "applewood")
 * // First node (NOT text node) so ignored:
 * // {
 * // "type": "paragraph",
 * //   "content": [
 * //       {
 * //           "type": "text",
 * //           "text": "ewo"
 * //       }
 * //   ]
 * // }
 * // Second node (text node):
 * // {
 * //   "type": "text",
 * //   "text": "ewo"
 * // }
 * // returns an array
 * [
 *  "ewo"
 * ]
 *
 * @example <caption>One paragraph selected</caption>
 * // This is the first paragraph text.
 * // First node (NOT text node) so ignored:
 * // {
 * // "type": "paragraph",
 * //   "content": [
 * //       {
 * //           "type": "text",
 * //           "text": "This is the first paragraph text."
 * //       }
 * //   ]
 * // }
 * // Second node (text node):
 * // {
 * //   "type": "text",
 * //   "text": "This is the first paragraph text."
 * // }
 * // returns an array of the text node texts
 * [ "This is the first paragraph text." ]
 *
 * @example <caption>Two paragraphs selected</caption>
 * // First paragraph text.
 * // Second paragraph text.
 * // First node (NOT text node) so ignored:
 * // {
 * // "type": "paragraph",
 * //   "content": [
 * //       {
 * //           "type": "text",
 * //           "text": "First paragraph text."
 * //       }
 * //   ]
 * // }
 * // Second node (text node):
 * // {
 * //   "type": "text",
 * //   "text": "First paragraph text."
 * // }
 * // Third node (NOT text node) so ignored:
 * // {
 * // "type": "paragraph",
 * //   "content": [
 * //       {
 * //           "type": "text",
 * //           "text": "Second paragraph text."
 * //       }
 * //   ]
 * // }
 * // Fourth node (text node):
 * // {
 * //   "type": "text",
 * //   "text": "Second paragraph text."
 * // }
 * // returns an array of the paragraph texts
 * [
 *  "First paragraph text.",
 *  "Second paragraph text.",
 * ]
 *
 * @param {Object} selection Prosemirror selection, may contain multiple nodes
 * @returns {array} an array of the selected text in each node
 */
export function textInSelection (selection) {
  const selectedTextInAllNodes = [];
  // selection.content() returns a Fragment
  selection.content().content.descendants((node) => {
    if (node.isText) {
      selectedTextInAllNodes.push(node.text);
    }
  });
  return selectedTextInAllNodes;
}

/**
 * Determine whether there is any text in a selection.
 * @param {Object} selection Prosemirror selection
 * @returns {Boolean}
 */
export function hasTextInSelection (selection) {
  const [text] = textInSelection(selection);

  return !!text;
}

/**
 * Counts a block type in the document
 * used for e.g. to determine the number of power words nodes
 * before and after a prosemirror transformation
 * @param {Object} state Prosemirror state
 * @param {string} blockType e.g. 'powerWord_block'
 * @returns {number}
 */
export function countBlockType (state, blockType) {
  let count = 0;

  state.doc.descendants((node) => {
    if (node.type.name === blockType) {
      count++;
    }
  });

  return count;
}

/**
 * Returns the Power Word block type nodes in the document
 * for the given Power Word ID or a given wordForm
 * @param {Object} doc Prosemirror document
 * @param {string} powerWordId
 * @param {string} wordForm
 * @returns {Array} array of Power Word block type nodes added of the nodes position in the document
 */
export function getPowerWordsNodes (doc, powerWordId, wordForm) {
  const powerWordsNodes = [];

  doc.descendants((node, pos) => {
    if (node.type.name === 'powerWord_block') {
      const content = doc.content?.textBetween(pos, pos + node.nodeSize);
      if (node.attrs.id === powerWordId || (wordForm && content?.toLowerCase().trim() === wordForm.toLowerCase().trim())) {
        /* eslint-disable-next-line no-param-reassign */
        node.pos = pos;
        powerWordsNodes.push(node);
      }
    }
  });

  return powerWordsNodes;
}

/**
 * Determine whether we're deleting text in a Prosemirror transaction.
 * @param {Transaction} tr
 * @returns {Boolean}
 */
export function isDeletingText (tr) {
  if (!tr.docChanged) {
    // If the doc hasn't changed, return early.
    return false;
  }

  let deletionFound = false;

  // Per a ProseMirror conversation about preventing node deletion
  // https://discuss.prosemirror.net/t/how-to-prevent-node-deletion/130/9
  // Look at each step in the transaction.
  tr.steps.forEach((step, index) => {
    const range = tr.mapping.maps[index].ranges;
    const oldSize = range[1];
    const newSize = range[2];
    // Go through each stepmap, and use the positions to check whether any
    // step deleted text.
    if (oldSize > newSize) {
      deletionFound = true;
    }
  });

  return deletionFound;
}

export const contentNodeIsSelected = (node, state) => state.selection.node && state.selection.node.attrs.contentId === node.attrs.contentId;

export const updatePlaceholder = (view, text) => {
  if (!view || !text) { return; }
  const doc = view.state.doc;
  const hasNoChildren = doc.childCount === 0;
  const isEmptyTextBlock =
    doc.childCount === 1 && doc.firstChild.isTextblock && doc.firstChild.content.size === 0;

  if (hasNoChildren || isEmptyTextBlock) {
    view.dom.setAttribute('data-placeholder', text);
    return;
  }

  view.dom.removeAttribute('data-placeholder');
};
