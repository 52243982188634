import React, { useRef, useEffect, createRef } from 'react';

import { Button } from '@newsela/angelou';
import cuid from 'cuid';
import PropTypes from 'prop-types';

import QuestionOptionSchema from '@client/common/schema/question-option';
import MultipleChoiceOption from '@client/forms/components/MultipleChoiceOption';
import { getFieldValidation, getCaption } from '@client/utils/form-validation';

import { $button } from './style';

export default function MultipleChoice ({ value, name, config, onChange, parent, validatedContent, fieldPath }) {
  value = value || []; // Cast empty values to an array.
  // We want to keep track of the updates we make to the form value as the user edits assessment options
  // but we don't need to trigger rerenders of the component when we set that value. This is a good use
  // case for the useRef hook over useState.
  const batchedUpdates = useRef(value);

  // When the form value updates (when information returns from requests to the server), we want
  // batchedUpdates to reflect those changes.
  useEffect(() => {
    batchedUpdates.current = value;
  }, [value]);

  const optionRef = createRef();
  // Unless we set an empty object, useEffect ignores it.
  optionRef.current = {};

  // Focus new option input when the user clicks the "Add option" button
  useEffect(() => {
    if (optionRef?.current?.focus) {
      optionRef.current.focus();
    }
  }, [optionRef.current]);

  // Adds a new blank option, updates the server and optimistic response
  const onAddOption = () => {
    const id = cuid();
    const newOption = QuestionOptionSchema.defaults(id);
    const optimisticData = [...batchedUpdates.current, newOption.client];

    batchedUpdates.current = optimisticData;
    onChange(
      { options: [newOption.server] },
      { options: optimisticData },
      'set',
      false // explicitly prevents this mutation from debouncing
    );
  };

  // Deletes option,  updates the server and optimistic response
  const onDelete = (option) => {
    const optimisticData = batchedUpdates.current.filter((item) => item.uid !== option.uid);
    batchedUpdates.current = optimisticData;
    onChange(
      { options: [{ uid: option.uid }] },
      { options: optimisticData },
      'unset',
      false // explicitly prevents this mutation from debouncing
    );
  };

  // Handles text input change, updates the server and optimistic response
  const onProsemirrorInput = (option) => {
    const uid = option.uid;
    return (serverData, optimistic) => {
      const updatedOption = {
        ...option,
        ...optimistic
      };

      // Use the current value of batchedUpdates as the basis from which to create our optimisticData
      const prevValue = batchedUpdates.current;
      const optimisticData = prevValue.map((item) => item.uid === uid ? updatedOption : item);
      batchedUpdates.current = optimisticData;

      // Construct an array of options that includes changes to the currently edited option, in addition to the optimistic data for other options.
      // This ensures that debounced updates for this form group contains all the changes for each prosemirror input.
      const newServerData = optimisticData.map((item) => {
        return (item.uid === uid ? { uid, ...serverData } : { uid: item.uid, text: item.rawText });
      });

      onChange(
        { options: newServerData },
        { options: optimisticData }
      );
    };
  };

  // Correct answer logic, updates the server and optimistic response.
  const onSelectCorrectAnswer = (option) => {
    const newValues = [...batchedUpdates.current];
    const optimisticData = newValues.map((item) => ({
      ...item,
      isCorrect: option.uid === item.uid ? !option.isCorrect : item.isCorrect
    }));
    batchedUpdates.current = optimisticData;

    onChange(
      // Include the position here, so it doesn't get changed on the server.
      { options: [{ uid: option.uid, position: option.position, isCorrect: !option.isCorrect }] },
      { options: optimisticData },
      'set',
      false // explicitly prevents this mutation from debouncing
    );
  };

  const renderAddOption = () => (
    <div css={{ textAlign: 'center' }}>
      <Button
        type='button'
        onClick={() => onAddOption()}
        /* AUTOGENERATED TODO: update angelou to new flavor.
          see https://github.com/newsela/angelou/blob/main/src/components/Button/README.md#MIGRATION
          for migration guide. */
        legacy_flavor={Button.legacy_flavor.solid}
        legacy_size={Button.legacy_size.justified}
        __cssFor={{ root: $button }}
        // Users can't add options after publication.
        disabled={parent.hasBeenPublished}
      >
        Add Option
      </Button>
    </div>
  );

  const renderOptions = () => {
    return value.map((option) => {
      // If an option has a temporary/optimistic uid, add the disabled flag to its Prosemirror config.
      // This prevents users from editing a record until its persisted in the database.
      const textConfig = option.uid.match(/^_:/) ? { ...config.textConfig, isDisabled: true } : config.textConfig;

      const fieldPathQuestionOption = `${fieldPath}.${option.position - 1}`;
      // Filter validation to this specific assessment question.
      const { errors, warnings } = getFieldValidation(validatedContent, { id: parent.id }, fieldPathQuestionOption, true);
      // const { variant, message } = getAggregateMessage(errors, warnings, { name: 'option', isMultiple: false });
      const { variant, caption } = getCaption(errors, warnings, { caption: null }) || null;
      return (
        <MultipleChoiceOption
          key={option.uid}
          name={name}
          value={option}
          config={{ ...config, textConfig }}
          onChange={onProsemirrorInput(option)}
          onDelete={() => onDelete(option)}
          onSelectCorrectAnswer={() => onSelectCorrectAnswer(option)}
          variant={variant}
          validationMessage={caption}
          ref={optionRef}
          hasBeenPublished={parent.hasBeenPublished}
        />
      );
    });
  };

  return (
    <div>
      {renderOptions()}
      {renderAddOption()}
    </div>

  );
}

MultipleChoice.propTypes = {
  /** Field value, from the form-level state */
  value: PropTypes.array,
  /** Field name, which is also the property the data will be saved to */
  name: PropTypes.string,
  /** Full configuration object */
  config: PropTypes.object,
  /** Function that updates the form state and persists data */
  onChange: PropTypes.func,
  /** Parent object, used to get the id of the assessment */
  parent: PropTypes.shape({
    id: PropTypes.string,
    uid: PropTypes.string,
    field: PropTypes.string,
    fieldType: PropTypes.string,
    hasBeenPublished: PropTypes.bool
  }),
  /** Errors and warnings when the component gets validated */
  validatedContent: PropTypes.object,
  /** Field path, used to get the validation errors and warnings */
  fieldPath: PropTypes.string
};

// We're calling this component MultipleChoice because the 'Group' part will
// prevent Mineral-UI's FormField from wrapping the entire thing in a <label>,
// allowing us to get around the native label issues with multiple child buttons
// (when hovering or clicking any input, that behavior is transmitted to the first
// button).
MultipleChoice.displayName = 'MultipleChoiceGroup';
