import { noop } from 'lodash-es';
import { Plugin, PluginKey } from 'prosemirror-state';

import { gradeBandToNumber } from '@client/utils/fields';

import decorateWords from './decorate-words';
import { passThroughTransaction } from './helpers';

// Exported for testing.
export const VOCAB_LEVELS = {
  q1: 'vocabLevelQ1',
  q2: 'vocabLevelQ2',
  q3: 'vocabLevelQ3',
  q4: 'vocabLevelQ4'
};

/**
 * Get the gradeBand and return the className related
 * Ex.: GRADE_COLLEGE => vocabLevelQ4
 * Exported for testing.
 * @param {string} gradeBand
 * @returns {string} vocabLevelGroup
 */
export function getVocabLevelGroup (gradeBand) {
  switch (gradeBand) {
    case 'GRADE_K':
    case 'GRADE_1':
    case 'GRADE_2':
    case 'GRADE_3':
    case 'GRADE_4':
      return VOCAB_LEVELS.q1;
    case 'GRADE_5':
    case 'GRADE_6':
    case 'GRADE_7':
      return VOCAB_LEVELS.q2;
    case 'GRADE_8':
    case 'GRADE_9':
    case 'GRADE_10':
      return VOCAB_LEVELS.q3;
    case 'GRADE_11':
    case 'GRADE_12':
    case 'GRADE_COLLEGE':
      return VOCAB_LEVELS.q4;
    default:
      return VOCAB_LEVELS.q1;
  }
}

/**
 * Function that is passed into decorateWords. Adds the correct class and
 * attributes when decorating words.
 * @param {object} vocabLevel with { gradeBand }
 * @returns {object}
 */
function getObjectToAdd (vocabLevel) {
  return {
    class: `vocabLevel ${getVocabLevelGroup(vocabLevel.gradeBand)}`,
    'vocab-level': gradeBandToNumber(vocabLevel.gradeBand)
  };
}

/**
 * Generate vocabLevel decorations.
 * @param {object} vocabLevel from query
 * @returns {Function} that returns a new DecorationSet
 */
function decorationsForVocabLevel (vocabLevels) {
  return (state) => {
    return decorateWords(state, vocabLevels, true, getObjectToAdd);
  };
}

/**
 * Function that runs when transactions are dispatched. If the transaction
 * concerns the vocab level, we run the decoration function.
 * @param {object} tr
 * @param {object} oldState
 * @returns {object}
 */
function applyState (tr, oldState) {
  const updatedVocabLevels = tr.getMeta('vocabLevels');

  if (updatedVocabLevels) {
    return decorationsForVocabLevel(updatedVocabLevels)(tr);
  } else {
    // Pass through the current state during all other transactions.
    return passThroughTransaction(tr, oldState);
  }
}

// This is a VocabLevel plugin that handles highlighting  VocabLevels.
// It updates the decorations when vocabLevels change.
export const vocabLevelPlugin = new Plugin({
  // Reference to this VocabLevelPlugin
  key: new PluginKey('vocabLevelPlugin'),
  state: {
    // When initializing, don't create decorators for vocabLevels.
    init: noop,
    // When we've dispatched a transaction with the 'vocabLevel' meta, update
    // the decorators with new VocabLevels.
    apply: applyState
  },
  props: {
    // Set decorators based on the current plugin state.
    decorations: (state) => vocabLevelPlugin.getState(state),
  }
});
