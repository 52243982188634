import React from 'react';

import { Button } from '@newsela/angelou';
import PropTypes from 'prop-types';

import { success } from '@client/utils/toast';

import { $copyTextContainer, $copyContainer } from './style';
/**
 * Text that can be copied.
 */
export default function CopyText ({ text, customCopyText, actionText }) {
  const copyToClipboard = () => {
    navigator.clipboard.writeText(customCopyText || text);
    success('Text copied successfully', {
      hideAfter: 2.5,
    });
  };

  return (
    <div css={$copyContainer}>
      <div css={$copyTextContainer}>{text}</div>
      <Button onClick={copyToClipboard} flavor={Button.flavors.secondary}>
        {actionText || 'Copy'}
      </Button>
    </div>
  );
}

CopyText.propTypes = {
  text: PropTypes.string.isRequired,
  customCopyText: PropTypes.string,
  actionText: PropTypes.string,
};
