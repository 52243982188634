import { css } from '@emotion/core';
import { typographyStyles } from '@newsela/angelou';

import { Z_INDEX_9999_MAX } from '@client/utils/constants';

export const $loadingContainer = css`
  display: block;
  position: sticky;
  text-align: center;
  top: 50%;
  z-index: 1;
  `;

export const $loadingContainerSmall = css`
  display: flex;
  align-items: center;
  justify-content: center;
  `;

export const $smallSpinner = css`
  display: flex;
  width: 28px;
  margin: 12px;
  `;

export const $loadingWrapper = (setHeight, setWidth) => {
  return ({ bodyHeight }) => css`
  align-items: center;
  background: #fff;
  display: flex;
  height: ${setHeight || bodyHeight};
  width: ${setWidth || 'auto'};
  justify-content: center;
  position: absolute;  
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: ${Z_INDEX_9999_MAX};
  `;
};

export const $loadingModal = (small) => {
  return () => css`
  background: #fff;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0px 16px 40px rgba(0, 0, 0, 0.32);
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: ${Z_INDEX_9999_MAX};
  padding: ${small ? '10px 20px' : '20px 72px 40px 72px'};
  `;
};

export const $spinnerLabel = css`
  ${typographyStyles.fontFamily.circular};
  ${typographyStyles.fontSize.textS};
`;
