import { action } from 'easy-peasy';

export default {
  // We're storing a few Bundle-specfic things. This is merged into the main
  // store (common/store.js) under 'bundles'.
  currentContentIDs: {
    // Store a list of the current IDs for all pieces of content we're currently
    // editing. Since duplicates are NOT allowed in the Outline, this will be
    // used to filter the results we get back when using the Content Picker.
    data: [],

    update: action((state, payload) => {
      state.data = payload;
    })
  }
};
