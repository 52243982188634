import { css } from '@emotion/core';

export const $value = ({ typography }) => typography.size.M;

export const $image = css`
  height: 200px;
  object-fit: cover;
  width: 300px;
`;

export const $noValue = ({ colors, typography }) => [
  typography.size.M,
  css`
    color: ${colors.ui.grey[300]};
    font-style: italic;
  `
];
