import React from 'react';

import { useStoreState } from 'easy-peasy';
import PropTypes from 'prop-types';
import DatePicker from 'react-datepicker';

import { getArticlePublicationChange } from '@client/utils/article-publications';
import { getLiteProductGrantChange } from '@client/utils/lite-product-grant';
import { logError } from '@client/utils/log';
import { toEasternTime, toUTCString } from '@client/utils/time';

import 'react-datepicker/dist/react-datepicker.min.css';
import { $root, $input } from './style';

export const DATE_OR_TIME_ERROR = "You wanted a datepicker but you don't want to pick either dates or times. What are you expecting to happen here?"; // eslint-disable-line

const getOptions = (config, name) => {
  const showDate = config.showDate !== false;
  const showTime = config.showTime !== false;
  const options = {
    ...config.min && { minDate: toEasternTime(config.min) },
    ...config.max && { maxDate: toEasternTime(config.max) }
  };

  if (showDate && showTime) {
    return {
      ...options,
      showTimeSelect: true,
      timeFormat: 'h:mm aaaaa',
      dateFormat: 'M/d/yy \'at\' h:mm a'
    };
  } else if (showDate) {
    return {
      ...options,
      dateFormat: 'M/d/yy'
    };
  } else if (showTime) {
    return {
      ...options,
      showTimeSelect: true,
      showTimeSelectOnly: true,
      dateFormat: 'h:mm a'
    };
  } else {
    logError(`Cannot render datepicker for ${name}`, new Error(DATE_OR_TIME_ERROR));
    return null;
  }
};

function getOnSelectArticlePublication (articlePublications, language, name, onChange) {
  return (value) => {
    const newDate = value ? toUTCString(value) : null;
    const changeType = value ? 'set' : 'unset';
    const change = getArticlePublicationChange(articlePublications, language, newDate);

    if (change) {
      onChange(
        { [name]: change.server },
        { [name]: change.optimisticData },
        changeType
      );
    }
  };
}

function getOnSelectLiteProductGrant (liteProductGrants, propertyName, name, onChange) {
  return (value) => {
    const changeType = value ? 'set' : 'unset';
    const newDate = value ? toUTCString(value) : null;
    const change = getLiteProductGrantChange(liteProductGrants, propertyName, newDate);

    if (change) {
      onChange(
        { [name]: change.server },
        { [name]: change.optimisticData },
        changeType
      );
    }
  };
}

export default function DateAndTime ({ value, name, config, onChange, variant, requiredText }) {
  // The DateAndTime component's behavior can differ depending on the field it's operating
  // against. We're specifically edge casing out article publications and lite product grants,
  // but this pattern could be extended to cover other divergent behavior as well. First and
  // foremost, let's set some default properties for standard behavior.
  const options = getOptions(config, name);
  const user = useStoreState((state) => state.user);
  if (!options) {
    return null;
  }
  let datepickerProps = {
    css: $input(variant),
    disabled: false,
    name,
    onChange: (val) => {
      if (val) {
        // Convert from eastern time to UTC.
        onChange({ [name]: toUTCString(val) });
      } else {
        onChange({ [name]: null }, 'unset');
      }
    },
    selected: value,
    ...options,
  };

  // Now, set our overrides for fields backed by models that needed to be treated
  // differently than a standard datetime/string field.
  if (name === 'articlePublications') {
    datepickerProps = {
      ...datepickerProps,
      disabled: !requiredText,
      onChange: getOnSelectArticlePublication(value, config.language, name, onChange),
      selected: !requiredText ? '' : config.transform(value, config.language),
      placeholderText: !requiredText ? config.placeholder : '',
    };
  } else if (name === 'liteProductGrants') {
    const propertyName = config.validationName.replace('liteProductGrants.', '');
    datepickerProps = {
      ...datepickerProps,
      disabled: user?.permissions?.editLiteProductGrants !== true,
      selected: config.transform(value, propertyName),
      onChange: getOnSelectLiteProductGrant(value, propertyName, name, onChange)
    };
  }

  // Any value we have in the selected prop -- representing the form field's value --
  // should be converted into Eastern Time.
  if (datepickerProps.selected) {
    datepickerProps.selected = toEasternTime(datepickerProps.selected);
  }

  return (
    <div css={$root}>
      <DatePicker {...datepickerProps} />
    </div>
  );
}

DateAndTime.propTypes = {
  /** Field value, from the form-level state */
  value: PropTypes.oneOfType([
    PropTypes.instanceOf(Date),
    PropTypes.array,
    PropTypes.string
  ]),
  /** Field name, which is also the property the data will be saved to */
  name: PropTypes.string,
  /** Full configuration object */
  config: PropTypes.object,
  /** Function that updates the form state and persists data */
  onChange: PropTypes.func,
  variant: PropTypes.string,
  requiredText: PropTypes.string
};
