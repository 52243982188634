import { css } from '@emotion/core';

import { getBorderColors } from '@client/utils/styles';

export const $files = css`
  margin: 10px 0;
`;

export const $noFiles = ({ colors }) => css`
  color: ${colors.ui.greyLight[500]};
  font-style: italic;
`;

export const $fileInfo = css`
  align-items: center;
  display: flex;
  jusify-content: flex-start;
  margin: 10px 0;
  padding: 0 2px;
  width: 100%;
`;

export const $fileName = ({ colors }) => css`
  color: ${colors.ui.greyDark[500]};
  margin-left: 10px;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;

export const $spacer = css`
  flex: 1 1 auto;
`;

export const $button = ({ colors }) => css`
  flex: 0 1 auto;

  svg[role='img'] {
    color: ${colors.ui.greyDark[500]};
  }

  &:hover {
    background-color: ${colors.ui.greyLight[100]};
  }
`;

export const $dropZone = (isDragActive, variant) => (theme) => {
  const [
    borderColor,
    borderColorHover,
    borderColorActive
  ] = getBorderColors(theme, variant);
  const base = css`
    align-items: center;
    border-radius: 4px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    padding: 20px;

    &:hover {
      border: 2px dashed ${borderColorHover};
    }

    &:active {
      border: 2px dashed ${borderColorActive};
    }

    & > * {
      flex: 0 1 auto;
      margin: 5px;
    }
  `;

  const active = css`
    border: 2px dashed ${borderColorActive};
  `;

  const inactive = css`
    border: 2px dashed ${borderColor};
  `;

  return isDragActive ? [base, active] : [base, inactive];
};
