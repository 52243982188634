import React from 'react';

import Button from 'mineral-ui/Button';
import PropTypes from 'prop-types';

import { gradeBandToNumber, numberToGradeBand } from '@client/utils/fields';

import { $root, $gradeQ1, $gradeQ2, $gradeQ3, $gradeQ4 } from './style';

const GRADES = ['K', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13'];

function getVocabLevelGroup (grade) {
  switch (grade) {
    case 'K':
    case '1':
    case '2':
    case '3':
    case '4':
      return $gradeQ1;
    case '5':
    case '6':
    case '7':
      return $gradeQ2;
    case '8':
    case '9':
    case '10':
      return $gradeQ3;
    case '11':
    case '12':
    case '13':
      return $gradeQ4;
    default:
      return $gradeQ1;
  }
}
export default function VocabLevel ({
  selectedGrades = [],
  showVocabButtons,
  dispatchTransaction
}) {
  const selectedGradeNumbers = selectedGrades.map(gradeBandToNumber);

  const toggleGradeLevel = (grade) => {
    let grades = selectedGradeNumbers;

    // Toggle the grade on or off.
    if (!grades.includes(grade)) {
      grades.push(grade);
    } else {
      grades = grades.filter((g) => g !== grade);
    }

    // Convert the grade numbers back to GradeBand enums,
    // then send them up to VocabSelector and Prosemirror.
    dispatchTransaction(grades.map(numberToGradeBand), true);
  };

  return (
    <div css={$root} className={showVocabButtons ? 'show' : ''}>
      {
        GRADES.map((grade) => (
          <Button
            key={grade}
            type='button'
            className={selectedGradeNumbers.includes(grade) ? 'active' : ''}
            onClick={() => toggleGradeLevel(grade)}
            css={getVocabLevelGroup(grade)}
          >{grade}
          </Button>
        ))
      }
    </div>
  );
}

VocabLevel.propTypes = {
  /** Currently selected grade bands (not just numbers) */
  selectedGrades: PropTypes.array,
  /** Value to hide/show the button for each grade */
  showVocabButtons: PropTypes.bool,
  /** Callback to update the selected grades in VocabSelector and
   * dispatch the transaction to Prosemirror */
  dispatchTransaction: PropTypes.func
};
