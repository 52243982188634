import React from 'react';

import { useStoreState } from 'easy-peasy';
import PropTypes from 'prop-types';

import PowerWord from '@client/forms/components/PowerWord';
import { titleCaseSpaced } from '@client/utils/cases';

import { $powerWordListWrapper } from './style';

export default function PowerWordList ({ formData }) {
  const onRemoveWord = useStoreState((state) => state.drawer?.data?.onRemoveWordCallback);
  const currentArticleLevel = formData.articleLevels[0];
  const powerWords = currentArticleLevel.powerWords;
  const wordsToList = powerWords.map((powerWord) => ({
    ...powerWord,
    gradeBand: titleCaseSpaced(powerWord.gradeBand).toLowerCase()
  }));

  const powerWordAssessment = formData.attached
    .find((attachment) => attachment.assessmentType === 'POWER_WORDS_ACTIVITY');
  const assessment = {
    ...powerWordAssessment,
    // Overwrite the levels to only include the level we care about
    levels: powerWordAssessment?.levels
      // Power Word Assessment level corresponding to current Article Level
      .filter((level) => level.gradeBand === currentArticleLevel.gradeBand)
  };

  const removeWord = async ({ wordForm, wordDefinition }) => {
    // Update easy-peasy store with removed definition. This will trigger
    // a callback in the Prosemirror input which removes the mark and re-fetches
    // the list of power words.
    onRemoveWord({
      articleId: formData.id,
      articleUid: formData.uid,
      levelUid: currentArticleLevel.uid,
      assessment,
      wordForm,
      wordDefinition
    });
  };

  const { activePowerWords, suggestedPowerWords } = wordsToList.reduce((acc, word) => {
    if (word.wordDefinition) {
      acc.activePowerWords.push(word);
    } else {
      acc.suggestedPowerWords.push(word);
    }
    return acc;
  }, { activePowerWords: [], suggestedPowerWords: [] });

  return (
    <div data-testid='power-words' css={$powerWordListWrapper}>
      {[...activePowerWords, ...suggestedPowerWords].map((powerWord) => (
        <PowerWord
          key={powerWord.uid}
          wordUid={powerWord.uid}
          wordForm={powerWord.wordForm}
          gradeBand={powerWord.gradeBand}
          wordDefinition={powerWord.wordDefinition}
          removeWord={removeWord}
        />
      ))}
    </div>
  );
}

PowerWordList.propTypes = {
  /** Field value, from the form-level state */
  formData: PropTypes.object
};

PowerWordList.displayName = 'PowerWordListGroup';
