import React from 'react';

import { isString, identity, isNil } from 'lodash-es';
import PropTypes from 'prop-types';

import { getLabel } from '@client/utils/fields';

import { $value, $image, $noValue } from './style';

export default function Static ({ value, config }) {
  const transform = config.transform || identity;

  // Transform the value if we've configured a transform function
  value = transform(value);

  if (config.transformToComponent) {
    return value;
  }

  if (!isNil(value) && !isString(value)) {
    // Cast the transformed value to a string if we're passed a number or boolean, etc.
    value = value.toString();
  }

  if (config.isLink && value) {
    return <a css={$value} href={value} rel='noopener noreferrer' target='_blank'>{value}</a>;
  } else if (config.isImage && value) {
    return <img css={$image} src={value} />;
  } else if (value) {
    return <span css={$value} dangerouslySetInnerHTML={{ __html: value }} />; // eslint-disable-line
  } else {
    return <span css={$noValue}>No {getLabel(config).toLowerCase()}</span>;
  }
}

Static.propTypes = {
  /** Field value, from the form-level state */
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.array,
    PropTypes.object
  ]),
  /** Full configuration object */
  config: PropTypes.object
};
