import React, { useState, useCallback, useEffect } from 'react';

import { useStoreState } from 'easy-peasy';
import { identity, debounce } from 'lodash-es';
import TextArea from 'mineral-ui/TextArea';
import PropTypes from 'prop-types';

import AttachedButton from '@client/forms/components/AttachedButton';
import { TEXT_DEBOUNCE_TIME } from '@client/utils/constants';

import { $root, $button, $textarea } from './style';

function getButton (config, formData, onButtonUpdate) {
  return config.button ? <AttachedButton config={config} onUpdate={onButtonUpdate} formData={formData} /> : null;
}

export default function TextAreaInput ({ value, name, config, onChange, formData, variant }) {
  const [text, setText] = useState(value || ''); // Cast empty values to a string.

  // For most fields, optimistic mutations update appropriately but for fields where the user is typing in real-time,
  // optimistic updates are slow enough to cause input lag. These fields (Text, TextArea, Prosemirror) require their onChange to be debounced.
  // The addition of the useCallback hook prevents the debounced onChange from being recreated on every render,
  // which would prevent the onChange from properly debouncing.
  const debouncedOnChange = useCallback(debounce(onChange, TEXT_DEBOUNCE_TIME), [formData]);
  const transform = config.transform || identity;

  const refreshForm = useStoreState((state) => state.forms.formRefresh.nonce);

  // Reset text when changing drawers and when the form updates from the server.
  useEffect(() => {
    setText(value || '');
  }, [formData?.id, formData?.uid, refreshForm]);

  const onTextInput = (e) => {
    if (config.isReadOnly) { return; }

    const val = transform(e.target.value);
    setText(val);
    debouncedOnChange({ [name]: val });
  };

  const onButtonUpdate = (value, test) => {
    onTextInput({ target: { value } });
  };

  const button = getButton(config, formData, onButtonUpdate);

  return (
    <div css={$root}>
      <TextArea
        css={$textarea(!!button)}
        placeholder={config.placeholder}
        value={text}
        name={name}
        {...variant && { variant }}
        autoSize
        rows={1}
        readOnly={config.isReadOnly}
        onChange={onTextInput}
      />
      <div css={$button}>{button}</div>
    </div>
  );
}

TextAreaInput.propTypes = {
  /** Field value, from the form-level state */
  value: PropTypes.string,
  /** Field name, which is also the property the data will be saved to */
  name: PropTypes.string,
  /** Full configuration object */
  config: PropTypes.object,
  formData: PropTypes.object,
  /** Function that updates the form state and persists data */
  onChange: PropTypes.func,
  variant: PropTypes.string
};
