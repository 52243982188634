import { faQuestionSquare } from '@fortawesome/pro-light-svg-icons';
import gql from 'graphql-tag';

import rawSchema from '@client/schema';
import { titleCase } from '@client/utils/cases';
import {
  createAssessmentQuestion,
  QUESTION_MULTIPLE_CHOICE,
  QUESTION_WRITE_PROMPT,
  QUESTION_POWER_WORD_ASSOCIATION,
  QUESTION_POWER_WORD_EXAMPLE
} from '@client/utils/create-assessments';

import Content from './content';
import MetadataStandard from './metadataStandard';
import MetadataTag from './metadataTag';
import QuestionOption from './question-option';
import Tag from './tag';
import WordDefinition from './word-definition';

// Since write prompts don't include multiple choice inputs, we need to insert
// the multiple choice input after the question prompt when we're rendering a
// multiple choice form. So we define the question (and the shared inputs)
// separately and put the MultipleChoice or Power Word fields between the question
// and the inputsAfter array.
// See `multipleChoiceForm` for reference.
const questionField = {
  input: 'prosemirror',
  name: 'question',
  value: 'rawQuestion',
  isMultiline: true,
  formats: ['bold', 'italic', 'quote'],
  required: true
};

const genreField = {
  input: 'radio',
  name: 'genre',
  options: rawSchema.enums.Genre.map((genre) => ({
    label: titleCase(genre),
    value: genre
  }))
};

const inputsAfter = [
  // Even though Assessment Questions are not Content, they have some of the
  // same fields, so we pull the field configs directly from the Content schema.
  Content.inputs.notes,
  Tag.inputs.tags,
  MetadataTag.inputs.metadataTags,
  MetadataStandard.inputs.metadataStandards,
  Content.inputs.id
];

// Inserts the multiple choice input after the question prompt input
// so it shows in the correct order in the drawer.
const multipleChoiceForm = [
  genreField,
  questionField,
  {
    input: 'multiple-choice',
    name: 'options',
    hasMultipleCorrectAnswers: false,
    textConfig: {
      formats: ['bold', 'italic'],
      name: 'text',
      value: 'rawText'
    }
  },
  {
    input: 'prosemirror',
    name: 'rationale',
    secondaryText: 'Students can view after submission',
    value: 'rawRationale',
    isMultiline: true,
    formats: ['bold', 'italic', 'link', 'orderedList', 'quote']
  },
  ...inputsAfter
];

// Inserts the power word input after the question prompt input
// so it shows in the correct order in the drawer.
const powerWordForm = [
  {
    input: 'static',
    name: 'wordDefinition',
    transform: (val) => `<strong>${val.wordForm}</strong> - <span>${val.definition || 'No definition selected'}</span>`,
    label: 'Word Definition'
  },
  genreField,
  questionField,
  {
    input: 'multiple-choice',
    name: 'options',
    hasMultipleCorrectAnswers: false,
    textConfig: {
      formats: ['bold', 'italic'],
      name: 'text',
      value: 'rawText'
    }
  },
  ...inputsAfter
];

// Write prompt questions only have the question and the shared fields,
// but the question gets a magic button so editors can quickly add default prompts.
const writePromptForm = [
  genreField,
  {
    ...questionField,
    // On write prompts, we add a magic button to enter in the default
    // ELA write prompt.
    button: {
      input: 'magic-button',
      value: () => 'Write two paragraphs explaining the main idea of this article',
      description: 'Default ELA Prompt'
    }
  },
  ...inputsAfter
];

const AssessmentQuestion = {
  // When fetching questions themselves (e.g. when editing them in the drawer),
  // don't fetch position, since that's not in the context of the level they're in.
  // TODO: Consolidate word definition fragments into a client-wide WordDefinition schema.
  fullFragment: gql`
    fragment fullAssessmentQuestion on AssessmentQuestion {
      id
      uid
      updatedAt
      certicaId
      questionType
      name
      question(format: TEXT_PLAIN)
      rawQuestion: question(format: TEXT_RAW)
      htmlQuestion: question(format: TEXT_HTML)
      notes(format: TEXT_PLAIN)
      rawNotes: notes(format: TEXT_RAW)
      genre
      tags { ...fullTag }
      metadataTags { ...fullMetadataTag }

      metadataStandards { ...fullMetadataStandard }
      ...on MultipleChoice {
        rationale(format: TEXT_PLAIN)
        rawRationale: rationale(format: TEXT_RAW)
        options { ...fullQuestionOption position }
      }

      ...on PowerWordAssociation {
        wordDefinition { ...fullWordDefinition }
        options { ...fullQuestionOption position }
      }

      ...on PowerWordExample {
        wordDefinition { ...fullWordDefinition }
        options { ...fullQuestionOption position }
      }
    }
    ${QuestionOption.fullFragment}
    ${Tag.fullFragment}
    ${MetadataTag.fullFragment}
    ${MetadataStandard.fullFragment}
    ${WordDefinition.fullFragment}
  `,
  defaults: (id, data) => {
    // NOTE: When adding fields to questions, make sure to also add them to the
    // defaults in @client/utils/create-assessments.js
    return createAssessmentQuestion(id, data);
  },
  isContent: false,
  icon: faQuestionSquare,
  typename: 'Assessment Question',
  // For assessment questions, we render distinct forms based on their
  // question type. They are keyed by the questionType value.
  forms: {
    [QUESTION_MULTIPLE_CHOICE]: multipleChoiceForm,
    [QUESTION_POWER_WORD_ASSOCIATION]: powerWordForm,
    [QUESTION_POWER_WORD_EXAMPLE]: powerWordForm,
    [QUESTION_WRITE_PROMPT]: writePromptForm
  }
};

export default AssessmentQuestion;
