/* eslint-disable camelcase */
import { css } from '@emotion/core';
import { constants } from '@newsela/angelou';

import { DRAWER_INNER_WIDTH } from '@client/utils/constants';
import { getVariantColor } from '@client/utils/styles';

const cardHeight = 115;

export const $root = (hasClickHandler, variant) => ({
  colors,
  backgroundColor,
  borderRadius_2
}) => {
  let border = `1px solid ${colors.ui.greyLight[100]};`;
  let margin = '0 0 20px';

  if (variant) {
    border = `2px solid ${getVariantColor(variant)}`;
    margin = '0 0 20px';
  }

  return css`
    align-items: center;
    background-color: ${backgroundColor};
    border: ${border};
    border-radius: ${borderRadius_2};
    cursor: ${hasClickHandler ? 'pointer' : 'default'};
    display: flex;
    flex-flow: row;
    height: ${cardHeight}px;
    justify-content: flex-start;
    margin: ${margin};
    max-width: ${DRAWER_INNER_WIDTH}px;
    padding: 0;
    position: relative;
    width: 100%;
    &:focus {
      outline: ${colors.ui.coreBlue[700]} auto 1px;
    }
  `;
};

export const $left = ({ colors, typography, borderRadius_4 }) => [
  typography.family.circular,
  typography.size.XXL,
  css`
    align-items: center;
    background: ${colors.biGradient.static};
    background: ${colors.biGradient.dynamic};
    border-bottom-left-radius: ${borderRadius_4};
    border-top-left-radius: ${borderRadius_4};
    color: ${colors.ui.white[500]};
    display: flex;
    flex: 0 0 ${cardHeight}px;
    height: ${cardHeight}px;
    justify-content: center;
    width: ${cardHeight}px;

    & svg {
      height: 100%;
    }

    & > img {
      border-bottom-left-radius: ${borderRadius_4};
      border-top-left-radius: ${borderRadius_4};
      height: 100%;
      object-fit: cover;
      width: 100%;
    }
  `
];

export const $right = () => {
  return css`
    align-items: flex-start;
    display: flex;
    flex: 0 1 calc(100% - ${cardHeight}px);
    flex-flow: row wrap;
    justify-content: flex-start;
    min-width: 0;
    padding: 0 20px;
  `;
};

export const $title = css`
  flex: 0 0 100%;
  padding: 0;
  width: 100%;

  & > span {
    width: 100%;
  }
`;

export const $titleInner = ({ colors, typography }) => [
  typography.family.circular,
  typography.size.M,
  css`
    color: ${colors.ui.greyDark[500]};
    font-weight: 700;
    margin: 0;
    overflow: hidden;
    padding: 0;
    text-align: left;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
    cursor: pointer;
    border: none;
    background-color: transparent;
  `
];

export const $label = ({ colors, typography }) => [
  typography.family.circular,
  typography.size.XS,
  css`
    color: ${colors.ui.greyLight[500]};
    flex: 0 0 100%;
    font-weight: 700;
    letter-spacing: 1px;
    margin: 0 0 2px;
    overflow: hidden;
    padding: 0;
    text-align: left;
    text-overflow: ellipsis;
    text-transform: uppercase;
    white-space: nowrap;
  `
];

export const $subtitle = ({ colors, typography }) => [
  typography.family.circular,
  typography.size.XS,
  css`
    color: ${colors.ui.grey[500]};
    flex: 0 0 100%;
    margin: 0 0 2px;
    overflow: hidden;
    padding: 0;
    text-align: left;
    text-overflow: ellipsis;
    white-space: nowrap;
  `
];

export const $actionMenu = css`
  border-radius: 50%;
  color: ${constants.colors.ui.greyDark[500]};
  align-items: center;
  display: flex;
  flex: 0 0 auto;
  height: 32px;
  justify-content: flex-end;
  bottom: 5px;
  right: 5px;
  position: absolute;
  width: 32px;

  & > span:first-of-type span {
    /* Angelou buttons add space for text, but this button only has an icon */
    padding: 0;
  }
`;

export const $status = css`
  position: absolute;
  right: 10px;
  top: 10px;
`;
