import { ArticleHeaderSVG } from '@newsela/angelou';
import deline from 'deline';
import gql from 'graphql-tag';

import Content from './content';
import MetadataStandard from './metadataStandard';
import MetadataTag from './metadataTag';
import Stream from './stream';
import Tag from './tag';

// All LegacyArticles technically do a quick filter, because there's only one
// thing we can search on: the header id.
function filter (query) {
  return {
    headerId: { eq: query },
    rootFn: 'headerId|eq'
  };
}

const LegacyArticle = {
  fullFragment: gql`
    fragment fullLegacyArticle on LegacyArticle {
      headerId
      headerImageUrl
      headerTitle
      headerAuthor
      headerUrl
      headerLevels
      articleId
    }
  `,
  filter,
  quickFilter: filter,
  imageSearch: (schema, query, { uid }) => ({
    variables: {
      // This filter uses two separate subqueries:
      // 1. recursively get all children of a specific bundle
      // 2. filter the results of the first query to return only LegacyArticles
      //    that have headerId fields
      rawFilter: deline`
        var(func: uid(${uid}))
          @filter(type(Content))
          @recurse(depth: 10) { c as children }
        var(func: uid(c))
          @filter(type(LegacyArticle) and has(headerId)) { v as uid }`
    }
  }),
  defaults: (id, data) => {
    const contentDefaults = Content.defaults(id, data);

    return {
      client: {
        ...contentDefaults.client,
        __typename: 'LegacyArticle',
        contentType: 'LEGACY_ARTICLE',
        contentId: null,
        name: 'Article Header',
        headerId: null,
        headerImageUrl: null,
        headerTitle: null,
        headerAuthor: null,
        headerUrl: null,
        headerLevels: null,
        articleId: null
      },
      server: {
        ...contentDefaults.server,
        contentType: 'LEGACY_ARTICLE'
      }
    };
  },
  isContent: true,
  icon: ArticleHeaderSVG,
  typename: 'Article Header',
  forms: {
    shared: [
      {
        input: 'app-button',
        name: 'articleId',
        type: 'Article',
        label: 'Edit Article'
      },
      {
        input: 'static',
        name: 'headerTitle',
        value: 'headerTitle'
      },
      {
        input: 'static',
        name: 'headerAuthor'
      },
      {
        input: 'static',
        isLink: true,
        name: 'headerUrl',
        label: 'Header URL',
        transform: (data = '') => data ? `${process.env.MONOLITH_BASE_URL}${data}` : null
      },
      {
        input: 'static',
        isImage: true,
        name: 'headerImageUrl',
        label: 'Header Image'
      },
      {
        input: 'static',
        name: 'headerLevels',
        label: 'Header Levels',
        transform: (data) => data ? data.join('<br />') : ''
      },
      Content.inputs.attached,
      Tag.inputs.tags,
      MetadataTag.inputs.metadataTags,
      MetadataStandard.inputs.metadataStandards,
      { ...Stream.inputs.customStreams, label: 'Streams' },
      Content.inputs.notes,
      Content.inputs.parentsList,
      Content.inputs.id
    ]
  }
};

export default LegacyArticle;
