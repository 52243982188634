import { faListOl } from '@fortawesome/pro-regular-svg-icons';

import { orderedList } from '@shared/prosemirror/blocks';

import {
  wrapperBlockActive,
  wrapInList,
  splitListItem,
  liftListItem,
  sinkListItem
} from './helpers';

const icon = faListOl;

export const blocks = orderedList;
export const keys = {
  Enter: splitListItem('orderedList_block_1'),
  'Shift-Ctrl-8': wrapInList('orderedList_block_0'),
  'Mod-[': liftListItem('orderedList_block_1'),
  'Mod-]': sinkListItem('orderedList_block_1')
};
export const menuBlock = {
  title: 'Numbered List',
  content: icon,
  active: wrapperBlockActive('orderedList_block_0', { order: 1 }),
  run: wrapInList('orderedList_block_0')
};
