import { constants, FolderHollowSVG } from '@newsela/angelou';
import deline from 'deline';
import gql from 'graphql-tag';
import { isString, escapeRegExp } from 'lodash-es';
import smartquotes from 'smartquotes';

import { PUBLISHABLE } from '@client/utils/constants';
import { transformSlug } from '@client/utils/fields';

import Content from './content';
import MetadataStandard from './metadataStandard';
import MetadataTag from './metadataTag';
import Stream from './stream';
import Tag from './tag';
import TaxonomyTag from './taxonomyTag';

function filter (query) {
  const quoted = smartquotes(query);

  return {
    or: [
      { title: { alloftext: quoted } },
      { description: { alloftext: quoted } },
      // Label and slug do not use smart quotes.
      { label: { allofterms: query } },
      { slug: { regexp: `/${escapeRegExp(query)}/i` } }
    ]
  };
}

function quickFilter (query) {
  const quoted = smartquotes(query);

  return {
    title: { alloftext: quoted },
    rootFn: 'title|alloftext'
  };
}

// Bundles and Smart Bundles have the same label options.
const labelOptions = [
  'Text Set',
  'Collection',
  'Unit',
  'Topic',
  'Instructional Set',
  'Row'
].sort(); // Sort them alphabetically.

// Groups of content
const bundlesGroup = {
  heading: 'Add Bundle',
  menuItems: [
    'Bundle',
    'SmartBundle'
  ]
};

const articlesGroup = {
  heading: 'Add Article',
  menuItems: [
    'LegacyArticle'
  ]
};

const otherContentGroup = {
  heading: 'Add Media',
  menuItems: [
    'ExternalLink'
  ]
};

const openSearch = {
  index: 'bundle',
  fields: ['title^10', 'name^7', 'slug^5', 'description^3'],
  sort: [
    {
      updated_at: {
        order: 'desc'
      }
    }
  ],
  // If the user included a query we disable the sort so we don't affect the ranking.
  disableSortOnQuery: true,
  _source: ['id', 'uid', 'content_type', 'name', 'thumbnail', 'status', 'updated_at', 'events', 'attached', 'streams', 'label', 'parents_count', 'updated_by'],
  mapping: {
    contentType: 'content_type',
    updatedAt: 'updated_at',
    createdAt: 'created_at'
  }
};

// Bundle uses the same form as 'shared' and 'app'. Additionally, many bundle
// fields are shared with SmartBundle.
const titleField = {
  input: 'prosemirror',
  name: 'title',
  value: 'rawTitle',
  formats: ['italic']
};
const labelField = {
  input: 'select',
  name: 'label',
  defaultValue: 'Text Set',
  isCreatable: true,
  options: labelOptions
};
const slugField = {
  input: 'text',
  name: 'slug',
  transform: (data = '') => transformSlug(data),
  button: {
    input: 'magic-button',
    // Get text from raw prosemirror if we haven't persisted it to the server
    // and received plain text back yet
    value: (data) => isString(data.title)
      ? data.title
      : data.title.textContent,
    description: 'Copy title'
  }
};
const descriptionField = {
  input: 'prosemirror',
  name: 'description',
  value: 'rawDescription',
  isMultiline: true,
  formats: ['bold', 'italic', 'list', 'orderedList', 'link']
};
const formConfig = [
  titleField,
  labelField,
  { // This saves as scalar ids
    input: 'select',
    name: 'legacyHeaderImageId',
    secondaryText: 'Choose a header image from associated articles',
    hasImage: true,
    isAsync: true,
    preloadOptions: true,
    type: 'LegacyArticle',
    query: 'legacyHeaderImages',
    search: 'imageSearch',
    mapping: {
      image: 'headerImageUrl',
      value: 'headerId'
    },
    initialMapping: { // Mapping between our data and react-select values
      image: 'legacyHeaderImage',
      value: 'legacyHeaderImageId'
    }
  },
  slugField,
  descriptionField,
  { section: 'Metadata' },
  Content.inputs.attached,
  Tag.inputs.tags,
  MetadataTag.inputs.metadataTags,
  TaxonomyTag.inputs.taxonomyTags,
  MetadataStandard.inputs.metadataStandards,
  Stream.inputs.subjectProductStreams,
  Stream.inputs.customStreams,
  Content.inputs.notes,
  Content.inputs.parentsList,
  Content.inputs.id
];

const Bundle = {
  previewFragment: gql`
    fragment previewBundle on Bundle {
      label
      legacyHeaderImage
      legacyHeaderTitle
      description(format: TEXT_PLAIN)
      htmlDescription: description(format: TEXT_HTML)
    }
  `,
  blueprintFragment: gql`
    fragment blueprintBundle on Bundle {
      label
      rawTitle: title(format: TEXT_RAW)
      rawDescription: description(format: TEXT_RAW)
    }
  `,
  // Include htmlDescription so that Apollo's cache will update when saving data.
  // This will trigger the preview to show the updated htmlDescription.
  fullFragment: gql`
    fragment fullBundle on Bundle {
      label
      title(format: TEXT_PLAIN)
      rawTitle: title(format: TEXT_RAW)
      description(format: TEXT_PLAIN)
      htmlDescription: description(format: TEXT_HTML)
      rawDescription: description(format: TEXT_RAW)
      slug
      legacyHeaderImage
      legacyHeaderImageId
      legacyHeaderTitle
    }
  `,
  filter,
  includeChildBundles: true,
  quickFilter,
  statusSelector: true,
  defaults: (id, data) => {
    const contentDefaults = Content.defaults(id, data);

    return {
      client: {
        ...contentDefaults.client,
        __typename: 'Bundle',
        contentType: 'BUNDLE',
        name: 'Text Set',
        label: 'Text Set',
        // All fields need to be specified here, as they're used
        // to optimistically update the apollo-client cache.
        title: null,
        rawTitle: null,
        description: null,
        htmlDescription: null,
        rawDescription: null,
        slug: null,
        legacyHeaderImage: null,
        legacyHeaderImageId: null,
        legacyHeaderTitle: null,
        children: [],
        taxonomyTags: [],
      },
      server: {
        ...contentDefaults.server,
        contentType: 'BUNDLE'
      }
    };
  },
  isContent: true,
  icon: FolderHollowSVG,
  typename: 'Bundle',
  openInApp: true,
  app: {
    icon: FolderHollowSVG,
    color: constants.colors.ui.greyDark[700],
    accentColor: constants.colors.ui.grey[700],
    title: 'Bundles',
    initialDrawer: {
      drawerType: PUBLISHABLE,
      customDrawerTitle: 'Details',
      formKey: 'app' // For bundles, app and shared are the same form.
    },
    href: '/bundles',
    permissionForAdmin: true,
    permissionForStaff: true,
    permissionForContributor: true,
    columns: [
      {
        value: 'id',
        showColumn: false
      },
      {
        value: 'uid',
        showColumn: false
      },
      {
        label: 'Title',
        value: 'name',
        isClickable: true
      },
      'label',
      {
        value: 'streams',
        list: 'name'
      },
      {
        value: 'status',
        enum: true
      },
      {
        label: 'Updated On',
        value: 'updatedAt',
        date: true
      },
      {
        label: 'Updated By',
        value: 'updatedBy',
        list: 'name'
      }
    ],
    order: { desc: 'updatedAt' },
    rawFilter: (id) => deline`var(func: eq(updatedByIds, ${id})) @filter(type(Event)) {
      ~events @filter(type(Content) and type(Bundle) and eq(count(~children), 0) and not eq(visibility, -1)) {
        v as uid
      }
    }`,
    openSearch
  },
  forms: {
    shared: formConfig,
    app: formConfig
  },
  inputs: {
    title: titleField,
    label: labelField,
    slug: slugField,
    description: descriptionField
  },
  // Types that we can include as children of bundles. Add content types here
  // when we want to include them as bundle children.
  availableChildrenGroups: [
    bundlesGroup,
    articlesGroup,
    otherContentGroup
  ]
};

export default Bundle;
