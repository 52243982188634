/* eslint-disable camelcase */
import { css } from '@emotion/core';

export const $root = ({
  borderColor,
  borderColor_theme,
  borderColor_theme_active,
  borderColor_theme_focus
}) => css`
  margin: 0 8px 30px;
  width: calc(100% - 16px);

  & .rc-slider-rail {
    background-color: ${borderColor};
  }

  & .rc-slider-track {
    background-color: ${borderColor_theme};
  }

  & .rc-slider-dot {
    border-color: ${borderColor};
  }

  & .rc-slider-dot-active,
  & .rc-slider-handle {
    border-color: ${borderColor_theme};
  }

  & .rc-slider-handle:active {
    box-shadow: 0 0 5px ${borderColor_theme_active};
  }

  & .rc-slider-handle:focus {
    box-shadow: 0 0 0 2px ${borderColor_theme_focus};
  }
`;
