import { faBooks } from '@fortawesome/pro-light-svg-icons';
import { constants } from '@newsela/angelou';
import cuid from 'cuid';
import gql from 'graphql-tag';
import { escapeRegExp } from 'lodash-es';
import smartquotes from 'smartquotes';

import { REGEX_SEARH_MIN } from '@client/utils/constants';
import { transformSlug } from '@client/utils/fields';

import Content from './content';
import MultilingualText from './multilingual-text';

function filter (query) {
  const quoted = smartquotes(query);

  return {
    ...query.length <= REGEX_SEARH_MIN
      ? {
          // If the query is 3 or fewer characters long, do a term filter.
          or: [
            { providerTitle: { allofterms: quoted } },
            { copyright: { allofterms: quoted } },
            { slug: { regexp: `/${escapeRegExp(query)}/i` } }
          ]
        }
      : {
          // If the query is longer than three characters, we can do a more accurate
          // regexp filter.
          or: [
            { providerTitle: { regexp: `/${escapeRegExp(quoted)}/i` } },
            { copyright: { allofterms: quoted } },
            { slug: { regexp: `/${escapeRegExp(query)}/i` } }
          ]
        }
  };
}

function quickFilter (query) {
  const quoted = smartquotes(query);

  return {
    ...query.length <= REGEX_SEARH_MIN
      ? {
          // If the query is 3 or fewer characters long, do a term filter.
          providerTitle: { allofterms: quoted },
          rootFn: 'providerTitle|allofterms'
        }
      : {
          // If the query is longer than three characters, we can do a more accurate
          // regexp filter.
          providerTitle: { regexp: `/${escapeRegExp(quoted)}/i` },
          rootFn: 'providerTitle|regexp'
        }
  };
}

const ContentProvider = {
  fullFragment: gql`
    fragment fullContentProvider on ContentProvider {
      id
      uid
      name
      thumbnail
      createdAt
      updatedAt
      providerTitles {
        ...fullMultilingualText
      }
      providerDescription
      copyright
      slug
      url
      logo
      allowedInSearch
      isPublicDomain
      subproviders {
        id
        uid
        name
        thumbnail
        createdAt
        updatedAt
      }
    }
    ${MultilingualText.fullFragment}
  `,
  searchFragment: gql`
    fragment searchContentProvider on ContentProvider {
      id
      uid
      createdAt
      updatedAt
      name
      thumbnail
      slug
      copyright
      url
      subproviders { id uid name }
    }
  `,
  defaults: (id, data = {}) => {
    // eslint-disable-next-line no-param-reassign
    id = id || cuid();
    // Use existing uid if we're adding an existing content provider.
    const uid = data.uid || `_:${id}`;
    // newLabel is passed in from AsyncSelect when existing content providers
    // are selected.
    const title = data.newLabel || null;
    // When creating a new content provider, we automatically add its first title.
    // This title is in English, and is set as the original language.
    const firstProviderTitle = MultilingualText.defaults(cuid(), {
      plainText: title,
      isOriginalLanguage: true
    });

    return {
      client: {
        id,
        uid,
        __typename: 'ContentProvider',
        name: title || 'Content Provider',
        createdAt: (new Date()).toISOString(),
        updatedAt: (new Date()).toISOString(),
        providerDescription: null,
        copyright: null,
        slug: null,
        url: null,
        logo: null,
        thumbnail: null,
        allowedInSearch: true,
        isPublicDomain: false,
        subproviders: [],
        providerTitles: [firstProviderTitle.client]
      },
      server: {
        id,
        uid,
        providerTitles: [firstProviderTitle.server]
      }
    };
  },
  filter,
  quickFilter,
  app: {
    icon: faBooks,
    color: constants.colors.ui.greyDark[700],
    accentColor: constants.colors.ui.grey[700],
    title: 'Content Providers',
    href: '/content-providers',
    permissionForAdmin: true,
    permissionForStaff: true,
    permissionForContributor: false,
    columns: [
      {
        value: 'id',
        showColumn: false
      },
      {
        value: 'uid',
        showColumn: false
      },
      {
        label: 'Title',
        value: 'name',
        isClickable: true
      },
      {
        label: 'Slug',
        value: 'slug'
      },
      {
        label: 'Copyright',
        value: 'copyright'
      },
      {
        label: 'Url',
        value: 'url'
      }
    ],
    order: { asc: 'providerTitle' }
  },
  icon: faBooks,
  typename: 'Content Provider',
  openInApp: false,
  forms: {
    // Content providers are ONLY edited in their own app, so they do not have
    // a 'shared' form.
    app: [
      {
        input: 'multilingual-text',
        name: 'providerTitles',
        label: 'Title',
        originalLanguageLabel: 'Original (content provider’s official title)',
        showLabel: false
      },
      {
        input: 'text',
        name: 'slug',
        transform: (data = '') => transformSlug(data),
        button: {
          input: 'magic-button',
          value: (data) => data.name,
          description: 'Copy title'
        }
      },
      {
        input: 'text',
        name: 'copyright',
        label: 'Copyright'
      },
      {
        input: 'text',
        name: 'url',
        label: 'Content Provider URL'
      },
      {
        input: 'checkbox',
        name: 'isPublicDomain',
        longLabel: 'Is Public Domain',
        showLabel: false,
        caption: 'Is this provider considered a public entity?'
      },
      {
        input: 'editor-list',
        name: 'subproviders',
        label: 'Sub-Providers',
        addText: 'Add Sub-Provider',
        editorTypes: ['ContentProvider'],
        emptyMessage: 'This content provider doesn’t have any sub-providers.'
      },
      {
        input: 'file',
        name: 'logo',
        label: 'Logo',
        accept: 'image/*'
      },
      {
        input: 'textarea',
        name: 'providerDescription',
        label: 'Description'
      },
      {
        input: 'checkbox',
        name: 'allowedInSearch',
        longLabel: 'Allow to be Indexed?',
        showLabel: false,
        caption: 'Allow content from this provider to be indexed by external search providers?'
      },
      // Content providers are not Content, but they do have an ID field.
      // We pull this in from the Content schema.
      Content.inputs.id
    ]
  },
  inputs: {
    contentProvider: {
      input: 'select',
      name: 'contentProvider',
      label: 'Content Provider',
      isAsync: true,
      isRelation: true,
      type: 'ContentProvider',
      query: 'contentProviders',
      showDropdown: false,
      mapping: {
        label: 'name',
        value: 'uid'
      }
    }
  }
};

export default ContentProvider;
