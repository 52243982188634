/* eslint-disable camelcase */
import { css } from '@emotion/core';
import { constants } from '@newsela/angelou';

// The ButtonWithPopOut component `__cssFor` prop takes in a style object, not a
// css function so we're creating a colors variable explicitly from
// Angelou to be able to use colors in the style object below.
const { colors } = constants;

export const $root = ({ colors }) => css`
  border: 1px solid ${colors.ui.greyLight[100]};
  border-radius: 4px;
  padding: 1rem 0.5rem 2rem;
  background-color: #fff;
`;

export const $button = ({ colors }) => css`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  font-size: 16px;
`;

export const $icon = css`
  margin-right: 10px;
  & path {
    stroke-width: 3px;
  }
`;

export const $buttonContainer = (isEmpty) => css`
  display: flex;
  flex-direction: column;
  align-items: ${isEmpty ? 'center' : 'none'};
`;

export const $popOutMenu = `
  width: 100%;
  z-index: 100;
  & div:not(:first-of-type) {
    padding-top: 16px;
  }
`;

export const $popOutButton = css`
  background-color: ${colors.ui.darkBlue[700]};
  color: ${colors.ui.white[700]};
  padding-bottom: 12px;
  padding-top: 12px;
  & span {
    margin: 0;
  }
`;

export const $cardContainer = css`
  margin-bottom: 20px;
`;

export const $message = ({ colors, typographyStyles }) => [
  typographyStyles.heading3,
  typographyStyles.fontFamily.circular,
  css`
  color: ${colors.ui.greyDark[500]};
  margin-bottom: 20px;
  font-weight: bold;
  text-align: center;
`];

export const $nothingToSeeImage = css`
  width: 80%;
`;

export const $messageContainer = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 20px;
`;
