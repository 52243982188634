import React, { useState, useEffect } from 'react';

import PropTypes from 'prop-types';
import { Title, Meta } from 'react-head';
import SVGIcon from 'react-svg-favicon';

import { APP_NAME } from '@client/utils/constants';

/**
 * Check if the matchMedia API is supported.
 * @returns {Boolean} true if the matchMedia API is supported
 */
function matchMediaIsSupported () {
  return 'matchMedia' in window;
}

/**
 * Get the desired favicon color based on the current theme.
 * If the user has a dark theme enabled, we want to use a white favicon, and vice versa.
 * @param {Boolean} isDarkTheme if not provided, we query the matchMedia API
 * @returns {String} faviconColor: 'white' or 'black'
 */
function getFaviconColor (isDarkTheme) {
  let isCurrentThemeDark;
  // If the parameter is not provided, we query the matchMedia API
  if (isDarkTheme === undefined) {
    isCurrentThemeDark = matchMediaIsSupported()
      ? window.matchMedia('(prefers-color-scheme: dark)').matches
      : false; // Default to false (black favicon) if matchMedia is not supported
  } else {
    isCurrentThemeDark = isDarkTheme;
  }
  return isCurrentThemeDark ? 'white' : 'black';
}

export default function PageMetadata ({
  title = APP_NAME,
  faviconObj,
  FaviconSVG,
  thumbnail,
  contentProvider
}) {
  // Set initial value of faviconColor state based on user's current theme
  // If matchMedia is not supported, we default to black favicon
  const [faviconColor, setFaviconColor] = useState(getFaviconColor());
  const hasFavicon = faviconObj || FaviconSVG;

  /**
   * Change favicon color based on OS theme (dark or light)
   * @param {Object} event with { matches: true/false }
   */
  function changeFaviconColor (event) {
    const isDarkTheme = event.matches;
    const newFaviconColor = getFaviconColor(isDarkTheme);
    setFaviconColor(newFaviconColor);
  }

  useEffect(() => {
    if (hasFavicon && matchMediaIsSupported()) {
      const colorSchemeQuery = window.matchMedia('(prefers-color-scheme: dark)');
      colorSchemeQuery.addEventListener('change', changeFaviconColor);
      return () => {
        colorSchemeQuery.removeEventListener('change', changeFaviconColor);
      };
    }
  }, []);

  return (
    <>
      <Title>{title}</Title>
      <Meta property='og:title' content={title} />
      <Meta name='twitter:title' content={title} />

      {hasFavicon && (
        <SVGIcon>
          {FaviconSVG ? <FaviconSVG fill={faviconColor} /> : (
            <svg viewBox={`0 0 ${faviconObj.width} ${faviconObj.height}`}>
              <path d={faviconObj.svgPathData} fill={faviconColor} />
            </svg>
          )}
        </SVGIcon>
      )}

      {thumbnail && (
        <>
          <Meta property='og:image' content={thumbnail} />
          <Meta name='twitter:card' content='summary_large_image' />
          <Meta name='twitter:image' content={thumbnail} />
        </>
      )}

      {contentProvider && (
        <>
          <Meta name='twitter:label1' content='Content Provider: ' />
          <Meta name='twitter:data1' content={contentProvider} />
        </>
      )}
    </>
  );
}

PageMetadata.propTypes = {
  /** Text to be used as the page title. */
  title: PropTypes.string,
  /** Shape to be used as a SVG favicon. */
  faviconObj: PropTypes.shape({
    width: PropTypes.number,
    height: PropTypes.number,
    svgPathData: PropTypes.string
  }),
  /** Directly passed-in Angelou icon. */
  FaviconSVG: PropTypes.object,
  /** URL to the thumbnail image. */
  thumbnail: PropTypes.string,
  /** Name of the content provider. */
  contentProvider: PropTypes.string
};
