import ls from 'local-storage';

/**
 * Set the unreleased flag in localstorage, based on the url.
 * Use ?unreleased=true to toggle it on, and ?unreleased=false to toggle it off.
 * @returns {boolean} true if the flag is present
 */
export function isUnreleased () {
  const params = new URLSearchParams(window.location.search.substring(1));

  if (params.get('unreleased') === 'true') {
    // Only toggle on if it's explicitly set to true.
    ls.set('unreleased', true);
  }

  if (params.get('unreleased') === 'false') {
    // Only toggle off if it's explicitly set to false.
    ls.set('unreleased', false);
  }

  return ls.get('unreleased');
}
