import { faQuoteLeft } from '@fortawesome/pro-regular-svg-icons';

import { quote } from '@shared/prosemirror/blocks';

import { wrapIn, wrapperBlockActive } from './helpers';

const icon = faQuoteLeft;
export const block = quote;
export const keys = {
  'Ctrl->': wrapIn('quote_block')
};
export const menuBlock = {
  title: 'Wrap in Block Quote',
  content: icon,
  active: wrapperBlockActive('quote_block'),
  run: wrapIn('quote_block')
};
