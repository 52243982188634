import { isEmpty, sortBy } from 'lodash-es';

import { VARIANT_ERROR, VARIANT_WARNING } from '@client/utils/constants';
import { formatGradeBand, sortGradeBands } from '@client/utils/fields';
import { getFieldPathPrefix, hasIntersection, getFieldValidation } from '@client/utils/form-validation';

/**
 * Get an array of levels for a given language, filtering out inactive levels.
 * @param {Object} levels all article levels keyed by language
 * @param {String} language
 * @returns {Array} activeLevels for the given language
 */
export function getActiveLevels (levels, language) {
  const languageLevels = levels[language];

  return languageLevels ? languageLevels.filter((level) => level.isActive) : [];
}

/**
 * Determines whether a language has active levels.
 * @param {Object} levels keyed by language
 * @returns {Function} run against each language to filter it.
 */
export function hasActiveLevels (levels) {
  return (language) => {
    const activeLevels = getActiveLevels(levels, language);

    return !isEmpty(activeLevels);
  };
}

/**
 * Maps a language to its article levels field paths.
 * @param {Object} levels ex: { LANG_EN: [{ uid: 0x1d, language: LANG_EN }], LANG_ES: [{ uid: 0x2f, language: LANG_ES }] }
 * @param {Object} uidFieldPaths ex: { 0x1d: articleLevels.0, 0x2f: articleLevels.8 }
 * @returns {Object} languagesFieldPath ex: { LANG_EN: [articleLevels.0], LANG_ES: [articleLevels.8] }
 */
export function getLanguagesFieldPath (levels, uidFieldPaths) {
  // Gathers all the article level objects (from all languages) into a single array.
  const languageLevels = Object.values(levels).flat();

  return languageLevels.reduce((languagesFieldPath, level) => {
    const language = level.language;
    const fieldPath = uidFieldPaths[level.uid];
    if (language in languagesFieldPath) languagesFieldPath[language].push(fieldPath);
    else languagesFieldPath[language] = [fieldPath];
    return languagesFieldPath;
  }, {});
}

/**
 * Gets the variant for each language.
 * @param {Object} validationData ex: {
 *    errors: [{ location: { field: articleLevels.0.question } }],
 *    warnings: [{ location: { field: articleLevels.2.title } }]
 *  }
 * @param {Object} languagesFieldPaths ex: { LANG_EN: [articleLevels.0], LANG_ES: [articleLevels.2] }
 * @returns {Object} languagesVariant ex: { LANG_EN: danger, LANG_ES: warning }
 */
export function getLanguagesVariant (validationData, languagesFieldPaths) {
  // Collects only the fieldPath ('articleLevels.0') from the field ('articleLevels.0.title').
  const errorFieldPaths = validationData.errors.map(getFieldPathPrefix);
  const warningFieldPaths = validationData.warnings.map(getFieldPathPrefix);

  // Set the variant for each language.
  return Object.entries(languagesFieldPaths).reduce((languagesVariant, languageFieldPaths) => {
    const [language, fieldPaths] = languageFieldPaths;
    const hasError = hasIntersection(fieldPaths, errorFieldPaths);
    const hasWarning = hasIntersection(fieldPaths, warningFieldPaths);

    if (hasError) languagesVariant[language] = VARIANT_ERROR;
    else if (hasWarning) languagesVariant[language] = VARIANT_WARNING;
    else languagesVariant[language] = null;
    return languagesVariant;
  }, {});
}

/**
 * Gets the language selector button variant.
 * @param {Object} languagesVariant ex: { LANG_EN: danger, LANG_ES: warning }
 * @param {String} currentLanguage ex: LANG_EN
 * @returns {String|null} variant: danger, warning or null
 */
export function getLanguageButtonVariant (languagesVariant, currentLanguage) {
  // Gets only the variants of non-current languages.
  const otherLanguagesVariants = Object.entries(languagesVariant)
    .filter(([language]) => language !== currentLanguage)
    .map(([language, langVariant]) => langVariant);
  // Return the language selector button variant.
  if (otherLanguagesVariants.includes(VARIANT_ERROR)) return VARIANT_ERROR;
  if (otherLanguagesVariants.includes(VARIANT_WARNING)) return VARIANT_WARNING;
  return null;
}

/**
 * Group active levels by language, and sort them by grade band.
 * @param {Array} levels article levels
 * @returns {Object} with arrays of active levels keyed by language
 */
export function groupLevels (levels) {
  return (levels || []).reduce((acc, level) => {
    const language = level.language;

    // Filter out inactive levels.
    if (!level.isActive) {
      return acc;
    }

    // Add the language if it doesn't exist.
    acc[language] = acc[language] || [];

    // Add the current level and sort all levels in the language.
    acc[language] = acc[language].concat([level]).sort(sortGradeBands);
    return acc;
  }, {});
}

/**
 * Ensure only one level per grade band.
 * @param {Array} form config
 * @param {Object} levels article levels keyed by language
 * @param {Object} currentLevel current article level
 * @returns {Array} form config to pass into InlineForm
 */
export function filterGradeBands (form, levels, currentLevel) {
  // If there isn't a current level, return early.
  if (isEmpty(levels) || !currentLevel) {
    return [];
  }

  return form.map((field) => {
    if (field.name !== 'gradeBand') {
      // If this field is not gradeBand, don't do anything.
      return field;
    }

    return {
      ...field,
      options: field.options.map((option) => {
        const currentLanguage = currentLevel.language;
        const existingLevel = levels[currentLanguage].find((level) => {
          return level.gradeBand === option;
        });
        return {
          label: formatGradeBand(option),
          value: option,
          isDisabled: !!existingLevel
        };
      })
    };
  });
}

/**
 * Get level validation with the correct fieldpaths.
 * @param {Stgring} id article id
 * @param {Object} groupedLevels article levels keyed by language and sorted by grade band
 * @param {Object} currentLevel current article level
 * @param {Object} validatedContent validated content from the server
 * @returns {Object} validation with { id, data, uidFieldPaths, currentLevelFieldPath }
 */
export function getValidation (id, groupedLevels, currentLevel, validatedContent) {
  const validation = {};

  if (!isEmpty(validatedContent?.errors) || !isEmpty(validatedContent?.warnings)) {
    // Remove the language keys and put all levels in a single array.
    const flattenedLevels = Object.values(groupedLevels).flat();
    // The server sorts the article levels by the uid as a number.
    // Here we need to do the same to get the right field path for errors and warnings.
    const allLevels = sortBy(flattenedLevels, (level) => parseInt(level.uid, 16));
    const uidFieldPaths = {};
    // Maps uids to field path.
    allLevels.forEach((level, index) => {
      uidFieldPaths[level.uid] = `articleLevels.${index}`;
    });
    // Filter articleLevels issues.
    validation.data = getFieldValidation(validatedContent, { id }, 'articleLevels', true);
    validation.uidFieldPaths = uidFieldPaths;
    validation.id = id;
    validation.currentLevelFieldPath = uidFieldPaths[currentLevel.uid];
  }

  return validation;
}
