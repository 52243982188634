import { faCode, faLaptopCode } from '@fortawesome/pro-regular-svg-icons';

import { code as codeBlock } from '@shared/prosemirror/blocks';
import { code as codeMark } from '@shared/prosemirror/marks';

import { markActive, toggleMark, blockActive, setBlockType, createNewline } from './helpers';

// Code can be inline or block-level

export const mark = codeMark;
export const block = codeBlock;
export const keys = (isMultiline) => ({
  'Mod-`': toggleMark('code'),
  ...isMultiline && {
    Enter: createNewline('code_block'),
    'Shift-Ctrl-\\': setBlockType('code_block')
  }
});
export const menuMark = {
  title: 'Toggle Code',
  content: faCode,
  active: markActive('code'),
  run: toggleMark('code')
};
export const menuBlock = {
  title: 'Change to Code Block',
  content: faLaptopCode,
  active: blockActive('code_block'),
  run: setBlockType('code_block')
};
