import {
  faRemoveFormat,
  faOutdent,
  faUndoAlt,
  faRedoAlt
} from '@fortawesome/pro-regular-svg-icons';
import {
  createParagraphNear,
  liftEmptyBlock,
  splitBlock,
  deleteSelection,
  joinBackward,
  selectNodeBackward,
  joinForward,
  selectNodeForward,
  selectAll,
  exitCode,
  joinUp,
  joinDown,
  lift,
  selectParentNode
} from 'prosemirror-commands';
import { dropCursor } from 'prosemirror-dropcursor';
import { gapCursor } from 'prosemirror-gapcursor';
import { history, undo, redo } from 'prosemirror-history';
import { smartQuotes, emDash, ellipsis, undoInputRule } from 'prosemirror-inputrules';

// eslint-disable-next-line camelcase
import { doc, paragraph, text, hard_break } from '@shared/prosemirror/blocks';

import {
  getBlock,
  setBlockType,
  isMac
} from './helpers';

/**
 * Remove all marks in selection. This removes:
 * - bold, italic, underline, other inline marks
 * - anchors (links)
 * - headings
 * This does NOT unwrap lists (as there's a dedicated button for that,
 * and implementing it here would be very complicated)
 *
 * @param  {Object} state
 * @param  {Function} dispatch
 * @return {boolean}
 */
const removeMarks = (state, dispatch) => {
  const { tr } = state;
  const { from, to } = state.selection;
  const { paragraph } = state.schema.nodes;

  // Set the block to paragraph if it is something else, e.g. heading
  tr.setBlockType(from, to, paragraph);

  // Remove any marks
  tr.removeMark(from, to);

  dispatch(tr);
  return true;
};

/**
 * Insert a hard line break, aka Shift+Enter.
 *
 * @param  {Object} state
 * @param  {Function} dispatch
 * @return {boolean}
 */
const insertBreak = (state, dispatch) => {
  const br = getBlock('hard_break', state).create();
  dispatch(state.tr.replaceSelectionWith(br).scrollIntoView());
  return true;
};

/**
 * SINGLE LINE INPUTS
 *
 * Common blocks, marks, and keys work with both single line inputs and multiline inputs.
 */

// A doc containing a single paragraph with a text node is the most
// basic structure of all prosemirror documents, including 'inline' documents.
export const blocks = {
  doc,
  paragraph,
  text
};

// Common marks that all prosemirror inputs must allow.
export const marks = {};

// Never thought a simple backspace would be the complicated, huh?
// Welcome to prosemirror!
const backspace = [undoInputRule, deleteSelection, joinBackward, selectNodeBackward];
const del = [deleteSelection, joinForward, selectNodeForward];

// Common keybindings all prosemirror inputs must allow.
export const keys = {
  Backspace: backspace,
  'Mod-Backspace': backspace,
  Delete: del,
  'Mod-Delete': del,
  'Mod-a': selectAll,
  'Mod-z': undo,
  'Shift-Mod-z': redo,
  'Mod-y': redo,
  // On Mac OS, we also include these:
  ...isMac && {
    'Ctrl-h': backspace,
    'Alt-Backspace': backspace,
    'Ctrl-d': del,
    'Ctrl-Alt-Backspace': del,
    'Alt-Delete': del,
    'Alt-d': del
  }
};

// Common menu items all prosemirror inputs must have.
export const menu = {
  marks: {
    // Remove Formatting will be hidden if no other marks are added to the menu.
    clear_marks: {
      title: 'Remove Formatting',
      content: faRemoveFormat,
      run: removeMarks
    }
  },
  blocks: {},
  insert: {},
  powerWord: {},
  vocabLevel: {},
  leveler: {},
  toggleLeveler: {},
  // Visual Undo and Redo indicators help onboard new users and provide a visual
  // shorthand reminding all users they can undo and redo any mistakes.
  history: {
    undo: {
      title: 'Undo',
      content: faUndoAlt,
      run: undo
    },
    redo: {
      title: 'Redo',
      content: faRedoAlt,
      run: redo
    }
  }
};

// Common plugins all prosemirror inputs must have.
export const plugins = [
  dropCursor(),
  history()
];

// Common inputs rules all prosemirror inputs must have.
export const rules = [
  ...smartQuotes,
  ellipsis,
  emDash
];

/**
 * MULTILINE INPUTS
 *
 * Multiline inputs allow for more variety in nodes, marks, keys, and input rules.
 */

// Common blocks all multiline prosemirror inputs must allow.
export const multiBlocks = {
  ...blocks,
  // eslint-disable-next-line camelcase
  hard_break
};

// Common keybindings all multiline prosemirror inputs must allow.
export const multiKeys = {
  ...keys,
  Enter: [
    createParagraphNear,
    liftEmptyBlock,
    splitBlock],
  'Mod-Enter': [exitCode, insertBreak],
  'Shift-Enter': insertBreak,
  'Ctrl-Enter': [exitCode, insertBreak],
  'Alt-ArrowUp': joinUp,
  'Alt-ArrowDown': joinDown,
  'Mod-BracketLeft': lift,
  Escape: selectParentNode,
  'Shift-Ctrl-0': setBlockType('paragraph')
};

// Common menu items all multiline prosemirror inputs must have.
export const multiMenu = {
  marks: { ...menu.marks },
  blocks: {
    ...menu.blocks,
    // When outdenting a list or blockquote, it eventually gets converted back
    // into a paragraph.
    // e.g. P → UL → UL will outdent to P → UL, then P
    // e.g. P → Blockquote → UL will outdent to P → Blockquote, then P
    lift: {
      title: 'Outdent',
      content: faOutdent,
      run: lift
    }
  },
  insert: { ...menu.insert },
  powerWord: { ...menu.powerWord },
  leveler: { ...menu.leveler },
  toggleLeveler: { ...menu.toggleLeveler },
  vocabLevel: { ...menu.vocabLevel },
  history: { ...menu.history }
};

// Common plugins all multiline prosemirror inputs must have.
export const multiPlugins = [
  ...plugins,
  gapCursor()
];

// Common input rules all multiline prosemirror inputs must allow.
export const multiRules = [
  ...rules
];
