import { faTag } from '@fortawesome/pro-light-svg-icons';
import cuid from 'cuid';
import gql from 'graphql-tag';

const TaxonomyTag = {
  searchFragment: gql`
        fragment searchTaxonomyTag on TaxonomyTag {
            id
            name
            tagTitle
            tagDescription
            tagDisplayName
            tagType
            hierarchy
            createdAt
            updatedAt
        }
    `,
  fullFragment: gql`
        fragment fullTaxonomyTag on TaxonomyTag {
            id
            name
            tagTitle
            tagDescription
            tagDisplayName
            tagType
            hierarchy
            origin
            createdAt
            updatedAt
        }
    `,
  defaults: (id, data = {}) => {
    const tagId = id || cuid();
    const tagTitle = data.newLabel || null;

    return {
      client: {
        id: tagId,
        __typename: 'TaxonomyTag',
        tagType: 'TAXONOMY_TAG',
        name: tagTitle || 'TaxonomyTag',
        tagTitle,
        tagDescription: null,
        tagDisplayName: null,
        hierarchy: [],
        origin: 'HUMAN',
        createdAt: (new Date()).toISOString(),
        updatedAt: (new Date()).toISOString(),
      },
      server: {
        id,
        tagTitle
      }
    };
  },
  icon: faTag,
  typename: 'TaxonomyTag',
  openInApp: false,
  forms: {
    // TaxonomyTags are sourced from Langston and are not edited in Alexandria
  },
  inputs: {
    taxonomyTags: {
      input: 'select',
      name: 'taxonomyTags',
      label: 'Taxonomy Tags',
      isMulti: true,
      isAsync: true,
      isRelation: true,
      isMetadataService: true,
      type: 'TaxonomyTag',
      query: 'searchTaxonomyTags',
      showDropdown: false,
      mapping: {
        label: 'tagTitle',
        value: 'id',
        type: 'tagType',
        path: 'hierarchy',
      }
    }
  }
};

export default TaxonomyTag;
