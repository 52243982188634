const IMAGE_DIMENSION_ERROR = 'Error getting image dimensions. Please try again, or try a different file.';

export async function getImageDimensions (file) {
  return new Promise((resolve, reject) => {
    const url = URL.createObjectURL(file);
    const img = new Image();
    img.src = url;
    img.onload = () => {
      URL.revokeObjectURL(img.src);
      resolve({ width: img.width, height: img.height });
    };
    img.onerror = () => {
      URL.revokeObjectURL(img.src);
      reject(new Error(IMAGE_DIMENSION_ERROR));
    };
  });
}
