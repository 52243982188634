import { get, isEmpty } from 'lodash-es';
import { Plugin } from 'prosemirror-state';
import { DecorationSet, Decoration } from 'prosemirror-view';

import { formatLexile } from '@client/utils/lexile';
import { NO_LEXILE } from '@shared/constants';

import { passThroughTransaction } from './helpers';

/**
 * Determine if a prosemirror node is any type that we'd want to hide.
 * Exported for testing.
 * @param {String} itemType
 * @returns {boolean}
 */
export function shouldDisplayLexile (itemType) {
  return ![
    // List of blocks that should NOT have their score displayed.
    'code_block',
    'hard_break',
    'headings_block',
    'image_block',
    'ignore_block',
    'pre_block'
  ].includes(itemType);
}

/**
 * Generate per-paragraph lexile decorations. Exported for testing.
 * @param {object} lexilePerParagraph
 * @returns {Function} that returns a new DecorationSet
 */
export function decorationsForLexile (lexilePerParagraph) {
  return (state) => {
    if (!isEmpty(lexilePerParagraph)) {
      const nodes = state.doc.content.content;
      const nodeSizes = state.doc.content.content.map((node) => node.nodeSize);
      const isInlineImage = (node) => node.attrs && !node.attrs.isFullWidth && node.type.name === 'image_block';
      const isEmptyBlock = (node) => node.type.name !== 'image_block' && node.content && isEmpty(node.content.content);
      let lexilePerParagraphIdx = 0;

      return DecorationSet.create(state.doc, nodes.map((node, nodeIdx) => {
        const blockType = get(state, `doc.content.content[${nodeIdx}].type.name`);
        const startPosition = nodeSizes.slice(0, nodeIdx).reduce((acc, size) => acc + size, 0);

        return Decoration.widget(startPosition, () => {
          const div = document.createElement('div');
          if (!isInlineImage(node)) {
            div.classList.add('lexileContainer');
            const paragraph = lexilePerParagraph[lexilePerParagraphIdx];
            const paragraphLexile = paragraph?.lexile || formatLexile(paragraph?.rawLexile);
            const aside = document.createElement('aside');
            aside.classList.add('lexileScore');
            div.appendChild(aside);

            if (!isEmptyBlock(node)) {
              if (paragraphLexile && paragraphLexile !== NO_LEXILE && shouldDisplayLexile(blockType)) {
                aside.textContent = paragraphLexile;
              } else {
                aside.textContent = 'N/A';
              }
              lexilePerParagraphIdx++;
            } else {
              aside.textContent = 'N/A';
            }
          }
          return div;
        });
      }));
    }
  };
}

/**
 * Function that runs when transactions are dispatched. If the transaction
 * concerns the lexile level, we run the decoration function.
 * @param {object} tr
 * @param {object} oldState
 * @returns {object}
 */
function applyState (tr, oldState) {
  const updatedLexileScores = tr.getMeta('lexile');

  if (updatedLexileScores) {
    return decorationsForLexile(updatedLexileScores)(tr);
  } else {
    // Pass through the current state during all other transactions.
    return passThroughTransaction(tr, oldState);
  }
}

/**
 * Function to instantiate the plugin. This gets passed formData.lexilePerParagraph
 * so it can decorate the paragraphs with lexile scores when Prosemirror loads.
 * @param {array} lexilePerParagraph
 * @returns {Plugin}
 */
export function createPerParagraphLexilePlugin (lexilePerParagraph) {
  // This is a Prosemirror plugin that handles our Lexile decorations. It creates
  // decorations for our per-paragraph lexile scores initially, and updates
  // the decorations when formData.lexilePerParagraph changes.
  const perParagraphPlugin = new Plugin({
    state: {
      // When initializing, create decorators for per-paragraph lexile scores.
      init: decorationsForLexile(lexilePerParagraph),
      // When we've dispatched a transaction with the 'lexile' meta, update
      // the decorators with new lexile scores.
      apply: applyState
    },
    props: {
      // Set decorators based on the current plugin state.
      decorations: (state) => perParagraphPlugin.getState(state)
    }
  });

  return perParagraphPlugin;
}
