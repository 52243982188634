import React, { useState } from 'react';

import { useApolloClient } from '@apollo/client';
import { constants } from '@newsela/angelou';
import debounce from 'debounce-promise';
import { useStoreState, useStoreActions } from 'easy-peasy';
import { isFunction, isArray, isEmpty } from 'lodash-es';
import { themed } from 'mineral-ui/themes';
import Tooltip from 'mineral-ui/Tooltip';
import pluralize from 'pluralize';
import PropTypes from 'prop-types';
import ReactSelect, { components as selectComponents } from 'react-select';
import Async from 'react-select/async';
import AsyncCreatable from 'react-select/async-creatable';
import Creatable from 'react-select/creatable';

import LoadingSpinner from '@client/common/components/LoadingSpinner';
import * as schemas from '@client/common/schema';
import { titleCaseSpaced } from '@client/utils/cases';
import { TAG_CONFIG } from '@client/utils/constants';
import { logError } from '@client/utils/log';
import { getSelectStyles } from '@client/utils/styles';

// Helper functions that allow us to load data and format options.
import {
  selectOption,
  deselectOption,
  clearOptions,
  createOption
} from './handlers';
import {
  formatDefaultValue,
  getSelectType,
  loadAsyncOptions,
  loadSyncOptions
} from './loaders';
// Helper functions that allow us to handle user input.
import {
  $option,
  $image,
  $tagOption,
  $tagOptionCodes,
  $tagType,
  $derivativeStandards,
  $derivativeStandardsChip,
  $path,
  $chipLabel,
  $chipDerivativeStandardsIcon,
  $menuList,
  $optionConfidenceRating
} from './style';

// This styles the custom tooltip.
const { ui } = constants.colors;
const CustomTooltip = themed(Tooltip)({
  TooltipContent_backgroundColor: ui.greyLight[50],
  TooltipContent_borderColor: ui.greyLight[50],
  TooltipContent_color: ui.greyDark[500],
  TooltipArrow_backgroundColor: ui.greyLight[50],
  TooltipArrow_borderColor: ui.greyLight[50]
});

export function TagOption (props) {
  const { type, path, label, hasDerivedStandards, confidenceRating } = props.data;
  return (
    <span css={$tagOption}>
      <div>
        {!isEmpty(path) && <span css={$path}>{path.join(' / ') + ' / '}</span>}
        <span>{label}</span>
      </div>
      {type !== 'PUBLIC_TAG' && (
        <div css={$tagOptionCodes}>
          {confidenceRating && (
            <CustomTooltip
              content='This tag includes confidence Rating.'
            >
              <span css={$optionConfidenceRating}>{confidenceRating}%</span>
            </CustomTooltip>
          )}
          {hasDerivedStandards && (
            <CustomTooltip
              content='This tag includes derivative standards.'
            >
              <span css={$derivativeStandards}>DS</span>
            </CustomTooltip>
          )}
          <CustomTooltip
            content={titleCaseSpaced(type)}
          >
            <span css={$tagType(type)}>{TAG_CONFIG[type]?.content}</span>
          </CustomTooltip>
        </div>
      )}
    </span>
  );
}

TagOption.propTypes = {
  /** Props passed into react-select component */
  props: PropTypes.object,
  data: PropTypes.object
};
function Option (props) {
  const isSelected = false;
  const tagTypes = ['tags', 'metadataTags', 'taxonomyTags'];
  // Checks incoming props to distinguish between tag and image options.
  if (tagTypes.includes(props.selectProps.name)) {
    return (
      <selectComponents.Option {...props}>
        <TagOption {...props} />
      </selectComponents.Option>
    );
  } else {
    return (
      <selectComponents.Option {...props} css={$option(isSelected)}>
        <img src={props.data.image} css={$image} />
      </selectComponents.Option>
    );
  }
}

Option.propTypes = {
  /** Props passed into react-select component */
  props: PropTypes.object
};

// Customizable menu/label component. See: https://react-select.com/components
const MenuList = (props) => {
  return (
    <>
      {props.options?.length !== 0 &&
        <div css={$menuList}>
          <span>Recommended Taxonomy</span>
          <span>Confidence Rating</span>
        </div>}
      <selectComponents.MenuList {...props}>
        {props.children}
      </selectComponents.MenuList>
    </>
  );
};

MenuList.propTypes = {
  /** Props passed into react-select component */
  props: PropTypes.object,
  children: PropTypes.object,
  options: PropTypes.array
};

function SingleValue (props) {
  const isSelected = true;

  // This component customizes how a single selected value will show on the input
  return (
    <selectComponents.SingleValue key={props.data.value} {...props} css={$option(isSelected)}>
      <img src={props.data.image} css={$image} />
    </selectComponents.SingleValue>
  );
}

SingleValue.propTypes = {
  /** Props passed into react-select component */
  props: PropTypes.object,
  data: PropTypes.object
};

export function MultiValue (props) {
  // This component customizes how chips with multiple values will show on the input

  const { path, label, hasDerivedStandards } = props.data;
  const getLabel = () => {
    if (!isEmpty(path)) { return path.join(' / ') + label.substring('...'.length); }
    return label;
  };
  return (
    <>
      {hasDerivedStandards && (
        <CustomTooltip
          css={$chipDerivativeStandardsIcon}
          content='This tag includes derivative standards.'
        >
          <span css={$derivativeStandardsChip}>DS</span>
        </CustomTooltip>
      )}
      <CustomTooltip
        css={$chipLabel}
        content={getLabel()}
      >
        {label}
      </CustomTooltip>
    </>
  );
}

MultiValue.propTypes = {
  data: PropTypes.object
};

/**
 * Selects come in four flavors:
 * - Regular - pulls options from config.options
 * - Async - pulls options from a GraphQL query
 * - Creatable - pulls options from config.options, but allows creating new options
 * - AsyncCreatable - pulls options from GraphQL query, allows creating new options
 *
 * Use 'isAsync' and 'isCreatable' booleans in the config to determine which to use.
 *
 * Use 'isMulti' to allow for multi-select. This will save data as an array, rather
 * than a string.
 *
 * Use 'showDropdown: false' to hide the dropdown indicator (shown by default).
 *
 * If using 'isAsync' or 'isCreatable' inputs, specify 'type' to determine which
 * search() and defaults() methods should be called when querying and mutating,
 * and specify `query` to determine which query (from client/common/queries) is run
 * when loading the options asynchronously.
 *
 * You may also specify 'isClearable' to allow clearing the input.
 */
export default function Select ({ value, name, config, onChange, formData, variant }) {
  // First, determine the type of select we're using
  const selectType = getSelectType(config);
  const isMulti = !!config.isMulti;
  const isClearable = !!config.isClearable;
  const isDisabled = !!config.isDisabled && config.isDisabled({ value });
  const schema = config.type ? schemas[config.type] : null;
  const client = useApolloClient();
  const styles = getSelectStyles(variant, config.hasImage, config?.reactSelectProps?.customStyles);
  const asyncSelectKey = useStoreState((state) => state.forms.headerImagesSelect.asyncSelectKey);
  const [inputValue, setInputValue] = useState(false);

  // Toggles state based on if input value is empty or not to determine whether or not
  // to show "Recommended taxonomy tags" label.
  const onInputChange = (input) => {
    !input ? setInputValue(false) : setInputValue(true);
  };

  const propertyWithIdsToExclude = {
    metadataTags: 'metadataTags',
    taxonomyTags: 'taxonomyTags',
  };

  let excludeIds = [];
  if (name in propertyWithIdsToExclude) {
    const propertyName = propertyWithIdsToExclude[name];
    excludeIds = formData?.[propertyName]?.map((tag) => tag.id) || [];
  }

  const setStatus = useStoreActions((actions) => actions.saveStatus.setStatus);

  // Format the default value if the input is empty.
  const formattedValue = formatDefaultValue(value, config);

  // When we load async options, we want to debounce the API calls.
  // This is used by <Async> and <AsyncCreatable>
  const debouncedLoadOptions = debounce(loadAsyncOptions({
    config,
    schema,
    formData,
    client,
    excludeIds
  }), 500);

  // When we load sync options, we don't need to debounce anything.
  // This is used by <ReactSelect> and <Creatable>
  const { options, objValue } = loadSyncOptions(formattedValue, config, { formData });

  // Used to add a disabled option.
  const isOptionDisabled = (option) => isFunction(option.isDisabled)
    ? option.isDisabled(option, value)
    : option.isDisabled;

  // Determine what should happen when options are selected, deselected, etc.
  // These call our various handlers (in handlers.js).

  const handleChange = (newOption, actionMeta) => {
    try {
      const loadingSpinnerLabelActions = {
        'select-option': 'Selecting',
        'deselect-option': 'Deselecting',
        'remove-value': 'Removing',
        'pop-value': 'Popping',
        clear: 'Clearing',
        'create-option': 'Creating'
      };
      const customSpinnerLabel = `${loadingSpinnerLabelActions[actionMeta.action]} ${pluralize(name, 1)}`;

      switch (actionMeta.action) {
        case 'select-option': return selectOption(name, newOption, { onChange, value: formattedValue, config, schema }, customSpinnerLabel);
        case 'deselect-option': // fall through
        case 'remove-value': // fall through
        case 'pop-value': return deselectOption(name, actionMeta.removedValue, { onChange, value: formattedValue, config }, customSpinnerLabel);
        case 'clear': return clearOptions(name, { onChange, config }, customSpinnerLabel);
        case 'create-option': return createOption(name, newOption, { onChange, value: formattedValue, config, schema }, customSpinnerLabel);
      }
    } catch (err) {
      setStatus({ isSaving: false });
      const inputFieldAction = actionMeta.action;
      const inputFieldName = name;
      logError(`handleChange error for ${inputFieldAction} ${inputFieldName} Select field: ${err.message}`, err);
      throw err;
    }
  };

  const LoadingMessage = (props) => {
    return (
      <div
        {...props.innerProps}
        style={props.getStyles('loadingMessage', props)}
      >
        <LoadingSpinner small label='Loading previous definitions' />
      </div>
    );
  };

  LoadingMessage.propTypes = {
    innerProps: PropTypes.object,
    getStyles: PropTypes.func,
  };

  // Determine if we should show the dropdown indicator.
  const components = {
    // Only hide the DropdownIndicator if we're explicitly setting
    // showDropdown to false.
    ...config.type === 'Tag' && { Option },
    ...config.type === 'MetadataTag' && { Option },
    ...config.type === 'TaxonomyTag' && { Option },
    ...config.showDropdown === false && { DropdownIndicator: null },
    ...config.hasImage && { Option, SingleValue },
    ...config.isMulti && { MultiValueLabel: MultiValue },
    ...config.type === 'WordDefinition' && { LoadingMessage },
    ...config.type === 'TaxonomyTag' && !inputValue && { MenuList }
  };
  const props = {
    name,
    value: isArray(objValue)
      ? objValue.map((element) => {
        const updatedLabel = !isEmpty(element.path) ? `... / ${element.label}` : element.label;
        return { ...element, label: updatedLabel };
      })
      : objValue,
    onChange: handleChange,
    isMulti,
    isClearable,
    isDisabled,
    components,
    isOptionDisabled,
    styles,
    placeholder: config.placeholder || ' '
  };

  // Based on the config, render one of the four types of selects
  switch (selectType) {
    case 'AsyncCreatable': return (
      <AsyncCreatable
        loadOptions={debouncedLoadOptions}
        defaultOptions
        onInputChange={onInputChange}
        {...props}
      />
    );
    // Unique key prop required here to force loadOptions on data changes
    case 'Async': return (
      <Async
        key={asyncSelectKey}
        loadOptions={debouncedLoadOptions}
        defaultOptions
        onInputChange={onInputChange}
        {...props}
        {...config?.reactSelectProps}
      />
    );
    case 'Creatable': return (<Creatable options={options} onInputChange={onInputChange} {...props} />);
    default: return (<ReactSelect options={options} onInputChange={onInputChange} {...props} />);
  }
}

Select.propTypes = {
  /** Field value, from the form-level state */
  value: PropTypes.oneOfType([
    PropTypes.string, // Single select
    PropTypes.number, // Single select on numerical values
    PropTypes.object, // Single select on objects (e.g. contentProvider)
    PropTypes.array // Multi-select
  ]),
  /** Field name, which is also the property the data will be saved to */
  name: PropTypes.string,
  /** Full configuration object */
  config: PropTypes.shape({
    dedupeBy: PropTypes.string,
    forceFilter: PropTypes.bool,
    hasImage: PropTypes.bool,
    isAsync: PropTypes.bool,
    isClearable: PropTypes.bool,
    isDisabled: PropTypes.func,
    isMulti: PropTypes.bool,
    label: PropTypes.string,
    mapping: PropTypes.object,
    preloadOptions: PropTypes.bool,
    query: PropTypes.string,
    showDropdown: PropTypes.bool,
    showLabel: PropTypes.bool,
    type: PropTypes.string,
    placeholder: PropTypes.string,
    reactSelectProps: PropTypes.object
  }),
  /** Function that updates the form state and persists data */
  onChange: PropTypes.func,
  /** Full form data */
  formData: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  variant: PropTypes.string,
  placeholder: PropTypes.string,
  breadcrumbs: PropTypes.array,
};
