import { faEmptySet } from '@fortawesome/pro-regular-svg-icons';

import { ignore as ignoreBlock } from '@shared/prosemirror/blocks';

import { wrapperBlockActive, wrapInToggle } from './helpers';

export const block = ignoreBlock;

export const menuBlock = {
  title: 'Exempt From Lexile',
  content: faEmptySet,
  active: wrapperBlockActive('ignore_block'),
  run: wrapInToggle('ignore_block')
};
