/* eslint-disable camelcase */
import { css } from '@emotion/core';

import { TAG_CONFIG } from '@client/utils/constants';
import { derivativeStandardCommon } from '@client/utils/styles';

export const $option = (isSelected) => css`
  display: flex;
  flex-direction: column;
  height: 100%;
  margin: 10px 0 0 0;
  width: ${isSelected ? '90%;' : 'auto;'};
`;

export const $image = css`
  height: auto;
  width: 70%;
`;

export const $tagOption = css`
  display: flex;
  justify-content: space-between;
`;

export const $tagOptionCodes = css`
  display: flex;
  align-items: center;
  justify-content: end;
`;

export const $tagType = (tagType) => ({ colors }) => {
  const presetColor = TAG_CONFIG[tagType]?.color;
  const color = presetColor ? colors.ui[presetColor][500] : colors.ui.greyDark[500];
  return css`
    color: ${color};
  }
  `;
};

export const $derivativeStandardsChip = ({ colors }) => {
  return [
    derivativeStandardCommon(),
    css`
    display: unset;
    background-color: ${colors.ui.greyDark[500]};
    font-size: 7px;
    margin-right: 5px;
  `
  ];
};

export const $derivativeStandards = ({ colors }) => {
  return [
    derivativeStandardCommon(),
    css`
    display: block;
    background-color: ${colors.ui.coreBlue[500]};
    font-size: 12px;
    margin-right: 10px;
  `
  ];
};

export const $path = ({ colors }) => css`
  color: ${colors.ui.grey[500]};
`;

export const $chipDerivativeStandardsIcon = ({ colors }) => css`
  div[role='tooltip'] {
    margin: 14px;
  }
`;

export const $chipLabel = ({ colors }) => css`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;

  div[role='tooltip'] {
    white-space: normal;
    word-wrap: break-word;
    margin: 14px;

    & span {
      text-shadow: 0 1px 0 ${colors.ui.greyLight[100]}, 0 1px 1px ${colors.ui.greyLight[100]};
    }
  }
`;

export const $menuList = ({ colors, typography }) => [
  typography.family.circular,
  typography.size.XS,
  css`
  color: ${colors.ui.greyDark[300]};
  margin: 5px;
  display: flex;
  justify-content: space-between;
  `
];

export const $optionConfidenceRating = ({ colors, typography }) => [
  typography.family.circular,
  typography.size.S,
  css`
  display: flex;
  justify-content: center;
  width: 5%;
  margin-left: 10px;
`];
