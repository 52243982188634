import { useEffect } from 'react';

import { useStoreState } from 'easy-peasy';

const FALSE = 'false';
const NOT_AVAILABLE = 'N/A';

export const useHeap = () => {
  const user = useStoreState((state) => state.user.data);

  useEffect(() => {
    if (user && window.heap) {
      window.heap.identify(user.id);

      const permissions = user.permissions;
      const permissionsArr = [];

      for (const permission in permissions) {
        if (permission === '__typename') {
          return;
        }
        permissionsArr.push(permission);
      }

      const userData = {
        username: user.username,
        user_id: user.id,
        name: user.name,
        has_wire_access: user.hasWireAccess ? user.hasWireAccess.toString() : FALSE,
        is_staff: user.isStaff ? user.isStaff.toString() : FALSE,
        is_wire_admin: user.isWireAdmin ? user.isWireAdmin.toString() : FALSE,
        is_contributor: user.isContributor ? user.isContributor.toString() : FALSE,
        permissions: permissionsArr.length !== 0 ? permissionsArr : NOT_AVAILABLE,
        groups: user.groups
      };
      window.heap.addUserProperties(userData);
      window.heap.clearEventProperties();
      window.heap.addEventProperties(userData);
    }
  }, [user]);

  const heapSignOut = () => {
    try {
      window.heap.resetIdentity();
    } catch (error) {
      console.error('Heap sign out failed: ', error);
    }
  };

  return { heapSignOut };
};
