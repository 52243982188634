import React from 'react';

import PropTypes from 'prop-types';

import FormField from '@client/forms/components/FormField';

export default function ModalForm ({ config, data, onChange, onSave }) {
  return (
    <form onSubmit={onSave}>
      {config.map((fieldConfig, index) => (
        <FormField
          key={`modalfield-${index}`}
          field={fieldConfig}
          data={data}
          onChange={onChange}
          layout='modal'
        />
      ))}
    </form>
  );
}

ModalForm.propTypes = {
  config: PropTypes.array,
  data: PropTypes.object,
  onChange: PropTypes.func,
  onSave: PropTypes.func
};
