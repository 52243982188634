/* eslint-disable camelcase */
import { css } from '@emotion/core';

export const $root = css`
  padding: 5px 0;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin: 10px 0;
`;

export const $input = css`
  width: 85%;
`;

// Add some top margin to account for the Prosemirror formatting menu.
export const $answerButton = 'margin-top: 40px;';
