import React, { useState, useEffect, useCallback } from 'react';

import { debounce } from 'lodash-es';
import TextInput from 'mineral-ui/TextInput';
import parse from 'parse-duration';
import ms from 'pretty-ms';
import PropTypes from 'prop-types';

import { $root } from './style';

// One second is 1000ms.
const MS = 1000;

// Export the onChange timeout, so we can use it in tests.
export const DEBOUNCE_TIME = 500;

/**
 * Format the value as a string, so we can display it in the text input.
 * @param {number} value integer of seconds
 * @returns {string}
 */
function toString (value) {
  if (!value) {
    return '';
  }

  // The value is stored as seconds but pretty-ms wants milliseconds, so
  // convert it before rendering. When we render, use the verbose format, e.g.
  // 600 -> '10 minutes' rather than '10m'
  return ms(value * MS, { verbose: true });
}

/**
 * Convert input value into an integer of seconds.
 * @param {string} val from input
 * @returns {number}
 */
function toSeconds (val) {
  // If the value is ONLY a number, default to minutes.
  const isBareNumber = val.match(/^\d+$/);
  const valToParse = isBareNumber ? `${val} minutes` : val;
  // The parser outputs ms, so convert it to seconds when saving.
  return parse(valToParse) / MS;
}

export default function Duration ({ value, name, config, onChange, formData, variant }) {
  const [text, setText] = useState(toString(value));

  // Debounce the call to onChange, since we want to eliminate lag when the
  // user is typing. The input will update with the parsed value every 500ms.
  const debouncedOnChange = useCallback(debounce(onChange, DEBOUNCE_TIME), [formData]);

  // Reset text when changing drawers and when the form updates from the server.
  useEffect(() => {
    setText(toString(value));
  }, [formData && formData.id, formData && formData.updatedAt]);

  // When user types, update the text immediately but debounce sending the
  // parsed value to the server.
  const onUpdate = (e) => {
    const val = e.target.value;

    if (val === '') {
      // If the input was cleared out, unset the field. This does NOT
      // happen if the input is explicitly set to '0 seconds', since that is a
      // perfectly valid value.
      setText(val);
      debouncedOnChange({ [name]: null }, 'unset');
      return; // Return early when unsetting this field.
    }

    setText(val);
    debouncedOnChange({ [name]: toSeconds(val) });
  };

  return (
    <TextInput
      css={$root(variant)}
      type='text'
      value={text}
      name={name}
      onChange={onUpdate}
      readOnly={config.isReadOnly}
      disabled={config.isDisabled}
      placeholder={config.placeholder || 'e.g. 3 hours 14 minutes'}
      required={config.required}
    />
  );
}

Duration.propTypes = {
  /** Field value, from the form-level state */
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  /** Field name, which is also the property the data will be saved to */
  name: PropTypes.string,
  /** Full configuration object */
  config: PropTypes.object,
  /** Function that updates the form state and persists data */
  onChange: PropTypes.func,
  /** Full form data */
  formData: PropTypes.object,
  variant: PropTypes.string
};
