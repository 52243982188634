import React from 'react';

import HtmlDiff from 'htmldiff-js';
import { isEmpty } from 'lodash-es';
import { FormField } from 'mineral-ui/Form';
import PropTypes from 'prop-types';

import {
  $revisionView,
  $emptyViewState,
  $viewField
} from './style';

// Responsible for read-only text diffs.
export default function RevisionView ({ values }) {
  const valuesExist = !isEmpty(values.base) || !isEmpty(values.comparison);
  const mappedFields = {
    htmlTitle: 'Title',
    shortTitle: 'Short Title',
    htmlTeaser: 'Teaser',
    htmlText: 'Text'
  };

  const createMarkup = (field) => {
    // Default fields to empty string so we can see if a field was added
    // or removed (the whole field will display with green or red highlighting).
    const baseField = values.base[field] || '';
    const comparisonField = values.comparison[field] || '';

    return {
      __html: HtmlDiff.execute(baseField, comparisonField)
    };
  };

  /* eslint-disable react/no-danger */
  return (
    <div css={$revisionView(valuesExist)}>
      {valuesExist
        ? Object.keys(mappedFields).map((field) => {
          return (
            <FormField label={mappedFields[field]} key={field}>
              <div dangerouslySetInnerHTML={createMarkup(field)} css={$viewField} />
            </FormField>
          );
        })
        : <p css={$emptyViewState}>Select revisions of the article to compare.</p>}
    </div>
  );
}

RevisionView.propTypes = {
  /** Values for base and comparison levels */
  values: PropTypes.object
};
