import React, { useEffect, useState } from 'react';

import { useQuery } from '@apollo/client';
import { get } from 'lodash';
import { CheckboxGroup } from 'mineral-ui/Checkbox';
import PropTypes from 'prop-types';

import { queries } from '@client/common/graph';
import * as schemas from '@client/common/schema';
import { getOptions, formatOptions } from '@client/utils/fields';
import isInline from '@client/utils/inline-group';

import { $checkboxGroup } from './style';
export default function CheckboxGroupInput ({ value, name, config, formData, onChange, variant }) {
  const schema = config?.type ? schemas[config.type] : null;

  const checkboxValues = value || []; // Cast empty values to an array.

  const [options, setOptions] = useState(
    !config?.query
      ? getOptions(config, formData) ?? []
      : []
  );

  const { data: res, loading } = config?.query
    // eslint-disable-next-line react-hooks/rules-of-hooks
    ? useQuery(queries[config.query])
    : { data: null, loading: false };

  const data = get(res, config?.query, null);

  useEffect(() => {
    if (data) {
      const options = formatOptions(data, config);
      setOptions(options);
    }
  }, [data]);

  const onSelect = async (e) => {
    // value is a string, like 'ck123'
    const { checked, value: newValue } = e.target;
    let serverData, optimisticData;
    const newValues = [...checkboxValues];

    // If this is a filterOnly config, we don't need to send serverData
    // into the onChange handler -- we only want to send optomistic data.
    // For example, in the PublishAll modal, we only want the stream checkboxes
    // to affect the status of the content that will be published so we don't
    // want to save anything to the server.
    if (config.filterOnly) {
      const index = newValues.indexOf(newValue);
      const hasValue = index !== -1;

      if (checked && !hasValue) {
        newValues.push(newValue);
      } else {
        newValues.splice(index, 1);
      }

      serverData = {};
      optimisticData = { [name]: newValues };
    } else {
      const index = newValues.map((v) => v.id).indexOf(newValue);
      const hasValue = index !== -1;

      const item = data.find((value) => value.id === newValue);

      if (checked && !hasValue) {
        const clientValue = schema?.defaults(
          newValue,
          { uid: item.uid, newLabel: item.name }
        ).client;

        newValues.push(clientValue);
      } else {
        newValues.splice(index, 1);
      }

      serverData = { [name]: { uid: item.uid } };
      optimisticData = { [name]: newValues };
    }

    onChange(
      serverData,
      optimisticData,
      checked ? 'set' : 'unset'
    );
  };

  const readyToRenderwithQuery = !loading && data;

  return (
    ((config?.query && readyToRenderwithQuery) || !config?.query) &&
      <CheckboxGroup
        inline={isInline(config)}
        checked={config.filterOnly ? checkboxValues : checkboxValues.map((v) => v.id)}
        name={name}
        {...variant && { variant }}
        data={options}
        onChange={onSelect}
        rootProps={{ css: $checkboxGroup }}
      />
  );
}

CheckboxGroupInput.propTypes = {
  /** Field value, from the form-level state */
  value: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.string
  ]),
  /** Full configuration object */
  config: PropTypes.object,
  /** Full form data */
  formData: PropTypes.object,
  /** Field name, which is also the property the data will be saved to */
  name: PropTypes.string,
  /** Function that updates the form state and persists data */
  onChange: PropTypes.func,
  variant: PropTypes.string
};
