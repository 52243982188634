import { logInfo } from './log';

const redirectUser = () => {
  const monolithUrl = process.env.MONOLITH_BASE_URL || '';
  const redirectUrl = `${monolithUrl}/signin?next=${window.location.href}`;
  window.location.href = redirectUrl;
};

export const isUnauthenticated = (graphQLErrors) => (graphQLErrors || []).some((e) => e.extensions.code === 'UNAUTHENTICATED');

export const sessionExpired = (e) => {
  logInfo(`Session expired: ${e.type} event`);
  document.addEventListener('click', preventBrowsing);
  redirectUser();
};

export const preventBrowsing = (e) => {
  e.preventDefault();
  logInfo(`Click prevented: ${e.type} event`);
  redirectUser();
};
