import { css } from '@emotion/core';

import { isLevelerFeatureOn } from '@client/forms/inputs/ProsemirrorLeveler/utils/feature';
import { DRAWER_INNER_WIDTH, MODAL_WIDTH } from '@client/utils/constants';

const fieldWidth = `${DRAWER_INNER_WIDTH}px`;
const modalFieldWidth = `${MODAL_WIDTH}px`;
const wideFieldWidth = isLevelerFeatureOn ? '1200px' : '700px';

export const $root = (layout) => {
  let width;
  let verticalMargin;

  switch (layout) {
    case 'wide':
      width = wideFieldWidth;
      verticalMargin = '20px';
      break;
    case 'two-column':
      width = fieldWidth;
      verticalMargin = '15px';
      break;
    case 'two-column-modal':
    case 'modal':
      width = modalFieldWidth;
      verticalMargin = '10px';
      break;
    default:
      width = fieldWidth;
      verticalMargin = '10px';
  }

  return css`
    margin: ${verticalMargin} 0;
    max-width: 100%;
    width: ${width};
  `;
};
