import { merge } from 'lodash-es';

import { logError } from './log';
import { error } from './toast';
// import mp3Duration from 'mp3-duration';

const monolithUrl = process.env.MONOLITH_BASE_URL || '';

/**
 * Check response status.
 *
 * @param  {Object} res
 * @param  {string} type - either 'json' or 'text'
 * @throws {Error} if status isn't between 200 and 399
 * @return {Object} res
 */
async function checkStatus (res, type) {
  if (res.status && res.status >= 200 && res.status < 400) {
    return res[type]();
  } else {
    throw new Error(res.statusText);
  }
}

/**
 * fetchJ JSON from a restful API
 * Minimal wrapper over the native `fetch` API
 *
 * @param  {string} url
 * @param  {Object} [options]
 * @return {Promise}
 */
export async function fetchJSON (url, options = {}) {
  try {
    const res = await fetch(url, options);
    return checkStatus(res, 'json');
  } catch (e) {
    error(`Cannot connect to ${url}`);
    logError(`Unable to ${options.method || 'GET'} ${url}: ${e.message}`);
  }
}

/**
 * Send a file to S3, using a pre-signed url. Any errors thrown should be
 * caught in the File input.
 *
 * @param  {string} url
 * @param  {ByteArray} body
 * @param  {string} mimetype of the file to upload
 * @return {Promise} with boolean, because successful API calls only return http status
 */
export async function uploadToS3 (url, body, mimetype) {
  const res = await fetch(url, {
    method: 'PUT',
    headers: new window.Headers({
      'Cache-Control': 'max-age=86400, public', // Cache for a day
      'Content-Type': mimetype
    }),
    body
  });

  return res.status >= 200 && res.status < 400;
}

/**
 * Fetch stuff from the Monolith's Object API, using a shared session key.
 *
 * @param {string} path
 * @param {Object} [options]
 * @return {Promise}
 */
export async function getMonolithJSON (path, options = {}) {
  const cookieOptions = {
    credentials: 'include'
  };

  try {
    const res = await fetch(`${monolithUrl}${path}`, merge(options, cookieOptions));
    return checkStatus(res, 'json');
  } catch (e) {
    error('Cannot connect to Monolith');
    logError(`Unable to fetch ${path} from Monolith API: ${e.message}`);
  }
}
