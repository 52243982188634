import { ListenSVG } from '@newsela/angelou';
import gql from 'graphql-tag';
import { isString, escapeRegExp } from 'lodash-es';
import smartquotes from 'smartquotes';

import { DEFAULT_LANG } from '@client/utils/constants';
import { transformSlug } from '@client/utils/fields';

import Content from './content';
import ContentProvider from './content-provider';
import Stream from './stream';
import Tag from './tag';
import rawSchema from '../../schema';

function filter (query) {
  const quoted = smartquotes(query);

  return {
    or: [
      { title: { alloftext: quoted } },
      { description: { alloftext: quoted } },
      { slug: { regexp: `/${escapeRegExp(query)}/i` } }
    ]
  };
}

function quickFilter (query) {
  const quoted = smartquotes(query);

  return {
    title: { alloftext: quoted },
    rootFn: 'title|alloftext'
  };
}

const Audio = {
  previewFragment: gql`
    fragment previewAudio on Audio {
      title(format: TEXT_PLAIN)
      description(format: TEXT_PLAIN)
      imageUrl
      audioTrackUrl
    }
  `,
  // Include htmlDescription so that Apollo's cache will update when saving data.
  // This will trigger the preview to show the updated htmlDescription.
  fullFragment: gql`
    fragment fullAudio on Audio {
      title(format: TEXT_PLAIN)
      rawTitle: title(format: TEXT_RAW)
      description(format: TEXT_PLAIN)
      rawDescription: description(format: TEXT_RAW)
      htmlDescription: description(format: TEXT_HTML)
      imageUrl
      isSupplemental
      language
      lengthInSeconds
      audioTrackUrl
      slug
      transcriptUrl
    }
  `,
  filter,
  quickFilter,
  defaults: (id, data) => {
    const contentDefaults = Content.defaults(id, data);

    return {
      client: {
        ...contentDefaults.client,
        __typename: 'Audio',
        contentType: 'AUDIO',
        name: 'Audio',
        title: 'Audio',
        rawTitle: null,
        description: null,
        rawDescription: null,
        htmlDescription: null,
        imageUrl: null,
        isSupplemental: false,
        language: DEFAULT_LANG,
        lengthInSeconds: null,
        audioTrackUrl: null,
        slug: null,
        transcriptUrl: null
      },
      server: {
        ...contentDefaults.server,
        contentType: 'AUDIO'
      }
    };
  },
  isContent: true,
  icon: ListenSVG,
  typename: 'Audio',
  forms: {
    shared: [
      {
        input: 'prosemirror',
        name: 'title',
        value: 'rawTitle',
        formats: ['italic']
      },
      {
        input: 'prosemirror',
        name: 'description',
        value: 'rawDescription',
        isMultiline: true,
        formats: ['bold', 'italic', 'list', 'orderedList', 'link']
      },
      {
        input: 'text',
        name: 'slug',
        transform: (data = '') => transformSlug(data),
        button: {
          input: 'magic-button',
          // Get text from raw prosemirror if we haven't persisted it to the server
          // and received plain text back yet
          value: (data) => isString(data.title)
            ? data.title
            : data.title.textContent,
          description: 'Copy title'
        }
      },
      {
        ...ContentProvider.inputs.contentProvider,
        required: true
      },
      {
        input: 'select',
        name: 'language',
        defaultValue: DEFAULT_LANG,
        enum: 'Language',
        options: rawSchema.enums.Language
      },
      {
        input: 'checkbox',
        name: 'isSupplemental',
        longLabel: 'Is Supplemental',
        showLabel: false,
        caption: 'A supplemental audio track will not appear in search, as its content is context-sensitive'
      },
      {
        input: 'file',
        name: 'imageUrl',
        label: 'Audio Thumbnail',
        accept: 'image/*'
      },
      {
        input: 'file',
        name: 'audioTrackUrl',
        label: 'Audio Track',
        caption: 'Audio track must be an MP3 file',
        accept: '.mp3'
      },
      {
        input: 'file',
        name: 'transcriptUrl',
        label: 'Transcription',
        accept: '.json'
      },
      Tag.inputs.tags,
      Stream.inputs.subjectProductStreams,
      Stream.inputs.customStreams,
      Content.inputs.notes,
      Content.inputs.parentsList,
      Content.inputs.id,
    ]
  }
};

export default Audio;
