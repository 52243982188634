/* eslint-disable camelcase */
import { css } from '@emotion/core';

export const $root = ({ colors }) => css`
  margin-right: 5px;

  & > span {
    justify-content: flex-start;
  }

  & svg[role='img'] {
    color: ${colors.ui.greyDark[500]} !important; /* stylelint-disable-line */
  }

  &:hover svg[role='img'],
  &:active svg[role='img'],
  &:focus svg[role='img'] {
    color: ${colors.ui.greyDark[500]};
  }
`;
