import React, { useState } from 'react';

import { faCaretDown, faCaretRight } from '@fortawesome/free-solid-svg-icons';
import { Button } from '@newsela/angelou';
import cuid from 'cuid';
import { filter, findIndex, isUndefined } from 'lodash-es';
import PropTypes from 'prop-types';

import Icon from '@client/common/components/Icon';
import { ArticleLevel, AssessmentLevel, Level } from '@client/common/schema';
import { sortGradeBands, filterGradeBands } from '@client/utils/fields';

import { $icon, $groups, $group, $groupLabel, $option } from './style';

export default function GradeBandLevelSelect ({ value, name, config, onChange, formData }) {
  value = value || [];
  // When the modal is initially opened, determine what levels already exist
  // on the server.
  const [originalLevels] = useState(value);

  // By default, we include all possible grade bands. For articles, we limit
  // the selector to only include individual grades (e.g. no MS, HS).
  const hasMultigradeBands = isUndefined(config.hasMultigradeBands)
    ? true
    : config.hasMultigradeBands;

  // isLanguageVisible tracks toggle state of each form section in our UI.
  const [isLanguageVisible, setLanguageVisible] = useState({
    LANG_EN: true,
    LANG_ES: false
  });

  // Toggle the visibility of form sections in our UI.
  const toggleVisible = (language) => setLanguageVisible((obj) => ({
    ...obj,
    [language]: !obj[language]
  }));

  // Helper function to find a level from its language and gradeBand.
  const findLevel = (language, gradeBand) => value.find((level) => {
    return level.language === language && level.gradeBand === gradeBand;
  });

  /**
   * Helper function to determine what parts of the optimistic data to send to the server.
   *
   * @param {object} optimisticData
   * @return {object}
   */
  const filterOptimisticData = (optimisticData) => {
    return optimisticData.map(({ uid, language, gradeBand, isActive, questions = null }) => ({
      uid,
      language,
      gradeBand,
      isActive,
      // If there are questions, include their id, uid, and options.
      ...questions && {
        questions: questions.map(({ id, uid, options, questionType }) => ({
          id,
          uid,
          questionType,
          // If there are options, include their uids.
          ...options && { options: options.map(({ uid }) => ({ uid })) }
        }))
      }
    }));
  };

  // Optimistic levels get updated depending on the type of level we're updating, e.g. Level, AssessmentLevel.
  // We can determine this based on content type.
  const levelId = cuid(); // Used to generate uid in the defaults() function.
  const updateOptimisticLevels = {
    ARTICLE: (language, gradeBand) => {
      const optimisticLevels = value.concat([
        ArticleLevel.defaults(levelId, { language, gradeBand }).client
      ]);
      return optimisticLevels;
    },
    ASSESSMENT: (language, gradeBand) => {
      const optimisticLevels = value.concat([
        AssessmentLevel.defaults(
          levelId,
          { language, gradeBand },
          { assessmentType: formData.assessmentType }
        ).client
      ]);
      return optimisticLevels;
    },
    IMAGE: (language, gradeBand) => {
      const optimisticLevels = value.concat([
        Level.defaults(levelId, { language, gradeBand }).client
      ]);
      return optimisticLevels;
    }
  };

  /**
   * Toggle a level on or off. If the level already exists on the server,
   * change 'isActive'. If the level does NOT exist but we've created it while
   * the modal is open, remove it. If the level does not exist anywhere, create it.
   * @param {string} language
   * @param {string} gradeBand
   */
  const toggleLevel = (language, gradeBand) => {
    // Does the level exist anywhere in our optimisic data?
    const existingLevel = findLevel(language, gradeBand);
    // If so, get its uid.
    const uid = existingLevel && existingLevel.uid;

    // Does the level exist on the server?
    const isOriginalLevel = !!originalLevels.find((level) => level.uid === uid);
    // Does the level exist only on the client?
    const isNewLevel = existingLevel && !isOriginalLevel;

    let optimisticLevels;
    let serverLevels;

    if (isOriginalLevel) {
      // Toggle or untoggle isActive on a level that already exists on the server.
      const updatedLevel = {
        ...existingLevel,
        isActive: !existingLevel.isActive
      };

      optimisticLevels = value.map((level) => level === existingLevel ? updatedLevel : level);
      serverLevels = filterOptimisticData(optimisticLevels);
    } else if (isNewLevel) {
      // Remove a level that's only been created on the client.
      optimisticLevels = value.filter((level) => level.uid !== uid);
      serverLevels = filterOptimisticData(optimisticLevels);
    } else {
      // Create a new level and set it as active.
      optimisticLevels = updateOptimisticLevels[formData.contentType](language, gradeBand);
      serverLevels = filterOptimisticData(optimisticLevels);
    }

    onChange({
      [name]: serverLevels
    }, {
      // But the optimistic data has all fields.
      [name]: optimisticLevels
    });
  };

  const renderGradeBands = (language) => {
    // If necessary, filter the grade bands to remove multi-grade bands (e.g. MS, HS)
    const gradeBands = config.gradeBands.filter(filterGradeBands(hasMultigradeBands));
    // To sort the grade bands, we need to put them into objects (sortGradeBands
    // expects to receive levels with a .gradeBand property).
    let sorted = gradeBands.sort((a, b) => sortGradeBands({ gradeBand: a.value }, { gradeBand: b.value }));
    sorted = filter(sorted, function (band) { return band.value !== 'GRADE_COLLEGE'; }); // remove college band from UI
    // Note that these groups are a suggestion based on common use cases, not
    // a hard rule, e.g. Grade 6 is usually MS but some schools may consider
    // it to be ES.
    // If the multi-grade bands don't exist, use the exact grades to group them.
    const msIndex = findIndex(sorted, (item) => {
      return item.value === 'GRADE_MIDDLE_SCHOOL' || item.value === 'GRADE_8';
    });
    const esIndex = findIndex(sorted, (item) => {
      return item.value === 'GRADE_LOWER_AND_UPPER_ELEMENTARY' || item.value === 'GRADE_5';
    });
    const groups = [
      { label: 'HS', grades: sorted.slice(0, msIndex) },
      { label: 'MS', grades: sorted.slice(msIndex, esIndex) },
      { label: 'ES', grades: sorted.slice(esIndex) }
    ];

    return (
      <div css={$groups}>
        {/* Map over the three groups. */}
        {groups.map((group) => (
          <div key={group.label} css={$group}>
            <span css={$groupLabel}>{group.label}:</span>
            {/* In each group, map over the relevant grades. */}
            {group.grades.map((grade) => {
              const level = findLevel(language.value, grade.value);
              const isActive = level ? level.isActive : false;
              const roleText = grade.value + (isActive ? '_ACTIVE' : '_INACTIVE');

              return (
                <button
                  type='button'
                  css={$option(isActive)}
                  role={roleText}
                  key={grade.value}
                  onClick={() => toggleLevel(language.value, grade.value)}
                >
                  {grade.label}
                </button>
              );
            })}
          </div>
        ))}
      </div>
    );
  };

  return (
    <>
      {config.languages.map((language) => {
        return (
          <div key={language.value}>
            <Button
              /* AUTOGENERATED TODO: update angelou to new flavor.
              see https://github.com/newsela/angelou/blob/main/src/components/Button/README.md#MIGRATION
              for migration guide. */
              legacy_flavor={Button.legacy_flavor.incognito}
              legacy_size={Button.legacy_size.small}
              type='button'
              role={language.value}
              onClick={() => toggleVisible(language.value)}
            >
              <Icon
                icon={isLanguageVisible[language.value] ? faCaretDown : faCaretRight}
                color='ui.greyLight.100'
                customCss={$icon}
              />
              {language.label}
            </Button>
            {isLanguageVisible[language.value] && renderGradeBands(language)}
          </div>
        );
      })}
    </>
  );
}

GradeBandLevelSelect.propTypes = {
  /** Field value, from the form-level state */
  value: PropTypes.array,
  /** Full configuration object */
  config: PropTypes.object,
  /** Field name, which is also the property the data will be saved to */
  name: PropTypes.string,
  /** Function that updates the form state and persists data */
  onChange: PropTypes.func,
  /** Full form data */
  formData: PropTypes.object
};

// Prevent this from being wrapped in a <label>, which would mess with button clicks.
GradeBandLevelSelect.displayName = 'GradeBandLevelSelectGroup';
