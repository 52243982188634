export function filterArchivedLegacyWorkflows (workflowData) {
  // Workflows come in as an object where the property is articleWorkflows, and the
  // value is an array. We want to filter that array to remove any workflows where
  // the date archived is null.
  if (!workflowData || !workflowData.articleWorkflows) { return; }
  const filteredWorkflows = workflowData.articleWorkflows.filter(
    (workflow) => workflow.dateArchived === null
  );
  return { articleWorkflows: filteredWorkflows };
}
