import { faBallotCheck } from '@fortawesome/pro-light-svg-icons';
import cuid from 'cuid';
import gql from 'graphql-tag';

const QuestionOption = {
  fullFragment: gql`
    fragment fullQuestionOption on QuestionOption {
        uid
        text(format: TEXT_PLAIN)
        rawText: text(format: TEXT_RAW)
        htmlText: text(format: TEXT_HTML)
        isCorrect
    }
  `,
  icon: faBallotCheck,
  defaults: (id) => {
    id = id || cuid();
    const uid = `_:${id}`;

    return {
      client: {
        uid,
        __typename: 'QuestionOption',
        text: null,
        rawText: null,
        htmlText: null,
        isCorrect: false
      },
      server: {
        uid
      }
    };
  }
};

export default QuestionOption;
