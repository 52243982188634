import React, { forwardRef } from 'react';

import { faStar as faStarRegular } from '@fortawesome/free-regular-svg-icons';
import { faTimes, faStar as faStarSolid } from '@fortawesome/free-solid-svg-icons';
import { Button } from '@newsela/angelou';
import Tooltip from 'mineral-ui/Tooltip';
import PropTypes from 'prop-types';

import Icon from '@client/common/components/Icon';
import ValidationMessage from '@client/forms/components/ValidationMessage';
import ProsemirrorGroup from '@client/forms/inputs/Prosemirror';

import {
  $root,
  $input,
  $answerButton
} from './style';

const MultipleChoiceOption = forwardRef(({
  hasBeenPublished,
  value,
  config,
  onDelete,
  onChange,
  onSelectCorrectAnswer,
  variant,
  validationMessage
}, ref) => {
  return (
    <div css={$root}>
      <Tooltip content={value.isCorrect ? 'Correct answer' : 'Select correct answer'}>
        <Button
          ariaProps={{ 'aria-label': 'Toggle correct answer' }}
          /* AUTOGENERATED TODO: update angelou to new flavor.
            see https://github.com/newsela/angelou/blob/main/src/components/Button/README.md#MIGRATION
            for migration guide. */
          legacy_flavor={Button.legacy_flavor.flat}
          legacy_size={Button.legacy_size.small}
          legacy_statusColor={Button.legacy_statusColor.black}
          onClick={onSelectCorrectAnswer}
          __cssFor={{ root: $answerButton }}
        >
          <Icon
            icon={value.isCorrect ? faStarSolid : faStarRegular}
            color={value.isCorrect ? 'ui.yellow.500' : null}
          />
        </Button>
      </Tooltip>
      <div css={$input}>
        <ProsemirrorGroup
          name='text'
          value={value ? value.rawText : null}
          onChange={onChange}
          config={config.textConfig}
          formData={value}
          variant={variant}
          ref={ref}
        />
        {(variant && validationMessage) && <ValidationMessage variant={variant} message={validationMessage} />}
      </div>
      <Button
        ariaProps={{ 'aria-label': 'Remove option' }}
        /* AUTOGENERATED TODO: update angelou to new flavor.
          see https://github.com/newsela/angelou/blob/main/src/components/Button/README.md#MIGRATION
          for migration guide. */
        legacy_flavor={Button.legacy_flavor.flat}
        legacy_size={Button.legacy_size.small}
        legacy_statusColor={Button.legacy_statusColor.black}
        onClick={onDelete}
        __cssFor={{ root: $answerButton }}
        // Users can't remove options after publication.
        disabled={hasBeenPublished}
      >
        <Icon icon={faTimes} />
      </Button>
    </div>
  );
});

MultipleChoiceOption.propTypes = {
  /** Field value, from the form-level state */
  value: PropTypes.object,
  /** Full configuration object */
  config: PropTypes.object,
  /** Function that updates the form state and persists data */
  onDelete: PropTypes.func,
  /** Function that updates the form state and persists data */
  onSelectCorrectAnswer: PropTypes.func,
  /** Callback function that is called when the input value changes */
  onChange: PropTypes.func,
  /** Type of validation, either 'danger' (for errors) or 'warning'. */
  variant: PropTypes.string,
  /** Validation message */
  validationMessage: PropTypes.string,
  /** Whether the form has been published, this is used to determine if the assessment is in a state
   *  that allows for adding/removing options. */
  hasBeenPublished: PropTypes.bool
};

export default MultipleChoiceOption;
