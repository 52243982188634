import {
  reduce,
  forEach,
  forOwn,
  mapValues,
  isArray,
  cloneDeep,
  isFunction,
  size,
  head,
  keys as _keys
} from 'lodash-es';
import { chainCommands } from 'prosemirror-commands';
import { inputRules } from 'prosemirror-inputrules';
import { keymap } from 'prosemirror-keymap';
import { Schema } from 'prosemirror-model';

import { logError, logWarning } from '@client/utils/log';

import * as bold from './bold';
import * as code from './code';
import * as common from './common';
import * as divider from './divider';
import * as headings from './headings';
import * as ignore from './ignore';
import * as image from './image';
import * as imageAlignment from './image-alignment';
import * as italic from './italic';
import * as language from './language';
import * as link from './link';
import * as list from './list';
import * as orderedList from './ordered-list';
import * as powerWord from './power-word';
import * as pre from './pre';
import * as quote from './quote';
import * as strikethrough from './strikethrough';

// Return an object without the 'toMarkdown()' method. Much faster than
// lodash's omit() method. This function destructures the 'toMarkdown' method
// out of the rest of the object, and then returns only the object.
function omitMarkdown ({ toMarkdown, ...obj } = {}) {
  return obj;
}

/**
 * When adding new formats, import them above and add them here.
 *
 * All formats may have these exported properties:
 * mark {Object} - defines the properties and serialization of the inline format
 * block {Object} - defines the properties and serialization of the block-level format.
 * blocks {Array} - array of blocks, if you need multiple.
 * keys {Object|Function} - keybinds. If providing a function, it will be called with
 *                          the isMultiline boolean from the field schema.
 * rules {Array} - input rules to automatically apply formatting when typing.
 * menuMark {Object|Array} - menu config(s) for the inline format.
 * menuBlock {Object|Array} - menu config(s) for the block-level format.
 * menuInsert {Object|Array} - menu config(s) for inserting formats that
 *                             don't allow children.
 * plugin {*} - plugin this format depends on
 * plugins {Array} - array of plugins, if you need multiple.
 *
 * Block-level formats will have their name (from the import statements above) appended with
 * '_block', so please take that into account when writing their configs.
 *
 * Arrays of marks and blocks will have their name appended with '_<index>'. So,
 * the first exported mark would be '<name>_0', and the first exported block
 * would be '<name>_block_0'.
 */

// When adding formats here, make sure you add them in the position they
// should appear in the menu.
const formattingOptions = [
  { bold },
  { italic },
  { image },
  { imageAlignment },
  { strikethrough },
  { link },
  { headings },
  { list },
  { orderedList },
  { language },
  { quote },
  { code },
  { pre },
  { ignore },
  { powerWord },
  { divider }
];

/**
 * Find a format from its name.
 *
 * @param  {string} formatName
 * @return {Object}
 */
const hasKey = (formatName) => (obj) => head(_keys(obj)) === formatName;

/**
 * Sort formats in order of their place in the list, above.
 *
 * @param  {string} a
 * @param  {string} b
 * @return {number}
 */
const orderFormats = (a, b) => {
  const formatA = formattingOptions.find(hasKey(a));
  const formatB = formattingOptions.find(hasKey(b));

  return formattingOptions.indexOf(formatA) - formattingOptions.indexOf(formatB);
};

export default function createOptions (formats, isMultiline, user) {
  const sorted = formats.sort(orderFormats);
  const schema = reduce(sorted, (acc, formatName) => {
    const {
      [formatName]: format
    } = formattingOptions.find(hasKey(formatName));

    if (!format) {
      // If the format doesn't exist, this is a programmer error. Show the error
      // and fail gracefully.
      logError(`Format "${formatName}" not defined!`);
      return acc;
    }

    if (acc.nodes[formatName] || acc.marks[formatName]) {
      // If the format has already been added, that's also a programmer error.
      logError(`Format "${formatName}" already added. Please check your formats.`);
      return acc;
    }

    // If the format exports `block` (and no `mark`), it's only allowed in
    // multiline fields. Give a warning and fail gracefully if we're not in a
    // multiline input.
    const isMultilineOnly = !format.mark && !format.marks;

    if (!isMultiline && isMultilineOnly) {
      logWarning(`Attempted to use multiline-only format "${formatName}" in an inline field.`);
      return acc;
    }

    // Build up the schema, keymap, and menu based on the chosen formats.
    if (isMultiline && format.block) {
      acc.nodes[`${formatName}_block`] = omitMarkdown(format.block);
    } else if (isMultiline && format.blocks) {
      forEach(format.blocks, (block, index) => {
        acc.nodes[`${formatName}_block_${index}`] = omitMarkdown(block);
      });
    }

    if (format.mark) {
      acc.marks[formatName] = omitMarkdown(format.mark);
    }

    // Go through the keybindings. If the keybinding already exists,
    // make an array of actions that key should be bound to. These will be merged
    // together after all formats are added to the schema.
    const keys = isFunction(format.keys) ? format.keys(isMultiline) : format.keys;

    forOwn(keys || {}, (val, key) => {
      const keys = acc.keys[key];

      // Always add the new keybindings in FRONT of the older ones.
      if (isArray(keys) && isArray(val)) {
        acc.keys[key] = [...val, ...keys];
      } else if (isArray(keys)) {
        acc.keys[key] = [val, ...keys];
      } else if (isArray(val)) {
        acc.keys[key] = [...val, keys];
      } else {
        acc.keys[key] = val;
      }
    });

    // Add menu items.
    if (isMultiline && format.menuBlock) {
      if (isArray(format.menuBlock)) {
        forEach(format.menuBlock, (menu, index) => {
          acc.menu.blocks[`${formatName}_block_${index}`] = menu;
        });
      } else {
        acc.menu.blocks[`${formatName}_block`] = format.menuBlock;
      }
    }

    if (format.menuMark) {
      if (isArray(format.menuMark)) {
        forEach(format.menuMark, (menu, index) => {
          acc.menu.marks[`${formatName}_${index}`] = menu;
        });
      } else {
        acc.menu.marks[formatName] = format.menuMark;
      }
    }

    if (format.menuInsert) {
      if (isArray(format.menuInsert)) {
        forEach(format.menuInsert, (menu, index) => {
          acc.menu.insert[`${formatName}_${index}`] = menu;
        });
      } else {
        acc.menu.insert[formatName] = format.menuInsert;
      }
    }

    // Do not limit power words to admins (contributors need to use them too)
    if (format.menuPowerWord) {
      if (isArray(format.menuPowerWord)) {
        forEach(format.menuPowerWord, (menu, index) => {
          acc.menu.powerWord[`${formatName}_${index}`] = menu;
        });
      } else {
        acc.menu.powerWord[formatName] = format.menuPowerWord;
      }
    }

    // Add plugins.

    if (format.plugins) {
      acc.plugins = acc.plugins.concat(format.plugins);
    } else if (format.plugin) {
      acc.plugins.push(format.plugin);
    }

    // Add input rules.

    if (format.rules) {
      acc.rules.push(format.rules);
    }

    return acc;
  }, cloneDeep({
    nodes: isMultiline
      ? mapValues(common.multiBlocks, omitMarkdown)
      : mapValues(common.blocks, omitMarkdown),
    marks: mapValues(common.marks, omitMarkdown),
    keys: isMultiline ? common.multiKeys : common.keys,
    menu: isMultiline ? common.multiMenu : common.menu,
    plugins: isMultiline ? common.multiPlugins : common.plugins,
    rules: isMultiline ? common.multiRules : common.rules
  }));

  // Generate final keymap.
  const chainedKeys = mapValues(schema.keys, (val) => {
    if (isArray(val)) {
      return chainCommands.apply(null, val);
    } else {
      return val;
    }
  });

  // Generate the final menu items.
  if (size(schema.menu.marks) === 1) {
    // If we don't allow ANY marks, remove the 'remove formatting' option.
    schema.menu.marks = {};
  } else if (schema.menu.marks.clear_marks) {
    // Move 'remove formatting' to the end.
    const clearMarks = schema.menu.marks.clear_marks;

    delete schema.menu.marks.clear_marks;
    schema.menu.marks.clear_marks = clearMarks;
  }

  if (size(schema.menu.blocks) === 1) {
    // If we don't allow ANY blocks, remove the 'outdent' option.
    schema.menu.blocks = {};
  } else if (schema.menu.blocks.lift) {
    // Move 'outdent' to the end.
    const lift = schema.menu.blocks.lift;

    delete schema.menu.blocks.lift;
    schema.menu.blocks.lift = lift;
  }

  return {
    schema: new Schema({ nodes: schema.nodes, marks: schema.marks }),
    plugins: [
      keymap(chainedKeys),
      inputRules({ rules: schema.rules }),
      ...schema.plugins
    ],
    menu: schema.menu
  };
}
