import React, { useState, useRef } from 'react';

import { round } from 'lodash-es';
import PropTypes from 'prop-types';
import Imgix from 'react-imgix';

import CroppingMask from '@client/forms/components/CroppingMask';
import Select from '@client/forms/inputs/Select';
import Slider from '@client/forms/inputs/Slider';
import { MAX_IMG_WIDTH, MAX_IMG_HEIGHT } from '@client/utils/constants';
import { imgixUrl, imgixCrop } from '@shared/images';

import {
  $root,
  $select,
  $image,
  $slider,
  $noimg
} from './style';

// Export this constant, for testing.
export const CROP_FOCAL_POINT = 'CROP_FOCAL_POINT';

// Preview contexts are formatted to match the syntax <Select> wants for options (based on Monolith end-user display ratios).
export const PREVIEW_CONTEXTS = [
  { label: 'Vertical Card', value: '7:4' },
  { label: 'Horizontal Card', value: '1:1' },
  { label: 'Article Header', value: '16:9' },
  { label: 'Article Inline', value: '2:2' }
];

/**
 * Get the maximum dimension of our image.
 * @param {string} context value of the current context
 */
export function getDimensions (context) {
  const [width, height] = context.value.split(':').map((value) => parseInt(value));

  // images will be limited to the MAX_IMG_WIDTH or MAX_IMG_HEIGHT (the smaller value), respecting the ratio.
  return width > height ? { w: MAX_IMG_WIDTH } : { h: MAX_IMG_HEIGHT };
}

// When using focal point cropping, we set the aspect ratio of the full image
// (i.e. NOT the cropping mask) to 23:15, which gives us a 460px by 300px preview image.
export const FOCAL_POINT_AR = '23:15';

/**
 * Generate imgix params based on the current image. Exported for testing.
 * @param {object} dimensions
 * @param {boolean} isFocalPoint do we have focal point cropping selected?
 * @param {object} previewContext the selected preview context
 * @param {object} cropOptions
 * @returns {object}
 */
export function getImgixParams ({ dimensions, isFocalPoint, previewContext, cropOptions }) {
  return {
    ...dimensions,
    // If we're using a crop type other than focal point, change the aspect
    // ratio of the image based on the current context. If we're using focal point
    // cropping, set the aspect ratio to 23:15 (460w x 300h, the size of the preview).
    ar: isFocalPoint ? FOCAL_POINT_AR : previewContext.value,
    // Only include auto, fit, and crop from imgixCrop. Don't include the
    // focal point options here, since we're displaying a cropping mask rather
    // than cropping the actual image.
    auto: cropOptions.auto,
    // When displaying images for focal point cropping, blur any extra space
    // outside of the original image dimensions.
    fit: isFocalPoint ? 'fillmax' : cropOptions.fit,
    ...isFocalPoint && { fill: 'blur', w: MAX_IMG_WIDTH, h: MAX_IMG_HEIGHT },
    crop: cropOptions.crop
  };
}

// Note that we don't care about value or config here, since this input operates
// directly on multiple fields (focalPointX, focalPointY, focalPointZ) in formData.
export default function FocalPoint ({ onChange, formData }) {
  // When calculating new focal points, use the rect from the current ref.
  const ref = useRef();

  // The preview context only exists in local state. It's never saved to the server.
  const [previewContext, setPreviewContext] = useState(PREVIEW_CONTEXTS[0]);
  const cropOptions = imgixCrop(formData);
  const dimensions = getDimensions(previewContext);
  const isFocalPoint = formData.cropType === CROP_FOCAL_POINT;

  // Get imgix params for the preview image.
  const imgixParams = getImgixParams({ dimensions, isFocalPoint, previewContext, cropOptions });

  /**
   * Change the preview context when a new option is selected.
   * @param {object} serverData
   */
  const onContextSelect = (serverData) => {
    const newContext = PREVIEW_CONTEXTS.find((ctx) => ctx.value === serverData.context);

    setPreviewContext(newContext);
  };

  /**
   * Handle change event on zoom slider.
   * @param {number} zoom
   */
  const onZoom = ({ zoom }) => {
    onChange({ focalPointZ: zoom });
  };

  /**
   * Handle click event on CroppingMask.
   * @param {Event} e
   */
  const onClick = (e) => {
    if (!ref.current || !isFocalPoint) {
      return;
    }

    const rect = ref.current.getBoundingClientRect();
    // Get the x and y offsets inside the image container.
    const x = e.clientX - rect.left;
    const y = e.clientY - rect.top;
    // Then get the focal point values based on the container dimensions.
    const focalPointX = round(x / MAX_IMG_WIDTH, 2);
    const focalPointY = round(y / MAX_IMG_HEIGHT, 2);

    onChange({ focalPointX, focalPointY });
  };

  return (
    <div css={$root}>
      <div css={$select}>
        <Select
          name='context'
          value={previewContext.value}
          config={{ options: PREVIEW_CONTEXTS }}
          onChange={onContextSelect}
        />
      </div>

      {/* Display the image if it exists, otherwise display a message. */}
      {formData.url
        ? (
          <div ref={ref} css={$image(isFocalPoint)} onClick={onClick}>
            <Imgix
              width={dimensions.w}
              height={dimensions.h}
              src={imgixUrl(formData.url)}
              alt={formData.altText || 'Blank Image'}
              imgixParams={imgixParams}
            />
            {/* If we're focal point cropping, display the cropping mask. */}
            {isFocalPoint
              ? (
                <CroppingMask
                  x={Math.round(formData.focalPointX * MAX_IMG_WIDTH)}
                  y={Math.round(formData.focalPointY * MAX_IMG_HEIGHT)}
                  zoom={formData.focalPointZ}
                  aspectRatio={previewContext.value}
                />
                )
              : null}
          </div>
          )
        : (
          <div css={$noimg}>No image selected</div>
          )}

      {/* If an image exists and we're focal point cropping, display the zoom slider. */}
      {formData.url && isFocalPoint
        ? (
          <div css={$slider}>
            <Slider
              name='zoom'
              value={formData.focalPointZ}
              config={{
                minValue: 1,
                maxValue: 3,
                step: 0.01,
                marks: { 1: '100%', 2: '200%', 3: '300%' }
              }}
              onChange={onZoom}
            />
          </div>
          )
        : null}
    </div>
  );
}

FocalPoint.propTypes = {
  /** Function that updates the form state and persists data */
  onChange: PropTypes.func,
  formData: PropTypes.object
};

FocalPoint.displayName = 'FocalPointGroup';
