import { get, isNumber } from 'lodash-es';
import ReactDOM from 'react-dom';

import { queries } from '@client/common/graph';

import { contentNodeIsSelected } from '../formats/helpers';

export default function createReactNodeView ({
  node,
  view,
  getPos,
  Component,
  portals,
  updatePortals,
  formData,
  client,
  validatedContent
}) {
  const setCaptionCallback = (node, caption) => {
    const state = view.state;
    const position = getPos();
    // Sometimes, getPos() returns undefined, which causes setModeMarkup to error.
    // We only want to update the image node if we are able to find its position.
    // TODO: investigate better ways to find node position within a ProseMirror NodeView.
    if (isNumber(position)) {
      // Set bestCaption attr on the Prosemirror node, which will be used in
      // generating the HTML for the monolith.
      const tr = state.tr.setNodeMarkup(position, node.type, { ...node.attrs, bestCaption: caption });
      view.dispatch(tr);
    }
  };
  /**
   * Replace the current (blank) content with the content we've picked in the
   * Content Picker. We pass in a custom function because we need to update
   * the Prosemirror document rather than doing the default onReplace behavior.
   * @param {object} node we're modifying in the prosemirror document
   * @returns {Function} that's called with the id of the new content
   */
  const customOnReplace = (node) => async (id) => {
    const state = view.state;
    const position = getPos();
    // Sometimes, getPos() returns undefined, which causes setModeMarkup to error.
    // We only want to update the image node if we are able to find its position.
    // TODO: investigate better ways to find node position within a ProseMirror NodeView.
    if (isNumber(position)) {
      // Unlike the normal onReplace, this function does NOT delete the old
      // (blank) content, because that might cause a race condition with the
      // updates to the prosemirror document. Instead, it simply replaces
      // the node attrs with a reference to the new content.

      // First, fetch data for the new content.
      const newContent = get(await client.query({
        query: queries.fullContent,
        variables: { id }
      }), 'data.content');

      // Update node in the prosemirror document. When InlineImage re-renders,
      // it'll call setCaptionCallback() to update the node's caption.
      const tr = state.tr.setNodeMarkup(position, node.type, {
        ...node.attrs,
        contentId: id
      });

      view.dispatch(tr);

      // Return data for the new content, so we can update the drawer.
      // (this is done in ContentResults)
      return newContent;
    }
  };
  const dom = document.createElement('div');
  dom.ondragstart = (e) => {
    e.stopPropagation(); // prevent propagation to child elements which aren't draggable
    // The ProseMirror content needs to be transformed by its position in the document.
    e.dataTransfer.setData('application/json', JSON.stringify({ nodePosition: getPos() }));
  };
  const Portal = () => ReactDOM.createPortal(
    <Component
      node={node}
      id={node.attrs.contentId}
      isFullWidth={node.attrs.isFullWidth}
      formData={formData}
      fieldName={name}
      onCaptionChange={setCaptionCallback}
      customOnReplace={customOnReplace}
      isSelected={contentNodeIsSelected(node, view.state)}
      validatedContent={validatedContent}
    />,
    dom
  );
  updatePortals([...portals.current, Portal]);
  // Returns object that implements ProseMirror NodeView interface
  return { dom };
}
