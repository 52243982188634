import { css } from '@emotion/core';

import { isLevelerFeatureOn } from '@client/forms/inputs/ProsemirrorLeveler/utils/feature';
import { DRAWER_INNER_WIDTH, MODAL_WIDTH } from '@client/utils/constants';
import { getVariantColor } from '@client/utils/styles';

export const $root = (hasTabs) => () => css`
  padding: ${hasTabs ? '10px 0 0' : '20px'};
  width: 100%;
`;

const fieldWidth = `${DRAWER_INNER_WIDTH}px`;
const modalFormWidth = `${MODAL_WIDTH - 36}px`;
const modalFieldWidth = `${(MODAL_WIDTH - 36) / 2 - 10}px`;
const wideFieldWidth = isLevelerFeatureOn ? '1200px' : '700px';
const twoColumnWidth = '1100px';

// Form columns are 460px wide, so we need to switch from 1-column to 2-column
// at 1100px (2 x 460px columns plus a gutter between them).
export const $fieldsWrapper = (layout) => {
  switch (layout) {
    case 'wide':
      return css`
        margin: 0 auto;
        max-width: 100%;
        width: ${wideFieldWidth};
      `;
    case 'two-column':
      return css`
        column-width: ${fieldWidth};
        margin: 0 auto;
        max-width: 100%;
        width: ${fieldWidth};

        & > * {
          display: inline-block;
        }

        @media screen and (min-width: ${twoColumnWidth}) {
          width: ${twoColumnWidth};
        }
      `;
    case 'two-column-modal':
      return css`
        column-width: ${modalFieldWidth};
        margin: 0 auto;
        width: ${modalFormWidth};
        padding: 2px;

        & > * {
          display: inline-block;
        }
      `;
    default:
      return css`
        max-width: 100%;
        width: ${fieldWidth};
      `;
  }
};

// react-tabs reacts weirdly to the `css` prop, so we're adding all of the
// styles for children inside the $tabs export.
export const $tabs = ({ colors, typography }) => [
  typography.size.M,
  typography.family.circular,
  css`
    width: 100%;

    .react-tabs__tab-list {
      align-items: center;
      border-bottom: 1px solid ${colors.ui.greyLight[100]};
      display: flex;
      justify-content: space-around;
      list-style: none;
      margin: 0;
      padding: 0;
    }

    .react-tabs__tab {
      border-bottom: 3px solid transparent;
      color: ${colors.ui.greyDark[700]};
      cursor: pointer;
      flex: 0 0 auto;
      padding: 10px 10px 6px;
      transition: border-bottom-color 300ms ease-out;

      &:hover,
      &:focus {
        border-bottom-color: ${colors.ui.coreBlue[300]};
        transition: background-color 250ms;
      }

      svg {
        font-size: 16px;
        margin-right: 5px;
      }
    }

    .react-tabs__tab--disabled {
      color: ${colors.ui.grey[500]};
      cursor: normal;
    }

    .react-tabs__tab--selected {
      font-weight: bold;
      border-bottom-color: ${colors.ui.coreBlue[500]};
    }

    .react-tabs__tab--selected:hover,
    .react-tabs__tab--selected:focus {
      border-bottom-color: ${colors.ui.coreBlue[500]};
    }

    .react-tabs__tab-panel {
      display: none;
      padding: 20px;
    }

    .react-tabs__tab[data-validation='danger']{
      &:hover,
      &:focus {
        border-bottom-color: ${getVariantColor('danger')};
      }

      color: ${getVariantColor('danger')};
    }

    .react-tabs__tab[data-validation='warning']{
      &:hover,
      &:focus {
        border-bottom-color: ${getVariantColor('warning')};
      }

      svg {
        color: ${getVariantColor('warning')};
      }
    }

    .react-tabs__tab--selected[data-validation='danger']{
      border-bottom-color: ${getVariantColor('danger')};
    }

    .react-tabs__tab--selected[data-validation='warning']{
      border-bottom-color: ${getVariantColor('warning')};
    }

    .react-tabs__tab-panel--selected {
      display: block;
    }
  `
];

export const $section = css`
  border: none;
  margin: 0;
  min-inline-size: auto;
  padding: 0;
  width: 100%;

  &:first-of-type > legend {
    padding-top: 10px;
  }
`;

export const $sectionTitle = ({ colors, typography }) => [
  typography.family.circular,
  typography.size.L,
  css`
    color: ${colors.ui.greyLight[500]};
    margin: 0;
    padding: 20px 0 10px;
  `
];

export const $sectionDescription = ({ colors, typography }) => [
  typography.family.circular,
  typography.size.S,
  css`
    width: ${fieldWidth};
    color: ${colors.ui.grey[700]};
    padding: 0 0 5px 0;
  `
];
