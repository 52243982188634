import { constants } from '@newsela/angelou';

import wire from './wire.svg';

const Wire = {
  app: {
    icon: wire,
    color: constants.colors.ui.greyDark[700],
    accentColor: constants.colors.ui.grey[700],
    title: 'The Wire',
    href: `${process.env.MONOLITH_BASE_URL}/wire/board/#/articles/`,
    isExternalLink: true,
    permissionForAdmin: true,
    permissionForStaff: true,
    permissionForContributor: true,
  },
  isContent: false
};

export default Wire;
