import React from 'react';

import { faExclamationCircle, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import PropTypes from 'prop-types';

import Icon from '@client/common/components/Icon';
import { VALIDATION_ERROR, VALIDATION_WARNING, VARIANT_ERROR } from '@client/utils/constants';

import { $root, $icon } from './style';

export default function ValidationMessage ({ variant, message, size = 'SMALL' }) {
  const icon = variant === VARIANT_ERROR ? faExclamationCircle : faExclamationTriangle;
  const iconSize = size === 'SMALL' ? 12 : 18;
  const preMessage = variant === VARIANT_ERROR ? `${VALIDATION_ERROR.toUpperCase()}:` : `${VALIDATION_WARNING.toUpperCase()}:`;

  if (!message) {
    return null;
  }

  return (
    <div css={$root(variant, size)}>
      <Icon icon={icon} size={iconSize} customCss={$icon(variant, size)} />
      <span><strong>{preMessage}</strong> {message}</span>
    </div>
  );
}

ValidationMessage.propTypes = {
  /**
   * Type of validation, either 'danger' (for errors) or 'warning'. This matches
   * the syntax of MineralUI's field variants. */
  variant: PropTypes.string,
  /** Validation message to display. */
  message: PropTypes.string,
  /** Validation message size to display. */
  size: PropTypes.oneOf(['SMALL', 'LARGE'])
};
