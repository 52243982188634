/* eslint-disable camelcase */
import { css } from '@emotion/core';

export const $root = ({
  backgroundColor_active,
  borderColor
}) => css`
  background-color: ${backgroundColor_active};
  border-bottom: 1px solid ${borderColor};
  padding: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  // TODO: Refactor Forms to allow custom margins
  // so we can remove these hacky styles
  margin: 0 -20px;
`;

export const $wordForm = ({ typography, colors }) => [
  typography.family.circular,
  typography.size.M,
  css`
  color: ${colors.ui.greyDark[700]};
  font-weight: 500;
  margin-bottom: 8px;
`];

export const $gradeBand = ({ typography, colors }) => [
  typography.family.circular,
  typography.size.XS,
  css`
  color: ${colors.ui.grey[700]};
`];

export const $isSuggested = (suggested) => ({ typography, colors }) => [
  typography.size.S,
  css`
    color: ${suggested ? colors.ui.grey[700] : colors.ui.red[700]};
    margin-right: 24px;
  `
];

export const $trash = ({ colors }) => css`
  border: 1px solid transparent;
  cursor: pointer;
  margin-left: 24px;

  &:focus, &:active {
    border: 1px solid ${colors.ui.red[500]};
  }
`;

export const $definitionArrow = ({ colors }) => css`
  border: 1px solid transparent;
  cursor: pointer;

  &:focus, &:active {
    border: 1px solid ${colors.ui.coreBlue[500]};
  }
`;
