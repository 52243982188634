import { faSignalStream } from '@fortawesome/pro-light-svg-icons';
import { constants } from '@newsela/angelou';
import cuid from 'cuid';
import deline from 'deline';
import gql from 'graphql-tag';
import { escapeRegExp } from 'lodash-es';
import smartquotes from 'smartquotes';

import { transformSlug } from '@client/utils/fields';
import { EXCLUDED_STREAM_IDS } from '@shared/constants';

import rawSchema from '../../schema';

function filter (query) {
  const quoted = smartquotes(query);

  return {
    or: [
      { streamTitle: { allofterms: quoted } },
      { streamDescription: { alloftext: query } },
      { slug: { regexp: `/${escapeRegExp(quoted)}/i` } }
    ]
  };
}

function quickFilter (query) {
  const quoted = smartquotes(query);

  return {
    streamTitle: { allofterms: quoted },
    rootFn: 'streamTitle|allofterms'
  };
}

const Stream = {
  outlineFragment: gql`
    fragment outlineStream on Stream {
      id
      uid
    }
  `,
  searchFragment: gql`
    fragment searchStream on Stream {
      id
      uid
      createdAt
      updatedAt
      name
      subject
      custom
      exemplar
    }
  `,
  blueprintFragment: gql`
    fragment blueprintStream on Stream {
      id
    }
  `,
  fullFragment: gql`
    fragment fullStream on Stream {
      id
      uid
      name
      createdAt
      updatedAt
      streamTitle
      slug
      streamDescription
      subject
      custom
      exemplar
      isBrandedProduct
    }
  `,
  filter,
  quickFilter,
  defaults: (id = cuid(), data = {}) => {
    const uid = data.uid || `_:${id}`;
    // newLabel is passed in from AsyncSelect, when new streams are created
    // and when existing streams are added.
    const streamTitle = data.newLabel || null;

    return {
      client: {
        id,
        uid,
        __typename: 'Stream',
        name: streamTitle || 'Stream',
        createdAt: (new Date()).toISOString(),
        updatedAt: (new Date()).toISOString(),
        streamTitle,
        slug: null,
        streamDescription: null,
        subject: 'OTHER',
        custom: false,
        exemplar: false,
        isBrandedProduct: true
      },
      server: {
        id,
        uid,
        streamTitle
      }
    };
  },
  app: {
    icon: faSignalStream,
    color: constants.colors.ui.greyDark[700],
    accentColor: constants.colors.ui.grey[700],
    title: 'Streams',
    href: '/streams',
    permissionForAdmin: true,
    permissionForStaff: true,
    permissionForContributor: false,
    columns: [
      {
        value: 'id',
        showColumn: false
      },
      {
        value: 'uid',
        showColumn: false
      },
      {
        label: 'Title',
        value: 'name',
        isClickable: true
      },
      {
        value: 'subject',
        enum: true
      },
      {
        value: 'custom',
        checked: true
      },
      {
        value: 'exemplar',
        checked: true
      },
      {
        label: 'Created On',
        value: 'createdAt',
        date: true
      },
      {
        label: 'Updated On',
        value: 'updatedAt',
        date: true
      }
    ],
    order: { asc: 'streamTitle' }
  },
  icon: faSignalStream,
  typename: 'Stream',
  openInApp: false,
  forms: {
    // Streams are only edited in their own app.
    app: [
      {
        input: 'text',
        name: 'streamTitle',
        label: 'Title',
        transform: (data = '') => smartquotes(data)
      },
      {
        input: 'text',
        name: 'slug',
        transform: (data = '') => transformSlug(data),
        button: {
          input: 'magic-button',
          value: (data) => data.streamTitle,
          description: 'Copy title'
        }
      },
      {
        input: 'textarea',
        name: 'streamDescription',
        placeholder: deline`Enter a description to differentiate this stream from others.
        This description will only appear in Alexandria.`,
        transform: (data = '') => smartquotes(data)
      },
      {
        input: 'select',
        name: 'subject',
        isCreatable: false,
        enum: true,
        options: rawSchema.enums.StreamSubject
      },
      {
        input: 'checkbox',
        name: 'custom',
        longLabel: 'Is Custom',
        showLabel: false,
        caption: 'Custom streams are built for a specific buyer and have different user-facing messaging than streams intended for general sale'
      },
      {
        input: 'checkbox',
        name: 'exemplar',
        longLabel: 'Is Exemplar',
        showLabel: false,
        caption: 'Indicates that this stream is intended as an exemplar, test, template, or proof of concept. This includes streams shared with end users as part of a sales process or beta test'
      }, {
        input: 'checkbox',
        name: 'isBrandedProduct',
        longLabel: 'Is Branded Product',
        showLabel: false,
        caption: 'Branded products appear in the My Content menu, on content cards, etc; non-branded products (like Essentials) are hidden from those menus.'
      },
      {
        input: 'static',
        name: 'id',
        label: 'Content ID',
        caption: 'Used for debugging'
      }
    ]
  },
  inputs: {
    subjectProductStreams: {
      input: 'checkbox-group',
      name: 'streams',
      label: 'Subject product streams',
      isInline: true,
      type: 'Stream',
      query: 'subjectProductStreams',
      mapping: {
        label: 'name',
        value: 'id'
      },
      shortNames: {
        'Social Studies': 'SS'
      }
    },
    customStreams: {
      input: 'select',
      name: 'streams',
      label: 'Additional streams',
      isAsync: true,
      isMulti: true,
      isRelation: true,
      isCreatable: false,
      type: 'Stream',
      query: 'customStreams',
      showDropdown: false,
      mapping: {
        label: 'name',
        value: 'uid',
        excludeIds: EXCLUDED_STREAM_IDS
      }
    }
  }
};

export default Stream;
