import { css } from '@emotion/core';
import { constants } from '@newsela/angelou';

const verticalPadding = '8px';
const horizontalPadding = '16px';

export const $root = ({ colors }) => css`
  background-color: ${colors.ui.white[500]};
  border-radius: 4px;
  box-shadow: 0px 2px 6px rgba(29, 29, 29, 0.16);
  list-style: none;
  margin: 0;
  padding: ${verticalPadding} 0;
  width: 230px;
  z-index: 1;
`;

export const $header = css`
  padding: 0 ${horizontalPadding} ${verticalPadding};
`;

export const $menuTitle = ({ typographyStyles, colors }) => [
  typographyStyles.heading5,
  css`
    color: ${colors.ui.greyDark[500]};
    margin: 0;
  `
];

export const $attachedCount = ({ typographyStyles, colors }) => [
  typographyStyles.textS,
  css`
    color: ${colors.ui.grey[500]};
  `
];

// These styles are passed directly into Angelou's Button, so they don't
// need css().
export const $button = `
  border-radius: 0;
  color: ${constants.colors.ui.grey[700]};
  justify-content: flex-start;
  padding: ${verticalPadding} ${horizontalPadding};

  & svg {
    margin-right: 8px;
  }
`;

export const $menuGroup = ({ typographyStyles, colors }) => [
  typographyStyles.textCaps,
  css`
    border-bottom: 1px solid ${colors.ui.greyLight[50]};
    color: ${colors.ui.grey[700]};
    font-weight: 400;
    margin: 16px 0 ${verticalPadding};
    padding: ${verticalPadding} ${horizontalPadding};
  `
];
