/* eslint-disable camelcase */
import { css } from '@emotion/core';
import { constants } from '@newsela/angelou';

import { getVariantColor } from '@client/utils/styles';

// The Angelou components `__cssFor` prop takes in a style object, not a css function.
// So we're creating a colors variable explicitly from Angelou
// to be able to use colors in some functions below.
const { colors } = constants;

export const $level = (variant) => {
  const backgroundColor = variant ? getVariantColor(variant, 'light') : colors.ui.greyExtraLight[100];
  const borderColor = variant ? getVariantColor(variant, 'dark') : colors.ui.coreBlue[500];
  // The 'root' property is required by the Angelou Button '__cssFor' property.
  return {
    root: css`
      background-color: ${colors.ui.white[500]};
      border: 1px solid ${colors.ui.greyLight[300]};
      border-radius: 20px;
      color: ${colors.ui.coreBlue[500]};
      cursor: pointer;
      margin: 5px;
      outline: none;
      padding: 8px 12px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      & > span { margin-right: 0; }

      &:focus-visible {
        outline: ${borderColor} auto 1px;
      }

      &:hover:not(:focus):not(:active):not([aria-current=true]):not([disabled]),
      &:active:not([aria-current=true]):not([disabled]),
      &[aria-current=true], &[aria-current=true]:hover {
        background-color: ${backgroundColor};
      }

      &[aria-current=true], &[aria-current=true]:hover {
        border: 1px solid ${borderColor};
      }
    `
  };
};

export const $language = ({ colors }) => css`
  color: ${colors.ui.greyLight[500]};
`;

export const $levels = css`
  display: flex;
  flex-wrap: wrap;
`;

export const $levelBlock = css`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  padding: 3px 0;
`;

// creates visual division between language groups
export const $root = ({ colors }) => css`
  & > div:not(:first-of-type)[class$="levelBlock"] {
    border-top: 1px solid ${colors.ui.greyLight[100]};
  }
`;

export const $emptyQuestions = ({ colors }) => css`
  border: 1px dashed ${colors.ui.grey[100]};
  border-radius: 10px;
  background-color: ${colors.ui.white[100]};
  color: ${colors.ui.grey[500]};
  font-size: 16px;
  margin: 10px 0;
  padding: 20px;
  text-align: center;
`;

// Use overridden h6 color from mineral ui theme
export const $label = ({ typography, h6_color }) => [
  typography.family.circular,
  typography.size.S,
  css`
    color: ${h6_color}; 
    font-weight: bold;
    `
];
