import React from 'react';

import { RadioGroup } from 'mineral-ui/Radio';
import PropTypes from 'prop-types';

import isInline from '../../../utils/inline-group';

export default function Radio ({ value, name, config, onChange, variant }) {
  value = value || ''; // Cast empty values to a string.
  const onSelect = (e) => {
    const val = e.target.value;

    if (val) {
      onChange({ [name]: val });
    } else {
      onChange({ [name]: null }, 'unset');
    }
  };

  return (
    <RadioGroup
      inline={isInline(config)}
      name={name}
      {...variant && { variant }}
      checked={value}
      data={config.options}
      onChange={onSelect}
    />
  );
}

Radio.propTypes = {
  /** Field value, from the form-level state */
  value: PropTypes.string,
  /** Field name, which is also the property the data will be saved to */
  name: PropTypes.string,
  /** Full configuration object */
  config: PropTypes.object,
  /** Function that updates the form state and persists data */
  onChange: PropTypes.func,
  variant: PropTypes.string
};
