import { CommentsHollowSVG } from '@newsela/angelou';
import cuid from 'cuid';
import gql from 'graphql-tag';

import { DEFAULT_LANG } from '@client/utils/constants';
import { NO_LEXILE } from '@shared/constants';

const Level = {
  fullFragment: gql`
    fragment fullLevel on Level {
      uid
      rawLexile
      lexile
      gradeBand
      isOriginalLevel
      language
      text(format: TEXT_PLAIN)
      rawText: text(format: TEXT_RAW)
      isActive
    }
  `,
  defaults: (existingId, data = {}) => {
    const id = existingId || cuid();
    const uid = `_:${id}`;
    const language = data.language || DEFAULT_LANG;
    const gradeBand = data.gradeBand || null;
    const isOriginalLevel = data.isOriginalLevel || false;

    return {
      client: {
        __typename: 'Level',
        uid,
        rawLexile: null,
        lexile: NO_LEXILE,
        gradeBand,
        isOriginalLevel,
        language,
        text: null,
        rawText: null,
        isActive: true
      },
      server: {
        uid,
        gradeBand,
        isOriginalLevel,
        language
      }
    };
  },
  icon: CommentsHollowSVG,
  typename: 'Level'
};

export default Level;
