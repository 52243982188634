const { marks } = require('prosemirror-schema-basic');

const bold = {
  ...marks.strong,
  toMarkdown: () => ['**', '**']
};
const code = {
  ...marks.code,
  toMarkdown: () => ['`', '`']
};
const italic = {
  ...marks.em,
  toMarkdown: () => ['_', '_']
};
const link = {
  ...marks.link,
  attrs: {
    href: {},
    title: { default: null },
    target: { default: '_blank' }
  },
  parseDOM: [
    {
      tag: 'a[href]',
      getAttrs: (dom) => {
        return {
          href: dom.getAttribute('href'),
          title: dom.getAttribute('title'),
          target: dom.getAttribute('target')
        };
      }
    }
  ],
  toDOM: (node) => {
    const { href, title, target } = node.attrs;
    return ['a', { href, title, target }, 0];
  },
  toMarkdown: (node) => {
    return node.attrs.title
      ? ['[', `](${node.attrs.href} "${node.attrs.title}")`]
      : ['[', `](${node.attrs.href})`];
  }
};
const strikethrough = {
  parseDOM: [
    { tag: 'strike' },
    { style: 'text-decoration=line-through' },
    { style: 'text-decoration-line=line-through' }
  ],
  toDOM: () => ['span', {
    style: 'text-decoration-line:line-through'
  }, 0],
  toMarkdown: () => ['~~', '~~']
};

const language = {
  attrs: {
    lang_code: { default: 'en' }
  },
  parseDOM: [{
    tag: 'span',
    getAttrs: (dom) => {
      return {
        lang_code: dom.getAttribute('lang'),
      };
    }
  }],
  toDOM: (node) => ['span', { lang: node.attrs.lang_code }, 0],
  toMarkdown: () => ['', '']
};

module.exports = {
  bold,
  code,
  italic,
  language,
  link,
  strikethrough
};
