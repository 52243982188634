import { faListUl } from '@fortawesome/pro-regular-svg-icons';

import { list } from '@shared/prosemirror/blocks';

import {
  wrapperBlockActive,
  wrapInList,
  splitListItem,
  liftListItem,
  sinkListItem
} from './helpers';

const icon = faListUl;

export const blocks = list;
export const keys = {
  Enter: splitListItem('list_block_1'),
  'Shift-Ctrl-8': wrapInList('list_block_0'),
  'Mod-[': liftListItem('list_block_1'),
  'Mod-]': sinkListItem('list_block_1')
};
export const menuBlock = {
  title: 'Bulleted List',
  content: icon,
  active: wrapperBlockActive('list_block_0'),
  run: wrapInList('list_block_0')
};
