/* eslint-disable camelcase */
import { css } from '@emotion/core';

const sideMargins = '24px';

export const $root = (appColor) => ({ colors, typography, headerHeight, zIndex_400 }) => [
  typography.family.circular,
  css`
  align-items: center;
  background-color: ${appColor};
  color: ${colors.ui.white[500]};
  display: flex;
  height: ${headerHeight};
  justify-content: space-between;
  width: 100%;
  z-index: ${zIndex_400};
`];

export const $nav = ({ colors, typography }) => [
  typography.family.circular,
  typography.size.XL,
  css`
  align-items: center;
  color: ${colors.ui.white[500]};
  display: flex;
  flex: 0 0 auto;
  justify-content: flex-start;

  a {
    color: ${colors.ui.greyExtraLight[500]};
  }
`];

export const $info = ({ breakpoint_wide }) => [
  css`
  position: absolute;
  right: 175px;

  /* float on small screens */
  @media screen and (max-width: ${breakpoint_wide}) {
    position: absolute;
    right: 20px;
  }
`];

export const $home = ({ headerHeight }) => css`
  display: flex;
  align-items: center;
  height: ${headerHeight};
  padding: 0 ${sideMargins};
`;

export const $contentTitle = (accentColor) => ({ colors, typography, headerHeight }) => [
  typography.size.L,
  css`
  border-left: 2px solid ${accentColor};
  height: ${headerHeight};
  padding: ${sideMargins};
  color: ${colors.ui.white[500]};
`];

export const $link = css`
  text-decoration: none;
`;

export const $title = (accentColor) => ({ colors, typography, headerHeight }) => [
  typography.size.XL,
  css`
  border-left: 2px solid ${accentColor};
  color: ${colors.ui.white[500]};
  height: ${headerHeight};
  margin: 0;
  padding: 16px ${sideMargins};
`];

export const $body = ({ breakpoint_medium }) => css`
  display: none;

  /* only display on wider screens */
  @media screen and (min-width: ${breakpoint_medium}) {
    align-items: center;
    display: flex;
    flex: 0 0 auto;
    justify-content: flex-end;
    padding-right: ${sideMargins};
  }
`;

export const $userWrapper = (accentColor) => ({ breakpoint_wide, headerHeight }) => css`
  display: none;

  /* only display on wider screens */
  @media screen and (min-width: ${breakpoint_wide}) {
    align-items: center;
    display: flex;
    flex: 0 0 auto;
    flex-flow: row wrap;
    height: ${headerHeight};
    justify-content: flex-end;
    margin: 0 0 0 20px;
    padding: 16px 0 16px 20px;
  }
`;

export const $user = css`
  display: flex;
  flex: 1 0 auto;
  flex-flow: column nowrap;
`;

export const $userName = ({ colors, typography }) => [
  typography.family.circular,
  typography.size.S,
  css`
  color: ${colors.ui.white[500]};
  flex: 0 0 auto;
  text-align: right;
`];

export const $userRole = ({ colors, typography }) => [
  typography.family.circular,
  typography.size.XS,
  css`
  color: ${colors.ui.white[500]};
  flex: 0 0 auto;
  text-align: right;
`];
