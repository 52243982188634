import { isEmpty } from 'lodash-es';
import { DecorationSet, Decoration } from 'prosemirror-view';

/**
 * Create decoration for a word, based on the regex match. Exported for testing.
 * @param {object} word with { wordForm }
 * @param {number} pos index of this node in the document
 * @param {array} decorations
 * @param {Function} getObjectToAdd
 * @returns {Function} that is called with each match
 */
export function decorateWord ({
  word,
  pos,
  decorations,
  getObjectToAdd
}) {
  return (match) => {
    // Start position of this text node PLUS start position of the match.
    const fromPos = pos + match.index;
    // fromPos PLUS length of the match/word.
    const toPos = fromPos + match.text.length;

    // Create the decoration itself.
    const decoration = Decoration.inline(fromPos, toPos, getObjectToAdd(word));

    decorations.push(decoration);
  };
}

/**
 * Run a regex against text, getting all matches with their indices.
 * Exported for testing.
 * @param {RegExp} regex
 * @param {string} text
 * @returns {array} of matches with { index, text }
 */
export function getTextMatches (regex, text) {
  const matches = [];
  let match;

  // Note: running the regex this way will allow us to access `.index`
  // on each word match.
  while ((match = regex.exec(text)) !== null) {
    matches.push({
      index: match.index,
      text: match[0]
    });
  }

  return matches;
}

/**
 * Generate text decorations
 * @param {object} state
 * @param {array} words
 * @param {boolean} allowDuplicates should we decorate the same word in multiple places?
 * @param {Function} getObjectToAdd
 * @returns {Function}
 */
export default function decorateWords (state, words, allowDuplicates, getObjectToAdd) {
  const decorations = [];
  const foundWords = [];

  let latestPosition = 0;
  words.forEach((word) => {
    // For each word in our list...

    state.doc.descendants((node, pos) => {
      // For each node in the document...
      if (!allowDuplicates && (pos < latestPosition || foundWords.includes(word.wordForm))) {
        // As we're looping through the words, we know that each word will be
        // in the order it appears within the document. When iterating through
        // the nodes of the document, we can ignore positions BEFORE the last
        // word's position, because the current word won't appear before a previous
        // word.

        // Additionally, if we've already found this word we can return early.

        // When allowing duplicates, we don't care about either of those two
        // situations, as we want to always scan the entire document for all
        // instances of a word!
        return;
      }

      const wordRegex = new RegExp(`\\b${word.wordForm}\\b`, 'ig');

      if (node.isText) {
        // Scan text nodes for the word.
        const matches = getTextMatches(wordRegex, node.text);

        if (!isEmpty(matches)) {
          // We've found the word at least once in this node!
          if (!foundWords.includes(word.wordForm)) {
            foundWords.push(word.wordForm);
          }

          // Set the latestPosition, so we'll start looking for the NEXT word
          // after the point where we found this word (Because words are always
          // in the order that they appear in the text).
          // pos: start position of this text node (scoped to the full document)
          latestPosition = pos;
          // If we're allowing duplicates, iterate through all of the matches and
          // decorate all of them. Otherwise, just decorate the first one.
          if (allowDuplicates) {
            matches.forEach(decorateWord({ word, pos, decorations, getObjectToAdd }));
          } else {
            decorateWord({ word, pos, decorations, getObjectToAdd })(matches[0]);
          }
        }
      }
    });
  });
  return DecorationSet.create(state.doc, decorations);
}
