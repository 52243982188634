import { TARGET_LEXILE, TARGET_WORD_COUNT } from '@client/utils/constants';
import { NO_LEXILE } from '@shared/constants';

/**
 * Format the expected range of values. Note that the original level doesn't
 * have an expected range, it simply returns 'N/A'.
 * @param {string} gradeBand
 * @param {string} type
 * @param {boolean} isOriginalLevel
 * @returns {string}
 */
export function getTarget (gradeBand, type, isOriginalLevel) {
  if (isOriginalLevel) {
    return 'N/A';
  }

  const source = type === 'rawLexile' ? TARGET_LEXILE : TARGET_WORD_COUNT;
  const [low, high] = source[gradeBand];

  return `${low}–${high}`;
}

/**
 * Format raw lexile number.
 * @param {number} num
 * @returns {string}
 */
// TODO: remove this function once lexile property is backfilled for LexileBlocks in lexilePerParagraph
export function formatLexile (num) {
  if (!num || num < -400) {
    // -400 is the lower lexile limit, but Lexile API will return
    // -9999 to denote a null lexile score.
    return NO_LEXILE;
  } else if (num < 0) {
    // Negative lexile scores between 0 and -400 denote 'Beginning Reader'.
    return `BR${Math.abs(num)}L`;
  } else {
    // Positive lexile scores
    return `${num}L`;
  }
}
