import React from 'react';

import { isEmpty } from 'lodash-es';
import PropTypes from 'prop-types';

import * as schemas from '@client/common/schema';
import FormField from '@client/forms/components/FormField';

import { parseFormConfig } from '../Form/form-config';
import { $section, $sectionTitle, $fieldsWrapper } from '../Form/style';

export default function InlineForm ({
  data,
  type,
  fieldName,
  parent,
  form,
  id,
  breadcrumbs,
  validatedContent = {},
  onChange,
  layout,
  lexileInView
}) {
  const schema = type && schemas[type];

  // Form can either be passed in directly, or derived from the schema.
  // Note that validation is only allowed if type is passed in. Also note that
  // inline forms ALWAYS use the 'shared' form if a config isn't passed in.
  form = form || schema?.forms?.shared;

  const formConfig = parseFormConfig(form, type);

  if (isEmpty(formConfig)) {
    return null; // No form to render!
  }

  const hasSections = formConfig.find((item) => item.type === 'section');

  // These props are passed into every form field. 'field' is the only prop
  // specific to an individual field.
  const fieldProps = {
    validatedContent,
    data,
    onChange,
    breadcrumbs,
    parent,
    lexileInView,
    layout // Inline form fields will all have the same layout.
  };

  const renderField = (field, index) => {
    return (
      <FormField
        {...fieldProps}
        key={`field-${index}`}
        field={field}
        fieldPath={`${fieldName}.${field.name}`}
        formId={id}
      />
    );
  };

  const renderSection = (section, index) => {
    if (section.title) {
      // Render a section wrapper.
      return (
        <fieldset key={`section-${index}`} css={$section}>
          <legend css={$sectionTitle}>{section.title}</legend>
          {section.fields.map((field, index) => renderField(field, index))}
        </fieldset>
      );
    } else {
      // Render the fields themselves, without a section wrapper.
      return section.fields.map((field, index) => renderField(field, index));
    }
  };

  return (
    <>
      {/* This form has sections. */}
      {hasSections
        ? (
          <div css={$fieldsWrapper(layout)}>
            {formConfig.map(renderSection)}
          </div>
          )
        : null}

      {/* This form has no sections. */}
      {!hasSections
        ? (
          <div css={$fieldsWrapper(layout)}>
            {formConfig.map(renderField)}
          </div>
          )
        : null}
    </>
  );
}

InlineForm.propTypes = {
  // InlineForm uses similar properties as Form...
  data: PropTypes.object,
  // This is necessary for Power Words for
  // access to the ArticleLevel's parent (the Article)
  parent: PropTypes.object,
  type: PropTypes.string,
  form: PropTypes.array,
  fieldName: PropTypes.string,
  id: PropTypes.string, // Id of the parent content
  breadcrumbs: PropTypes.array,
  validatedContent: PropTypes.object,
  // But also includes its own properties!
  onChange: PropTypes.func,
  layout: PropTypes.string,
  lexileInView: PropTypes.bool
};
