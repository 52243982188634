import smartquotes from 'smartquotes';

/**
 * Sanitize the text by removing some special characters.
 * @param {String} text
 * @returns {String} sanitized text
 */
export function sanitizeText (text) {
  return text
    .replace(/(?:&nbsp;|\u00a0)/g, ' ') // Remove nonbreaking spaces.
    .replace(/(\u{2028}|\u{2029})/ug, '') // Remove Line and Paragraph separators.
    /* eslint-disable-next-line no-control-regex */
    .replace(/\u{0009}/ug, ' '); // Replace Tab with Space.
}

/**
 * Transform text when pasting into the editor.
 * Sanitize the text by removing some special characters,
 * and educating quotes and dashes.
 * @param {String} text
 * @returns {String} sanitized text
 */
export function transformPastedText (text) {
  // Educate quotes and dashes.
  return smartquotes(sanitizeText(text
    .replace(/\u002d\u002d/g, '\u2014')
  ));
}

/**
 * Transform html when pasting into the editor.
 * This function is called BEFORE the html is parsed by Prosemirror.
 * @param {String} html
 * @return {String} sanitized html
 */
export function transformPastedHTML (html) {
  return sanitizeText(html) // Remove some special characters.
    // Add target="_blank" to all links, but don't duplicate it if it's already present.
    .replace(/<a (?:target=".*?"\s?)?(.*?)(?:\s?target=".*?")?>/g, '<a $1 target="_blank">');
}
