const { nodes } = require('prosemirror-schema-basic');
const { orderedList: oL, bulletList, listItem } = require('prosemirror-schema-list');

const code = {
  ...nodes.code_block,
  toMarkdown: () => ['```\n', '\n```\n']
};

const pre = {
  content: 'inline*',
  group: 'block',
  defining: true,
  parseDOM: [{ tag: 'pre' }],
  toDOM: () => ['pre', 0],
  toMarkdown: () => ['```\n', '\n```\n']
};

const powerWord = {
  content: 'text*',
  group: 'inline',
  marks: '', // Does not allow marks. https://prosemirror.net/docs/guide/#schema.marks
  isolating: true,
  draggable: false,
  selectable: true,
  inline: true,
  attrs: {
    id: {},
    definition: { default: null }
  },
  // When we serialize to HTML, we want to use the `toDOM` method.
  // This will convert the node to a DOM node, which we can then
  // serialize to HTML.
  // This is used when copying and pasting, for example.
  parseDOM: [{ class: 'power-word' }],
  toDOM: (node) => {
    const className = `power-word power-word-${node.attrs.definition ? 'defined' : 'undefined'}`;
    return ['span', {
      class: className,
      'data-id': node.attrs.id,
      'data-isdefined': !!node.attrs.definition
    }, 0];
  },
  // Power words render to normal plaintext in Markdown.
  toMarkdown: () => ['', '']
};

const image = {
  // contentId is an attribute that gets added on node creation, and is what allows us to know to make
  // calls to the server to fetch extra data when rendering this block to HTML (see @client/.../Prosmirror/formats/image.js)
  // bestCaption and isFullWidth are updated via user interaction with the Prosemirror Editor.
  attrs: {
    bestCaption: { default: null },
    contentId: {},
    isFullWidth: { default: true }
  },
  group: 'block',
  // image blocks are the first Prosemirror node to include data from the server in its translation to HTML.
  // See related implementation in: serializeNode in server/utils/prosemirror-dom-serializer.js
  // and renderTo in server/utils/prosemirror.js
  toDOM: (node, serverData) => {
    return ['figure', {},
      ['img', {
        src: serverData?.url || '', // Adding an empty string here prevents errors in the sync to the monolith
        alt: serverData?.altText,
        // The monolith expects to parse inline image data from the HTML Article text.
        // We've agreed to pass through metadata about the image record via HTML data attributes.
        'data-contentId': node.attrs.contentId,
        'data-isFullWidth': node.attrs.isFullWidth.toString(),
        'data-caption': node.attrs.bestCaption || '' // Adding an empty string here prevents errors in the sync to the monolith
      }],
      // If there is a caption, we'll render it as a <figcaption> otherwise we won't render anything.
      (node.attrs.bestCaption && ['figcaption', node.attrs.bestCaption]) || ''
    ];
  },
  toMarkdown: (node, serverData) => {
    return [`![${serverData?.altText || ''}](${serverData?.url || ''})`, ''];
  }
};

const ignore = {
  content: 'block+',
  group: 'block',
  defining: true,
  parseDOM: [{ class: 'ignore' }],
  // Using an Angelou light yellow for visual treatment on the block's background color.
  toDOM: () => ['div', { class: 'ignore' }, 0],
  toMarkdown: () => ['```\n', '\n```\n']
};

const divider = {
  ...nodes.horizontal_rule,
  toMarkdown: () => ['\n---\n', '']
};

// Unlike prosemirror-schema-basic, we only support two levels of headings.
const headings = {
  ...nodes.heading,
  attrs: { level: { default: 2 } },
  parseDOM: [
    { tag: 'h1', attrs: { level: 2 } },
    { tag: 'h2', attrs: { level: 2 } },
    { tag: 'h3', attrs: { level: 3 } },
    { tag: 'h4', attrs: { level: 3 } },
    { tag: 'h5', attrs: { level: 3 } },
    { tag: 'h6', attrs: { level: 3 } }
  ],
  toDOM: (node) => ['h' + node.attrs.level, 0],
  toMarkdown: (node) => [node.attrs.level === 2 ? '## ' : '### ', '']
};

// FYI: the order of the items in the orderedList and list blocks are important.
const orderedList = [{
  ...oL,
  content: 'orderedList_block_1+',
  group: 'block',
  toMarkdown: (node) => {
    // Add the list item number to all child nodes, so we can use that when
    // serializing them.
    node.content = node.content.map((item, index) => ({ ...item, _listItem: index + 1 }));
    return ['', '\n'];
  }
}, {
  ...listItem,
  parseDOM: [{
    ...listItem.parseDOM[0],
    // Limit the parsing context to disambiguate between <li> tags
    // within <ul> vs <ol> parent elements.
    context: 'orderedList_block_0/'
  }],
  content: 'paragraph block*',
  toMarkdown: (node) => [`${node._listItem}. `, '']
}];

const list = [{
  ...bulletList,
  content: 'list_block_1+',
  group: 'block',
  toMarkdown: () => ['', '\n']
}, {
  ...listItem,
  parseDOM: [{
    ...listItem.parseDOM[0],
    // Limit the parsing context to disambiguate between <li> tags
    // within <ul> vs <ol> parent elements.
    context: 'list_block_0/'
  }],
  content: 'paragraph block*',
  toMarkdown: () => ['* ', '']
}];

const quote = {
  ...nodes.blockquote,
  toMarkdown: () => ['> ', '\n']
};

const doc = {
  ...nodes.doc,
  toMarkdown: () => ['', '']
};

const paragraph = {
  ...nodes.paragraph,
  toMarkdown: () => ['', '\n']
};

const text = {
  ...nodes.text,
  toMarkdown: () => ['', '']
};

const hardBreak = {
  ...nodes.hard_break,
  // In github-flavored markdown, hard breaks are delimited with two spaces
  // before a newline.
  toMarkdown: () => ['  \n', '']
};

module.exports = {
  // Note: the keys in this object are the names of the blocks in the data, e.g.
  // headings will be 'headings_block', 'list' will be 'list_block_0' and 'list_block_1'.
  // All nodes that don't match other blocks should be considered paragraphs,
  // so we put the paragraph tag first here. When the schema is constructed,
  // paragraph will be the first item in the nodes array.
  paragraph,
  code,
  divider,
  headings,
  image,
  orderedList,
  list,
  quote,
  doc,
  text,
  pre,
  ignore,
  powerWord,
  // Prosemirror "hard breaks" are explicit newlines, rather than paragraph breaks.
  // This is snake_cased, because it's a built-in prosemirror format that all
  // (multiline) editors use, thus we don't add the '_block' suffix on the client.
  hard_break: hardBreak
};
