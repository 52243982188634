import { css } from '@emotion/core';

export const $root = ({ colors }) => css`
  align-items: center;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-around;
  background-color: white;
  padding: 5px 10px;
`;

export const $rootCompared = ({ colors }) => css`
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-around;
  background-color: white;
`;

export const $buttonWrapper = () => css`
 margin: 4px 0 4px 4px;
`;

export const $button = ({ colors }) => css`
  color: ${colors.ui.coreBlue[700]};
`;
