import React, { useRef, useEffect } from 'react';

import { useQuery } from '@apollo/client';
import { faImagePolaroid } from '@fortawesome/pro-solid-svg-icons';
import { Image } from '@newsela/angelou';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { find, isEqual, isEmpty } from 'lodash-es';
import PropTypes from 'prop-types';

import Icon from '@client/common/components/Icon';
import { queries } from '@client/common/graph';
import ValidationMessage from '@client/forms/components/ValidationMessage';
import { PUBLISHABLE } from '@client/utils/constants';
import { sortGradeBands, matchGradeBands } from '@client/utils/fields';
import { filterContent, getAggregateMessage } from '@client/utils/form-validation';

import { $commonStyle, $image, $placeholder } from './style';

export default function InlineImage ({
  node,
  id,
  isFullWidth,
  formData,
  fieldName,
  onCaptionChange,
  customOnReplace,
  validatedContent,
  isSelected
}) {
  const gradeBand = formData.gradeBand;
  const language = formData.language;
  const { data: res, loading } = useQuery(queries.fullContent, {
    variables: { id }
  });
  const data = !loading && res && res.content;
  const switchDrawer = useStoreActions((actions) => actions.drawer.switch);
  const drawer = useStoreState((state) => state.drawer.data, isEqual);
  const addImageToVariantsMap = useStoreActions((actions) => actions.forms.validation.addToVariants);
  const isDrawerOpen = !isEmpty(drawer);
  // keep count of clicks on the image in order to handle both single-click and double-click behavior.
  const selected = useRef(0);
  const bestCaption = useRef(null);
  let altText;
  if (data) {
    altText = data.altText || data.name;
  }

  const availableCaptionsInSameLanguage = data?.captionLevels
    ? data.captionLevels.filter((level) => level.language === language && level.isActive).sort(sortGradeBands)
    : null;

  const getBestCaption = () => {
    const availableCaptionLevels = availableCaptionsInSameLanguage.map((caption) => caption.gradeBand);
    const bestMatch = matchGradeBands(gradeBand, availableCaptionLevels);
    const caption = bestMatch
      ? find(availableCaptionsInSameLanguage, (caption) => caption.gradeBand === bestMatch).text
      : null;
    bestCaption.current = caption;
    return caption;
  };

  useEffect(() => {
    addImageToVariantsMap({ [id]: null });
  }, [id]);

  const errors = validatedContent?.errors?.filter(filterContent({ id })) || [];
  const warnings = validatedContent?.warnings?.filter(filterContent({ id })) || [];
  const { variant, message } = getAggregateMessage(errors, warnings, { name: 'image' });
  // When we update the caption levels in the data for this image, we hook into a callback passed from the parent Prosemirror component
  // in order to save this caption as an attribute on the associated Prosemirror node. This is a necessary step that allows us
  // to render the appropriate caption in the HTML we send to the monolith.
  useEffect(() => {
    if (data?.captionLevels) {
      getBestCaption();
      // Send the caption to the PM document when it's changed we don't do this when the
      // component loads for the first time (bestCaption is null) and we don't do this
      // if has not been changed.
      if (bestCaption.current !== null && node.attrs.bestCaption !== bestCaption.current) {
        onCaptionChange(node, bestCaption.current);
      }
    }
  }, [data?.captionLevels]);

  const handleOnClick = () => {
    // Each time we click the node, increment the ref
    selected.current = selected.current + 1;
    // There are two cases where we want a click to trigger a switch in the drawer data:
    // - if the drawer is already open and we single-click on another image node, or
    // - if we are double-clicking an image node before we have clicked another node.
    if (isDrawerOpen || selected.current === 2) {
      // We're in a double click! Open the drawer to edit this image!
      switchDrawer({
        id: data.id,
        type: data.__typename,
        drawerType: PUBLISHABLE,
        parent: {
          id: formData.id, // May be undefined in types like ArticleLevel
          uid: formData.uid,
          field: fieldName,
          fieldType: 'inline-content'
        },
        customOnReplace: customOnReplace(node)
      });
      // and reset the ref
      selected.current = 0;
    }
  };

  const getValidationMessage = () => {
    if (!(variant && message)) {
      return null;
    }
    return (
      <div>
        <ValidationMessage variant={variant} message={message} />
      </div>
    );
  };

  return (
    <div draggable='true' onDragStart={(e) => e.preventDefault() /* This is needed to prevent dragging the caption or other content */}>
      {data && data.url
        ? (
          <div css={$image(isFullWidth, variant)}>
            <figure
              css={$commonStyle(variant)}
              onClick={handleOnClick}
              className={isSelected ? 'selected' : ''}
            >
              <Image src={decodeURI(data.url)} alt={altText} />
              <figcaption>{getBestCaption()}</figcaption>
            </figure>
            {getValidationMessage()}
          </div>
          )
        : (
          <div>
            <div
              css={$placeholder(variant)}
              onClick={handleOnClick}
              className={isSelected ? 'selected' : ''}
            >
              <Icon icon={faImagePolaroid} size={32} />
              <span>Double-click to open the Image Editor</span>
            </div>
            {getValidationMessage()}
          </div>
          )}
    </div>
  );
}

InlineImage.propTypes = {
  /** Prosemirror image_block node */
  node: PropTypes.object,
  /** Image id, from contentId attr */
  id: PropTypes.string,
  /** Full width boolean, from isFullWidth attr */
  isFullWidth: PropTypes.bool,
  /** Data for the object that includes the prosemirror field we're inside of,
   * e.g. ArticleLevel
   */
  formData: PropTypes.object,
  /** Field name we're inside of, e.g. 'text' */
  fieldName: PropTypes.string,
  /** Function that is called when captions are changed */
  onCaptionChange: PropTypes.func,
  /** Function that is passed into the Drawer, which passes it down to
   * the Content Picker.
   */
  customOnReplace: PropTypes.func,
  isSelected: PropTypes.bool,
  /** Errors and warnings when the component gets validated */
  validatedContent: PropTypes.object
};
