import { faBold } from '@fortawesome/pro-regular-svg-icons';

import { bold } from '@shared/prosemirror/marks';

import { markActive, toggleMark } from './helpers';

const icon = faBold;
export const mark = bold;
export const keys = {
  'Mod-b': toggleMark('bold')
};
export const menuMark = {
  title: 'Toggle Bold',
  content: icon,
  active: markActive('bold'),
  run: toggleMark('bold')
};
