/* eslint-disable camelcase */
import { css } from '@emotion/core';

export const $root = css`
  align-items: center;
  display: flex;
  flex-flow: column nowrap;
  height: calc(100vh - 70px);
  justify-content: center;
  width: 100%;
`;

export const $header = ({ colors, typography }) => [
  typography.family.circular,
  typography.size.XXL,
  css`
    color: ${colors.ui.greyDark[500]};
`];

export const $image = css`
  object-fit: cover;
  max-width: 100vw;
  width: 800px;
`;

export const $button = css`
  margin-top: 25px;
  width: 200px;
`;
