import { isArray } from 'lodash-es';

import { titleCaseSpaced } from '@client/utils/cases';
import { PUBLISHABLE } from '@client/utils/constants';

export const fields = {
  headerImage: { fieldType: 'editor', drawerType: PUBLISHABLE },
  attached: { fieldType: 'editor-list', drawerType: PUBLISHABLE },
  children: { fieldType: 'childhood', drawerType: PUBLISHABLE }
};

const typeToField = {
  Assessment: 'attached'
};

const getBaseFields = (content) => ({
  id: content.id,
  uid: content.uid,
  title: content.name,
  type: content.__typename,
});

/**
 * Get siblings for the current node that we are parsing in the tree.
 * These siblings get added to the parent object, so we can navigate
 * to the prev / next item in the Drawer.
 * @param {array} [items]
 * @param {object} parent
 * @returns {array}
 */
export function getSiblings ({ items = [], parent = null, isContent = true }) {
  return items.map((item) => ({ ...getBaseFields(item), isContent, parent }));
}

export function getParent ({ parent, field, includeSiblings = true }) {
  const items = isArray(parent[field]) ? parent[field] : [];
  const nextParentArgs = { parent, field, includeSiblings: false };
  return {
    id: parent.id,
    uid: parent.uid,
    field,
    fieldType: fields[field].fieldType,
    ...includeSiblings && { siblings: getSiblings({ items, parent: getParent(nextParentArgs) }) }
  };
}

export function createSingleBreadcrumb ({ content, parent = null, isContent = true }) {
  const breadcrumb = { ...getBaseFields(content), isContent, parent };

  const field = typeToField[content.__typename];

  if (field === 'attached') {
    breadcrumb.title = titleCaseSpaced(content.assessmentType);
  }

  return breadcrumb;
}

function getNextContent (content, field, id) {
  if (isArray(content[field])) {
    return content[field].find((item) => item.id === id);
  } else {
    return content[field];
  }
}

export function createBreadcrumbs (rootData, path = []) {
  let content = rootData;
  const rootBreadcrumb = createSingleBreadcrumb({ content });
  const pathArray = path.slice(0, -1); // Removes the last element because we don't need a breadcrumb for it.
  const otherBreadcrumbs = pathArray.map((pathItem) => {
    const parent = getParent({ parent: content, field: pathItem.field });
    content = getNextContent(content, pathItem.field, pathItem.id);
    return createSingleBreadcrumb({ content, parent });
  });

  return [rootBreadcrumb, ...otherBreadcrumbs];
}
