import { css } from '@emotion/core';

export const $fileContainer = css`
  margin: 10px 0;
`;

export const $widgetButton = css`
    margin-bottom: 20px;
    width: 100%;
`;

export const $noFiles = ({ colors }) => css`
  color: ${colors.ui.greyLight[500]};
  font-style: italic;
`;
