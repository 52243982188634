import { faBook } from '@fortawesome/pro-light-svg-icons';
import gql from 'graphql-tag';

const Word = {
  fullFragment: gql`
    fragment fullWord on Word {
      id
      uid
      headWord
      wordForms
      wordDefinitions {
        id
        uid
        definition
      }
    }
  `,
  icon: faBook
};

export default Word;
