import { AssignmentsSVG } from '@newsela/angelou';
import gql from 'graphql-tag';

import rawSchema from '@client/schema';
import { createAssessment } from '@client/utils/create-assessments';
import { enumOptions } from '@client/utils/fields';

import AssessmentLevel from './assessment-level';
import Content from './content';
import MetadataStandard from './metadataStandard';
import MetadataTag from './metadataTag';
import Stream from './stream';
import Tag from './tag';
import TaxonomyTag from './taxonomyTag';

const Assessment = {
  fullFragment: gql`
    fragment fullAssessment on Assessment {
      assessmentType
      levels {
        ...fullAssessmentLevel
      }
    }
    ${AssessmentLevel.fullFragment}
  `,
  // TODO: How do we query assessments? They currently have no filter.
  defaults: (id, data, relatedData) => {
    return createAssessment(id, data, relatedData);
  },
  isContent: true,
  icon: AssignmentsSVG,
  typename: 'Assessment',
  forms: {
    shared: [
      {
        input: 'assessment-levels',
        name: 'levels',
        label: 'Grade Level / Band',
        languages: enumOptions(rawSchema.enums.Language, 'Language'),
        questionTypes: (assessmentType) => {
          // For Power Words Activities, we only want to show the Power Word question type
          // For other assessments, we want to show all question types except for Power Word questions
          const questionTypes = enumOptions(rawSchema.enums.QuestionType);
          return questionTypes.filter(({ value }) => {
            return assessmentType === 'POWER_WORDS_ACTIVITY'
              ? value.startsWith('POWER_WORD') : !value.startsWith('POWER_WORD');
          });
        }
      },
      {
        input: 'select',
        name: 'assessmentType',
        label: 'Type',
        defaultValue: 'QUIZ',
        enum: true,
        options: enumOptions(rawSchema.enums.AssessmentType)
      },
      Tag.inputs.tags,
      MetadataTag.inputs.metadataTags,
      TaxonomyTag.inputs.taxonomyTags,
      MetadataStandard.inputs.metadataStandards,
      Stream.inputs.subjectProductStreams,
      Stream.inputs.customStreams,
      Content.inputs.notes,
      Content.inputs.id,
      Content.inputs.attachmentsCount
    ],
    levelSelect: [{
      input: 'grade-band-level-select',
      name: 'levels',
      showLabel: false,
      gradeBands: enumOptions(rawSchema.enums.GradeBand, 'GradeBand'),
      languages: enumOptions(rawSchema.enums.Language, 'Language')
    }]
  }
};

export default Assessment;
