import React, { useState, useRef, useEffect } from 'react';

import { InfoModal, Button } from '@newsela/angelou';
import { useStoreState, useStoreActions } from 'easy-peasy';
import { isEqual, isString, forOwn } from 'lodash-es';

import PublishAllModal from '@client/forms/components/PublishAllModal';
import ModalForm from '@client/forms/containers/ModalForm';
import { unsetValue } from '@client/utils/fields';
import { getFormValidity } from '@client/utils/form-validation';
import { logError } from '@client/utils/log';

import { $root, $modalContentWrapper, $scrollableBody, $footer } from './style';

const supportedModals = {
  PublishAllModal,
  ModalForm
};

export function getNewFormState (data, serverData, optimisticData, changeType) {
  // If optimisticData is null, use the changes from serverData
  const changedData = optimisticData ?? serverData;
  // The new form state is based on data (current form state)
  let newFormState = { ...data };

  // Merge the changed data with the current form state
  if (changeType === 'set') {
    // Prevent apollo from reverting updates before the real server
    // response comes back (with a real updatedAt value)
    const updatedAt = (new Date()).toUTCString();
    // Add new data to the form state.
    newFormState = { ...newFormState, ...changedData, updatedAt };
  } else {
    // Remove data from the form state.
    forOwn(changedData, (val, key) => unsetValue(key, val, newFormState));
  }

  return newFormState;
}

// Initial structure of the data that will be sent to the server
const EMPTY_BUFFER = { set: {}, unset: {} };

// Modal forms are different to regular forms in that they do NOT autosave.
// They only save data when the save button is pressed, and thus are an
// example of the limited amounts of BLOCKING UI in Alexandria.
export default function Modal () {
  // Destructure only the properties that will NOT be passed down.
  const {
    componentName = 'ModalForm',
    buttonLabel = 'Save',
    returnFocusRef = { current: null },
    containerStyles, uid, title, onSave, footer, ...modalProps
  } = useStoreState((state) => state.forms.modal.current, isEqual);
  const { config, data } = modalProps; // Destructure properties that will ALSO be passed down.
  const setData = useStoreActions((actions) => actions.forms.modal.setData);
  const clearData = useStoreActions((actions) => actions.forms.modal.close);
  const [isDisplayed, setIsDisplayed] = useState(false);
  const [isFormValid, setFormValidity] = useState(getFormValidity(data, config));
  const serverBuffer = useRef(EMPTY_BUFFER); // Data that will be sent to the server is saved separately.

  // We only display the modal if there's a title in the modal store state.
  useEffect(() => { title && setIsDisplayed(true); }, [title]);

  // Always clear the server buffer when opening modal forms. Since this component always
  // exists on the page (even when it isn't visible), the local component state persists.
  useEffect(() => {
    if (isDisplayed) {
      serverBuffer.current = EMPTY_BUFFER;
      setFormValidity(getFormValidity(data, config));
    }
  }, [isDisplayed]);

  /**
   * Form change handler. Updates the form data and the server data.
   * @param {object} serverData to send to the server
   * @param {object} [optimisticDataOrChangeType] if we want slightly different data to appear instantly in the form state
   * @param {string} [changeType] defaults to 'set'
   */
  const onChange = (serverData, optimisticDataOrChangeType, changeType = 'set') => {
    let optimisticData = optimisticDataOrChangeType;
    // If you want the same data to both the server and the form state, then you can
    // pass changeType as the second argument (you don't have to pass all three arguments).
    if (isString(optimisticDataOrChangeType)) {
      changeType = optimisticDataOrChangeType;
      optimisticData = null;
    }

    // Merge the changed data with the current form state,
    // making the form update instantly to reflect the changes.
    const newFormState = getNewFormState(data, serverData, optimisticData, changeType);
    setData(newFormState);

    // Keep track of what data we want to send to the server when the user hits Save.
    serverBuffer.current = {
      ...serverBuffer.current,
      [changeType]: { ...serverBuffer.current[changeType], ...serverData }
    };

    // Check to see if the form is valid.
    setFormValidity(getFormValidity(newFormState, config));
  };

  // Only close the modal. The modal store state will be cleared after the modal is closed.
  // If we clear the modal store state here, the returnFocusRef will be cleared too
  // and focus will not be returned to the element that triggered the modal.
  const closeModal = () => setIsDisplayed(false);

  // Pass serverBuffer and local form data to the parent when user clicks save.
  const save = () => {
    // Don't allow saving modal forms when they're not valid.
    if (isFormValid) {
      onSave(uid, serverBuffer.current, data);
      closeModal();
    }
  };

  // Get the component that will be rendered in the modal.
  const ModalComponent = supportedModals[componentName];
  // labelId is required for InfoModal.
  const labelId = componentName || '';

  if (!ModalComponent) {
    logError(`Cannot open modal ${componentName}, component does not exist`);
    return null;
  }

  return (
    <InfoModal
      __cssFor={{
        root: $root,
        modalContentWrapper: $modalContentWrapper,
        scrollableBody: $scrollableBody(containerStyles),
        footer: $footer
      }}
      title={title || ''} // title is required for InfoModal
      labelId={labelId}
      ariaProps={{ 'aria-describedby': labelId }}
      isActive={isDisplayed}
      closeModal={closeModal}
      onEsc={closeModal}
      onBackgroundClick={closeModal}
      // Only clears the modal store state after the modal is closed
      // and focus is returned to the triggerRef element.
      onClose={() => clearData()}
      triggerRef={returnFocusRef}
      footer={
        <>
          <Button
            /* AUTOGENERATED TODO: update angelou to new flavor.
              see https://github.com/newsela/angelou/blob/main/src/components/Button/README.md#MIGRATION
              for migration guide. */
            legacy_flavor={Button.legacy_flavor.flat}
            legacy_statusColor={Button.legacy_statusColor.primary}
            onClick={closeModal}
          >
            Cancel
          </Button>
          <Button
            /* AUTOGENERATED TODO: update angelou to new flavor.
              see https://github.com/newsela/angelou/blob/main/src/components/Button/README.md#MIGRATION
              for migration guide. */
            legacy_flavor={Button.legacy_flavor.solid}
            legacy_statusColor={Button.legacy_statusColor.primary}
            onClick={save}
            disabled={!isFormValid}
          >
            {buttonLabel}
          </Button>
        </>
      }
    >
      <ModalComponent {...modalProps} onChange={onChange} onSave={save} />
      {footer}
    </InfoModal>
  );
}
