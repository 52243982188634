import React from 'react';

import PropTypes from 'prop-types';

import { titleCaseSpaced } from '@client/utils/cases';

import { $status } from './style';

export default function StatusBadge ({ status }) {
  return (
    <div css={$status(status)}>
      <span>
        {titleCaseSpaced(status)}
      </span>
    </div>
  );
}

StatusBadge.propTypes = {
  status: PropTypes.string,
};
