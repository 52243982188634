import { constants } from '@newsela/angelou';

import videoControls from './video-controls.svg';

const url = process.env.ENVIRONMENT_NAME === 'prod'
  ? 'https://studio.newsela.com/home'
  : 'https://interactive-video-studio.staging.newseladev.com/';

const Studio = {
  app: {
    icon: videoControls,
    color: constants.colors.ui.greyDark[700],
    accentColor: constants.colors.ui.grey[700],
    title: 'Studio CMS',
    href: url,
    isExternalLink: true,
    permissionForAdmin: true,
    permissionForStaff: true,
    permissionForContributor: true,
  },
  isContent: false
};

export default Studio;
