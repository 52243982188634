import React, { useRef } from 'react';

import { Button } from '@newsela/angelou';
import { useStoreActions } from 'easy-peasy';
import { isEmpty } from 'lodash-es';
import PropTypes from 'prop-types';

import { Article } from '@client/common/schema';
import { CHECK_MARK } from '@client/utils/constants';
import { formatGradeBand, enumOption, sortGradeBands } from '@client/utils/fields';
import { NO_LEXILE } from '@shared/constants';

import {
  $table,
  $heading,
  $row,
  $cell
} from './style';

function LevelRow ({ level, index }) {
  const language = enumOption(level.language, 'Language').value.slice(5);
  const formattedLexile = level.lexile;
  const originalText = level.isOriginalLevel ? CHECK_MARK : '';

  return (
    <tr css={$row(index)}>
      <td css={$cell}>{language}</td>
      <td css={$cell}>{formatGradeBand(level.gradeBand)}</td>
      <td css={$cell}>{formattedLexile || NO_LEXILE} / {level.wordCount} words</td>
      <td css={$cell}>{originalText}</td>
    </tr>
  );
}

LevelRow.propTypes = {
  level: PropTypes.object,
  index: PropTypes.number
};

export default function ArticleLevels ({ value, name, onChange, formData }) {
  const availableLevels = (value || []).filter((level) => level.isActive);
  const sortedAvailableLevels = availableLevels.sort(sortGradeBands);
  const openModal = useStoreActions((actions) => actions.forms.modal.open);
  // Reference to the DOM element that opened the modal.
  // This reference is used to get the focus back when modal closes.
  const returnFocusRef = useRef(null);

  const openLevelsModal = (e) => {
    returnFocusRef.current = e.currentTarget;
    openModal({
      title: 'Create / Remove Article Levels',
      config: Article.forms.levelSelect,
      data: {
        uid: formData.uid,
        // Pass in all article levels, including inactive ones.
        articleLevels: value,
        contentType: formData.contentType
      },
      returnFocusRef,
      onSave: (uid, serverData, optimisticData) => {
        if (!isEmpty(serverData.set)) {
          onChange(
            { [name]: serverData.set.articleLevels },
            { [name]: optimisticData.articleLevels }
          );
        }
      }
    });
  };

  return (
    <>
      <table css={$table}>
        <thead>
          <tr>
            <th css={$heading}>Language</th>
            <th css={$heading}>Grade</th>
            <th css={$heading}>Current Lexile / Word Count</th>
            <th css={$heading}>Original</th>
          </tr>
        </thead>
        <tbody>
          {sortedAvailableLevels.map((level, index) => <LevelRow key={index} level={level} index={index} />)}
        </tbody>
      </table>
      <Button
        /* AUTOGENERATED TODO: update angelou to new flavor.
          see https://github.com/newsela/angelou/blob/main/src/components/Button/README.md#MIGRATION
          for migration guide. */
        legacy_flavor={Button.legacy_flavor.flat}
        legacy_size={Button.legacy_size.small}
        legacy_statusColor={Button.legacy_statusColor.primary}
        type='button'
        role='button'
        ariaProps={{ 'aria-label': 'Edit Levels' }}
        onClick={openLevelsModal}
      >
        Edit Levels
      </Button>
    </>
  );
}

ArticleLevels.propTypes = {
  /** Field value, from the form-level state */
  value: PropTypes.array,
  /** Field name, which is also the property the data will be saved to */
  name: PropTypes.string,
  /** Function that updates the form state and persists data */
  onChange: PropTypes.func,
  formData: PropTypes.object
};

ArticleLevels.displayName = 'ArticleLevelsGroup';
