import { faLink } from '@fortawesome/pro-regular-svg-icons';

import { link } from '@shared/prosemirror/marks';

import { promptForString, markActive, toggleMark } from './helpers';

function toggleLink (state, dispatch) {
  if (markActive('link')(state)) {
    toggleMark('link')(state, dispatch);
    return true;
  }

  const href = promptForString('Enter the URL');
  if (!href) return false;

  toggleMark('link', { href })(state, dispatch);
}

const icon = faLink;
export const mark = link;
export const keys = {
  'Mod-k': toggleLink
};
export const menuMark = {
  title: 'Toggle Link',
  content: icon,
  active: markActive('link'),
  run: toggleLink
};
