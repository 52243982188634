import { css } from '@emotion/core';

import { getBorderColors, getActiveShadow } from '../../../utils/styles';

export const $root = (variant) => (theme) => {
  const [
    color,
    hoverColor,
    activeColor
  ] = getBorderColors(theme, variant);
  const activeShadow = getActiveShadow(activeColor);

  return css`
    z-index: 0;

    & > div:last-child {
      border-color: ${color};
    }

    &:hover > div:last-child {
      border-color: ${hoverColor};
    }

    &:active > div:last-child,
    &:focus > div:last-child,
    & > input:focus ~ div:last-child {
      box-shadow: ${activeShadow};
    }
  `;
};
