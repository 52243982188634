import { compareDesc, parseISO } from 'date-fns';
import listify from 'listify';
import { last, initial, words, head, isArray, isEmpty, first } from 'lodash-es';

import { titleCaseSpaced } from '@client/utils/cases';
import { EVENTS } from '@client/utils/constants';
import { formatDynamic } from '@client/utils/time';

const { ARCHIVE, PUBLISH, SUBMIT_DRAFT, UNPUBLISH } = EVENTS;

function descendingTime (a, b) {
  return compareDesc(parseISO(a.updatedAt), parseISO(b.updatedAt));
}

/**
 * Format action text based on the event name.
 * e.g. 'PUBLISH' -> 'Published'
 * e.g. 'EDIT' -> 'Edited'
 * e.g. 'SUBMIT_DRAFT' -> 'Draft Submitted' (special case)
 * @param {string} eventName
 * @returns {string}
 */
export function formatActionText (eventName) {
  if (eventName === SUBMIT_DRAFT) {
    return 'Draft Submitted';
  } else {
    const actionText = last(eventName) === 'E'
      ? titleCaseSpaced(initial(eventName).join(''))
      : titleCaseSpaced(eventName);

    return `${actionText}ed`;
  }
}

/**
 * Get intials from a user's name.
 * @param {string} name e.g. 'Abigail Thorn'
 * @returns {string} e.g. 'AT'
 */
function toInitials (name) {
  return words(name).map((word) => word.charAt(0)).join('');
}

/**
 * Map through events, formatting them nicely. Exported for testing.
 * @param {Object} event
 * @param {number} currentUserId
 * @return {Object}
 */
export function parseEvent (event, currentUserId) {
  const actionText = formatActionText(event.event);
  const users = event.updatedBy.length > 1
    ? listify(event.updatedBy.map((user) => user.name))
    : event.createdBy.name;
  const isCurrentUser = event.updatedBy.length > 1
    ? !!event.updatedBy.find((user) => user.id === currentUserId)
    : event.createdBy.id === currentUserId;
  const userInitials = event.updatedBy.length > 1
    ? event.updatedBy.map((user) => toInitials(user.name)).join(', ')
    : toInitials(event.createdBy.name);

  return {
    uid: event.uid,
    eventType: event.event,
    text: `${actionText} by ${users}`,
    // For sorting and displaying in revision history.
    updatedAt: event.updatedAt,
    hasSnapshot: event.hasSnapshot,
    userInitials, // For displaying in revision history.
    isCurrentUser,
    secondaryText: formatDynamic(event.updatedAt),
    actionText,
    users,
    updatedBy: event.updatedBy,
    ...event.event === PUBLISH && { variant: 'success' },
    ...event.event === UNPUBLISH && { variant: 'warning' },
    ...event.event === ARCHIVE && { variant: 'danger' }
  };
}

export default function parseEvents (events = [], currentUserId = null) {
  return events.map((event) => parseEvent(event, currentUserId)).sort(descendingTime);
}

export function getIsDraftSubmitted (events, currentUserId) {
  const parsedEvents = isArray(events) ? parseEvents(events, currentUserId) : [];
  // Early return if there are no events.
  if (isEmpty(parsedEvents)) return false;
  const latestEvent = head(parsedEvents);
  const isSubmitDraft = latestEvent.eventType === SUBMIT_DRAFT;
  return isSubmitDraft && latestEvent.isCurrentUser;
}

export function getAction (saveStatus = {}) {
  /* Gets the text based on which action is currently being performed */
  const ACTIONS = {
    Saving: saveStatus.isSaving,
    Publishing: saveStatus.isPublishing,
    Unpublishing: saveStatus.isUnpublishing,
    Archiving: saveStatus.isArchiving,
    Unarchiving: saveStatus.isUnarchiving,
    Validating: saveStatus.isValidating,
    'Submitting draft': saveStatus.isSubmittingDraft,
    'Loading article': saveStatus.isLoadingArticle,
    'Uploading file': saveStatus.isUploadingFile
  };
  return first(Object.entries(ACTIONS).find(([key, value]) => value));
}
