import React, { Suspense, lazy, useEffect } from 'react';

import { useQuery } from '@apollo/client';
import { datadogRum } from '@datadog/browser-rum';
import { Global } from '@emotion/core';
import { Toaster } from '@newsela/angelou';
import { Router } from '@reach/router';
import * as Sentry from '@sentry/react';
import { parseISO, isAfter } from 'date-fns';
import { useStoreActions, useStoreState } from 'easy-peasy';
import $normalize from 'emotion-normalize';
import { ThemeProvider } from 'emotion-theming';
import usePageVisibility from 'use-page-visibility';

import Modal from '@client/common/containers/Modal';
import { ERROR_TOAST_ZINDEX } from '@client/utils/constants';
import { isUnreleased } from '@client/utils/feature-flag';
import { globalToasterRef, info } from '@client/utils/toast';

import { $global, $fonts } from './style';
import theme from './theme';
import FourOhFour from '../../components/FourOhFour';
import LoadingSpinner from '../../components/LoadingSpinner';
import { queries } from '../../graph';
import AppHeader from '../AppHeader';
import EventTracker from '../EventTracking/EventTracker';

const Home = lazy(() => import('@client/home/containers/Home'));
const Bundles = lazy(() => import('@client/bundles/containers/Bundles'));
const Streams = lazy(() => import('@client/streams/containers/Streams'));
const Articles = lazy(() => import('@client/articles/containers/Articles'));
const ContentProviders = lazy(() => import('@client/content-providers/containers/ContentProviders'));
const Tags = lazy(() => import('@client/tags/containers/Tags'));
const Inventory = lazy(() => import('@client/inventory/containers/Inventory'));

const POLLING_INTERVAL = 60000; // Refresh every minute.
export const NO_ALEXANDRIA_ACCESS_MESSAGE = 'You\'re logged in to Newsela as a user who does not have access to Alexandria.';
const ERROR_FETCHING_USER = 'Oops! Something went wrong while loading your user. Please try again.';
export default function App () {
  const isAppLoaded = useStoreState((state) => state.isAppLoaded);
  const isWireUser = useStoreState((state) => state.user.isWireUser);
  const globalLoadingSpinner = useStoreState((state) => state.globalLoadingSpinner);
  // Initialize the store, fetching user data and redirecting if user isn't
  // authenticated against alexandria-server.
  const setAppLoaded = useStoreActions((actions) => actions.setAppLoaded);
  const addUser = useStoreActions((actions) => actions.user.addData);
  const { data, error: userError } = useQuery(queries.me);
  const { data: buildData, startPolling, stopPolling } = useQuery(queries.buildTimestamp, {
    pollInterval: POLLING_INTERVAL
  });

  // Dynamically start and stop polling when page isn't visible. This reduces
  // the number of background API calls to alexandria-server.
  usePageVisibility((isVisible) => {
    if (isVisible) {
      startPolling(POLLING_INTERVAL);
    } else {
      stopPolling();
    }
  }, 1000); // Only check visibility once per second.

  if (data && data.me && !isAppLoaded) {
    // Add user data to easy-peasy store
    addUser(data.me);
    // Add user data to sentry logging
    Sentry.setUser(data.me);

    // If the user isn't authenticated, this will be caught in the onError
    // link: See the ApolloClient config in index.jsx for details

    datadogRum.setUser(data.me);
    setAppLoaded(true);
  }

  // Display a message when the app is loaded with unreleased: true
  useEffect(() => {
    // Never display this message in a preview.
    const isPreview = window.location.pathname.includes('/preview');

    if (!isPreview && isUnreleased()) {
      info('Unreleased features enabled. Reload the page with ?unreleased=false to disable.');
    }
  }, []);

  useEffect(() => {
    // The App finishes loading when we either have a user or an error grabbing the user.
    if (userError && !isAppLoaded) {
      setAppLoaded(true);
    }
  }, [userError]);

  // Check the build timestamp (set when the client is built) against the
  // timestamp from the server (set when the server is started). If the server
  // timestamp is more recent, it means that there has been a release since
  // the client was built. To update the client, users must hard refresh.
  useEffect(() => {
    if (buildData && buildData.buildTimestamp) {
      const clientTimestamp = parseISO(process.env.DEPLOYED_AT || (new Date(0)).toISOString());
      const serverTimestamp = parseISO(buildData.buildTimestamp);
      // Never display this message in a preview.
      const isPreview = window.location.pathname.includes('/preview');

      if (isAfter(serverTimestamp, clientTimestamp) && !isPreview) {
        info('Alexandria has been updated! Click here to reload the page and get the latest changes.', {
          persist: true,
          // Hard refresh the page on click.
          onClick: () => window.location.reload(true)
        });
      }
    }
  }, [buildData]);

  return (
    <ThemeProvider theme={theme}>
      <EventTracker />
      <Toaster
        ref={globalToasterRef}
        __cssFor={
        {
          root: {
            top: '150px',
            zIndex: ERROR_TOAST_ZINDEX,
          }
        }
      }
      />
      <Global styles={[$normalize, $fonts, $global]} />
      {(!isAppLoaded || globalLoadingSpinner.loadingState) && (<LoadingSpinner isLoadingModal label={globalLoadingSpinner.label} />)}
      {isAppLoaded && (
        <AppHeader />
      )}
      {isAppLoaded && isWireUser && (
        <Suspense fallback={<LoadingSpinner isLoadingModal />}>
          <>
            <Router basename={process.env.CLIENT_BASE_PATH}>
              <Home path='/' />
              <Bundles path='/bundles/*' />
              <Streams path='/streams/*' />
              <Articles path='/articles/*' />
              <ContentProviders path='/content-providers/*' />
              <Inventory path='/inventory/*' />
              <Tags path='/tags/*' />
              <FourOhFour default />
            </Router>
            <Modal />
          </>
        </Suspense>
      )}
      {isAppLoaded && !isWireUser && (
        <FourOhFour message={!data?.me ? ERROR_FETCHING_USER : NO_ALEXANDRIA_ACCESS_MESSAGE} />
      )}
    </ThemeProvider>
  );
}
