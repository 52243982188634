import React, { useState, useEffect, useRef } from 'react';

import { useNavigate } from '@reach/router';
import { useStoreState } from 'easy-peasy';
import { first, isString, isEmpty, findIndex, startCase } from 'lodash-es';
import Button from 'mineral-ui/Button';
import ButtonGroup from 'mineral-ui/ButtonGroup';
import Dropdown from 'mineral-ui/Dropdown';
import IconArrowDropdownDown from 'mineral-ui-icons/IconArrowDropdownDown';
import PropTypes from 'prop-types';
import { useInView } from 'react-hook-inview';

import InlineForm from '@client/forms/containers/InlineForm';
import { addMagicButtons } from '@client/utils/add-magic-buttons';
import {
  filterGradeBands,
  hasActiveLevels,
  getActiveLevels,
  getLanguageButtonVariant,
  getLanguagesFieldPath,
  getLanguagesVariant,
  getValidation,
  groupLevels
} from '@client/utils/article-levels-selector';
import { getUpdatedSearchParams, useSearchParams } from '@client/utils/deep-link';
import { formatGradeBand, enumOption } from '@client/utils/fields';
import { getFieldValidation, getAggregateMessage } from '@client/utils/form-validation';
import { getTarget } from '@client/utils/lexile';
import { getVariantIcon } from '@client/utils/styles';
import { NO_LEXILE } from '@shared/constants';

import {
  $addLevelWrapper,
  $levelMeta,
  $metaCurrent,
  $metaCurrentVal,
  $metaTarget,
  $metaTargetVal,
  $levelSelector,
  $languageSelector,
  $languageDropdown,
  $languageItem,
  $selectorButton,
  $selectorButtonInner,
  $selectorGrade,
  $selectorLexile
} from './style';

function LevelSelector ({ levels, currentLevel, setCurrentLevel, validation }) {
  const currentLanguage = currentLevel.language;
  // Only display active levels in the level selector.
  const currentLanguageLevels = getActiveLevels(levels, currentLanguage);
  const currentLevelIndex = findIndex(currentLanguageLevels, (level) => level.uid === currentLevel.uid);
  const languages = Object.keys(levels)
    .filter(hasActiveLevels(levels)) // Only show languages with active levels.
    .map((language) => enumOption(language, 'Language'));
  const hasMultipleLanguages = languages.length > 1;

  const onChangeLanguage = (lang) => {
    const newLevel = first(getActiveLevels(levels, lang));

    setCurrentLevel(newLevel);
  };

  const onChangeLevel = (e) => {
    const index = parseInt(e.target.getAttribute('data-index'));
    const newLevel = currentLanguageLevels[index];

    setCurrentLevel(newLevel);
  };

  let languagesVariant = {};
  let languageButtonVariant, languageButtonIcon;

  if (!isEmpty(validation) && hasMultipleLanguages) {
    const languagesFieldPath = getLanguagesFieldPath(levels, validation.uidFieldPaths);
    languagesVariant = getLanguagesVariant(validation.data, languagesFieldPath);
    languageButtonVariant = getLanguageButtonVariant(languagesVariant, currentLanguage);
    languageButtonIcon = languageButtonVariant && { iconStart: getVariantIcon(languageButtonVariant) };
  }

  return (
    <div css={$levelSelector}>
      {/* Language selector allows switching languages */}
      {hasMultipleLanguages
        ? (
          <Dropdown
            css={$languageDropdown}
            data={languages.map((language) => {
              const langVariant = languagesVariant[language.value];
              const langIcon = langVariant ? getVariantIcon(langVariant) : null;
              return {
                iconStart: langIcon,
                text: language.label,
                onClick: () => onChangeLanguage(language.value),
                css: $languageItem(langVariant)
              };
            })}
          >
            <Button
              {...languageButtonIcon}
              css={$languageSelector(languageButtonVariant)}
              type='button'
              aria-label='Select Language'
              size='medium'
              iconEnd={<IconArrowDropdownDown />}
            >{enumOption(currentLanguage, 'Language').label}
            </Button>
          </Dropdown>
          )
        : null}

      {/* Level selector allows picking a level within the current language */}
      <ButtonGroup
        size='jumbo'
        mode='radio'
        aria-label='Select Level'
        checked={currentLevelIndex}
        onChange={onChangeLevel}
      >
        {currentLanguageLevels.map((level) => {
          let variant, icon;

          if (!isEmpty(validation)) {
            const fieldPath = validation.uidFieldPaths[level.uid];
            const { errors, warnings } = getFieldValidation(validation.data, { id: validation.id }, fieldPath, true);
            ({ variant } = getAggregateMessage(errors, warnings, { name: 'articlelevel' }));
            icon = variant && { iconStart: getVariantIcon(variant) };
          }

          return (
            <Button minimal type='button' key={level.gradeBand} css={$selectorButton(variant)} {...icon}>
              <div css={$selectorButtonInner}>
                <span css={$selectorGrade}>{formatGradeBand(level.gradeBand)}</span>
                <span css={$selectorLexile}>{level.lexile || NO_LEXILE}</span>
              </div>
            </Button>
          );
        })}
      </ButtonGroup>
    </div>
  );
}

LevelSelector.propTypes = {
  levels: PropTypes.object,
  currentLevel: PropTypes.object,
  setCurrentLevel: PropTypes.func,
  validation: PropTypes.shape({
    id: PropTypes.string, // Article id needed to filter nested issues
    uidFieldPaths: PropTypes.object, // Map article level uid to fieldPath prefix
    data: PropTypes.object // validatedContent
  })
};

export function LevelMetaItem ({ level, type, label }) {
  const { gradeBand, isOriginalLevel, lexile } = level;

  const val = level[type];
  const displayLabel = label || startCase(type);
  const displayVal = type === 'rawLexile' ? (lexile || NO_LEXILE) : val;

  return (
    <div>
      <div aria-label={`Current ${displayLabel}`} css={$metaCurrent}>{displayLabel}: <span css={$metaCurrentVal(val, gradeBand, type, isOriginalLevel)}>{displayVal}</span></div>
      <div aria-label={`Target ${displayLabel}`} css={$metaTarget}>Target: <span css={$metaTargetVal}>{getTarget(gradeBand, type, isOriginalLevel)}</span></div>
    </div>
  );
}

LevelMetaItem.propTypes = {
  level: PropTypes.object,
  type: PropTypes.string,
  label: PropTypes.string
};

function LevelMeta ({ level }) {
  return (
    <section css={$levelMeta}>
      <LevelMetaItem level={level} type='rawLexile' label='Lexile' />
      <LevelMetaItem level={level} type='wordCount' />
    </section>
  );
}

LevelMeta.propTypes = {
  level: PropTypes.object
};

export default function ArticleLevelsSelector ({ value, name, onChange, validatedContent, layout, formData }) {
  // Group the levels by language, and sort them by grade band.
  const levels = groupLevels(value);
  const navigate = useNavigate();
  const articleLevelsConcurrency = useStoreState((state) => state.articleLevelsConcurrency.data);

  const searchParams = useSearchParams();

  const uidFromQueryParam = searchParams.get('tabPath')?.split('level.')[1] || '';
  const levelFromQueryParam = value?.find((level) => level.uid === uidFromQueryParam);
  // Default the current level to the highest english level. Fall back to the
  // highest spanish level.
  const highestEnglishLevel = first(levels.LANG_EN || []);
  const highestSpanishLevel = first(levels.LANG_ES || []);
  const defaultLevel = levelFromQueryParam || (highestEnglishLevel || highestSpanishLevel);

  const [currentLevel, setCurrentLevel] = useState(defaultLevel);
  const [scrollRef, inView] = useInView();

  // Update the current level's data when the server updates.
  // If there is no current level, attempt to set it.
  useEffect(() => {
    if (!currentLevel) {
      // Set the current level if it's not set.
      setCurrentLevel(highestEnglishLevel || highestSpanishLevel);
    } else {
      // Update the current level with the data from the server.
      const uid = currentLevel.uid;
      setCurrentLevel(value.find((level) => level.uid === uid));
    }
  }, [value]);

  const latestUpdatedAt = useRef();

  useEffect(() => {
    if (currentLevel) {
      const uid = currentLevel.uid;
      const pathQueryParam = `level.${uid}`;
      navigate(getUpdatedSearchParams(searchParams, 'tabPath', pathQueryParam));
    }
    return () => navigate(getUpdatedSearchParams(searchParams, 'tabPath'));
  }, [currentLevel]);

  // When adding a default level from the Content tab, default it to English, Grade 12.
  // DON'T add optimistic data, because we don't want to show the new level until
  // it is editable.
  const addLevel = () => {
    onChange(
      { [name]: { gradeBand: 'GRADE_12' } },
      { [name]: [] },
      'set',
      false
    );
  };

  useEffect(() => {
    latestUpdatedAt.current = articleLevelsConcurrency;
  }, [articleLevelsConcurrency]);

  const editLevel = (serverData, optimisticDataOrChangeType, changeType, isDebounced) => {
    if (isString(optimisticDataOrChangeType)) {
      // eslint-disable-next-line no-param-reassign
      changeType = optimisticDataOrChangeType;
      // eslint-disable-next-line no-param-reassign
      optimisticDataOrChangeType = null;
    }

    const updatedLevel = {
      ...currentLevel,
      ...optimisticDataOrChangeType
    };
    const optimisticData = value.map((level) => level.uid === currentLevel.uid ? updatedLevel : level);

    onChange(
      { [name]: { uid: currentLevel.uid, updatedAt: latestUpdatedAt.current[currentLevel.uid]?.updatedAt, ...serverData } },
      { [name]: optimisticData },
      changeType,
      isDebounced
    );
  };

  // Add a magic button to every field if we're not rendering the highest grade band.
  // The button will pull in data from the next highest grade band.
  let form = addMagicButtons(levels, currentLevel);
  form = filterGradeBands(form, levels, currentLevel);

  const validation = getValidation(formData?.id, levels, currentLevel, validatedContent);
  const validationProp = !isEmpty(validation) && { validation };

  return (
    <>
      {!isEmpty(value) && currentLevel &&
        <LevelSelector
          levels={levels}
          currentLevel={currentLevel}
          setCurrentLevel={setCurrentLevel}
          {...validationProp}
        />}
      {currentLevel
        ? (
          <>
            <div ref={scrollRef} />
            <LevelMeta level={currentLevel} />
            <InlineForm
              data={currentLevel}
              validatedContent={validatedContent}
              parent={formData}
              type='ArticleLevel'
              form={form}
              fieldName={validation?.currentLevelFieldPath}
              id={formData.id}
              onChange={editLevel}
              layout={layout}
              lexileInView={inView}
            />
          </>
          )
        : (
          <div css={$addLevelWrapper}>
            <p>There are no article levels</p>
            <Button primary type='button' onClick={addLevel}>Add Default Level</Button>
          </div>
          )}
    </>
  );
}

ArticleLevelsSelector.propTypes = {
  /** Field value, from the form-level state */
  value: PropTypes.array,
  /** Field name, which is also the property the data will be saved to */
  name: PropTypes.string,
  /** Function that updates the form state and persists data */
  onChange: PropTypes.func,
  /** Content validation messages */
  validatedContent: PropTypes.object,
  /** Tab layout, if we're inside a tab and need to render fields differently */
  layout: PropTypes.string,
  formData: PropTypes.object
};

ArticleLevelsSelector.displayName = 'ArticleLevelsSelectorGroup';
