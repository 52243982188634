import { faLanguage } from '@fortawesome/pro-regular-svg-icons';

import { promptForString, markActive, toggleMark } from '@client/forms/inputs/Prosemirror/formats/helpers';
import * as toast from '@client/utils/toast';
import { language } from '@shared/prosemirror/marks';

export function toggleLanguage (state, dispatch) {
  if (markActive('language')(state)) {
    toggleMark('language')(state, dispatch);
    return true;
  }

  const lang = promptForString('Please enter two-character language code, e.g. ‘en’ for English or ‘es’ for Spanish.');
  if (!lang) return false;

  // We are limiting the choices to only en or es
  if (!['en', 'es'].includes(lang)) {
    toast.error("Only 'en' and 'es' languages are supported");
    return false;
  }

  toggleMark('language', { lang_code: lang })(state, dispatch);
}

const icon = faLanguage;
export const mark = language;
export const keys = {
  'Ctrl l': toggleLanguage
};
export const menuBlock = {
  title: 'Set Language',
  content: icon,
  active: markActive('language'),
  run: toggleLanguage
};
