import { faShapes } from '@fortawesome/pro-light-svg-icons';
import { constants } from '@newsela/angelou';
import cuid from 'cuid';
import gql from 'graphql-tag';
import { escapeRegExp } from 'lodash-es';
import smartquotes from 'smartquotes';

import rawSchema from '@client/schema';
import { titleCaseSpaced } from '@client/utils/cases';
import { transformParentBundleList, transformContentStatus } from '@client/utils/fields';

const assessmentTypes = rawSchema.enums.AssessmentType.map((assessmentType) => ({
  type: 'Assessment',
  title: titleCaseSpaced(assessmentType),
  data: { assessmentType }
}));

// Types that we can include as attachments of content. Add content types here
// when we want to include them as attachments.
const availableAttachments = [
  ...assessmentTypes,
  'InstructionalNote',
  'LessonSpark',
];

const openSearch = {
  index: 'inventory',
  fields: ['title^10', 'name^7', 'slug^5', 'description^3'],
  sort: [
    {
      updated_at: {
        order: 'desc'
      }
    }
  ],
  // If the user included a query we disable the sort so we don't affect the ranking.
  disableSortOnQuery: true,
  _source: ['id', 'content_type', 'name', 'thumbnail', 'status', 'updated_at', 'events', 'attached', 'streams', 'label', 'parents_count', 'updated_by']
};

export const Event = {
  fullFragment: gql`
    fragment fullEvent on Event {
      uid
      event
      createdAt
      createdBy {
        name
        id
      }
      updatedAt
      updatedBy {
        name
        id
      }
      hasSnapshot
    }
  `,
  // Because events are NEVER sent as mutations to the server, Events.defaults()
  // returns empty 'server' information.
  defaults: (id, data = {}) => {
    return {
      client: {
        __typename: 'Event',
        uid: `_:${id}`,
        hasSnapshot: false,
        event: 'CREATE',
        createdAt: data.now,
        createdBy: data.user,
        updatedAt: data.now,
        updatedBy: [data.user]
      },
      server: {}
    };
  }
};

// When searching through all content types, we need to include the major
// fields (things that contribute to 'name') for different content types.
// Because content types have different fields, we don't have a single field to
// query for quickFilter().
function filter (query) {
  const quoted = smartquotes(query);

  return {
    or: [
      // Some fields use smart quotes. Check the content type's filter()
      // methods to determine which ones should be quoted.
      { title: { alloftext: quoted } },
      { linkTitle: { allofterms: quoted } },
      { altText: { alloftext: quoted } },
      // Label and slug do not use smart quotes.
      { label: { allofterms: query } },
      { slug: { regexp: `/${escapeRegExp(query)}/i` } }
    ]
  };
}

const Content = {
  outlineFragment: gql`
    fragment outlineContent on Content {
      id
      uid
      contentType
      name
      status
      position
    }
  `,
  previewFragment: gql`
    fragment previewContent on Content {
      id
      name
      thumbnail
    }
  `,
  searchFragment: gql`
    fragment searchContent on Content {
      id
      uid
      contentType
      name
      thumbnail
      status
      updatedAt
      updatedBy { name id }
      events { event createdAt }
      contentProvider { name id }
    }
  `,
  blueprintFragment: gql`
    fragment blueprintContent on Content {
      id
      name
    }
  `,
  // Include the eventCount so the outline updates when editing content.
  // Don't include position, because it doesn't update when editing content
  // in its drawer. If we added position here, it would be reset to 'null'
  // when the drawer updates, messing up the outline.
  fullFragment: gql`
    fragment fullContent on Content {
      id
      uid
      contentType
      name
      thumbnail
      status
      createdAt
      updatedAt
      updatedBy {
        name
        id
      }
      certicaId
      displayPublishedAt
      eventCount
      notes(format: TEXT_PLAIN)
      rawNotes: notes(format: TEXT_RAW)
      events { ...fullEvent }
      parentsCount
      parentsList: parents {
        id
        name
      }
      attachmentsCount
    }
    ${Event.fullFragment}
  `,
  isContent: true,
  // We set isContentInterface to true to explicitly stop the search util from
  // attempting to set a contentType when generating the search query.
  isContentInterface: true,
  statusSelector: true,
  filter,
  typename: 'Content',
  icon: faShapes,
  defaults: (id, data = {}) => {
    const contentId = id || cuid();
    const uid = `_:${contentId}`;
    const now = (new Date()).toISOString();
    const user = {
      __typename: 'User',
      name: 'You',
      id: 0
    };
    const createEvent = Event.defaults(`${contentId}-event-create`, { user, now });
    const position = data.position || null;
    const streams = data.streams || [];

    return {
      client: {
        id: contentId,
        uid,
        name: null, // This is usually overridden by the content type's defaults()
        certicaId: null,
        thumbnail: null,
        status: 'DRAFT',
        createdAt: now,
        updatedAt: now,
        updatedBy: [user],
        eventCount: 1,
        events: [createEvent.client],
        displayPublishedAt: null,
        tags: [],
        metadataTags: [],
        metadataStandards: [],
        streams,
        attached: [],
        contentProvider: null,
        notes: null,
        rawNotes: null,
        position,
        parentsCount: 0,
        parentsList: null,
        attachmentsCount: 0,
        replacedByContent: null
      },
      server: {
        id: contentId,
        uid,
        streams,
        position
      }
    };
  },
  // Inventory Management config
  app: {
    icon: faShapes,
    color: constants.colors.ui.greyDark[700],
    accentColor: constants.colors.ui.grey[700],
    title: 'Inventory Management',
    href: '/inventory',
    permissionForAdmin: true,
    permissionForStaff: true,
    permissionForContributor: false,
    columns: [
      {
        value: 'id',
        showColumn: false
      },
      {
        value: 'uid',
        showColumn: false
      },
      {
        label: 'Title',
        value: 'name',
        isClickable: true
      },
      // __typename is used to determine how to open items,
      // whereas contentType is used to determine how to display the
      // human-readable content type.
      {
        value: '__typename',
        showColumn: false
      },
      {
        label: 'Content Type',
        value: 'contentType',
        humanReadableType: true
      },
      {
        value: 'streams',
        list: 'name'
      },
      {
        value: 'status',
        enum: true
      },
      {
        label: 'Updated On',
        value: 'updatedAt',
        date: true
      },
      {
        label: 'Updated By',
        value: 'updatedBy',
        list: 'name'
      }
    ],
    order: { desc: 'updatedAt' },
    openSearch
  },
  forms: {
    publishAll: [
      {
        input: 'checkbox-group',
        name: 'streams',
        label: 'Filter by stream',
        isInline: false,
        showValidation: false,
        required: true,
        column: 'left',
        filterOnly: true
      },
      {
        input: 'static',
        name: 'content',
        label: 'Content to be published',
        transform: transformContentStatus,
        transformToComponent: true,
        column: 'right'
      }
    ]
  },
  // Most content types include some shared fields from Content.
  // All content types must include, at a minimum, id.
  // All possible children (defined in Bundle schema) must include parentsList.
  // All possible attachments (defined in Content.availableAttachments) must
  // include attachmentsCount.
  inputs: {
    expiresAt: {
      // We don't have any sync code to handle this field, so it is not
      // embedded in any content types' forms. If we add downstream logic to
      // handle this in the future, we can easily embed this field in forms
      // for the relevant content types.
      input: 'datepicker',
      name: 'expiresAt',
      label: 'Content Expiration Date',
      caption: 'Does this content become out-of-date after a certain amount of time? (in Eastern Time)'
    },
    notes: {
      input: 'prosemirror',
      name: 'notes',
      value: 'rawNotes',
      label: 'Staff/Contributor Notes',
      secondaryText: 'Only viewable by Newsela staff & contributors',
      isMultiline: true,
      formats: ['bold', 'italic', 'orderedList', 'link']
    },
    parentsList: {
      input: 'static',
      name: 'parentsList',
      label: (config, formData) => `Parent Bundle List (Total: ${formData.parentsCount})`,
      transform: transformParentBundleList,
      transformToComponent: true,
    },
    id: {
      input: 'static',
      name: 'id',
      label: 'Content ID',
      caption: 'Used for debugging'
    },
    attachmentsCount: {
      input: 'static',
      name: 'attachmentsCount',
      label: 'Content Attachments',
      caption: 'How many pieces of content is this attached to?'
    },
    attached: {
      input: 'editor-list',
      name: 'attached',
      label: 'Attachments',
      addText: 'Add Attachment',
      editorTypes: availableAttachments,
      emptyMessage: 'This content doesn’t have any attachments.'
    }
  },
  availableAttachments
};

export default Content;
