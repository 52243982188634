import { ApolloClient, InMemoryCache, ApolloLink } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { createPersistedQueryLink } from '@apollo/client/link/persisted-queries';
import { RetryLink } from '@apollo/client/link/retry';
import * as Sentry from '@sentry/react';
import DebounceLink from 'apollo-link-debounce';
import createUploadLink from 'apollo-upload-client/createUploadLink.mjs';
import { sha256 } from 'crypto-hash';
import cuid from 'cuid';
import { reduce } from 'lodash-es';

import { titleCase } from '@client/utils/cases';
import { logError } from '@client/utils/log';
import { handleServerErrors } from '@client/utils/on-server-error';
import { offsetStartPagination } from '@client/utils/pagination-cache';

import rawSchema from './schema';
import { isUnauthenticated } from './utils/authentication';

const alexandriaUrl = process.env.ALEXANDRIA_URL ? `${process.env.ALEXANDRIA_URL}` : '/graphql';
const DEBOUNCE_TIMEOUT = 2000;

// Available content types are generated from the schema.
const contentTypes = rawSchema.enums.ContentType.map(titleCase);
const questionTypes = rawSchema.enums.QuestionType.map(titleCase);

// Custom merge function to prevent Apollo from logging a warning when removing items.
function returnIncoming (existing, incoming) {
  return incoming;
}

function logSentryErrors (operation, graphQLErrors, networkError) {
  // Add sentry context for the error(s).
  Sentry.configureScope((scope) => {
    scope.setTransactionName(operation.operationName);

    if (operation.variables.id) {
      scope.setContext('Request Details', {
        queryId: operation.variables.id
      });
    }

    if (graphQLErrors) {
      graphQLErrors.forEach((e) => {
        // Because GraphQL errors are not real JavaScript Error instances,
        // create a new error from their message and send it to sentry.
        Sentry.captureException(new Error(e.message));
        logError(`GraphQL Error for ${operation.operationName}${operation.variables?.id ? `: ${operation.variables.id}` : ''}`, e);
      });
    } else if (networkError) {
      Sentry.captureException(networkError);
      logError(networkError);
    }
  });
}

const cache = new InMemoryCache({
  // When adding new Content implementations, add them here:
  possibleTypes: {
    Content: contentTypes,
    AssessmentQuestion: questionTypes
  },
  typePolicies: {
    // Nodes that have id fields:
    // Iterate through all content types (and Content itself), and make sure
    // we're merging their fields correctly when doing shallow queries.
    ...reduce(contentTypes.concat(['Content']), (acc, type) => ({
      ...acc,
      [type]: {
        keyFields: ['id'],
        // When doing deep queries + shallow mutations, the mutation will update
        // the cache but won't set fields that the query wants to know about,
        // specifically 'attached' and 'children'
        fields: {
          attached: {
            read (data) {
              return data || [];
            },
            merge: returnIncoming
          },
          children: {
            read (data) {
              return data || [];
            },
            merge: returnIncoming
          },
          // When editing, update the client with any new events generated
          // on the server.
          events: { merge: returnIncoming },
          // When editing, we ignore content position. This means that it
          // returns as `undefined` from the server. Apollo Client's cache
          // doesn't like this, so make sure it returns `null` instead.
          position: {
            read (data) {
              return data || null;
            }
          },
          ...type === 'Bundle' && {
            gradeBands: { merge: returnIncoming },
            streams: { merge: returnIncoming }
          },
          ...type === 'Article' && {
            legacyWorkflows: { merge: returnIncoming },
            liteProductGrants: { merge: returnIncoming },
            streams: { merge: returnIncoming }
          },
          ...type === 'Image' && {
            altTexts: { merge: returnIncoming },
            streams: { merge: returnIncoming }
          },
          ...[
            'Audio',
            'Assessment',
            'ExternalLink',
            'InstructionalNote',
            'LegacyArticle',
            'LessonSpark',
            'SmartBundle',
            'Video'
          ].includes(type) && {
            streams: { merge: returnIncoming }
          }
        }
      }
    }), {}),
    // Also reduce through the assessment question types, adding their keys.
    ...reduce(questionTypes.concat(['AssessmentQuestion']), (acc, type) => ({
      ...acc,
      [type]: {
        keyFields: ['id', 'uid'],
        fields: {
          ...type === 'MultipleChoice' && {
            options: { merge: returnIncoming }
          }
        }
      }
    }), {}),
    Standard: { keyFields: ['id'] },
    Tag: { keyFields: ['id'] },
    Stream: { keyFields: ['id'] },
    ContentProvider: {
      keyFields: ['id'],
      fields: {
        subproviders: { merge: returnIncoming },
        providerTitles: { merge: returnIncoming }
      }
    },
    User: { keyFields: ['id'] },
    WordDefinition: { keyFields: ['id'] },
    // Nodes that do not have id fields:
    Level: { keyFields: ['uid'] },
    AssessmentLevel: { keyFields: false },
    ArticleLevel: { keyFields: false },
    LexileBlock: { keyFields: false },
    QuestionOption: { keyFields: false },
    ContentIssue: { keyFields: false },
    ContentIssueLocation: { keyFields: false },
    // Events are effectively read-only on the client-side.
    Event: { keyFields: false },
    // Deduplicate data in the cache when fetching lists of items.
    Query: {
      fields: {
        contentProvider: { keyArgs: ['id'] },
        contentProviders: offsetStartPagination(['filter', 'order']),
        content: { keyArgs: ['id'] },
        contentSnapshot: { keyArgs: false },
        contents: offsetStartPagination(
          ['filter', 'order', 'version', 'versionAt', 'rawFilter']
        ),
        contentValidity: { keyArgs: ['id', 'cascadeToStreams'] },
        assessmentQuestion: { keyArgs: ['id'] },
        standard: { keyArgs: ['id'] },
        standards: offsetStartPagination(['filter', 'order']),
        stream: { keyArgs: ['id'] },
        streams: offsetStartPagination(['filter', 'order']),
        subjectProductStreams: { keyArgs: ['id'] },
        customStreams: { keyArgs: ['id'] },
        tag: { keyArgs: ['id'] },
        tags: offsetStartPagination(['filter', 'order']),
        me: { keyArgs: ['id'] },
        powerWords: { merge: returnIncoming }
      }
    }
  }
});

// Create the link from:
// - Automatic Persisted Queries -> https://www.apollographql.com/docs/apollo-server/performance/apq/
// - Http Link (the link that handles errors, dedupes, and actual http fetches)
const link = ApolloLink.from([
  new DebounceLink(DEBOUNCE_TIMEOUT),
  onError(({ graphQLErrors, networkError, operation }) => {
    handleServerErrors({ graphQLErrors, networkError, operation });
    logSentryErrors(operation, graphQLErrors, networkError);
  }),
  createPersistedQueryLink({ sha256, useGETForHashedQueries: true }),
  new RetryLink({
    attempts: {
      retryIf: (error) => !isUnauthenticated(error.result?.errors || [])
    }
  }),
  // Create the terminating link, which will handle file uploads and regular
  // GraphQL requests.
  createUploadLink({
    // when calling Alexandria Server.
    uri: alexandriaUrl,
    credentials: 'include',
    fetch: (url, options) => {
      // When we do API calls, generate a unique correlation ID for each request.
      // This is used to correlate logs between Newsela systems.
      const correlationId = cuid();

      Sentry.setTag('correlation_id', correlationId);

      return fetch(url, {
        ...options,
        headers: {
          ...options.headers,
          'X-Correlation-ID': correlationId
        }
      });
    }
  })
]);

export const apolloClient = new ApolloClient({
  link,
  cache,
  name: 'Alexandria Client',
  version: process.env.GIT_TAG || process.env.GIT_BRANCH || process.env.GIT_SHA,
  // Allow apollo dev tools on prod.
  connectToDevTools: true
});
