import React, { useRef } from 'react';

import { useApolloClient } from '@apollo/client';
import { useStoreActions } from 'easy-peasy';
import Button from 'mineral-ui/Button';
import ButtonGroup from 'mineral-ui/ButtonGroup';
import IconAttachFile from 'mineral-ui-icons/IconAttachFile';
import IconInsertLink from 'mineral-ui-icons/IconInsertLink';
import PropTypes from 'prop-types';

import { mutations } from '@client/common/graph';
import { logError } from '@client/utils/log';

import { $warningHeading } from './style';

function ArticleLoaderWarning () {
  return (
    <p css={$warningHeading}>
      <strong>Warning:</strong> This will overwrite the article's current slug, author, source URL, title, teaser, and body text, if any.
    </p>
  );
}

export default function ArticleLoader ({ formData }) {
  const client = useApolloClient();
  const openModal = useStoreActions((actions) => actions.forms.modal.open);
  const setStatus = useStoreActions((actions) => actions.saveStatus.setStatus);
  const refreshForm = useStoreActions((actions) => actions.forms.formRefresh.refreshForm);
  const setGlobalLoadingSpinner = useStoreActions((actions) => actions.setGlobalLoadingSpinner);

  const id = formData.id;
  // Reference to the DOM element that opened the modal.
  // This reference is used to get the focus back when modal closes.
  const returnFocusRef = useRef(null);

  const onSave = async (uid, serverData, isFile) => {
    if (!serverData.set) {
      return;
    }
    const url = serverData.set.url;
    try {
      // Load the article data from the URL specified or the file.
      // Because it was uploaded to S3, we want to tell the server that it
      // should fetch it and use our file parser rather than simply
      // using mercury-parser.
      const url = serverData.set.url;

      setStatus({ isLoadingArticle: true });
      setGlobalLoadingSpinner({ loadingState: true, label: 'Loading article' });
      await client.mutate({
        mutation: mutations.loadFromUrl,
        variables: {
          id,
          url,
          isFile
        }
      });
      // We call the easy-peasy action to update the form
      // with the information from the server
      refreshForm();
    } catch (err) {
      logError(`Error uploading file to ${url}: ${err.message}`, err);
    } finally {
      setStatus({ isLoadingArticle: false });
      setGlobalLoadingSpinner({ loadingState: false });
    }
  };

  const openUrlLoaderModal = (e) => {
    returnFocusRef.current = e.currentTarget;
    openModal({
      title: 'Load Article From URL',
      config: [{ input: 'text', type: 'url', name: 'url' }],
      data: { uid: formData.uid },
      footer: <ArticleLoaderWarning />,
      returnFocusRef,
      onSave: async (uid, serverData) => onSave(uid, serverData)
    });
  };

  const openFileLoaderModal = (e) => {
    returnFocusRef.current = e.currentTarget;
    openModal({
      title: 'Load Article From File',
      footer: <ArticleLoaderWarning />,
      returnFocusRef,
      config: [{
        input: 'file',
        name: 'url',
        label: 'Upload File',
        accept: [
          // .htm, .html
          'text/html',
          // .txt
          'text/plain'
          // We'll add support for other formats based on content team needs.
        ]
      }],
      data: { uid: formData.uid, __typename: 'Article' },
      onSave: async (uid, serverData) => onSave(uid, serverData, true)
    });
  };

  return (
    <ButtonGroup fullWidth aria-label='Load Article'>
      <Button
        type='button'
        size='jumbo'
        aria-label='Load From URL'
        iconStart={<IconInsertLink />}
        onClick={openUrlLoaderModal}
      >From URL
      </Button>
      <Button
        type='button'
        size='jumbo'
        aria-label='Load From File'
        iconStart={<IconAttachFile />}
        onClick={openFileLoaderModal}
      >From File
      </Button>
    </ButtonGroup>
  );
}

ArticleLoader.propTypes = {
  formData: PropTypes.object
};

ArticleLoader.displayName = 'ArticleLoaderGroup';
