import {
  upperFirst,
  camelCase,
  startCase,
  toLower,
  toUpper,
  snakeCase
} from 'lodash';

// Useful to convert contentType to __typename: FOO_BAR -> FooBar
export function titleCase (str) {
  return upperFirst(camelCase(str));
}

// Useful to convert __typename to contentType: FooBar -> FOO_BAR
export function enumCase (str) {
  return toUpper(snakeCase(str));
}

// Useful where case should be ignored (defaults to original formatting)
export function evaluateCase (str, ignoreCase = false) {
  return ignoreCase ? str : titleCaseSpaced(str);
}

// Useful to convert contentType to standard title case: FOO_BAR -> Foo Bar
export function titleCaseSpaced (str) {
  return startCase(toLower(enumCase(str)));
}
