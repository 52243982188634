import React, { useState } from 'react';

import { useApolloClient } from '@apollo/client';
import { constants } from '@newsela/angelou';
import debounce from 'debounce-promise';
import { orderBy, isEmpty, some } from 'lodash-es';
import Checkbox from 'mineral-ui/Checkbox';
import { themed } from 'mineral-ui/themes';
import Tooltip from 'mineral-ui/Tooltip';
import plur from 'plur';
import PropTypes from 'prop-types';
import { components } from 'react-select';
import AsyncSelect from 'react-select/async';
import states from 'us-state-codes';

import { queries } from '@client/common/graph';
import { MetadataStandard } from '@client/common/schema';
import { CERTICA_TAG_TYPES } from '@client/utils/constants';
import { logError } from '@client/utils/log';
import search from '@client/utils/search';
import { getSelectStyles } from '@client/utils/styles';

import {
  $optionBody,
  $optionLabel,
  $optionLabelDetail,
  $optionFirstRow,
  $optionSecondRow,
  $optionDescWithRating,
  $optionDesc,
  $optionConfidenceRating,
  $menuList,
  $optionDivider,
  $checkbox,
  $standardContainer,
  $derivativeStandards
} from './style';

// This styles the custom tooltip.
const { ui } = constants.colors;
const CustomTooltip = themed(Tooltip)({
  TooltipContent_backgroundColor: ui.greyLight[50],
  TooltipContent_borderColor: ui.greyLight[50],
  TooltipContent_color: ui.greyDark[500],
  TooltipArrow_backgroundColor: ui.greyLight[50],
  TooltipArrow_borderColor: ui.greyLight[50]
});

/**
 * Render grade ranges. Exported for testing.
 * @param {array} grades - array of grades
 * @return {string} grade range
 */
export function renderGrades (grades) {
  if (!isEmpty(grades)) {
    return grades.length === 1
      ? `Grade ${grades[0]}`
      : `${plur('Grade')} ${grades[0]} - ${grades[grades.length - 1]}`;
  } else {
    return '';
  }
}

/**
 * Abbreviate the region name. Exported for testing.
 * @param {string} region
 * @returns {string}
 */
export function getState (region) {
  if (region === 'United States of America') {
    return 'US';
  } else if (states.getStateCodeByStateName(region)) {
    return states.getStateCodeByStateName(region);
  } else {
    return region; // Fall back to longer state name if abbreviation fails
  }
}

/**
 * Get the state from a standard's region.
 * @param {object} standard
 * @returns {string|null}
 */
function transformRegion (standard) {
  if (standard.region) {
    return getState(standard.region);
  } else {
    return null;
  }
}

/**
 * Get the grades from a standard.
 * @param {object} standard
 * @returns {string}
 */
function transformGrades (standard) {
  if (standard.grades) {
    return renderGrades(standard.grades);
  } else {
    return null;
  }
}

/**
 * Get the subject for a standard. Will use the shortest subject available.
 * Exported for testing.
 * @param {object} standard
 * @returns {string}
 */
export function transformSubject (standard) {
  if (standard.subjectShort) {
    return standard.subjectShort;
  } else if (standard.subjectLong) {
    return standard.subjectLong;
  } else {
    return null;
  }
}

// Convert a value to the form that react-select wants
function toOptions (value) {
  const sortedValueDescending = orderBy(value, ['confidenceRating', 'standardTitle'], ['desc', 'asc']);

  return (sortedValueDescending || []).map((standard) => ({
    label: standard.standardTitle,
    value: standard.id,
    // Also pass through the standard id, even though it's not consumed
    // by the option component.
    id: standard.id,
    description: standard.standardDescription,
    region: transformRegion(standard),
    grades: transformGrades(standard),
    subject: transformSubject(standard),
    confidenceRating: standard.confidenceRating,
    hasDerivedStandards: standard.hasDerivedStandards,
    // Also pass through the underlying standard object, so we can use it for
    // optimistic re-rendering.
    standard
  }));
}

// Customizable value component. See: https://react-select.com/components
/* eslint-disable react/prop-types */
const MultiValue = (props) => {
  const { menuIsOpen } = props.selectProps;
  const { id, label, description, hasDerivedStandards } = props.data;
  const isChip = true;
  return (
    <components.MultiValue {...props} key={id}>
      {hasDerivedStandards && (
        <CustomTooltip content='This standard includes derivative standards.' disabled={menuIsOpen}>
          <span css={$derivativeStandards(isChip)}>DS</span>
        </CustomTooltip>
      )}
      <CustomTooltip content={description} disabled={menuIsOpen}>
        <span>{label}</span>
      </CustomTooltip>
    </components.MultiValue>
  );
};

const optionBody = (props) => (
  <div css={$optionBody}>
    <span css={
      props.data.confidenceRating ? $optionDescWithRating : $optionDesc
    }
    >
      {props.data.description}
    </span>
    <span css={$optionConfidenceRating}>{
      props.data.confidenceRating
        ? `${Math.round(props.data.confidenceRating)}%`
        : ''
    }
    </span>
  </div>
);

// Customizable option component. See: https://react-select.com/components
const Option = (props) => {
  const { label, subject, region, grades, hasDerivedStandards } = props.data;
  const isChip = false;
  return (
    <components.Option {...props}>
      <div css={$optionFirstRow}>
        <span css={$optionLabel}>{label}</span>
        {hasDerivedStandards && (
          <div>
            <CustomTooltip
              content='This standard includes derivative standards.'
            >
              <span css={$derivativeStandards(isChip)}>DS</span>
            </CustomTooltip>
          </div>
        )}
      </div>
      <div css={$optionSecondRow}>
        <span css={$optionLabelDetail}>{subject}</span>
        <span css={$optionLabelDetail}>{
            region
              ? <span css={$optionDivider}>|</span>
              : ''
          }
        </span>
        <span css={$optionLabelDetail}>{
            region ? `${region}` : ''
          }
        </span>
        <span css={$optionLabelDetail}>{
            grades
              ? <span css={$optionDivider}>|</span>
              : ''
          }
        </span>
        <span css={$optionLabelDetail}>{grades}</span>

      </div>
      {optionBody(props)}
    </components.Option>
  );
};

// Customizable menu/label component. See: https://react-select.com/components
const MenuList = (props) => {
  return (
    <>
      <div css={$menuList}>
        <span>Suggested Standards</span>
        <span>Confidence Rating</span>
      </div>
      <components.MenuList {...props}>
        {props.children}
      </components.MenuList>
    </>
  );
};
/* eslint-enable react/prop-types */

// Checks whether the select input should fetch predicted standards
function shouldFetchPredictions (formData) {
  const hasCerticaId = !!formData.certicaId;
  const hasStandards = !isEmpty(formData.metadataStandards);
  const hasCerticaTags = some(formData.tags, (tag) => {
    return CERTICA_TAG_TYPES.includes(tag.tagType);
  });

  return hasCerticaId && (hasStandards || hasCerticaTags);
}

/**
 * Set up function that asynchronously loads standards from our API. Exported
 * for testing.
 * @param {object} formData
 * @param {ApolloClient} apolloClient
 * @param {boolean} isQuickSearch
 * @return {Promise}
 */
export function loadOptions (formData, apolloClient, isQuickSearch, excludeIds) {
  /**
   * Load predicted standards or queried standards from our API.
   * @param {string} inputValue - value being typed into the field
   * @return {Promise}
   */
  return async (inputValue) => {
    if (!inputValue && shouldFetchPredictions(formData)) {
      const { data } = await apolloClient.query({
        query: queries.predictedStandards,
        variables: { certicaId: formData.certicaId },
        fetchPolicy: 'network-only'
      });
      return toOptions(data.predictedStandards);
      // If Certica doesn't return any predictions, don't add any default options
      // when the input is loaded. We'll rely solely on the user query to find
      // relevant standards.
    } else if (inputValue) {
      const { data } = await apolloClient.query({
        query: queries.searchMetadataStandards,
        fetchPolicy: 'network-only',
        ...search(MetadataStandard, inputValue, {
          isQuickSearch,
          id: formData.id,
          // Always search for 20 standards here.
          variables: {
            needle: inputValue,
            limitToNationalStandards: isQuickSearch,
            excludeIds
          }
        })
      });
      // Convert to the format that react-select wants.
      return toOptions(data.searchMetadataStandards);
    } else {
      // No input, and no Certica predications.
      return [];
    }
  };
}

/**
 * Determine what should happen when options are selected, deselected, etc.
 * Exported for testing.
 * @param {string} name of the field
 * @param {array} value of the field before the change
 * @param {Function} onChange passed in from Form
 * @returns {Function}
 */
export function onSelectChange (name, value, onChange) {
  return async (selectedValue, actionMeta) => {
    try {
      switch (actionMeta.action) {
        case 'select-option':
          return await onChange(
            { [name]: { id: actionMeta.option.value } },
            { [name]: [...value, actionMeta.option.metadataStandard] },
            'set',
            debounce,
          );
        case 'deselect-option': // fall through
        case 'pop-value': // fall through
        case 'remove-value':
          return await onChange(
            { [name]: { id: actionMeta.removedValue.value } },
            { [name]: value.filter((option) => option.id !== actionMeta.removedValue.value) },
            'unset',
            debounce,
          );
        case 'clear':
          return await onChange(
            { [name]: null },
            { [name]: [] },
            'unset',
            debounce,
          );
      }
    } catch (err) {
      const inputFieldAction = actionMeta.action;
      logError(`onSelectChange error while ${inputFieldAction} in StandardSelect field: ${err.message}`, err);
      throw err;
    }
  };
}

export default function StandardSelect ({ value, name, onChange, formData, variant }) {
  const initialValue = value || [];
  const selectedOptions = toOptions(initialValue);
  const apolloClient = useApolloClient();
  const styles = getSelectStyles(variant);
  const [inputValue, setInputValue] = useState(false);
  const [isQuickSearch, setQuickSearch] = useState(true);
  const excludeIds = formData?.metadataStandards?.map((standard) => standard.id) || [];
  const debounceLoadOptions = debounce(loadOptions(formData, apolloClient, isQuickSearch, excludeIds), 500);

  // Handle changes to the select input.
  const handleChange = onSelectChange(name, initialValue, onChange);

  // Toggles state based on if input value is empty or not to determine whether or not
  // to show "Suggested Standards" label.
  const onInputChange = (input) => {
    !input ? setInputValue(false) : setInputValue(true);
  };

  // Determine if we should show MenuList component ("Suggested Standards" label)
  const components = {
    Option,
    MultiValue,
    DropdownIndicator: null,
    ...!inputValue && { MenuList }
  };

  return (
    <>
      <Checkbox
        checked={isQuickSearch}
        label='Limit to national standards'
        name={`${name}|quicksearch`}
        onChange={(e) => setQuickSearch(e.target.checked)}
        css={$checkbox}
      />
      <AsyncSelect
        isMulti
        classNamePrefix='standard-container'
        value={selectedOptions}
        onChange={handleChange}
        components={components}
        loadOptions={debounceLoadOptions}
        styles={styles}
        // defaultOptions needs to be true so it can call loadOptions initially.
        defaultOptions
        onInputChange={onInputChange}
        css={$standardContainer}
      />
    </>
  );
}

MultiValue.propTypes = {
  /** Props passed into react-select component */
  props: PropTypes.object,
  data: PropTypes.object
};

Option.propTypes = {
  /** Props passed into react-select component */
  props: PropTypes.object,
  data: PropTypes.object
};

StandardSelect.propTypes = {
  /** Field value, from the form-level state */
  value: PropTypes.array,
  /** Field name, which is also the property the data will be saved to */
  name: PropTypes.string,
  /** Function that updates the form state and persists data */
  onChange: PropTypes.func,
  /** Full form data */
  formData: PropTypes.object,
  variant: PropTypes.string
};

// Set the display name to Group so we can have multiple inputs inside of the
// component.
StandardSelect.displayName = 'StandardSelectGroup';
