import { css } from '@emotion/core';

export const $root = css`
  position: relative;
  z-index: 0;
`;

export const $textarea = (hasButton) => {
  const base = css`
    min-height: 40px;
    z-index: 0;
  `;

  return hasButton ? [
    base,
    css`padding-right: 34px;`
  ] : base;
};

export const $button = css`
  position: absolute;
  right: 0;
  top: 7px;
`;
