import { faFileWord } from '@fortawesome/pro-light-svg-icons';
import cuid from 'cuid';
import gql from 'graphql-tag';

const WordDefinition = {
  fullFragment: gql`
    fragment fullWordDefinition on WordDefinition {
      id
      uid
      wordForm
      definition
      rawDefinition: definition(format: TEXT_RAW)
      belongsTo {
        id
        uid
      }
      powerWordQuestions { id uid }
    }
  `,
  filter,
  quickFilter: filter,
  icon: faFileWord,
  // When creating new word definitions, we need to set the belongsTo property
  // based on the Word data (passed in as relatedData).
  defaults: (id, data = {}, relatedData = {}) => {
    id = id || cuid();
    const uid = `_:${id}`;
    const wordForm = data.wordForm;
    const belongsTo = relatedData.id ? {
      id: relatedData.id,
      uid: relatedData.uid
    } : null;

    return {
      client: {
        id,
        uid,
        wordForm,
        belongsTo
      },
      server: {
        id,
        uid,
        wordForm
      }
    };
  },
};

function filter (query, { uid }) {
  return {
    definedIn: { uid_in: [uid] },
    definition: { exists: true }
  };
}

export default WordDefinition;
