import cuid from 'cuid';
import gql from 'graphql-tag';

import { DEFAULT_LANG } from '@client/utils/constants';

const ArticlePublication = {
  fullFragment: gql`
    fragment fullArticlePublication on ArticlePublication {
      uid
      language
      visibleAfter
      articleHeaderId
    }
  `,
  defaults: (existingUid, data = {}) => {
    const uid = existingUid || `_:${cuid()}`;
    const language = data.language || DEFAULT_LANG;
    const visibleAfter = data.visibleAfter || null;
    const articleHeaderId = data.articleHeaderId || null;
    return {
      client: {
        __typename: 'ArticlePublication',
        uid,
        language,
        visibleAfter,
        articleHeaderId
      },
      server: {
        uid,
        language,
        visibleAfter,
      }
    };
  },
  typename: 'Article Publication',
};

export default ArticlePublication;
