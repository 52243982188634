import React from 'react';

import { Badge } from '@newsela/angelou';

import { getEnvironmentFlags } from '@client/utils/environment';

export default function NonProdEnv () {
  const hostname = window.location.hostname;
  const nonProdEnv = getEnvironmentFlags(hostname);
  return (
    nonProdEnv
      ? <Badge
          text={`${nonProdEnv.name} Environment`}
          flavor={Badge.flavor.solid}
          color={Badge.color[nonProdEnv.color]}
          size={Badge.size.lg}
          hideIcon
        />
      : null
  );
}
