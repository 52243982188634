import { faH1, faH2 } from '@fortawesome/pro-regular-svg-icons';

import { headings } from '@shared/prosemirror/blocks';

import { blockActive, setBlockType, toggleBlockType } from './helpers';

// Prosemirror allows all six heading levels by default, but
// we only want to allow two.
export const block = headings;
export const keys = {
  'Shift-Ctrl-1': setBlockType('headings_block', { level: 2 }),
  'Shift-Ctrl-2': setBlockType('headings_block', { level: 3 })
};
export const menuBlock = [
  {
    title: 'Subheading',
    content: faH1,
    active: blockActive('headings_block', { level: 2 }),
    run: toggleBlockType('headings_block', { level: 2 })
  }, {
    title: 'Subsection under subheading',
    content: faH2,
    active: blockActive('headings_block', { level: 3 }),
    run: toggleBlockType('headings_block', { level: 3 })
  }
];
