import { Z_INDEX_9999_MAX, MODAL_WIDTH } from '@client/utils/constants';

export const $root = `
  z-index: ${Z_INDEX_9999_MAX};
`;

export const $modalContentWrapper = `
  display: grid;
  grid-template-rows: auto 1fr auto;
  max-width: ${MODAL_WIDTH}px !important;
  max-height: 75vh !important;
  height: auto;
`;

export const $scrollableBody = (containerStyles) => [
  `
    display: flex;
    flex-direction: column;
    overflow: hidden;
    overflow-y: auto;
  `,
  { ...containerStyles }
];

export const $footer = `
  text-align: end;
  & > button {
    margin-left: 20px;
  }
`;
