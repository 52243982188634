import React, { useState, useEffect, useRef, createRef } from 'react';

import { Button } from '@newsela/angelou';
import cuid from 'cuid';
import { useStoreActions } from 'easy-peasy';
import { first, find, isEmpty, sortBy } from 'lodash-es';
import PropTypes from 'prop-types';

import { Assessment, AssessmentQuestion } from '@client/common/schema';
import AddQuestionButton from '@client/forms/components/AddQuestionButton';
import QuestionCard from '@client/forms/components/QuestionCard';
import ValidationMessage from '@client/forms/components/ValidationMessage';
import rawSchema from '@client/schema';
import { formatGradeBand, enumOptions } from '@client/utils/fields';
import { getFieldValidation, getAggregateMessage, getCaption } from '@client/utils/form-validation';
import { getVariantIcon } from '@client/utils/styles';

import { $root, $levels, $level, $levelBlock, $language, $emptyQuestions, $label } from './style';

// An array of grade bands in the correct order to reference when sorting them into respective language objects.
const gradeBandsSorted = enumOptions(rawSchema.enums.GradeBand, 'GradeBand').map((item) => item.value);

/**
 * Sort assessment levels by grade band.
 * @param {Object} a assessment level with { gradeBand }
 * @param {Object} b assessment level with { gradeBand }
 * @returns {Number}
 */
function byGradeBand (a, b) {
  return gradeBandsSorted.indexOf(a.gradeBand) - gradeBandsSorted.indexOf(b.gradeBand);
}

// We're calling this component AssessmentLevelsGroup because the 'Group' part will
// prevent Mineral-UI's FormField from wrapping the entire thing in a <label>,
// allowing us to get around the native label issues with multiple child buttons
// (when hovering or clicking any input, that behavior is transmitted to the first
// button).

export default function AssessmentLevelsGroup ({
  value,
  name,
  config,
  onChange,
  formData,
  parent,
  breadcrumbs,
  validatedContent,
  levelUid
}) {
  const currentLevels = value || [];
  const [selected, setSelected] = useState();
  // Keep track of when new QuestionCards are created
  // so that we can focus on them as soon as they are added (for accessibility).
  const [isNewQuestionCard, setIsNewQuestionCard] = useState(false);

  // Sort the currentLevels into language groups, and order them to ascend by grade.
  const currentLevelsByLanguage = {
    ...config.languages.reduce((acc, lang) => ({ ...acc, [lang.value]: [] }), {})
  };

  config.languages.forEach((language) => {
    const languageSortedLevels = currentLevels
      .filter((level) => (level.language === language.value && level.isActive))
      .sort(byGradeBand);

    currentLevelsByLanguage[language.value] = [...languageSortedLevels];
  });

  const questionCardRef = createRef();
  // Unless we set an empty object, useEffect ignores it.
  questionCardRef.current = {};

  // Focus new QuestionCard
  useEffect(() => {
    if (isNewQuestionCard && questionCardRef?.current?.focus) {
      questionCardRef.current.focus();
      setIsNewQuestionCard(false);
    }
  }, [questionCardRef.current]);

  // If we update the levels and set the selected one inactive, update the selected level to the max active level one.
  // If we came from a question, select the question level.
  useEffect(() => {
    // A note on deactivating existing levels while creating new levels:
    // 1. When creating a new level that will become the first active level, the level will be represented in
    // currentLevels with its optimistic data and will initially be selected.
    // 2. When the data returns from the server, there will no longer be a match in currentLevels for the selected
    // (temporary) uid, so we need to set the selectedLevel again to the max active level.

    let levelToSelect = levelUid
      // When navigating back from questions into the Assessment, select the level the question was on.
      ? currentLevels.find((level) => level.uid === levelUid)
      // When navigating from a sibling Assessment, select the same level.
      : currentLevels
        .filter((level) => level.isActive)
        .find((level) => selected?.gradeBand === level.gradeBand);

    if (!levelToSelect || !selected || !selected.isActive) {
      // If the selected level is toggled off or its uid has changed (e.g. when a newly-created
      // level returns from the server), then select the max active level.
      levelToSelect = first(currentLevelsByLanguage.LANG_EN) || first(currentLevelsByLanguage.LANG_ES);
    }

    setSelected(levelToSelect);
  }, [currentLevels]);

  const openModal = useStoreActions((actions) => actions.forms.modal.open);

  // Reference to the DOM element that opened the modal.
  // This reference is used to get the focus back when modal closes.
  const returnFocusRef = useRef(null);

  const openLevelsModal = (e) => {
    returnFocusRef.current = e.currentTarget;
    openModal({
      title: 'Select Levels',
      // Uses 'gradeBandLevelSelect' input.
      config: Assessment.forms.levelSelect,
      data: {
        uid: formData.uid,
        assessmentType: formData.assessmentType,
        contentType: formData.contentType,
        levels: currentLevels
      },
      returnFocusRef,
      onSave: (uid, serverData, optimisticData) => {
        // We never remove levels, we only set them as inactive. Thus, all
        // updates to the form are additive (we never use 'unset').
        if (serverData.set) {
          onChange(
            { [name]: serverData.set.levels },
            { [name]: optimisticData.levels }
          );
        }
      }
    });
  };

  const onAddItem = async (data) => {
    const id = cuid();
    const newQuestion = AssessmentQuestion.defaults(id, data);
    const updatedLevel = {
      ...selected,
      questions: [
        ...selected.questions,
        newQuestion.client
      ]
    };
    const optimisticData = value.map((level) => level.uid === selected?.uid ? updatedLevel : level);

    // We need to wait for the new QuestionCard to be created before we can focus on it.
    await onChange(
      // Server data.
      {
        [name]: {
          uid: updatedLevel.uid,
          questions: newQuestion.server
        }
      },
      // Optimistic data.
      {
        [name]: optimisticData
      }
    );

    // Flag to focus on the new QuestionCard.
    setIsNewQuestionCard(true);
  };

  const onDeleteItem = (deletedQuestion) => {
    const updatedLevel = {
      ...selected,
      questions: selected.questions.filter((question) => {
        return question.id !== deletedQuestion.id;
      })
    };
    const optimisticData = value.map((level) => level.uid === selected?.uid ? updatedLevel : level);

    onChange(
      // Server data.
      {
        [name]: {
          uid: updatedLevel.uid,
          questions: {
            uid: deletedQuestion.uid
          }
        }
      },
      // Optimistic data.
      {
        [name]: optimisticData
      },
      'unset'
    );
  };

  const renderQuestionCards = (selectedLevel, fieldPathPrefix) => {
    const questionParent = {
      // The id field is the assessment id, it's used for filter validation in the question options.
      id: formData.id,
      uid: selectedLevel.uid,
      field: 'questions',
      fieldType: 'assessment-levels',
      hasBeenPublished: !!formData.events?.some((event) => event.event === 'PUBLISH')
    };
    const questionSiblings = selectedLevel.questions.map((question, index) => ({
      id: question.id,
      uid: question.uid,
      // Splits out name prefix
      // For example, "Q1: Multiple Choice" becomes "Q1"
      title: question?.name ? question.name.split(':')[0] : `Q${index + 1}`,
      type: 'AssessmentQuestion',
      isContent: false,
      parent: questionParent,
      fieldPath: `${fieldPathPrefix}.questions.${index}`
    }));
    const parentWithSiblings = {
      ...questionParent,
      siblings: questionSiblings
    };

    return (
      <div>
        {selectedLevel.questions.map((question, index) => {
          const fieldPath = `${fieldPathPrefix}.questions.${index}`;
          // Filter validation to this specific assessment question.
          const { errors, warnings } = getFieldValidation(validatedContent, { id: formData.id }, fieldPath, true);
          const { variant, message } = getAggregateMessage(errors, warnings, { name: 'question', isMultiple: false });
          return (
            <div key={question.id}>
              <QuestionCard
                onDeleteItem={onDeleteItem}
                question={question}
                assessment={formData}
                questionPosition={index + 1}
                questionParent={parentWithSiblings}
                currentLevel={selectedLevel}
                assessmentParent={parent}
                breadcrumbs={breadcrumbs}
                variant={variant}
                fieldPath={fieldPath}
                ref={questionCardRef}
              />
              {(variant && message) && <ValidationMessage variant={variant} message={message} />}
            </div>
          )
          ;
        })}
      </div>
    );
  };

  const renderQuestions = (validation) => {
    // Used to compose the location field to properly display errors and warnings.
    let fieldPathPrefix;
    // Return the level data if it is selected and has questions associated
    const selectedHasQuestions = find(currentLevels, (level) => {
      if (level.uid === selected?.uid && !isEmpty(level.questions)) {
        fieldPathPrefix = validation?.uidFieldPaths ? validation.uidFieldPaths[level.uid] : '';
        return true;
      }
    });

    const { errors, warnings } = getFieldValidation(validatedContent, { id: formData.id }, `${validation.uidFieldPaths[selected?.uid]}.questions`, false);
    const { variant, caption } = getCaption(errors, warnings, { caption: null });

    return (
      <>
        <hr />
        <p css={$label}>Questions</p>
        {caption && (<ValidationMessage variant={variant} message={caption} />)}
        {/* Return JSX for each of the questions to display in the UI. */}
        {selectedHasQuestions
          ? renderQuestionCards(selectedHasQuestions, fieldPathPrefix)
          : <p css={$emptyQuestions}>There are no questions for this grade level/band. Add a question below.</p>}
      </>
    );
  };

  const renderLevels = (languageGroup, levels, validation) => (
    <div key={languageGroup} css={$levelBlock}>
      <div css={$levels}>
        {levels.map((level) => {
          const isSelected = level.uid === selected?.uid;
          let variant, icon;
          if (!isEmpty(validation)) {
            const fieldPath = validation.uidFieldPaths[level.uid];
            const { errors, warnings } = getFieldValidation(validation.data, { id: validation.id }, fieldPath, true);
            ({ variant } = getAggregateMessage(errors, warnings, { name: 'level' }));
            icon = variant && { legacy_icon: { SvgComponent: getVariantIcon(variant), alignment: Button.iconAlignments.left } };
          }
          return (
            <Button
              /* AUTOGENERATED TODO: update angelou to new flavor.
                see https://github.com/newsela/angelou/blob/main/src/components/Button/README.md#MIGRATION
                for migration guide. */
              legacy_flavor={Button.legacy_flavor.incognito}
              legacy_size={Button.legacy_size.small}
              type='button'
              ariaProps={{ 'aria-label': level.gradeBand || '', 'aria-current': isSelected }}
              key={level.uid}
              {...icon}
              __cssFor={$level(variant)}
              onClick={() => setSelected(level)}
            >
              {formatGradeBand(level.gradeBand)}
            </Button>
          );
        })}
      </div>
      <div css={$language}>
        {languageGroup.slice(5)}
      </div>
    </div>
  );

  // Render a validation message if the current assessment level
  // does not have a corresponding article level.
  const renderLanguageGroupsValidation = (validation) => {
    const fieldPath = validation.uidFieldPaths[selected?.uid];
    const { errors, warnings } = getFieldValidation(validation.data, { id: validation.id }, fieldPath, false);
    if (!isEmpty(errors) || !isEmpty(warnings)) {
      const { variant, caption: message } = getCaption(errors, warnings, { caption: null });
      return <ValidationMessage variant={variant} message={message} />;
    }
  };

  // Render a selector for the levels that currently exist
  // (grouped by the languages they exist in).
  const renderLanguageGroups = (currentLevelsByLanguage, validation) => (
    <>
      {Object.keys(currentLevelsByLanguage).map((languageGroup) => {
        if (!isEmpty(currentLevelsByLanguage[languageGroup])) {
          return renderLevels(
            languageGroup,
            currentLevelsByLanguage[languageGroup],
            validation
          );
        } else {
          return null;
        }
      })}
      {renderLanguageGroupsValidation(validation)}
    </>
  );

  // The server sorts the article levels by the uid as a number.
  // Here we need to do the same to get the right field path for errors and warnings.
  const sortedLevels = sortBy(Object.values(currentLevelsByLanguage).flat(), (level) => parseInt(level.uid, 16));
  // We need to always pass the uidFieldPaths to the question cards so they can filter validation.
  const validation = {
    id: formData.id,
    // Maps uids to field path.
    uidFieldPaths: sortedLevels.reduce((uidFieldPaths, level, index) => {
      uidFieldPaths[level.uid] = `levels.${index}`;
      return uidFieldPaths;
    }, {}),
  };

  if (!isEmpty(validatedContent.errors) || !isEmpty(validatedContent.warnings)) {
    // Filter levels issues.
    validation.data = getFieldValidation(validatedContent, { id: formData.id }, 'levels', true);
  }

  return (
    <>
      <Button
        /* AUTOGENERATED TODO: update angelou to new flavor.
        see https://github.com/newsela/angelou/blob/main/src/components/Button/README.md#MIGRATION
        for migration guide. */
        legacy_flavor={Button.legacy_flavor.flat}
        legacy_size={Button.legacy_size.small}
        legacy_statusColor={Button.legacy_statusColor.primary}
        type='button'
        ariaProps={{ 'aria-label': 'Edit Levels' }}
        onClick={openLevelsModal}
      >
        Edit Levels
      </Button>
      <div css={$root}>
        {renderLanguageGroups(currentLevelsByLanguage, validation)}
      </div>
      {selected && renderQuestions(validation)}
      {selected && (
        <AddQuestionButton
          config={config}
          formData={formData}
          onAddItem={onAddItem}
          articleLevels={parent.articleLevels}
          assessmentLevel={selected}
        />)}
    </>
  );
}

AssessmentLevelsGroup.propTypes = {
  /** Field value, from the form-level state */
  value: PropTypes.array,
  /** Field name, which is also the property the data will be saved to */
  name: PropTypes.string,
  /** Full configuration object */
  config: PropTypes.object,
  /** Function that updates the form state and persists data */
  onChange: PropTypes.func,
  formData: PropTypes.object,
  parent: PropTypes.object,
  breadcrumbs: PropTypes.array,
  validatedContent: PropTypes.shape({
    errorIds: PropTypes.array,
    errors: PropTypes.array,
    hasErrors: PropTypes.bool,
    hasWarnings: PropTypes.bool,
    warningIds: PropTypes.array,
    warnings: PropTypes.array
  }),
  levelUid: PropTypes.string
};

AssessmentLevelsGroup.displayName = 'AssessmentLevelsGroup';
