import React from 'react';

import { useApolloClient } from '@apollo/client';
import { faFileUpload } from '@fortawesome/pro-light-svg-icons';
import { useStoreActions } from 'easy-peasy';
import { isArray, isString, isNull, get, first, isEmpty } from 'lodash-es';
import PropTypes from 'prop-types';
import Dropzone from 'react-dropzone';

import Icon from '@client/common/components/Icon';
import { queries } from '@client/common/graph';
import FileItem from '@client/forms/components/FileItem';
import { getImageDimensions } from '@client/utils/get-image-dimensions';
import { logError } from '@client/utils/log';
import { uploadToS3 } from '@client/utils/rest';
import { error } from '@client/utils/toast';

import { $files, $noFiles, $dropZone } from './style';

// Exported for testing
export const UPLOAD_ERROR = 'There was an error uploading the file to S3. Please try again, or try a different file.';
export const PROVIDE_ACCEPTED_FILE_FORMATS = 'Please provide accepted file formats to file input:';
export const NO_FILES_UPLOADED = 'No files uploaded';
export const SELECT_FILES = 'Drag here to upload or click to select files';

/**
 * Read a file from the input, converting it into an ArrayBuffer
 * that can be uploaded to S3.
 * @param {object} file
 * @returns {Promise}
*/
async function readFile (file) {
  return new Promise((resolve) => {
    const reader = new window.FileReader();

    reader.addEventListener('load', () => {
      resolve(reader.result);
    });

    reader.readAsArrayBuffer(file);
  });
}

export default function File ({ name, value, config, onChange, variant, formData }) {
  const setStatus = useStoreActions((actions) => actions.saveStatus.setStatus);
  const setGlobalLoadingSpinner = useStoreActions((actions) => actions.setGlobalLoadingSpinner);
  const client = useApolloClient();

  // We don't need to cast empty values in this input.

  if (!config.accept) {
    logError(`${PROVIDE_ACCEPTED_FILE_FORMATS} ${name}`);
    return null;
    // More info: https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/file#Unique_file_type_specifiers
  }

  // Determine if we allow multiple files to be uploaded.
  const multiple = !!config.isMulti;

  /**
   * Upload a file to S3, using a presigned url.
   * @param {object} file
   * @returns {Promise} with file url
   */
  const uploadFile = async (file) => {
    try {
      // Get the filename and mimetype, which are needed to generate
      // the presigned url.
      const filename = file.name;
      const mimetype = file.type;
      const imageDimensions = file.type.startsWith('image') ? await getImageDimensions(file) : {};
      // Convert the file into a ArrayBuffer that can be sent to S3.
      const data = await readFile(file);
      // Generate the presigned url itself.
      const presignedUrl = get(await client.query({
        query: queries.presignedUrl,
        variables: {
          // This uses the typename rather than contentType, because it works
          // for non-content stuff like ContentProvider.
          type: formData.__typename,
          filename,
          mimetype
        },
        // Don't cache presigned urls in the client-side cache at all.
        fetchPolicy: 'no-cache'
      }), 'data.presignedUrl');

      // Upload the file to S3, using the presigned url.
      await uploadToS3(presignedUrl, data, mimetype);

      // Once we've uploaded the file, figure out the resulting url (it is NOT
      // returned by the upload itself, so we have to generate it from the
      // presigned url).
      const { protocol, host, pathname } = new URL(presignedUrl);
      const url = `${protocol}//${host}${pathname}`;

      return isEmpty(imageDimensions)
        ? { url }
        : {
            url,
            mimetype,
            width: imageDimensions.width,
            height: imageDimensions.height
          };
    } catch (err) {
      logError(`Error uploading file (${file.name}): ${err.message}`);
      error(UPLOAD_ERROR);
    }
  };

  const uploadFiles = async (files) => {
    try {
      setStatus({ isUploadingFile: true });
      setGlobalLoadingSpinner({ loadingState: true, label: 'Saving file' });
      const filesInfo = await Promise.all(files.map(uploadFile));
      const firstFile = first(filesInfo);
      let variables = multiple
        ? { [name]: filesInfo.map((file) => file.url) }
        : { [name]: firstFile.url };
      if (!multiple && firstFile.mimetype?.startsWith('image')) {
        variables = { ...variables, width: firstFile.width, height: firstFile.height };
      }
      if (variables) {
        await onChange(
          variables,
          null,
          'set',
          false // Don't debounce file uploads.
        );
      }
    } catch (err) {
      logError(`Error uploading file(s): ${err.message}`);
      setStatus({ isSaving: false });
    } finally {
      setStatus({ isUploadingFile: false });
      setGlobalLoadingSpinner({ loadingState: false });
    }
  };
  const onRemove = (filename) => {
    onChange({ [name]: filename }, null, 'unset', false);
  };

  return (
    <div>
      <div css={$files}>
        {/* List multiple files */}
        {isArray(value) ? value.map((filename) => <FileItem key={filename} filename={filename} onRemove={onRemove} />) : null}
        {/* Show single file */}
        {isString(value) ? <FileItem filename={value} onRemove={onRemove} /> : null}
        {/* Show message if no files uploaded */}
        {isNull(value) ? <p css={$noFiles}>{NO_FILES_UPLOADED}</p> : null}
      </div>
      <Dropzone
        accept={config.accept}
        disabled={config.isDisabled}
        multiple={multiple}
        onDrop={uploadFiles}
      >
        {({ getRootProps, getInputProps, isDragActive }) => (
          <div css={$dropZone(isDragActive, variant)} {...getRootProps()}>
            <input {...getInputProps()} aria-label={name} />
            <Icon icon={faFileUpload} size={32} />
            <p>{isDragActive ? 'Drop files here' : SELECT_FILES}</p>
          </div>
        )}
      </Dropzone>
    </div>
  );
}
File.propTypes = {
  /** Field name, which is also the property the data will be saved to */
  name: PropTypes.string,
  /** Field value, from the form-level state */
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array
  ]),
  /** Full configuration object */
  config: PropTypes.object,
  /** Function that updates the form state and persists data */
  onChange: PropTypes.func,
  variant: PropTypes.string,
  formData: PropTypes.object
};

// Add 'group' to the display name, to prevent wrapping the whole input in <label>.
File.displayName = 'FileUploadGroup';
