/* eslint-disable camelcase */
import { css } from '@emotion/core';

export const $revisionContext = () => css`
  margin-right: 30px;
  /* Make sure the heading always displays in a single line. */
  min-width: 185px;
`;

export const $header = () => css`
  margin-top: 0;
`;
