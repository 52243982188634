import { isUndefined, findKey, isObject, isEmpty, isEqual } from 'lodash-es';

import * as schemas from '@client/common/schema';
import rawSchema from '@client/schema';
import { NON_EDITABLE_TAG_TYPES } from '@client/utils/constants';

import { enumCase } from './cases';

/**
 * Determine whether we should limit the search to a specific content type.
 * @param {object} schema
 * @param {string} type
 * @param {object} options
 * @returns {object|null}
 */
function getContentType (schema, type, options) {
  // isContentInterface is ONLY set on Content itself, not on any content types
  // that implement the Content interface. When searching the interface directly,
  // we search all content types unless specific types are specified.
  if (schema.isContentInterface && !isEmpty(options.includeContentTypes)) {
    // We've specified the content types we want to include! Add them to
    // the filter.
    return { contentType: { eq: options.includeContentTypes } };
  } else if (schema.isContentInterface && isEqual(options.includeContentTypes, [])) {
    // We've explicitly specified that we don't want to include any content types.
    // This happens when unselecting everything on the Inventory Management App.
    // This query will return no results.
    return { contentType: { exists: false } };
  } else if (schema.isContentInterface) {
    // This is a content interface but we haven't specified content types,
    // so include all content types.
    return null;
  }

  // If we're searching for a specific content type, include the contentType filter.
  return { contentType: { eq: enumCase(type) } };
}

/**
 * Determine whether we should limit the search to a specific content provider.
 * @param {object} options with { includeContentProvider: providerUid }
 * @returns {object|null}
 */
function getContentProvider (options) {
  // If we haven't specified a content provider, include all content providers.
  if (!options?.includeContentProvider?.uid) return null;
  // Otherwise, include only the specified content provider.
  return { contentProvider: { uid_in: options.includeContentProvider.uid } };
}

/**
 * Determine whether we should include or exclude archived content from the search.
 * @param {object} options
 * @returns {object}
 */
export function getVisibility (options) {
  // Default content searches to NON-archived items (draft and published).
  const isArchived = options.isArchived || false;

  return isArchived ? { visibility: { eq: -1 } } : { not: { visibility: { eq: -1 } } };
}

/**
 * Determine whether we should limit our search to only root bundles, or allow
 * searching through all child bundles.
 * @param {string} type
 * @param {object} options
 * @returns {object|null}
 */
function getRootBundles (type, options) {
  const parentsFilter = { parents: { eq: 0 } };

  if (type === 'Bundle' && !options.includeChildBundles) {
    // If we're searching for bundles and don't explicitly ask for children,
    // limit the search to root bundles.
    return parentsFilter;
  } else if (type === 'Content') {
    // If we're searching all content types, always limit the search to
    // root bundles.
    return parentsFilter;
  } else {
    // In all other situations, don't filter the search.
    return null;
  }
}

/**
 * If we're searching for content, determine the contentType, contentProvider, and visibility.
 * If we're specifically searching for bundles, determine if we should allow
 * child bundles.
 * @param {object} schema
 * @param {object} options
 */
function getContentFilters (schema, options) {
  const isContent = schema.isContent;

  // If we're not searching for content, return early.
  if (!isContent) {
    return;
  }

  // Determine the type by checking typenames against the exported
  // client-side schemas.
  const type = findKey(schemas, (schemaConfig) => {
    return isObject(schemaConfig) && schemaConfig.typename === schema.typename;
  });
  // Determine the contentType filter.
  const contentType = getContentType(schema, type, options);
  // Determine the contentProvider filter.
  const contentProvider = getContentProvider(options);
  // Determine the visibility filter.
  const visibility = getVisibility(options);
  // Determine if we should limit the search to root bundles.
  const parents = getRootBundles(type, options);

  return {
    ...contentType,
    ...contentProvider,
    ...visibility,
    ...parents
  };
}

/**
 * If we're searching for tags on Tags homepage, filter out all Certica tags.
 * @param {object} schema
 * @param {object} options
 * @returns {object|undefined} tag filter if necessary
 */
function getTagFilters (schema, options) {
  const notFilteringTags = options.includeNonEditableTagTypes || options.uid;
  const notSearchingTags = schema.typename !== 'Tag';

  // If we're not on Tags homepage, return early so we show ALL tags.
  if (notFilteringTags || notSearchingTags) {
    return;
  }

  const editableTagTypes = rawSchema.enums.TagType.filter(
    (tagType) => !NON_EDITABLE_TAG_TYPES.includes(tagType)
  );
  const tagsFilter = {
    or:
    editableTagTypes.map((tagType) => ({ tagType: { eq: tagType } }))
  };

  return tagsFilter;
}

/**
 * Determine which search function to use, and call it.
 * @param {object} schema
 * @param {string} query
 * @param {object} [options]
 */
export default function search (schema, query, options = {}) {
  // Default to quick search unless otherwise specified.
  const isQuickSearch = isUndefined(options.isQuickSearch) ? true : options.isQuickSearch;
  // Fall back to using the regular filter if no quickFilter exists.
  const filter = schema.filter;
  const quickFilter = schema.quickFilter || filter;
  // Determine the type of filter to use.
  const searchFn = isQuickSearch ? quickFilter : filter;
  const shouldCallSearchFn = !!(query || options.forceFilter) && searchFn;
  const filters = {
    // Only call the filter() or quickFilter() if we're searching with a
    // query string or forceFilter is set. Otherwise, return the contentFilters
    // and any passed-in variables.
    ...getContentFilters(schema, options),
    ...shouldCallSearchFn && searchFn(query, options),
    ...getTagFilters(schema, options)
  };
  const hasFilters = Object.keys(filters).length !== 0;

  return {
    variables: {
      ...options.variables,
      ...(hasFilters && { filter: { ...filters } })
    }
  };
}
