/* eslint-disable camelcase */
import { css } from '@emotion/core';

export const $revisionView = (valuesExist) => ({ colors }) => css`
  border-top: 1px solid ${colors.ui.greyLight[100]};
  padding-top: 40px;
  height: 100%;
  margin-top: 40px;
  text-align: ${valuesExist ? 'inherit' : 'center'};
  img {
    max-width: 100%;
  }
`;

export const $viewField = ({ typography, colors }) => [
  typography.family.georgia,
  typography.size.M,
  css`
  margin: 20px 0;

  del {
    text-decoration: ${colors.ui.red[500]} line-through solid 1px;
    background-color: ${colors.ui.red[50]};
  }

  ins {
    text-decoration: ${colors.ui.green[500]} underline solid 2px;
    background-color: ${colors.ui.green[50]};
  }

  /* Make sure <code> doesn't overflow */
  & code {
    white-space: normal !important;
  }

  figcaption{
    color: ${colors.ui.grey[500]};
  }
`];

export const $emptyViewState = () => css`
  margin-top: 20vh;
`;
